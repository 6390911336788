import { useState, useEffect } from "react";
import JWT from "../contents/JWT";
import { useNavigate } from "react-router-dom";

export function useDisplayDiagnosticProfile(
  profileEvent,
  profileType,
  profileTotalGR,
  profileTotalRC,
  profileTotalLC,
  profileTotalALL,
  runDisplayDiagnosticProfile,
  checkGrDashboardDP,
  checkRcDashboardDP,
  checkLcDashboardDP
) {
  //** Global State Context Calls, links to context/AssessmentProvider.js */
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setLatestError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (profileEvent !== null && runDisplayDiagnosticProfile !== false) {
      setIsLoading(true);
      if (profileType === "grammar") {
        let grID = null;
        grID = profileEvent.target.parentElement.id;
        JWT.fetchData()
          .then((result) => {
            const grTotal = profileTotalGR;
            const grDashboardDP = checkGrDashboardDP;
            setData({
              navigateTo: "/profileGrammar",
              grID: grID,
              grTotal: grTotal,
              grDashboardDP: grDashboardDP,
            });
          })
          .catch((error) => {
            setLatestError(error);
          })
          .finally(() => {
            setIsLoading(false);
          });
      } else if (profileType === "reading") {
        let rcID = null;
        rcID = profileEvent.target.parentElement.id;
        JWT.fetchData()
          .then((result) => {
            const rcTotal = profileTotalRC;
            const rcDashboardDP = checkRcDashboardDP;
            setData({
              navigateTo: "/profile",
              rcID: rcID,
              rcTotal: rcTotal,
              rcDashboardDP: rcDashboardDP,
            });
          })
          .catch((error) => {
            setLatestError(error);
          })
          .finally(() => {
            setIsLoading(false);
          });
      } else if (profileType === "listening") {
        let lcID = null;
        lcID = profileEvent.target.parentElement.id;
        JWT.fetchData()
          .then((result) => {
            const lcTotal = profileTotalLC;
            const lcDashboardDP = checkLcDashboardDP;
            setData({
              navigateTo: "/profile",
              lcID: lcID,
              lcTotal: lcTotal,
              lcDashboardDP: lcDashboardDP,
            });
          })
          .catch((error) => {
            setLatestError(error);
          })
          .finally(() => {
            setIsLoading(false);
          });
      }
    }
  }, [
    profileEvent,
    profileType,
    runDisplayDiagnosticProfile,
    isLoading,
    navigate,
    profileTotalGR,
    profileTotalRC,
    profileTotalLC,
    profileTotalALL,
  ]);

  return [data, isLoading, error];
}
