import React from "react";
import Passage from "../test/Passage";
import AnswerField from "../test/AnswerField";
import Next from "../../../../../components/testlet/Next";

export default function Test(props) {
  const renderAnswerField = (options, index) => {
    const item = options._id;
    return (
      <AnswerField
        key={index}
        testitem={item}
        optionPassage={options.questionTexts}
        optionChoice={options.questions}
        optionNumber={index}
        answerFieldsID={options.answerFields[0]._id}
        instruction={props.instruction}
        totalNumber={props.assessment}
        actID={props.actID}
        dir={props.dir}
        clickCounter={props.clickCounter}
        onAnswerSelected={props.onAnswerSelected}
        onAnswerSelectedMod={props.onAnswerSelectedMod}
      />
    );
  };

  return (
    <div className="templateInner GMCA">
      <Passage
        passage={props.passage}
        dir={props.dir}
        answerLength={props.answerLength}
      />
      {props.assessment.map(renderAnswerField)}
      <Next
        onEvaluation={props.onEvaluation}
        score={props.score}
        hint={props.hint}
        clickCounter={props.clickCounter}
        navLen={props.navLen}
        navCrrtItem={props.navCrrtItem}
      />
    </div>
  );
}
