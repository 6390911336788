/**
 *
 *  ReactJS reducer assessmentStateReducer
 *  handles global state for the purpose of
 *  computing the next module in an Assessment.
 *
 *  initialAssessmentState & assessmentStateReducer
 *  work together to form the reactjs useReducer hook
 *
 *  then, in AssessmentProvider, useContext AssessmentProvider
 *  & AssessmentContext joins with the useReducer to
 *  create global access to the state and other functionality
 *
 */

//initial state of a new ODA assessment
export const initialAssessmentState = {
  assessmentSkillFloor: -999,
  assessmentNextModule: null,
  assessmentModuleRef: null,
  assessmentTestComplete: false,
  assessmentSessionId: null,
  assessmentUserEmail: null,
  assessmentUserId: null,
  assessmentUserToken: null,
  assessmentLanguage: null, 
  assessmentScoreMatrix: {
    L1: [-999, -999],
    L1P: [-999, -999],
    L2: [-999, -999],
    L2P: [-999, -999],
    L3: [-999, -999]
  },
  assessmentTestletMatrix: {
    L1: [null, null, null],
    L1P: [null, null, null],
    L2: [null, null, null],
    L2P: [null, null, null],
    L3: [null, null, null]
  },
  assessmentModuleData: [],
  assessmentNLPMatrix: {},
  assessmentFontSize: 16,
  assessmentModality: null
};

//reducer function to handle dispatch calls to modify ODA assessment state
export const assessmentStateReducer = (state, action) => {
  switch (action.type) {
    case "NEW_ASSESSMENT_RESET":
      return {
        ...state,
        assessmentSkillFloor: -999,
        assessmentNextModule: null,
        assessmentTestComplete: false,
        assessmentSessionId: null,
        assessmentScoreMatrix: {
          L1: [-999, -999],
          L1P: [-999, -999],
          L2: [-999, -999],
          L2P: [-999, -999],
          L3: [-999, -999]
        },
        assessmentTestletMatrix: {
          L1: [null, null, null],
          L1P: [null, null, null],
          L2: [null, null, null],
          L2P: [null, null, null],
          L3: [null, null, null]
        },
        assessmentModuleData: [],
        assessmentModulesRCLC: [],
        assessmentNLPMatrix: {},
        assessmentLanguage: null,
        assessmentModality: null
      };
    case "UPDATE_MODALITY":
      return {
        ...state,
        assessmentModality: action.payload
      }
    case "UPDATE_TOKEN":
      return {
        ...state,
        assessmentUserToken: action.payload
      };
    case "UPDATE_SESSION_ID":
      return {
        ...state,
        assessmentSessionId: action.payload
      };
    case "UPDATE_USER_ID":
      return {
        ...state,
        assessmentUserId: action.payload
      };
    case "UPDATE_USER_EMAIL":
      return {
        ...state,
        assessmentUserEmail: action.payload
      };
    case "UPDATE_SKILL_FLOOR":
      return {
        ...state,
        assessmentSkillFloor: action.payload
      };
    case "UPDATE_NEXT_MODULE":
      return {
        ...state,
        assessmentNextModule: action.payload
      };
    case "UPDATE_MODULE_REF":
      return {
        ...state,
        assessmentModuleRef: action.payload
      };
    case "UPDATE_TEST_COMPLETE":
      return {
        ...state,
        assessmentTestComplete: action.payload
      };
    case "UPDATE_SCORE_MATRIX":
      return {
        ...state,
        assessmentScoreMatrix: action.payload
      };
    case "UPDATE_TESTLET_MATRIX":
      return {
        ...state,
        assessmentTestletMatrix: action.payload
      };
    case "UPDATE_RCLC_MODULES":
      return {
        ...state,
        assessmentModulesRCLC: action.payload
      };
    case "UPDATE_FROM_INCOMPLETE_SESSION":
      return {
        ...state,
        assessmentSkillFloor: action.payload.assessmentSkillFloor,
        assessmentModuleRef: action.payload.assessmentModuleRef,        
        assessmentScoreMatrix: action.payload.assessmentScoreMatrix,
        assessmentTestletMatrix: action.payload.assessmentTestletMatrix,
        assessmentModuleData: action.payload.assessmentModuleData,
        assessmentNLPMatrix: action.payload.assessmentNLPMatrix,
        assessmentSessionId: action.payload.assessmentSessionId,  
        assessmentLanguage: action.payload.assessmentLanguage,
        assessmentModulesRCLC: action.payload.assessmentModulesRCLC 
      };
    case "UPDATE_LANGUAGE":
      return {
        ...state,
        assessmentLanguage: action.payload
      };
    case "RESET_MODULE_CONTENT_SCORE_DATA":
      return {
        ...state,
        assessmentModuleData: []
      };
    case "UPDATE_FONT_SIZE":
      return {
        ...state,
        assessmentFontSize: action.payload
      };
    case "APPEND_MODULE_CONTENT_SCORE_DATA":
      let updateModuleData = state.assessmentModuleData;
      //let identifier = action.payload.identifier.split("_");
      //let moduleName = identifier[0] + "_" + identifier[1];
      //let moduleQuestion = identifier[2];
      if (action.payload.data.isPassed === false) {
        updateModuleData.push(0);
      } else if (action.payload.data.isPassed === true) {
        updateModuleData.push(1);
      }
      return {
        ...state,
        assessmentModuleData: updateModuleData
      };
    case "APPEND_NLP_SCORES":
      const updateNLP = Object.assign({}, state.assessmentNLPMatrix);
      //console.log(Object.values(updateNLP).indexOf(action.payload.identifier));
      if (Object.values(updateNLP).indexOf(action.payload.identifier) !== -1) {
        updateNLP[action.payload.identifier].push(action.payload.data);
      } else {
        updateNLP[action.payload.identifier] = action.payload.data;
      }
      return {
        ...state,
        assessmentNLPMatrix: updateNLP
      };
    default:
      return state;
  }
};
