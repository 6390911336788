import axios from "axios";

/** 
 * @component JWT
 * @description handles json web token (JWT) authentication,
 *  fetchAll grabs all data from token as an object 
 *  fetchData grabs the id from token
 * @todo convert to functional component; encrypt sensitive data
**/

class JWT {
  constructor() {
    this.state = {};
  }
  
  fetchData = async () => {
    if (localStorage.getItem("token") !== null) {
      const accessToken = localStorage
        .getItem("token")
        .slice(1, -1)
        .split(":")[1]
        .slice(1, -1);

      const authAxios = axios.create({
        headers: {
          "Content-Type": "application/json",
          "X-Auth-Token": accessToken,
        },
      });
      try {
        const result = await authAxios.get("/api/auth");
        return result.data._id;
      } catch (err) {
        // console.log("failed");
      }
    } else {
      // console.log("no token");
    }
  };

  fetchEmail = async () => {
    if (localStorage.getItem("token") !== null) {      
      const accessToken = localStorage
        .getItem("token")
        .slice(1, -1)
        .split(":")[1]
        .slice(1, -1);
    
      const authAxios = axios.create({
        headers: {
          "Content-Type": "application/json",
          "X-Auth-Token": accessToken,
        },
      });
      try {
        const result = await authAxios.get("/api/auth");
        return result.data.email;
      } catch (err) {
        // console.log("failed");
      }
    } else {
      //console.log("no token");
    }
  };

  fetchCreationDate = async () => {
    if (localStorage.getItem("token") !== null) {
      const accessToken = localStorage
        .getItem("token")
        .slice(1, -1)
        .split(":")[1]
        .slice(1, -1);

      const authAxios = axios.create({
        headers: {
          "Content-Type": "application/json",
          "X-Auth-Token": accessToken,
        },
      });
      try {
        const result = await authAxios.get("/api/auth");       
        return result.data.createdAt;
      } catch (err) {
        //console.log("failed");
      }
    } else {
      //console.log("no token");
    }
  };

  fetchUpdatedDate = async () => {
    if (localStorage.getItem("token") !== null) {
      const accessToken = localStorage
        .getItem("token")
        .slice(1, -1)
        .split(":")[1]
        .slice(1, -1);

      const authAxios = axios.create({
        headers: {
          "Content-Type": "application/json",
          "X-Auth-Token": accessToken,
        },
      });
      try {
        const result = await authAxios.get("/api/auth");       
        return result.data.updatedAt;
      } catch (err) {
        //console.log("failed");
      }
    } else {
      //console.log("no token");
    }
  };

  //grab ALL data; may need to encrypt this to obscure account details
  fetchAll = async () => {
    if (localStorage.getItem("token") !== null) {
      const accessToken = localStorage
        .getItem("token")
        .slice(1, -1)
        .split(":")[1]
        .slice(1, -1);

      const authAxios = axios.create({
        headers: {
          "Content-Type": "application/json",
          "X-Auth-Token": accessToken,
        },
      });
      try {
        const result = await authAxios.get("/api/auth");       
        return result.data;
      } catch (err) {
        //console.log("failed");
      }
    } else {
      //console.log("no token");
    }
  };

}

export default new JWT();
