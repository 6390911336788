// ** All code for draggable functions should go here **//
// ** replaces jQuery formerly in fbPopups.js **
import { offsetCoords, offset } from "./genericFunctions";

//draggable feedback modal
export default function draggableUI() {
  const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
  const vh = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0);

  function handle_mousedown(e) {
    window.dragging = {};
    window.dragging.pageX0 = e.pageX;
    window.dragging.pageY0 = e.pageY;
    window.dragging.dragElement = e.target.parentElement;
    window.dragging.init = window.dragging.dragElement.getBoundingClientRect();
    window.dragging.offset0 = { top: window.dragging.init.top, left: window.dragging.init.left };

    function handle_dragging(e) {
      var left = window.dragging.offset0.left + (e.pageX - window.dragging.pageX0);
      var top = window.dragging.offset0.top + (e.pageY - window.dragging.pageY0);
      if (window.dragging.dragElement.hasAttribute("id")) {
        offsetCoords("#" + window.dragging.dragElement.attributes.id.value, left + "px", top + "px");
      } else if (window.dragging.dragElement.parentElement.hasAttribute("id")) {
        offsetCoords(
          "#" + window.dragging.dragElement.parentElement.attributes.id.value,
          left + "px",
          top + "px",
          true
        );
      }
    }

    function handle_mouseup() {
      document.body.removeEventListener("mousemove", handle_dragging);
      document.body.removeEventListener("mouseup", handle_mouseup);
    }

    document.body.addEventListener("mouseup", handle_mouseup);
    document.body.addEventListener("mousemove", handle_dragging);
  }

  let selEl = document.querySelectorAll(".feedbackHeader, #helpModalHeader");
  selEl.forEach((el) => {
    el.addEventListener("mousedown", (e) => {
      handle_mousedown(e);
    });
  });

  //now do it again for touch
  function handle_touchstart(e) {
    e.preventDefault();
    window.touching = {};
    window.touching.pageX0 = e.touches[0].pageX;
    window.touching.pageY0 = e.touches[0].pageY;
    window.touching.dragElement = e.target.parentElement;
    window.touching.init = window.touching.dragElement.getBoundingClientRect();
    window.touching.offset0 = { top: window.touching.init.top, left: window.touching.init.left };

    function handle_touching(e) {
      var left = window.touching.offset0.left + (e.touches[0].pageX - window.touching.pageX0);
      var top = window.touching.offset0.top + (e.touches[0].pageY - window.touching.pageY0);
      if (window.touching.dragElement.hasAttribute("id")) {
        offsetCoords("#" + window.touching.dragElement.attributes.id.value, left + "px", top + "px");
      } else if (window.touching.dragElement.parentElement.hasAttribute("id")) {
        offsetCoords(
          "#" + window.touching.dragElement.parentElement.attributes.id.value,
          left + "px",
          top + "px",
          true
        );
      }
    }

    function handle_touchend() {
      document.body.removeEventListener("touchmove", handle_touching);
      document.body.removeEventListener("touchend", handle_touchend);
    }

    document.body.addEventListener("touchend", handle_touchend);
    document.body.addEventListener("touchmove", handle_touching);
  }

  selEl = document.querySelectorAll(".feedbackHeader, #helpModalHeader");
  selEl.forEach((el) => {
    el.addEventListener("touchstart", handle_touchstart);
  });
}
