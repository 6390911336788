import React from "react";
import { useNavigate } from "react-router-dom";
import "../../css/index.css";
import HeaderNull from "./HeaderNull";

/**
 * @component NotFoundPage
 * @description This function will create a static 404 page that will be linked
 * to anytime a non routed page or resource is requested
 * @params {object} props
 *
 */

const NotFoundPage = (props) => {
  //console.log(props);
  const navigate = useNavigate();
  let lostPath = window.location.pathname;

  const handleReturnHome = () => {
    navigate("/");
  };

  return lostPath === "/about" ||
    lostPath === "/home" ||
    lostPath === "/contact" ||
    lostPath === "/testdescriptions" ||
    lostPath === "/proficiency" ||
    lostPath === "/faqs" ||
    lostPath === "/policy" ? (
    <div></div>
  ) : (
    <React.Fragment>
      <HeaderNull />

      <div style={{ textAlign: "center" }}>
        <h1>Error 404</h1>
        <h2>Requested page not available.</h2>
        <br />
        <p>
          Unfortunately, the page you have requested is not available at this time.
          <br />
          <br /> If you believe this message has been received in error, please <a href="/contact">contact</a> the ODA
          support team.
        </p>
        <br />
        <button onClick={handleReturnHome}>Return Home</button>
      </div>
    </React.Fragment>
  );
};

export default NotFoundPage;
