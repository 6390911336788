import React from "react";
import dompurify from "dompurify";

const UserAnswerSearchPanelDisplay = (props) => {
  const sanitizer = dompurify.sanitize;
  const data = Array.isArray(props.content) ? [...props.content] : []; // Ensure data is always an array

  console.log(JSON.stringify(props.content));

  const searchWord = props.searchWord;
  const firstCheck = props.firstCheck;

  if (data.length === 0 && firstCheck) {
    return <h3>No such a word found in the database.</h3>;
  }

  const Tracking = (sessionId, modality) => {
    const items = [sessionId, modality];
    localStorage.setItem("trackItems", JSON.stringify(items));

    const url = "/adminreport/useranswer/itemtrack";
    window.open(url, "_blank");
  };

  data.forEach((dataObject) => {
    if (Array.isArray(dataObject.items)) {
      dataObject.items.sort((a, b) => (a.itemId > b.itemId ? 1 : -1));
    }
  });

  data.sort((a, b) => {
    if (a._id.includes("No matches found")) return 1;
    if (b._id.includes("No matches found")) return -1;
    return a._id.localeCompare(b._id);
  });

  return (
    <div>
      {data.map((dataObject, index) =>
        dataObject._id.includes("No matches found") ? (
          <div
            style={{
              color: "red",
              textAlign: "left",
            }}
            key={index}
          >
            {dataObject._id}
            <br />
            <br />
          </div>
        ) : (
          <div key={index}>
            <h2>{dataObject._id}</h2>
            <div className="table-container">
              <div className="table-row table-header">
                <div className="table-column space-2 align-text">Item ID</div>
                <div className="table-column space-4 align-text">
                  User Answer
                </div>
                <div className="table-column space-3 align-text">User ID</div>
                <div className="table-column space-1">P/F</div>
                <div className="table-column space-1">User Scr</div>
                <div className="table-column space-1">Pass scr</div>
                <div className="table-column space-1">Date</div>
                <div className="table-column"></div>
              </div>
              {Array.isArray(dataObject.items) &&
                dataObject.items.filter(Boolean).map((item, itemIndex) => (
                  <div className="table-row" key={itemIndex}>
                    <div className="table-column space-2 align-text">
                      {item.itemId.replace(/_/g, "-")}
                    </div>
                    <div className="table-column space-4 align-text">
                      <div
                        dangerouslySetInnerHTML={{
                          __html: sanitizer(
                            searchWord
                              ? decodeURIComponent(
                                  item.userAnswer.replace(
                                    new RegExp(searchWord, "gi"),
                                    `<span style="color: red">$&</span>`
                                  )
                                )
                              : item.userAnswer
                          ),
                        }}
                      />
                    </div>
                    <div className="table-column space-3 align-text">
                      {item.userId.replace(/(.{10})/g, "$1-")}
                    </div>
                    <div className="table-column space-1">
                      {item.isPassed ? "P" : "F"}
                    </div>
                    {/* <div className="table-column space-1">{item.userScore}</div> */}
                    <div className="table-column space-1">
                      {" "}
                      {parseInt(item.userScore, 10)}
                    </div>

                    <div className="table-column space-1">
                      {item.passingScore}
                    </div>
                    <div className="table-column space-1">
                      {item.date.substring(0, 10)}
                    </div>
                    <div
                      className="column"
                      style={{ cursor: "pointer", paddingTop: "8px" }}
                      onClick={() =>
                        Tracking(item.sessionId, dataObject.modality)
                      }
                    >
                      Record
                    </div>
                  </div>
                ))}
            </div>
          </div>
        )
      )}
    </div>
  );
};

export default UserAnswerSearchPanelDisplay;
