import React, { useRef, useState } from "react";
import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";
import dompurify from "dompurify";
import { IoPlay } from "react-icons/io5";
import { IoPause } from "react-icons/io5";
import { GiSpeaker } from "react-icons/gi";
import { IoIosRewind } from "react-icons/io";
import { IoMdFastforward } from "react-icons/io";

export default function AnswerOption(props) {
  const sanitizer = dompurify.sanitize;
  const LO = props.LO;
  const lang = props.lang;
  const actID = props.actID + "_0";
  const audioOption = props.question;
  const len = audioOption.length;
  const audioPlayerAns = useRef(null);

  const numberLetter = ["One", "Two", "Three", "Four", "Five"];

  let mp3 = [];
  for (let i = 0; i < len - 1; i++) {
    mp3.push("/audio/" + lang + "/" + LO + "/" + audioOption[i].value.replace(/\s/g, "_"));
  }

  const createAudioRadioButton = () => {
    let radioButton = [];
    for (let i = 0; i < len; i++) {
      if (i !== len - 1)
        radioButton.push(
          <div className="answerBlock" key={i}>
            <label className="answer" key={i}>
              {/* <span className="inlinePlay paused" file={mp3[i]}>
                Audio {numberLetter[i]}
                <span className="modifiedFlag" />
              </span> */}
              <input key={i} type="radio" name="radio" id={actID} value={i} onChange={props.onAnswerSelected} />
              <span className="radio" />
            </label>
            <div className="audioQ forceInlineBlock">
              <AudioPlayer
                autoPlay={false}
                autoPlayAfterSrcChange={false}
                // header={props.toggleState ? "(modified)" : null}
                header={"Audio " + numberLetter[i]} 
                footer={null}
                hasDefaultKeyBindings={false}
                customAdditionalControls={[]}
                customVolumeControls={[]}
                ref={audioPlayerAns.inline0}
                src={mp3[i]}
                showJumpControls={false}
                showDownloadProgress={false}
                showFilledProgress={false}
                customIcons={{
                  play: <IoPlay />,
                  pause: <IoPause />,
                  rewind: <IoIosRewind />,
                  forward: <IoMdFastforward />,
                  volume: <GiSpeaker />,
                }}
                // onPlay={(e) => console.log("onPlay")}
              />
            </div>
          </div>
        );
      else
        radioButton.push(
          <div className="answerBlock" key={i}>
            <label className="answer" key={i}>
              {" "}
              {audioOption[i].value}
              <input key={i} type="radio" name="radio" id={actID} value={i} onChange={props.onAnswerSelected} />
              <span className="radio" />
            </label>
          </div>
        );
    }
    return radioButton;
  };
  return createAudioRadioButton();
}
