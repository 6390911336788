import "../../css/index.css";
/**
 * @component RegisterAccountModal
 * @description
 * @param {object} props
 **/

export default function AboutModal(props) {
  const afterCreation = (e) => {
    props.handleAboutModalState(e);
  };

  return (
    <div className="modalDialog" id="RegisterAccountModal">
      <div className="RegisterAccountModal">
        <div
          className="feedbackHeader"
          id="modalHeader"
          style={{ fontSize: "16px" }}
        >
          About
        </div>
        <h5
          style={{
            fontSize: "16px",
            fontWeight: "normal",
            overflow: "auto",
          }}
        >
          ODA is a tool created by the Defense Language Institute Foreign
          Language Center (DLIFLC) to aid foreign language learners in assessing
          and improving their language skills.
          <br />
          <br />
          The ODA can help you figure out where you stand in terms of your
          language skills and what you need to do to improve.
          <br />
          <br />
          Once you take the assessment, the results are used to create a
          personalized "Diagnostic Profile" that can guide you on your language
          learning journey.
          <br />
          <br />
          The feedback you receive is tailored to your specific strengths and
          weaknesses and is based on a variety of different levels, topics,
          tasks, and specific linguistic features. The assessment texts used in
          ODA are chosen or adapted from authentic sources that align with
          Interagency Language Roundtable (ILR) level descriptions.
        </h5>

        <button
          className="goLogin outlined"
          style={{
            fontSize: "14px",
            top: "6px",
            padding: "3px 7px",
          }}
          onClick={(e) => afterCreation(e)}
        >
          Close
        </button>
        <br />
        <br />
      </div>
    </div>
  );
}
