import React from "react";
import PassageAnswerOption from "../test/PassageAnswerOption";
import Instruction from "../test/Instruction";
import Next from "../../../../../components/testlet/Next";

export default function Test(props) {
  const renderAssessment = (options, index) => {
    const number = options.multipleChoiceAns.map((option, index) => {
      return index;
    });

    const items = options.questions.map((option, index) => {
      return option;
    });

    const lists = options.multipleChoiceAns.map((option, index) => {
      return option;
    });

    const answerField = options.answerFields.map((option, index) => {
      return option._id;
    });

    return (
      <PassageAnswerOption
        key={index}
        optionContent={items}
        optionList={lists}
        optionNumber={number}
        answerFieldsID={answerField}
        totalNumber={props.assessment}
        testitem={props.assessment[0]._id}
        actID={props.actID}
        dir={props.dir}
        clickCounter={props.clickCounter}
        onAnswerSelected={props.onAnswerSelected}
        onAnswerSelectedMod={props.onAnswerSelectedMod}
      />
    );
  };

  return (
    <div className="templateInner GMPG">
      <Instruction instruction={props.instruction} />
      <div>{props.assessment.map(renderAssessment)}</div>
      <Next
        onEvaluation={props.onEvaluation}
        score={props.score}
        hint={props.hint}
        clickCounter={props.clickCounter}
        navLen={props.navLen}
        navCrrtItem={props.navCrrtItem}
      />
    </div>
  );
}
