import React, { useEffect, useState } from "react";
import AdminFooter from "./adminComponents/AdminFooter";

const apis = [
  // RC Session APIs
  {
    name: "Get Reading Session",
    url: "/api/sessionRC/",
    params: [{ name: "id", placeholder: "sessionId" }],
    method: "GET",
    noQueryStr: true,
  },
  {
    name: "Delete Reading Session",
    url: "/api/sessionRC/",
    params: [{ name: "id", placeholder: "sessionId" }],
    method: "PURGE",
    noQueryStr: true,
  },
  {
    name: "Get Reading Session by User ID",
    url: "/api/sessionRC",
    params: [
      { name: "user._id", placeholder: "Enter userId in quotes" },
      { name: "endtime", default: "null", placeholder: "null" },
      {
        name: "sort",
        default: '{"starttime": -1}',
        placeholder: '{"starttime": -1}',
      },
    ],
    method: "GET",
    noQueryStr: false,
  },
  // LC Session APIs
  {
    name: "Get Listening Session",
    url: "/api/sessionLC/",
    params: [{ name: "id", placeholder: "sessionId" }],
    method: "GET",
    noQueryStr: true,
  },
  {
    name: "Delete Listening Session",
    url: "/api/sessionLC/",
    params: [{ name: "id", placeholder: "sessionId" }],
    method: "PURGE",
    noQueryStr: true,
  },
  {
    name: "Get Listening Session by User ID",
    url: "/api/sessionLC",
    params: [
      { name: "user._id", placeholder: "Enter userId in quotes" },
      { name: "endtime", default: "null", placeholder: "null" },
      {
        name: "sort",
        default: '{"starttime": -1}',
        placeholder: '{"starttime": -1}',
      },
    ],
    method: "GET",
    noQueryStr: false,
  },
  // GR Session APIs
  {
    name: "Get Grammar Session",
    url: "/api/sessionGR/",
    params: [{ name: "id", placeholder: "sessionId" }],
    method: "GET",
    noQueryStr: true,
  },
  {
    name: "Delete Grammar Session",
    url: "/api/sessionGR/",
    params: [{ name: "id", placeholder: "sessionId" }],
    method: "PURGE",
    noQueryStr: true,
  },
  {
    name: "Get Grammar Session by User ID",
    url: "/api/sessionGR",
    params: [
      { name: "user._id", placeholder: "Enter userId in quotes" },
      { name: "endtime", default: "null", placeholder: "null" },
      {
        name: "sort",
        default: '{"starttime": -1}',
        placeholder: '{"starttime": -1}',
      },
    ],
    method: "GET",
    noQueryStr: false,
  },
  // User APIs
  {
    name: "Get User by Id",
    url: "/api/user/",
    params: [{ name: "id", placeholder: "userId" }],
    method: "GET",
    noQueryStr: true,
  },
  {
    name: "Get User by Email",
    url: "/api/user/email/",
    params: [{ name: "id", placeholder: "user email" }],
    method: "GET",
    noQueryStr: true,
  },
  {
    name: "Change User Email",
    url: "/api/user/",
    params: [{name: "id", placeholder: "userId"}, {name: "email", placeholder: "New email"}],
    method: "PUT",
    noQueryStr: true,
  },
  {
    name: "Deactivate User",
    url: "/api/user/",
    params: [{ name: "id", placeholder: "userId" }],
    method: "DELETE",
    noQueryStr: true,
  },
  {
    name: "Delete User",
    url: "/api/user/",
    params: [{ name: "id", placeholder: "userId" }],
    method: "PURGE",
    noQueryStr: true,
  },
  // Testlet APIs
  {
    name: "Deactivate RC Testlet",
    url: "/api/testletRC/",
    params: [{ name: "id", placeholder: "testletId" }],
    method: "DELETE",
    noQueryStr: true,
  },
  {
    name: "Deactivate LC Testlet",
    url: "/api/testletLC/",
    params: [{ name: "id", placeholder: "testletId" }],
    method: "DELETE",
    noQueryStr: true,
  },
  {
    name: "Deactivate GR Testlet",
    url: "/api/testletGR/",
    params: [{ name: "id", placeholder: "testletId" }],
    method: "DELETE",
    noQueryStr: true,
  },
];

const SysAdminSuite = () => {
  const [selectedApi, setSelectedApi] = useState(null);
  const [params, setParams] = useState({});
  const [response, setResponse] = useState(""); // new state variable for the response

  const handleApiChange = (event) => {
    const api = apis.find((api) => api.name === event.target.value);
    setSelectedApi(api);
    setParams({});
  };

  const handleParamChange = (param, value) => {
    if (param === "user._id") {
      value = `"${value}"`;
    }
    setParams((prevParams) => ({ ...prevParams, [param]: value }));
  };

  const handleSubmit = () => {
    if (
      selectedApi &&
      ["GET", "PURGE", "DELETE"].includes(selectedApi.method)
    ) {
      let url = selectedApi.url;
      if (selectedApi.noQueryStr) {
        url += params.id;
      } else {
        const queryString = Object.entries(params)
        .map(([key, value]) => `${key}=${value}`)
        .join("&");

        url += `?${queryString}`;
      }
      fetch(url, {
        method: selectedApi.method,
      })
        .then((response) => response.json())
        .then((data) => {
          setResponse(JSON.stringify(data, null, 2)); // update the response state variable
        })
        .catch((error) => console.error(error));
    } else if (selectedApi && ["POST", "PUT"].includes(selectedApi.method)) {
      if(selectedApi.noQueryStr){
        console.log(params);
        selectedApi.url += params.id;
      }
      fetch(selectedApi.url, {
        method: selectedApi.method,
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(params),
      })
        .then((response) => response.json())
        .then((data) => {
          setResponse(JSON.stringify(data, null, 2)); // update the response state variable
        })
        .catch((error) => console.error(error));
    }
  };

  return (
    <div>
      <div>
        API:{" "}
        <select onChange={handleApiChange}>
          <option>Select an API</option>
          <optgroup label="RC Session APIs">
            {apis
              .filter((api) => api.url.includes("sessionRC"))
              .map((api) => (
                <option key={api.name}>{api.name}</option>
              ))}
          </optgroup>
          <optgroup label="LC Session APIs">
            {apis
              .filter((api) => api.url.includes("sessionLC"))
              .map((api) => (
                <option key={api.name}>{api.name}</option>
              ))}
          </optgroup>
          <optgroup label="GR Session APIs">
            {apis
              .filter((api) => api.url.includes("sessionGR"))
              .map((api) => (
                <option key={api.name}>{api.name}</option>
              ))}
          </optgroup>
          <optgroup label="User APIs">
            {apis
              .filter((api) => api.url.includes("user"))
              .map((api) => (
                <option key={api.name}>{api.name}</option>
              ))}
          </optgroup>
          <optgroup label="Testlet APIs">
            {apis
              .filter((api) => api.url.includes("testlet"))
              .map((api) => (
                <option key={api.name}>{api.name}</option>
              ))}
          </optgroup>
        </select>
        {selectedApi &&
          selectedApi.params.map((param) => (
            <input
              key={`${selectedApi.name}-${param.name}`}
              placeholder={param.placeholder}
              onChange={(event) =>
                handleParamChange(param.name, event.target.value)
              }
              style={{ width: "300px" }}
            />
          ))}
        <button onClick={handleSubmit} style={{ marginBottom: "10px" }}>
          Send Request
        </button>
        <textarea
          value={response}
          readOnly
          style={{ width: "97%", height: "700px" }}
        />{" "}
        {/* display the response in a textarea */}
      </div>
      <AdminFooter />
    </div>
  );
};

export default SysAdminSuite;
