import React, { useEffect, useState } from "react";
import reading from "../../img/icons/reading_icon_material.svg";
import listening from "../../img/icons/listening_icon_material.svg";
import grammar from "../../img/icons/grammar_icon_material.svg";
import sharedFunctions from "../../functions/sharedFunctions";
import { GrResume } from "react-icons/gr";
import { IconContext } from "react-icons";

export default function Resume(props) {
  const [incompleteTest, setIncompleteTest] = useState(props.incompleteTest);
  const [selectedTab, setSelectedTab] = useState("GR");
  const [returnOutput, setReturnOutput] = useState(null);
  const incompleteTestLen = incompleteTest["ALL"].length;
  //** Reformatted to display new UI with tabs, functionality the same  **/
  let allLang = [];
  let langOccurence = [];
  let individualLang = [];
  let language = "";
  let skill = "";
  let level = "";
  let date = "";
  let readableDate = "";
  let id = "";

  useEffect(() => {
    sharedFunctions();
  }, []);

  useEffect(() => {
    if (incompleteTest["GR"].length > 0) {
      setSelectedTab("GR");
    } else if (incompleteTest["RC"].length > 0) {
      setSelectedTab("RC");
    } else if (incompleteTest["LC"].length > 0) {
      setSelectedTab("LC");
    }

    sharedFunctions();
  }, [incompleteTest["RC"].length, incompleteTest["GR"].length]);

  useEffect(() => {
    if (
      incompleteTest !== null &&
      incompleteTest !== undefined &&
      incompleteTest !== [] &&
      incompleteTest !== "" &&
      incompleteTest?.ALL !== undefined &&
      incompleteTest?.ALL[0]?.hasOwnProperty("language")
    ) {
      for (let i = 0; i < incompleteTestLen; i++) {
        let lang = incompleteTest["ALL"][i].language.displayName;
        allLang.push(lang);
        allLang.sort();
        let countLG = {};
        allLang.forEach(function (i) {
          countLG[i] = (countLG[i] || 0) + 1;
        });

        individualLang = Object.keys(countLG);
        langOccurence = Object.values(countLG);
      }

      let first = 0;
      let last = 0;
      let extractByLang = [];
      for (let i = 0; i < langOccurence.length; i++) {
        if (i === 0) {
          first = 0;
          last = langOccurence[i];
          extractByLang.push(allLang.slice(first, last));
        } else {
          first = first + langOccurence[i - 1];
          last = first + langOccurence[i];
          extractByLang.push(allLang.slice(first, last));
        }
      }
      setReturnOutput(createResumeItems());
    }
  }, [incompleteTest, selectedTab]);

  const levelNdate = (modality, num) => {
    const lang = individualLang[num];
    let levelDate = [];
    let pageNum = null;
    for (let i = 0; i < incompleteTest[modality].length; i++) {
      let generatedTestLen;
      if (lang === incompleteTest[modality][i].language.displayName) {
        if (incompleteTest[modality][i].modality === "grammar") {
          let endPage;
          let questionItemLen;
          if (
            incompleteTest[modality][i].modules[0].hasOwnProperty(
              "questionItemAns"
            )
          ) {
            questionItemLen =
              incompleteTest[modality][i].modules[0].questionItemAns.length;
            endPage =
              incompleteTest[modality][i].modules[0].questionItemAns[
                questionItemLen - 1
              ].testlet;
          } else {
            questionItemLen = 0;
            endPage = null;
          }
          if (
            incompleteTest[modality][i].modules[0].hasOwnProperty(
              "generatedTestlets"
            )
          ) {
            generatedTestLen =
              incompleteTest[modality][i].modules[0].generatedTestlets.length;
          } else {
            generatedTestLen = 0;
          }
          for (let j = 0; j < generatedTestLen; j++) {
            if (
              endPage ===
              incompleteTest[modality][i].modules[0].generatedTestlets[j]
            ) {
              pageNum = j;
              break;
            }
          }
          level = incompleteTest[modality][i].grammarLevel.displayName;
        } else {
          let questionItemLengthCounter = incompleteTest[modality][i].modules;
          let questionItemLen = 0;
          let questionItemUniqueTestlets = [];
          questionItemLengthCounter.forEach((item, index) => {
            let QIAArray = item.questionItemAns;
            QIAArray.forEach((item2, index2) => {
              if (!questionItemUniqueTestlets.includes(item2.testitem)) {
                questionItemUniqueTestlets.push(item2.testitem);
              }
            });
          });
          questionItemLen += questionItemUniqueTestlets.length;
          generatedTestLen = 99;
          pageNum = questionItemLen;
          level = incompleteTest[modality][i].currentLevel;
        }

        id = incompleteTest[modality][i]._id;
        language = incompleteTest[modality][i].language.displayName;
        date = incompleteTest[modality][i].starttime;

        readableDate = Date.parse(date);
        readableDate = new Date(readableDate);
        readableDate = readableDate.toLocaleString("default", {
          month: "short",
          day: "numeric",
          year: "numeric",
        });
        let resume = "levelDate" + i + "_" + modality;

        if (level == "L1") {
          level = "1";
        } else if (level == "L1+/L2") {
          level = "1+/2";
        } else if (level == "L2+/L3") {
          level = "2+/3";
        }

        let progressText;
        if (modality === "GR") {
          progressText =
            (pageNum + 1).toString() + "/" + generatedTestLen.toString();
        } else {
          progressText = (pageNum + 1).toString();
        }

        levelDate.push(
          <ul key={resume} className="dpResultsHover">
            <li>
              <span
                className={
                  "assessmentResults ares-grid-container assessmentType_" +
                  modality +
                  " assessmentLvl_" +
                  level
                }
                id={id}
                page={pageNum}
                onClick={props.restorePrevTestlet}
                title="Click to resume this incompleted assessment."
              >
                <div className="ar-grid-btn">
                  <IconContext.Provider
                    value={{ className: "react-icons-grresume" }}
                  >
                    <GrResume fill="green" color="green" stroke="green" />
                  </IconContext.Provider>
                </div>
                <div className="ares-grid-date"> {readableDate} </div>
                <div className="ares-grid-level"> {level} </div>
                <div className="ares-grid-progress">{progressText}</div>
               
                <div className="ares-grid-remove">
                <button
                  id={id}
                  onClick={(e) => {
                    e.stopPropagation(); // Prevent the click from bubbling up to the parent
                    props.removeIncomplete(e, id); // Pass id directly
                  }}
                  title="Click to permanently delete this incompleted assessment."
                >
                  Delete
                </button>
              </div>
              </span>
            </li>
          </ul>
        );
      }
    }
    return levelDate;
  };

  const createLangItems = (lang, i, modality, icon, title) => {
    const filteredLang = incompleteTest[modality].filter(
      (assessment) => assessment.language.displayName === lang
    );
    if (filteredLang.length > 0) {
      let key = `${modality}_${i}`;
      return (
        <div key={key} className="gridItem accordion">
          <span className="cardTitle">
            <img src={icon} alt={`${title} Assessments`} />
            {title}
          </span>
          <div className="ares-grid-container">
            <div className="ar-grid-btn ar-grid-header">Resume</div>
            <div className="ares-grid-date ar-grid-header">Last Date</div>
            <div className="ares-grid-level ar-grid-header">Skill Level</div>
            <div className="ares-grid-level ar-grid-header">Progress</div>
            <div className="ares-grid-remove ar-grid-header">Delete</div>
          </div>
          {levelNdate(modality, i)}
          <h5 className="fontWeightNormal">
            Click on an incompleted assessment's{" "}
            <IconContext.Provider
              value={{ className: "react-icons-grresume-inline" }}
            >
              <GrResume fill="green" color="green" stroke="green" />
            </IconContext.Provider>{" "}
            above to resume working on it.{" "}
          </h5>
        </div>
      );
    } else {
      return null;
    }
  };

  const createResumeItems = () => {
    let resumeField = [];
    for (let i = 0; i < individualLang.length; i++) {
      language = individualLang[i];
      let lang = "lang" + i;

      const hasRCItems = createLangItems(language, i, "RC", reading, "Reading");
      const hasLCItems = createLangItems(
        language,
        i,
        "LC",
        listening,
        "Listening"
      );
      const hasGRItems = createLangItems(language, i, "GR", grammar, "Grammar");

      // Check if there are any items for RC, LC, or GR
      if (
        (selectedTab === "RC" && hasRCItems) ||
        (selectedTab === "LC" && hasLCItems) ||
        (selectedTab === "GR" && hasGRItems)
      ) {
        resumeField.push(
          <div key={lang}>
            <div className="assessments ">
              <div className="gridContainerTitle">
                <h4 className="" style={{ marginTop: 0 }}>
                  Your Incompleted{" "}
                  {language
                    .replace(
                      /^(North_Korean|North Korean|Korean\/North Korean|Korean\/North_Korean)$/i,
                      "Korean (South & North)"
                    )
                    .replace(/^Korean$/i, "Korean (South)")
                    .replace("_", " ")}{" "}
                  Language Assessments
                </h4>
                {selectedTab === "RC" && hasRCItems}
                {selectedTab === "LC" && hasLCItems}
                {selectedTab === "GR" && hasGRItems}
              </div>
            </div>
          </div>
        );
      }
    }
    return resumeField;
  };

  return (
    <div style={{ marginTop: "60px" }}>
      <h3 style={{ textAlign: "center", padding: 0, color: "#ad3939" }}>
        Incomplete Assessments
      </h3>
      <div className="tab-container">
        {incompleteTest["RC"].length > 0 ? (
          <button
            className={`tab ${selectedTab === "RC" ? "active" : ""}`}
            onClick={() => setSelectedTab("RC")}
          >
            Reading
          </button>
        ) : (
          ""
        )}
        {incompleteTest["LC"].length > 0 ? (
          <button
            className={`tab ${selectedTab === "LC" ? "active" : ""}`}
            onClick={() => setSelectedTab("LC")}
          >
            Listening
          </button>
        ) : (
          ""
        )}
        {incompleteTest["GR"].length > 0 ? (
          <button
            className={`tab ${selectedTab === "GR" ? "active" : ""}`}
            onClick={() => setSelectedTab("GR")}
          >
            Grammar
          </button>
        ) : (
          ""
        )}
      </div>
      {returnOutput !== null
        ? returnOutput
        : "An error occurred collecting your incomplete assessments."}
    </div>
  );
}
