import { useState, useEffect } from "react";
import axios from "axios";
import axiosRetry from "axios-retry";

/*
 * useCollectLanguages:
 * a custom hook to gather the available languages from the database and return as so:
 *
 * [
 *  id1 : langDisplayName1
 *  id2 : langDisplayName2
 *  id3 : langDisplayName3
 * ]
 *
 * Used in client/contents/EditProfile currently.
 */

export function useCollectLanguages(currentData) {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  axiosRetry(axios, {
    retries: 4, // number of retries

    retryDelay: (...arg) => axiosRetry.exponentialDelay(...arg, 1000), // Exponential delay with backoff of 1000mst
            retryCondition: (err) => {return axiosRetry.isNetworkError(err) || err.code === "ECONNABORTED" || err.code === "ENOTFOUND" || err.code === "ETIMEDOUT" || (err.response && err.response.status >= 500 && err.response.status <= 599) }


  });

  useEffect(() => {
    if (currentData === null) {
      axios
        //grab the languages from API
        //.get("api/language", {params: {isActive: true, _sort : { "sequence": 1}}})
        //.get("/api/language/includeUnsetAndOther?modalities_$ne=[]") //testing api changes
        .get("/api/language/includeUnsetAndOther?modalities__ne=[]") //testing api changes
        .then((res) => {
          //parse output into an associative array
          const langArr = Object.entries(res.data).reduce(function (accum, current) {
            const [, value] = current;
            accum[value._id] = value.displayName;
            return accum;
          }, []);
          setData(langArr);
        })
        .catch((err) => {
          setError(err);
        })
        .finally();
    }
  }, [currentData]);

  return [data, error];
}
