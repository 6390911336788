import { Link } from "react-router-dom";

const AdminReportBody = (props) => {
  const content = props.content;
  const role = content.roles;
  const panels = content.adminPanels;
  let itemList = "";

  const data = [
    { text: "Main Panel", url: "mainpanel", insertSpaceBefore: false },
    {
      text: "Item-User Correlation",
      url: "itemusercorrelation",
      insertSpaceBefore: false,
    },
    {
      text: "Item-Item Correlation",
      url: "itemitemcorrelation",
      insertSpaceBefore: false,
    },
    {
      text: "Group/Class DPs",
      url: "groupdps",
      insertSpaceBefore: true,
    },
    { text: "Keyword Panel", url: "keywordpanel", insertSpaceBefore: false },

    { text: "Tags Panel", url: "tagpanel", insertSpaceBefore: false },
    {
      text: "User Answer Search Panel",
      url: "useranswersearchpanel",
      insertSpaceBefore: false,
    },
    {
      text: "Question by Primary Tag",
      url: "questionbyprimarytag",
      insertSpaceBefore: false,
    },
    {
      text: "Passage by Proficiency Level",
      url: "passagebyproficiencylevel",
      insertSpaceBefore: true,
    },

    {
      text: "Data Types Search",
      url: "datatypessearch",
      insertSpaceBefore: false,
    },
    {
      text: "Testlet Usage Frequency (TUF)",
      url: "testletusagefrequency",
      insertSpaceBefore: false,
    },
    {
      text: "ODA Testlet Inventory",
      url: "odatestletinventory",
      insertSpaceBefore: false,
    },
    { text: "ODA Usage", url: "odausage", insertSpaceBefore: false },
    { text: "ODA User Survey", url: "usersurvey", insertSpaceBefore: true },
    {
      text: "Single Testlet",
      url: "singletestlet",
      insertSpaceBefore: true,
    },
    {
      text: (
        <span
          style={{
            color: "#187680",
            fontSize: "14pt",
            textDecoration: "underline",
          }}
        >
          MASTER ADMIN
        </span>
      ),
      url: "masteradmin",
    },
    {
      text: "Sys Admin Suite",
      url: "sysadminsuite",
      insertSpaceBefore: false,
    },
  ];

  if (role[0] === "sysadmin") {
    itemList = data;
  } else {
    itemList = panels
      .map((panel) => {
        const matchedItem = data[panel._id - 1];
        return matchedItem
          ? { ...matchedItem, text: matchedItem.text, url: matchedItem.url }
          : null;
      })
      .filter(Boolean);
  }

  const createList = () => {
    const renderColumn = (items) => {
      let shouldInsertSpace = false;

      return items.map((item) => {
        if (item.insertSpaceBefore) {
          shouldInsertSpace = true;
        }

        const itemComponent = (
          <Link
            key={item.url}
            to={item.url}
            style={{
              backgroundColor: "transparent",
              color: "#187680",
              textAlign: "left",
              display: "block",
              marginBottom: "8px",
              textDecoration: "none",
            }}
            onMouseOver={(e) => (e.target.style.fontWeight = "bold")}
            onMouseOut={(e) => (e.target.style.fontWeight = "")}
          >
            {item.text}
          </Link>
        );

        if (shouldInsertSpace) {
          shouldInsertSpace = false;
          return (
            <div key={`space-${item.url}`} style={{ marginBottom: "16px" }}>
              {itemComponent}
            </div>
          );
        }

        return itemComponent;
      });
    };

    const halfLength = Math.ceil(itemList.length / 2);
    const firstHalf = itemList.slice(0, halfLength);
    const secondHalf = itemList.slice(halfLength);

    return (
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "1fr 1fr",
          gridGap: "16px",
        }}
      >
        <div>{renderColumn(firstHalf)}</div>
        <div>{renderColumn(secondHalf)}</div>
      </div>
    );
  };

  return <div>{createList()}</div>;
};

export default AdminReportBody;
