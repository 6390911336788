import {useReducer, createContext} from "react";

import {errorStateReducer, initialErrorState } from "./ErrorStateReducer";

export const ErrorContext = createContext({
  state: initialErrorState,
  dispatch: () => null
});

export const ErrorProvider = ({children}) => {
  const [state,dispatch] = useReducer(errorStateReducer,initialErrorState);

  return (
    <ErrorContext.Provider value={[state,dispatch]}>
      {children}
    </ErrorContext.Provider>
  );
};

//how to use:
//wrap the App.js tree in <ErrorProvider /> after importing
//to access global error context, add this in a component:
//const [errorState,errorDispatch] = useContext(ErrorContext);
//then call the dispatch function, ie:
//      errorDispatch({
//        type: "UPDATE_ERROR_STATE",
//        payload: {
//          errorStatusCode: error.response.status,
//          errorUserMessage: "New Error",
//          errorDevData: error.response
//        }
//      });

