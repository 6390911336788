import dompurify from "dompurify";

const QuestionByPrimaryTagDisplay = (props) => {
  const sanitizer = dompurify.sanitize;
  const data = props.content;
  const isData = props.isData;
  const firstCheck = props.firstCheck;
  const mod = props.mod;
  const checkAPI = props.checkAPI;
  const sortedData = data.sort((a, b) => a._id.localeCompare(b._id));

  const playFile = (filePath) => {
    const audio = new Audio(filePath);
    audio.play();
  };

  const Reading = () => {
    return (
      <div>
        {sortedData.length !== 0 && (
          <div style={{ textAlign: "left" }}>
            <h3>{sortedData.length} Items Found</h3>
            {console.log("t=", sortedData.length)}
            {sortedData.map((item) => (
              <div key={item._id}>
                <h4>{item._id}</h4>
                <div
                  dangerouslySetInnerHTML={{
                    __html: sanitizer(item.questionText),
                  }}
                />

                {item.template === "MCT" ? (
                  <>
                    {item.questionItems.map((questionItem, index) => (
                      <div
                        key={questionItem._id}
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <input
                          type="radio"
                          name={item._id}
                          style={{ marginRight: "8px" }}
                        />

                        <div
                          dangerouslySetInnerHTML={{
                            __html: sanitizer(questionItem.value),
                          }}
                        />
                      </div>
                    ))}
                  </>
                ) : item.template === "CIM" ? (
                  <div>
                    {item.questionItems.map((questionItem) => (
                      <div
                        key={questionItem._id}
                        style={{
                          display: "grid",
                          gridTemplateColumns: "1fr 1fr 1fr",
                          alignItems: "center",
                          gridRowGap: "50px",
                        }}
                      >
                        <p>{questionItem.value}</p>

                        <select>
                          <option>Select</option>
                          {item.multipleChoiceItems[0].map((choice) => (
                            <option key={choice}>{choice}</option>
                          ))}
                        </select>
                      </div>
                    ))}
                  </div>
                ) : (
                  <div>
                    {item.questionItems.length === 0 ? (
                      <input
                        type="text"
                        style={{ width: "250px" }}
                        placeholder="Enter your answer"
                      />
                    ) : (
                      item.questionItems.map((questionItem) => (
                        <div
                          key={questionItem._id}
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <p style={{ marginRight: "8px" }}>
                            {questionItem.value}
                          </p>
                          <input
                            type="text"
                            style={{ width: "250px" }}
                            placeholder="Enter your answer"
                          />
                        </div>
                      ))
                    )}
                  </div>
                )}
                <hr />
              </div>
            ))}
          </div>
        )}
      </div>
    );
  };

  const Listening = () => {
    return (
      <div>
        {sortedData.length !== 0 && (
          <div style={{ textAlign: "left" }}>
            <h3>{sortedData.length} Items Found</h3>
            {sortedData.map((item) => (
              <div key={item._id}>
                <h4>{item._id}</h4>
                <div
                  dangerouslySetInnerHTML={{
                    __html: sanitizer(item.questionText[0]),
                  }}
                />
                <br></br>
                <p>
                  {item.questionText[1] && (
                    <span
                      onClick={() =>
                        playFile(
                          `../../audio/${
                            item._id.split("_")[0]
                          }/${item._id.substr(0, 10)}/${item.questionText[1]}`
                        )
                      }
                      className="inlinePlay paused"
                      file={`../../audio/${
                        item._id.split("_")[0]
                      }/${item._id.substr(0, 10)}/${item.questionText[1]}`}
                    >
                      <span className="modifiedFlag" />
                      ________
                    </span>
                  )}
                  {item.questionText[2]}
                </p>
                {item.template === "AMC2" || item.template === "TMC2" ? (
                  <>
                    {item.questionItems.map((questionItem, index) => (
                      <p key={questionItem._id}>
                        <input
                          type="radio"
                          name={item._id}
                          style={{ marginRight: "8px" }}
                        />
                        {questionItem.value &&
                        !questionItem.value.includes(".mp") ? (
                          questionItem.value
                        ) : (
                          <span
                            onClick={() =>
                              playFile(
                                `../../audio/${
                                  item._id.split("_")[0]
                                }/${item._id.substr(0, 10)}/${
                                  questionItem.value
                                }`
                              )
                            }
                            className="inlinePlay paused"
                            file={`../../audio/${
                              item._id.split("_")[0]
                            }/${item._id.substr(0, 10)}/${questionItem.value}`}
                          >
                            <span className="modifiedFlag" /> Audio
                          </span>
                        )}
                      </p>
                    ))}
                  </>
                ) : item.template === "LCIA" ? (
                  <>
                    {item.questionItems.map((questionItem) => (
                      <div
                        key={questionItem._id}
                        style={{
                          display: "flex",
                          alignItems: "right",
                          columnGap: "20px",
                        }}
                      >
                        <span
                          onClick={() =>
                            playFile(
                              `../../audio/${
                                item._id.split("_")[0]
                              }/${item._id.substr(0, 10)}/${questionItem.value}`
                            )
                          }
                          className="inlinePlay paused"
                          file={`../../audio/${
                            item._id.split("_")[0]
                          }/${item._id.substr(0, 10)}/${questionItem.value}`}
                        >
                          <span className="modifiedFlag" /> Audio
                        </span>{" "}
                        <select style={{ height: "25px" }}>
                          <option value="">Select</option>
                          {item.multipleChoiceItems[0].map((choice) => (
                            <option key={choice} value={choice}>
                              {choice}
                            </option>
                          ))}
                        </select>
                      </div>
                    ))}
                  </>
                ) : item.template === "LCM" ? (
                  // "ss"
                  <>
                    {item.questionItems.map((questionItem, index) => (
                      <p key={questionItem._id}>
                        {questionItem.value &&
                        !questionItem.value.includes(".mp") ? (
                          <>
                            <div
                              dangerouslySetInnerHTML={{
                                __html: sanitizer(questionItem.value),
                              }}
                            />
                            <br />
                            <input
                              type="text"
                              style={{ width: "250px" }}
                              placeholder="Enter your answer"
                            />
                            <hr style={{ borderTop: "1px dashed" }} />
                          </>
                        ) : (
                          <>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                columnGap: "20px",
                                flexWrap: "wrap",
                              }}
                            >
                              {/* {index % 2 === 1 ? ( */}
                              {index === 0 ||
                              index === 3 ||
                              index === 6 ||
                              index === 9 ||
                              index === 12 ||
                              index === 15 ||
                              index === 18 ? (
                                <div>Isolated</div>
                              ) : (
                                <div>Context</div>
                              )}
                              <span
                                onClick={() =>
                                  playFile(
                                    `../../audio/${
                                      item._id.split("_")[0]
                                    }/${item._id.substr(0, 10)}/${
                                      questionItem.value
                                    }`
                                  )
                                }
                                className="inlinePlay paused"
                                file={`../../audio/${
                                  item._id.split("_")[0]
                                }/${item._id.substr(0, 10)}/${
                                  questionItem.value
                                }`}
                              >
                                <span className="modifiedFlag" /> Audio
                              </span>
                              <br />
                            </div>
                          </>
                        )}
                      </p>
                    ))}
                  </>
                ) : (
                  <div>
                    {item.questionItems.length === 0 ? (
                      <input
                        type="text"
                        style={{ width: "250px" }}
                        placeholder="Enter your answer"
                      />
                    ) : (
                      item.questionItems.map((questionItem) => (
                        <div
                          key={questionItem._id}
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <div
                            style={{ marginRight: "8px" }}
                            dangerouslySetInnerHTML={{
                              __html: sanitizer(questionItem.value),
                            }}
                          />

                          <input
                            type="text"
                            style={{ width: "250px" }}
                            placeholder="Enter your answer"
                          />
                        </div>
                      ))
                    )}
                  </div>
                )}
                <hr />
              </div>
            ))}
          </div>
        )}
      </div>
    );
  };

  let selectedMod = "";
  if (isData && mod === "reading") {
    selectedMod = Reading();
  }
  if (isData && mod === "listening") {
    selectedMod = Listening();
  }

  if ((!isData && checkAPI) || (!isData && !checkAPI && firstCheck)) {
    selectedMod = <h3>No record to be displayed</h3>;
  }
  return <div>{selectedMod}</div>;
};

export default QuestionByPrimaryTagDisplay;
