import React from "react";

export default function AnswerOption(props) {
  const actID = props.actID;
  const ddList = props.dropdownOptions;

  const selValue = props.selValue;
  const RTL = props.dir;
  const dir = RTL ? "RTL" : "";

  let queWord = "";
  let dropdown = "";
  //error was occurring because ddList was sometimes null
  if (typeof ddList !== "undefined" && ddList !== null && ddList.length > 0) {
    dropdown = ddList.map((item, index) => (
      <option key={item} value={index}>
        {item}
      </option>
    ));
  }

  const createAnswerField = () => {
    const len = props.question.length;
    let items = [];
    for (let i = 0; i < len; i++) {
      queWord = props.question[i].value;

      items.push(
        <div className="categorizeAnswer" key={i + 1}>
          <p className={[dir, "question"].join(" ")}>
            <span className={[dir, "questionNum"].join(" ")}>{i + 1}. </span>
            {queWord}
          </p>
          <select name="" defaultValue={selValue} id={actID + "_" + i} onChange={props.onAnswerSelected}>
            <option value={selValue} disabled>
              Select
            </option>
            {dropdown}
          </select>
        </div>
      );
    }
    return items;
  };
  return createAnswerField();
}
