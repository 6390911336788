import "../../css/index.css";
/**
 * @component RegisterAccountModal
 * @description
 * @param {object} props
 **/

export default function StudyPlanModal(props) {
  const afterCreation = (e) => {
    props.handleStudyPlanModalState(e);
  };

  return (
    <div className="modalDialog" id="RegisterAccountModal">
      <div className="RegisterAccountModal">
        <div
          className="feedbackHeader"
          id="modalHeader"
          style={{ fontSize: "16px" }}
        >
          Learning Plans
        </div>
        <h5
          style={{
            fontSize: "16px",
            fontWeight: "normal",
            overflow: "auto",
          }}
        >
          At the end of each Diagnostic Profile, you will discover a ‘Suggested
          Materials’ button. This leads to Global Language Online Support System
          (GLOSS) lessons, which are customized to meet your specific needs,
          areas for improvement, and language features.
          <br />
          <br />
          <ul>
            <li>
              GLOSS lessons are designed to assist you in advancing toward your
              target proficiency level.
            </li>
            <br />
            <br />
            <li>
              Coming Soon: Receive personalized lesson plans that focus on the areas where
              you most need to improve.
            </li>
            <br />
            <br />
            <li>
              Identify the precise GLOSS lessons that cater to your target level
              needs and areas in need of improvement.
            </li>
          </ul>
        </h5>

        <button
          className="goLogin outlined"
          style={{
            fontSize: "16px",
            top: "6px",
            padding: "3px 7px",
          }}
          onClick={(e) => afterCreation(e)}
        >
          Close
        </button>
        <br />
        <br />
      </div>
    </div>
  );
}
