import React, { useState, useEffect, useCallback } from "react";
import Test from "./test/Test";
import Footer from "../../../../components/footer/Footer";
import "../../../../css/index.css";
import draggableFunctions from "../../../../functions/draggableFunctions";
//import fontsizeFunctions from "../../../../functions/fontsizeFunctions";

/**
 * @component GMCA
 * @description Grammar Testlet
 **/

const GMCA = (props) => {
  const testData = props.testData;
  const [state, setState] = useState({
    passage: testData.mainPassage,
    instruction: testData.instruction,
    actID: testData._id,
    modality: testData.modality,
    template: testData.template,
    skill: testData.grammarLevel,
    dir: testData.language.isRightToLeft,
    assessment: testData.testitems,
    userAnswerMainPre: [],
    userAnswerMain: [],
    userAnswerMod: [],
    userAnswerModPre: [],
    userAnswerAll: [],
    hint: testData.hint,
    score: "",
    correctAnswer: testData.testitems,
    clickCounter: 0,
  });
  const [finalChecking, setFinalChecking] = useState(null);
  const [theBtnName, setTheBtnName] = useState(null);
  state.userAnswerMain.length = state.correctAnswer.length;
  state.userAnswerMod.length = state.correctAnswer.length;
  state.userAnswerAll.length = state.correctAnswer.length * 2; //6;

  useEffect(() => {
    draggableFunctions();
    //fontSizeFunctions();

    if (props.navLen === 20) {
      document.querySelector(".progressGrid").style.gridTemplateColumns =
        "repeat(20, 1fr)";
    }

    const footerLogo = document.querySelector(".footerLogo");
    footerLogo.style.top = "13px";
    footerLogo.style.height = "67px";

    return () => {
      // let allsuspects = document.querySelectorAll("script");
      // for (let i = allsuspects.length; i >= 0; i--) {
      //   if (
      //     allsuspects[i] &&
      //     allsuspects[i].getAttribute("src") !== null &&
      //     (allsuspects[i].getAttribute("src").indexOf("js/fontsize.js") !==
      //       -1 ||
      //       allsuspects[i].getAttribute("src").indexOf("js/fbPopups.js") !== -1)
      //   ) {
      //     allsuspects[i].parentNode.removeChild(allsuspects[i]);
      //   }
      // }
    }; // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    //disable next/submit until previous api call is complete; prevents overrunning api calls to change same data
    if (props.buttonWaiting) {
      const apiWaitButtons = document.querySelectorAll(".nextBtn, .submitBtn");
      const titleChanger = document.querySelector(".nextSubmitParentDiv");
      apiWaitButtons.forEach((item, index) => {
        item.classList.add("waitingForAPI");
        if (item.classList.contains("disabled")) {
          titleChanger.title =
            "Please answer the questions above before proceeding. You will also need to wait for the system to record your previous answer before proceeding.";
        } else {
          titleChanger.title = "Please wait for your assessment to save your previous answer before proceeding.";
        }
      });
      titleChanger.classList.remove("nextSubmitParentDivUnanswered");
      titleChanger.classList.add("nextSubmitParentDivWaiting");
    } else {
      const titleChanger = document.querySelector(".nextSubmitParentDiv");
      const apiWaitButtons = document.querySelectorAll(".nextBtn, .submitBtn");
      apiWaitButtons.forEach((item, index) => {
        item.classList.remove("waitingForAPI");
        if (item.classList.contains("disabled")) {
          titleChanger.title = "Please attempt to answers all questions before proceeding.  Note: If you feel your assessment is stuck and you can not proceed, you may skip to the next question by holding SHIFT and clicking this button.";
        } else {
          titleChanger.title = "Click to submit your answer(s).";
        }
      });
      titleChanger.classList.remove("nextSubmitParentDivWaiting");
      titleChanger.classList.add("nextSubmitParentDivUnanswered");
    }
  }, [props.buttonWaiting]);

  const updateUserAnswer = useCallback(
    (id) => {
      const itemId = id.substr(-1);
      let checkAnswer = false;
      let userAns;
      if (state.clickCounter === 0) {
        userAns = state.userAnswerMainPre;
      } else {
        userAns = state.userAnswerModPre;
      }

      const crrtAns = state.correctAnswer;

      if (userAns[itemId] !== undefined) {
        if (
          parseInt(userAns[itemId].userAnswer) ===
          parseInt(crrtAns[itemId].answerFields[0].multipleChoiceAnsNums)
        ) {
          checkAnswer = true;
        }
      }
      return { checkAnswer };
    },
    [state]
  );

  const handleChange = useCallback(
    (event) => {
      const answerFieldsID = event.currentTarget.name;
      const testitem = event.target.getAttribute("data-testitem");
      const index = event.currentTarget.name.substr(-1);
      const id = event.currentTarget.placeholder;
      const value = event.currentTarget.value;
      let new_state = Object.assign({}, state);
      let a = new_state.userAnswerMainPre;
      let b = new_state.userAnswerMod;
      let z = new_state.userAnswerMain;

      a[index] = {
        uiControlID: id,
        userAnswer: value,
      };

      const answerCheck = updateUserAnswer(answerFieldsID).checkAnswer;
      z[index] = {
        uiControlID: id,
        testlet: state.actID,
        testitem: testitem,
        answerField: answerFieldsID,
        try: 0,
        keywordMatches: [],
        userAnswer: value,
        isPassed: answerCheck,
        isModifiedAudioPlayed: false,
        isTranscriptShown: false,
      };

      b[index] = {
        uiControlID: id.substring(0, id.length - 1) + 1,
        testlet: state.actID,
        testitem: testitem,
        answerField: answerFieldsID,
        try: 1,
        keywordMatches: [],
        userAnswer: value,
        isPassed: answerCheck,
        isModifiedAudioPlayed: false,
        isTranscriptShown: false,
      };
      setState({
        ...state,
        userAnswerMainPre: a,
        userAnswerMain: z,
        userAnswerMod: b,
      });

      let TransToMod = [];
      if (id != null) {
        let selID = id.substring(0, id.length - 1);
        let selVal = value;
        TransToMod.push(selID + "1" + selVal);
      }
      document.querySelector("#" + TransToMod).checked = true;
      document
        .querySelector("#" + TransToMod)
        .nextElementSibling.firstChild.classList.add("selectedAnswer");

      const len = state.userAnswerMain.length;
      let num = 0;
      for (let i = 0; i < len; i++) {
        if (state.userAnswerMain[i] !== undefined) {
          num++;
        }
      }
      if (num === len) {
        document.querySelector(".nextBtn").classList.remove("disabled");
        const titleChanger = document.querySelector(".nextSubmitParentDiv");
        titleChanger.classList.remove("nextSubmitParentDivUnanswered");
        if (titleChanger.classList.contains("nextSubmitParentDivWaiting")) {
          titleChanger.title = "Please wait for your assessment to save your previous answer before proceeding.";
        } else {
          titleChanger.title = "Click to submit your answers.";
        }
      }
    },
    [state, updateUserAnswer]
  );

  const handleChangeMod = useCallback(
    (event) => {
      const answerFieldsID = event.currentTarget.name;
      const testitem = event.target.getAttribute("data-testitem");
      const index = event.currentTarget.name.substr(-1);
      const id = event.currentTarget.placeholder;
      const value = event.currentTarget.value;
      let new_state = Object.assign({}, state);
      let a = new_state.userAnswerModPre;
      let z = new_state.userAnswerMod;

      a[index] = {
        uiControlID: id,
        userAnswer: value,
      };

      const answerCheck = updateUserAnswer(answerFieldsID).checkAnswer;
      z[index] = {
        uiControlID: id,
        testlet: state.actID,
        testitem: testitem,
        answerField: answerFieldsID,
        try: 1,
        keywordMatches: [],
        userAnswer: value,
        isPassed: answerCheck,
        isModifiedAudioPlayed: false,
        isTranscriptShown: false,
      };
      setState({ ...state, userAnswerModPre: a, userAnswerMod: z });

      const len = state.userAnswerMain.length;
      let num = 0;
      for (let i = 0; i < len; i++) {
        if (state.userAnswerMod[i] !== undefined) {
          num++;
        }
      }
      if (num === len) {
        document.querySelector(".nextBtn").classList.remove("disabled");
        const titleChanger = document.querySelector(".nextSubmitParentDiv");
        titleChanger.classList.remove("nextSubmitParentDivUnanswered");
        if (titleChanger.classList.contains("nextSubmitParentDivWaiting")) {
          titleChanger.title = "Please wait for your assessment to save your previous answer before proceeding.";
        } else {
          titleChanger.title = "Click to submit your answers.";
        }
      }
    },
    [state, updateUserAnswer]
  );

  const combinedAnswers = useCallback(
    (btnName) => {
      const mainAns = state.userAnswerMain;
      const modAns = state.userAnswerMod;
      let combinedAns = null;
      let finalAns = null;

      if (state.clickCounter === 0 && finalChecking == true) {
        combinedAns = mainAns;
      } else if (state.clickCounter >= 1 && finalChecking === true) {
        combinedAns = mainAns.concat(modAns);
      }

      if (combinedAns !== null && finalChecking !== null) {
        finalAns = '{"questionItemAns":' + JSON.stringify(combinedAns) + "}";
        if (!(state.clickCounter === 0 && finalChecking == null))
          props.parentCallback(finalAns, btnName);
      }
    },
    [state, finalChecking]
  );

  const handleEval = useCallback(
    (event) => {
      const btnName = event.currentTarget.name;
      setTheBtnName(btnName);

      let userAns = "";
      if (state.clickCounter === 0) {
        userAns = state.userAnswerMain;
      } else {
        userAns = state.userAnswerMod;
      }

      const crrtAns = state.correctAnswer;
      let crrtAnsCounter = 0;
      let scoreCal = "";

      for (let i = 0; i < state.correctAnswer.length; i++) {
        if (userAns[i] !== undefined) {
          if (
            parseInt(userAns[i].userAnswer) ===
            parseInt(crrtAns[i].answerFields[0].multipleChoiceAnsNums)
          ) {
            crrtAnsCounter = crrtAnsCounter + 1;
          }
        }
      }

      if (
        crrtAnsCounter === state.correctAnswer.length &&
        state.clickCounter === 0
      ) {
        // console.log("correct");
        setFinalChecking(true);
        if (props.navCrrtItem === props.navLen) {
          props.submitButton();
        } else {
          props.nextButton();
        }
      } else {
        document.querySelector(".nextBtn").classList.add("disabled");
        const titleChanger = document.querySelector(".nextSubmitParentDiv");
        titleChanger.classList.add("nextSubmitParentDivUnanswered");
        if (titleChanger.classList.contains("nextSubmitParentDivWaiting")) {
          titleChanger.title = "Please attempt to re-answer at least one incorrect question before proceeding. You will also need to wait for the system to record your previous answer before proceeding.";
        } else {
          titleChanger.title = "Please attempt to re-answer at least one incorrect question before proceeding.";
        }
        if (state.clickCounter === 0) {
          // console.log("incorrect");
          scoreCal =
            "<p>You scored " +
            crrtAnsCounter +
            "/" +
            state.correctAnswer.length +
            " on this grammar point. You may wish to try again.</p>";
          setState({
            ...state,
            score: scoreCal,
            clickCounter: state.clickCounter + 1,
          });
          document.querySelector(".feedbackModal").style.display = "block";
        } else {
          setFinalChecking(true);

          // aleconsole.logrt("no judging for scores");
          if (props.navCrrtItem === props.navLen) {
            props.submitButton();
          } else {
            props.nextButton();
          }
          setState({ ...state, clickCounter: state.clickCounter + 1 });
        }
      }
    },
    [state, combinedAnswers]
  );

  useEffect(() => {
    if (finalChecking === true) {
      combinedAnswers(theBtnName);
    }
  }, [combinedAnswers, theBtnName, finalChecking]);

  const renderTest = () => {
    return (
      <>
        <Test
          passage={state.passage}
          assessment={state.assessment}
          actID={state.actID}
          dir={state.dir}
          userAnswerMain={state.userAnswerMain}
          userAnswerMod={state.userAnswerMod}
          instruction={state.instruction}
          correctAnswer={state.correctAnswer}
          hint={state.hint}
          score={state.score}
          clickCounter={state.clickCounter}
          answerLength={state.userAnswerMain.length}
          onAnswerSelected={handleChange}
          onAnswerSelectedMod={handleChangeMod}
          onEvaluation={handleEval}
          navLen={props.navLen}
          navCrrtItem={props.navCrrtItem}
        />
        <Footer
          actID={state.actID}
          mod={state.modality}
          template={state.template}
          skill={state.skill}
        />
      </>
    );
  };

  return <div>{renderTest()}</div>;
};

export default GMCA;
