import React, { useEffect } from "react";
import FontSize from "../../../../../components/fontsize/FontSize";

export default function AssessmentPassage(props) {
  const passage = props.optionContent;
  const len = passage.length;
  const RTL = props.dir;
  const dir = RTL ? "RTL" : "";

  const testitem = props.testitem;
  const answerFieldsID = props.answerFieldsID;

  let clickCounter = props.clickCounter;

  useEffect(() => {
    if (clickCounter === 0) {
      document.querySelectorAll(".fstAttempt").forEach(function (event) {
        event.style.display = "inline";
      });
      document.querySelectorAll(".sndAttempt").forEach(function (event) {
        event.style.display = "none";
      });
    } else {
      document.querySelectorAll(".fstAttempt").forEach(function (event) {
        event.style.display = "none";
      });
      document.querySelectorAll(".sndAttempt").forEach(function (event) {
        event.style.display = "inline";
      });
    }
  }, [clickCounter]);

  const createAnswerField = () => {
    let items = [];
    for (let i = 0; i < len; i++) {
      if (i < len - 1) {
        const ddList = props.optionList;
        const dropdown = ddList[i].map((item, index) => (
          <option key={item} value={index}>
            {item}
          </option>
        ));
        const itemID_fst = answerFieldsID[i] + "_0";
        const itemID_snd = answerFieldsID[i] + "_1";

        if (i === 0) {
          let shortPassage = passage[i].value + "\u00A0";
          shortPassage = shortPassage
            .replace(/\r\n/g, "<br />")
            .replace(/\n\r/g, "<br />")
            .replace(/\r/g, "<br />")
            .replace(/\n/g, "<br />");
          shortPassage = shortPassage
            .replace(/<P>/g, "")
            .replace(/<p>/g, "")
            .replace(/<\/P>/g, "<br /><br />")
            .replace(/<\/p>/g, "<br /><br />")
            .replace(/(<br \/>\s*){3,4}/g, "<br /><br />")
            .replace(/^(<br \/>\s*){2,4}/g, "");
          items.push(
            <span key={i + "_0"}>
              <span
                dangerouslySetInnerHTML={{
                  __html: shortPassage
                }}
              />
              <span>
                <span className="fstAttempt">
                  <select
                    id={itemID_fst}
                    name={answerFieldsID[i]}
                    data-testitem={testitem}
                    onChange={props.onAnswerSelected}
                  >
                    <option key="select" value="select">
                      Select
                    </option>
                    {dropdown}
                  </select>
                </span>
                <span className="sndAttempt">
                  <select
                    id={itemID_snd}
                    name={answerFieldsID[i]}
                    data-testitem={testitem}
                    onChange={props.onAnswerSelectedMod}
                  >
                    <option key="select" value="select">
                      Select
                    </option>
                    {dropdown}
                  </select>
                </span>
              </span>
            </span>
          );
        } else {
          let shortPassage = "\u00A0" + passage[i].value + "\u00A0";
          shortPassage = shortPassage
            .replace(/\r\n/g, "<br />")
            .replace(/\n\r/g, "<br />")
            .replace(/\r/g, "<br />")
            .replace(/\n/g, "<br />")
            .replace(/<br \/><br \/>$/g, "")
            .replace(/<br \/><br \/>\s*$/g, "")
            .replace(/\s*$/g, "")
            .replace(/<p><\/p>$/g, "")
            .replace(/<p>\s*<\/p>$/g, "");
          shortPassage = shortPassage
            .replace(/<P>/g, "")
            .replace(/<p>/g, "")
            .replace(/<\/P>/g, "<br /><br />")
            .replace(/<\/p>/g, "<br /><br />")
            .replace(/(<br \/>\s*){3,4}/g, "<br /><br />")
            .replace(/^(<br \/>\s*){2,4}/g, "");
          if (i === len - 1) {
            shortPassage = shortPassage.replace(/(<br \/>){2,4}/g, "");
          }

          items.push(
            <span key={i + "_1"}>
              <span
                dangerouslySetInnerHTML={{
                  __html: shortPassage
                }}
              />

              <span>
                <span className="fstAttempt">
                  <select
                    id={itemID_fst}
                    name={answerFieldsID[i]}
                    data-testitem={testitem}
                    onChange={props.onAnswerSelected}
                  >
                    <option key="select" value="select">
                      Select
                    </option>
                    {dropdown}
                  </select>
                </span>
                <span className="sndAttempt">
                  <select
                    id={itemID_snd}
                    name={answerFieldsID[i]}
                    data-testitem={testitem}
                    onChange={props.onAnswerSelectedMod}
                  >
                    <option key="select" value="select">
                      Select
                    </option>
                    {dropdown}
                  </select>
                </span>
              </span>
            </span>
          );
        }
        // }
      } else {
        let shortPassage = "\u00A0" + passage[i].value + "\u00A0";
        shortPassage = shortPassage
          .replace(/\r\n/g, "<br />")
          .replace(/\n\r/g, "<br />")
          .replace(/\r/g, "<br />")
          .replace(/\n/g, "<br />")
          .replace(/<br \/><br \/>$/g, "")
          .replace(/<br \/><br \/>\s*$/g, "")
          .replace(/\s*$/g, "")
          .replace(/<p><\/p>$/g, "")
          .replace(/<p>\s*<\/p>$/g, "");
        shortPassage = shortPassage
          .replace(/<P>/g, "")
          .replace(/<p>/g, "")
          .replace(/<\/P>/g, "<br /><br />")
          .replace(/<\/p>/g, "<br /><br />")
          .replace(/(<br \/>\s*){3,4}/g, "<br /><br />")
          .replace(/^(<br \/>\s*){2,4}/g, "");
        if (i === len - 1) {
          shortPassage = shortPassage.replace(/(<br \/>){2,4}/g, "");
        }
        items.push(
          <span
            key={i + "_2"}
            dangerouslySetInnerHTML={{
              __html: shortPassage
            }}
          />
        );
      }
    }
    return items;
  };
  return (
    <div>
      <div className={[dir, "textPassage noSelect"].join(" ")}>
        <FontSize />
        <div>{createAnswerField()}</div>
        <hr />
      </div>
      <br />
    </div>
  );
}
