import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { AssessmentContext } from "../../context/AssessmentProvider";
import "../../css/index.css";

/**
 * @component ContactFormSentModal
 * @description Lets the user know the contact form has been sent.
 **/

export default function ContactFormSentModal() {
  const [assessmentState] = useContext(AssessmentContext);
  const navigate = useNavigate();
  const goHome = () => {
    if (assessmentState.assessmentUserToken !== null) {
      if (document.querySelector(".bodyItems") !== null) {
        document.querySelector(".bodyItems").style.display = "inline";
      }
      navigate("/dashboard");
    } else {
      if (document.querySelector(".bodyItems1") !== null)
        document.querySelector(".bodyItems1").style.display = "inline";
      if (document.querySelector(".bodyItems2") !== null)
        document.querySelector(".bodyItems2").style.display = "inline";
      if (document.querySelector(".bodyItems3") !== null)
        document.querySelector(".bodyItems3").style.display = "inline";
      if (document.querySelector(".evalInfo") !== null) 
        document.querySelector(".evalInfo").style.display = "inline";
      if (document.querySelector(".switch") !== null) 
        document.querySelector(".switch").style.display = "none";
      navigate("/");
    }
  };

  return (
    <div className="modalDialog" id="modalDialog">
      <div id="contactModal">
        <div className="feedbackHeader" id="contactModalHeader">
          Contact Form Sent!
        </div>
        <h3>Thank you for your interest in ODA!</h3>
        <h5>A member of the ODA team will reach out to you if necessary.</h5>
        <button onClick={goHome}>Return to ODA</button>
        <br />
        <br />
      </div>
    </div>
  );
}
