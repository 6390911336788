import React, { useState } from "react";
import axios from "axios";

const GroupDPDisplay = (props) => {
  const [getAll, setGetAll] = useState(null);
  const [selectedItems, setSelectedItems] = useState([]);

  const lang = props.lang;
  const mod = props.mod;
  const data = props.content;

  const countTargetLevels = (data) => {
    let count = 0;
    data.forEach((item) => {
      item.sessions.forEach((session) => {
        if (session.sessionId !== null) {
          count++;
        }
      });
    });
    return count;
  };

  const numItems = countTargetLevels(data);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString("en-US", {
      month: "2-digit",
      day: "2-digit",
      year: "2-digit",
    });
  };

  const textColor = {
    color: "#67a3aa",
    fontWeight: "normal",
  };

  // Function to check if userID repeats in subsequent sessions
  const isUserIdRepeated = (sessions, currentIndex, userId) => {
    return sessions.some(
      (session, index) => index > currentIndex && session.userId === userId
    );
  };

  // Inline CSS for cells with no bottom border
  const noBottomBorderStyle = {
    borderBottom: "none",
  };

  const navigateExternal = (target, options) => {
    if (options.state) {
      localStorage.setItem("state", JSON.stringify(options.state));
    }
    window.open(target, "_blank", "noreferrer");
  };

  const handleCheckboxChange = (sessionId, userId, isChecked, lang, mod) => {
    if (isChecked) {
      // Add session details to the selectedItems state
      setSelectedItems((prev) => [...prev, { sessionId, userId, lang, mod }]);
    } else {
      // Remove session details from the selectedItems state
      setSelectedItems((prev) =>
        prev.filter((session) => session.sessionId !== sessionId)
      );
    }
  };

  const formatItem = (item) => {
    if (item.includes("@")) {
      const [username, domain] = item.split("@");
      return (
        <span>
          {username}
          <br />@{domain}
        </span>
      );
    }
    return item;
  };

  const isDisabled = (mod, session) => {
    return (
      (mod === "reading" || mod === "listening") && session.targetLevel === null
    );
  };

  const grouping = () => {
    if (selectedItems.length === 0) {
      alert("Please select at least one item before submitting for grouping.");
    } else {
      // Store the selectedItems in localStorage
      localStorage.setItem("selectedItems", JSON.stringify(selectedItems));

      // Create the URL for the new page
      const url = "/adminreport/groupdps/compare";

      // Open a new tab with the URL
      window.open(url, "_blank");
    }
  };

  const groupingGR = () => {
    if (selectedItems.length === 0) {
      alert("Please select at least one item before submitting for grouping.");
    } else {
      // Store the selectedItems in localStorage
      localStorage.setItem("selectedItemsGR", JSON.stringify(selectedItems));

      // Create the URL for the new page
      const url = "/adminreport/groupdps/compareGR";

      // Open a new tab with the URL
      window.open(url, "_blank");
    }
  };

  const userDP = (itemID, userID) => {
    const user = { userId: userID };
    if (mod === "grammar") {
      axios
        .get("/api/sessionGR/diagProfileTotal", {
          params: user,
        })
        .then((res) => {
          //setState({ ...state, getAll: res.data });
          setGetAll(res.data);

          navigateExternal("/profileGrammarAdmin", {
            // state: { from: "external navigation" }
            state: { grID: itemID, getGrTotal: res.data, mod: mod },
          });
        })
        .catch((err) => {
          console.log("error");
        });
    }

    if (mod === "reading") {
      axios
        .get("/api/sessionRC/diagProfileTopLevelInfo", {
          params: user,
        })
        .then((res) => {
          //setState({ ...state, getAll: res.data });
          setGetAll(res.data);

          navigateExternal("/profileAdmin", {
            // state: { from: "external navigation" }
            state: { getID: itemID, getAll: res.data, mod: mod },
          });
        })
        .catch((err) => {
          console.log("error");
        });
    }
    if (mod === "listening") {
      axios
        .get("/api/sessionLC/diagProfileTopLevelInfo", {
          params: user,
        })
        .then((res) => {
          //setState({ ...state, getAll: res.data });
          setGetAll(res.data);

          navigateExternal("/profileAdmin", {
            // state: { from: "external navigation" }
            state: { getID: itemID, getAll: res.data, mod: mod },
          });
        })
        .catch((err) => {
          console.log("error");
        });
    }
  };

  const getLevelDisplay = (session) => {
    if (mod === "grammar") {
      return session.grammarLevel;
    } else {
      return session.targetLevel || "Above 3";
    }
  };

  const emailColumnStyle = {
    width: "150px", // Set a specific width for the Email column
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  };

  return (
    <div>
      <div style={{ marginTop: "-30px" }}>
        {data &&
          data.length > 0 && ( // Check if data is available and has length greater than 0
            <>
              <h4 style={{ textAlign: "left" }}>Items found: {numItems}</h4>
              <span
                style={{
                  textAlign: "left",
                  float: "left",
                  paddingBottom: "15px",
                  paddingTop: "-20px",
                }}
              >
                Among the following item(s), please select the ones to be
                included in the group data.
              </span>
              <br />
              {data.map((classData) => (
                <div key={classData._id}>
                  <h4 style={{ textAlign: "left" }}>
                    ClassID: {classData._id}
                  </h4>
                  <table>
                    <thead>
                      <tr>
                        <th style={emailColumnStyle}>Email</th>
                        <th>sessionID</th>
                        <th>Date</th>
                        <th>{mod === "grammar" ? "Current" : "Target"}</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {classData.sessions.map((session, index, array) => {
                        const showUserId =
                          index === 0 ||
                          session.userId !== array[index - 1].userId;
                        const repeatUserId = isUserIdRepeated(
                          array,
                          index,
                          session.userId
                        );

                        return (
                          <tr key={session.sessionId}>
                            <td
                              style={{
                                height: "30px",
                                color: "#67a3aa",
                                ...(showUserId
                                  ? repeatUserId
                                    ? noBottomBorderStyle
                                    : {}
                                  : { border: "none" }),
                                emailColumnStyle,
                              }}
                            >
                              {showUserId ? formatItem(session.userEmail) : ""}
                            </td>
                            <td
                              style={{
                                textAlign: "left",
                                paddingLeft: "20px",
                                ...textColor,
                              }}
                            >
                              {session.sessionId}
                            </td>
                            <td style={textColor}>
                              {" "}
                              {formatDate(session.endtime)}
                            </td>
                            <td
                              style={{ ...textColor, cursor: "pointer" }}
                              onClick={() =>
                                userDP(session.sessionId, session.userId)
                              }
                            >
                              {/* {session.targetLevel || "Above 3"} */}
                              {getLevelDisplay(session)}
                            </td>
                            <td style={textColor}>
                              <input
                                type="checkbox"
                                onChange={(e) =>
                                  handleCheckboxChange(
                                    session.sessionId,
                                    session.userId,
                                    e.target.checked,
                                    lang,
                                    mod
                                  )
                                }
                                disabled={isDisabled(mod, session)}
                              />
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              ))}
              <br />
              <br />
              <button
                onClick={mod === "grammar" ? groupingGR : grouping}
                // style={{ textAlign: "center", padding: "20px" }}
                style={{
                  padding: "8px 10px",
                  fontSize: "14px",
                }}
              >
                Submit for Grouping
              </button>
              <br />
            </>
          )}
      </div>{" "}
    </div>
  );
};

export default GroupDPDisplay;
