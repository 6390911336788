import Footer from "../components/footer/Footer";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useState, useEffect, useCallback } from "react";
import JWT from "./JWT";
import axios from "axios";
import axiosRetry from "axios-retry";
import LoadingSpinner from "../components/spinner/LoadingSpinner";
import { useCollectLanguages } from "../hooks/useCollectLanguages";
import { useCollectAffiliations } from "../hooks/useCollectAffiliations";
import { useCollectSchoolhouse } from "../hooks/useCollectSchoolhouse";
import useErrorState from "../hooks/useErrorState";
import RequestDataModal from "../components/modals/RequestDataModal";
import RequestDataSentModal from "../components/modals/RequestDataSentModal";
import RequestDeleteDataModal from "../components/modals/RequestDeleteDataModal";
import RequestDeleteDataSentModal from "../components/modals/RequestDeleteDataSentModal";

/**
 * @component EditProfile
 * @description displays and allows a user to adjust their account details
 **/

export default function EditProfile() {
  const location = useLocation();
  const [lastError, setLastError] = useState(null);
  const [accountName, setAccountName] = useState(null);
  const [theLangData, setTheLangData] = useState(null);
  const [theAffilData, setTheAffilData] = useState(null);
  const [theSchoolData, setTheSchoolData] = useState(null);
  const [langData, langError] = useCollectLanguages(theLangData);
  const [affilData, affilError] = useCollectAffiliations(theAffilData);
  const [schoolData, schoolError] = useCollectSchoolhouse(theSchoolData);
  const [ifDLIFLCStudent, setIfDLIFLCStudent] = useState(true);
  const [hasCACRef, setHasCACRef] = useState(false);
  const [otherAffiliation, setOtherAffiliation] = useState(false);
  const [createdAt, setCreatedAt] = useState("Loading...");
  const [updatedAt, setUpdatedAt] = useState("Loading...");
  const [epEmail, setEmail] = useState(location.state?.accountName);
  const [epAffil, setAffil] = useState(null);
  const [epAffilOther, setAffilOth] = useState(null);
  const [otherLanguage, setOtherLanguage] = useState(false);
  const [epLangOther, setLangOther] = useState(null);
  const [epTargetLang, setTargetLang] = useState(null);
  const [epSchool, setDLISchool] = useState(null);
  const [otherSchool, setOtherSchool] = useState(false);
  const [epSchoolOther, setDLISchoolOther] = useState(null);
  const [epClass, setDLIClass] = useState(null);
  const [epTeam, setDLITeam] = useState(null);
  const [epDept, setDLIDept] = useState(null);
  const [isSaving, setIsSaving] = useState(false);
  const [epId, setEpId] = useState(null);
  const [resetPasswordToken, setResetPasswordToken] = useState(null);
  const [data, setData] = useState(null);
  const [loadingSpinner, setLoadingSpinner] = useState(true);
  const [showUpdatedMessage, setShowUpdatedMessage] = useState(false);
  const [showRequestDataModal, setShowRequestDataModal] = useState(false);
  const [showRequestDataSentModal, setShowRequestDataSentModal] = useState(false);
  const [showRequestDeleteDataModal, setShowRequestDeleteDataModal] = useState(false);
  const [showRequestDeleteDataSentModal, setShowRequestDeleteDataSentModal] = useState(false);
  //**react-router-dom v6.8 version of redirect, initiation */
  const navigate = useNavigate();
  axiosRetry(axios, {
    retries: 4, // number of retries

    retryDelay: (...arg) => axiosRetry.exponentialDelay(...arg, 1000), // Exponential delay with backoff of 1000mst
            retryCondition: (err) => {return axiosRetry.isNetworkError(err) || err.code === "ECONNABORTED" || err.code === "ENOTFOUND" || err.code === "ETIMEDOUT" || (err.response && err.response.status >= 500 && err.response.status <= 599) }


  });

  //**error handling */
  useErrorState(lastError);
  useEffect(() => {
    if (langError) {
      setLastError(langError);
    }
    if (affilError) {
      setLastError(affilError);
    }
    if (schoolError) {
      setLastError(schoolError);
    }
  }, [langError, affilError, schoolError]);

  //**initial data grab from db and setting */
  useEffect(() => {
    if (langData !== null && affilData !== null && schoolData !== null) {
      setTheLangData(langData);
      setTheAffilData(affilData);
      setTheSchoolData(schoolData);
      //grab and show creation and updated dates
      JWT.fetchAll()
        .then((result) => {
          if (result.hasOwnProperty("_id")) {
            setEpId(result._id);
          }
          if (result.hasOwnProperty("email")) {
            setAccountName(result.email);
          }
          if (result.hasOwnProperty("affiliation")) {
            if (result.affiliation._id === "other") {
              setAffilOth(result.affiliation.otherDescription);
            }
            setAffil(result.affiliation._id);
          }
          if (result.hasOwnProperty("primaryLanguage")) {
            if (result.primaryLanguage._id === "other") {
              setLangOther(result.primaryLanguage.otherDescription);
            }
            setTargetLang(result.primaryLanguage._id);
          }
          if (result.hasOwnProperty("school")) {
            if (result.school._id === "OTHER") {
              setDLISchoolOther(result.school.otherDescription);
            }
            setDLISchool(result.school._id);
          }
          if (result.hasOwnProperty("department")) {
            setDLIDept(result.department);
          }
          if (result.hasOwnProperty("team")) {
            setDLITeam(result.team);
          }
          if (result.hasOwnProperty("class")) {
            setDLIClass(result.class);
          }
          if (result.hasOwnProperty("updatedAt")) {
            setUpdatedAt(result.updatedAt);
          }
          if (result.hasOwnProperty("createdAt")) {
            setCreatedAt(result.createdAt);
          }
          if (result.hasOwnProperty("resetPasswordToken")) {
            setResetPasswordToken(result.resetPasswordToken);
          }
        })
        .catch((error) => {
          //console.log("Error fetching profile data.");
          setLastError(error);
        })
        .finally(() => {
          setLoadingSpinner(false);
        });
    }
  }, [langData, affilData, schoolData]);

  //**link to reset password token */
  const handleResetPassword = useCallback(
    (e) => {
      //navigate("/reset/"+localStorage.getItem("token").slice(1, -1).split(":")[1].slice(1, -1));
      //navigate("/reset/"+epId, { state: { token: epId } });
      navigate("/reset", { state: { id: epId, email: accountName, token: resetPasswordToken } });
    },
    [epId, navigate, accountName, resetPasswordToken]
  );

  //**handles saving data to database */
  const handleSaveAccountDetails = useCallback(
    (e) => {
      let params = {
        id: data.id
      };
      setIsSaving(true);
      axios({ method: "put", url: "/api/user/" + params.id, params, data })
        .then((res) => {
          setShowUpdatedMessage(true);
        })
        .catch((err) => {
          setLastError(err);
        })
        .finally(() => {
          setIsSaving(false);
        });
    },
    [data]
  );

  //**temporarily show updated message when saved successful */
  useEffect(() => {
    if (showUpdatedMessage === true) {
      setTimeout(() => {
        setShowUpdatedMessage(false);
      }, 5000);
    }
  }, [showUpdatedMessage]);

  //**validate and parse data before saving to database */
  useEffect(() => {
    //must validate input, ie change email; check if it exists first or not
    let otherAffilOutput = epAffil === "other" ? epAffilOther : null;
    let otherLangOutput = epTargetLang === "other" ? epLangOther : null;
    let otherSchoolOutput = epSchool === "OTHER" ? epSchoolOther : null;
    let handledSchool = epAffil === "student" ? epSchool : null;
    let handledDepartment = epAffil === "student" ? epDept : null;
    let handledTeam = epAffil === "student" ? epTeam : null;
    let handledClass = epAffil === "student" ? epClass : null;

    // set this so that it updates and isnt static...
    let thisBody = {
      email: epEmail,
      affiliation: epAffil,
      otherAffiliation: otherAffilOutput,
      primaryLanguage: epTargetLang,
      otherPrimaryLanguage: otherLangOutput,
      school: handledSchool,
      otherSchool: otherSchoolOutput,
      department: handledDepartment,
      team: handledTeam,
      class: handledClass,
      id: epId
    };
    setData(thisBody);
  }, [
    epEmail,
    epId,
    epAffil,
    epAffilOther,
    epTargetLang,
    epLangOther,
    epSchool,
    epSchoolOther,
    epDept,
    epTeam,
    epClass
  ]);

  //**on change handlers from form */
  const handleSetLanguage = (e) => {
    setTargetLang(e.target.value);
  };

  const handleSetSchool = (e) => {
    setDLISchool(e.target.value);
  };

  const handleAffiliationSelect = (e) => {
    setAffil(e.target.value);
  };

  useEffect(() => {
    if (epAffil === "student" ? setIfDLIFLCStudent(true) : setIfDLIFLCStudent(false));
    if (epAffil === "student" || epAffil === "faculty" || epAffil === "dod" ? setHasCACRef(true) : setHasCACRef(false));
    if (epAffil === "other" ? setOtherAffiliation(true) : setOtherAffiliation(false));
    if (epSchool === "OTHER") {
      setOtherSchool(true);
    } else {
      setOtherSchool(false);
    }
    if (epTargetLang === "other") {
      setOtherLanguage(true);
    } else {
      setOtherLanguage(false);
    }
  }, [epAffil, epSchool, epTargetLang]);

  //allows us to close the modals from the child element

  //handle modals for data request/deletion
  //set 1 is for requesting data
  const handleRefModalState = useCallback((e) => {
    setShowRequestDataModal(false);
  }, []);
  const handleRefModalStateConfirm = useCallback((e) => {
    setShowRequestDataModal(false);
    setShowRequestDataSentModal(true);
  }, []);
  const handleRefConfirmationModalState = useCallback((e) => {
    setShowRequestDataSentModal(false);
  }, []);

  //set 2 is for deleting data
  const handleRefModalState2 = useCallback((e) => {
    setShowRequestDeleteDataModal(false);
  }, []);
  const handleRefModalStateConfirm2 = useCallback((e) => {
    setShowRequestDeleteDataModal(false);
    setShowRequestDeleteDataSentModal(true);
  }, []);
  const handleRefConfirmationModalState2 = useCallback((e) => {
    setShowRequestDeleteDataSentModal(false);
  }, []);

  //** output */
  return (
    <div>
      <div className="editProfileTop">
        <h1>Account Details</h1>
      </div>
      {loadingSpinner && (
        <div>
          <LoadingSpinner msg="" />
          <h3>Account Actions</h3>
          <h4>
            <a className="infoLink" onClick={handleResetPassword}>
              Change Password
            </a>{" "}
            ||{" "}
            <a className="infoLink" onClick={() => setShowRequestDataModal(true)}>
              Request Account Data
            </a>{" "}
            ||{" "}
            <a className="infoLink" onClick={() => setShowRequestDeleteDataModal(true)}>
              Request Account Deletion
            </a>{" "}
          </h4>
        </div>
      )}
      {!loadingSpinner && (
        <div className="editProfileBody">
          {hasCACRef ? (
            <div>
              {/* <h4>
                <b>Account Name:</b>&nbsp; CAC AccountRef# Placeholder
              </h4> */}
              <h4>
                <b>Email Address:</b>&nbsp;&nbsp;
                <input
                  disabled
                  id="editProfileEmail"
                  type="text"
                  size="30"
                  className="editProfile-input"
                  placeholder={accountName}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </h4>
            </div>
          ) : (
            <h4>
              <b>Account Name:</b>&nbsp;{accountName}
            </h4>
          )}

          <h4>
            <b>User Affiliation:</b>&nbsp;&nbsp;
            <select id="editProfileAffil" className="profileSelect" onChange={handleAffiliationSelect} value={epAffil}>
              {theAffilData === null && <option value="loading">Loading...</option>}
              {theAffilData !== null &&
                Object.entries(theAffilData).map((entry) => {
                  let [key, value] = entry;
                  return (
                    <option key={key} value={key}>
                      {value}
                    </option>
                  );
                })}
            </select>
            {otherAffiliation && (
              <input
                id="editProfileAffilOther"
                type="text"
                size="30"
                className="editProfile-input"
                placeholder="Other Institution"
                onChange={(e) => setAffilOth(e.target.value)}
                defaultValue={epAffilOther}
              />
            )}
          </h4>
          <h4>
            <b>Primary Target Language:</b>&nbsp;&nbsp;
            <select
              className="profileSelect"
              id="editProfileTargetLanguage"
              onChange={handleSetLanguage}
              value={epTargetLang}
            >
              {theLangData === null && <option value="loading">Loading...</option>}
              {theLangData !== null &&
                Object.entries(theLangData).map((entry) => {
                  let [key, value] = entry;
                  return (
                    <option key={key} value={key}>
                      {value}
                    </option>
                  );
                })}
            </select>
            {otherLanguage && (
              <input
                id="editProfileLangOther"
                type="text"
                size="30"
                className="editProfile-input"
                placeholder="Other Language"
                onChange={(e) => setLangOther(e.target.value)}
                defaultValue={epLangOther}
              />
            )}
          </h4>
          {ifDLIFLCStudent && (
            <div>
              <h4>
                <b>DLIFLC School:</b>&nbsp;&nbsp;
                <select className="profileSelect" id="editProfileSchool" onChange={handleSetSchool} value={epSchool}>
                  {theSchoolData === null && <option value="loading">Loading...</option>}
                  {theSchoolData !== null &&
                    Object.entries(theSchoolData).map((entry) => {
                      let [key, value] = entry;
                      return (
                        <option key={key} value={key}>
                          {value}
                        </option>
                      );
                    })}
                </select>
                {otherSchool && (
                  <input
                    size="30"
                    id="editProfileSchoolOther"
                    type="text"
                    className="editProfile-input"
                    placeholder="Other School"
                    onChange={(e) => setDLISchoolOther(e.target.value)}
                    defaultValue={epSchoolOther}
                  />
                )}
              </h4>
              <h4>
                <b>DLIFLC Department:</b>&nbsp;&nbsp;
                <input
                  id="editProfileDept"
                  type="text"
                  size="30"
                  className="editProfile-input"
                  placeholder="DLIFLC Department"
                  onChange={(e) => {
                    setDLIDept(e.target.value);
                  }}
                  defaultValue={epDept}
                />
              </h4>
              <h4>
                <b>DLIFLC Team Number:</b>&nbsp;&nbsp;
                <input
                  id="editProfileTeam"
                  type="text"
                  size="30"
                  className="editProfile-input"
                  placeholder="DLIFLC Team Number"
                  onChange={(e) => setDLITeam(e.target.value)}
                  defaultValue={epTeam}
                />
              </h4>
              <h4>
                <b>DLIFLC Class Number:</b>&nbsp;&nbsp;
                <input
                  id="editProfileClass"
                  type="text"
                  size="30"
                  className="editProfile-input"
                  placeholder="DLIFLC Class Number"
                  onChange={(e) => setDLIClass(e.target.value)}
                  defaultValue={epClass}
                />
              </h4>
            </div>
          )}
          <h4>
            <b>Account Last Updated:</b>&nbsp;&nbsp;{updatedAt}
          </h4>
          <h4>
            <b>Account Creation Date:</b>&nbsp;&nbsp;{createdAt}
          </h4>
          <br />
          <div className="headingLeft">
            {isSaving ? (
              <button>Saving Changes...</button>
            ) : (
              <button onClick={handleSaveAccountDetails}>Save Account Details</button>
            )}
            {showUpdatedMessage && (
              <div>
                <br />
                <br />
                <h4>Profile Updated!</h4>
              </div>
            )}
          </div>
          <br />
          <br />
          <br />
          <br />
          <br />
          <h3>Account Actions</h3>
          <h4>
            <a className="infoLink" onClick={handleResetPassword}>
              Change Password
            </a>{" "}
            ||{" "}
            <a className="infoLink" onClick={() => setShowRequestDataModal(true)}>
              Request Account Data
            </a>{" "}
            ||{" "}
            <a className="infoLink" onClick={() => setShowRequestDeleteDataModal(true)}>
              Request Account Deletion
            </a>{" "}
          </h4>
        </div>
      )}

      {showRequestDataModal && (
        <RequestDataModal
          thisEmail={epEmail}
          thisId={epId}
          refModalState={handleRefModalState}
          refModalStateConfirm={handleRefModalStateConfirm}
        />
      )}
      {showRequestDataSentModal && <RequestDataSentModal refModalState={handleRefConfirmationModalState} />}

      {showRequestDeleteDataModal && (
        <RequestDeleteDataModal
          thisEmail={epEmail}
          thisId={epId}
          refModalState={handleRefModalState2}
          refModalStateConfirm={handleRefModalStateConfirm2}
        />
      )}
      {showRequestDeleteDataSentModal && (
        <RequestDeleteDataSentModal refModalState={handleRefConfirmationModalState2} />
      )}

      <Footer showReportProblem={false} />
    </div>
  );
}
