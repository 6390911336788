import React from "react";

export default function Instruction(props) {
  return (
    <h3
      className="instruction"
      dangerouslySetInnerHTML={{
        __html: props.instruction,
      }}
    />
  );
}
