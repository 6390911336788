import React from "react";

export default function EdgeCaseAbove(props) {
  //console.log(props);

  return (<>Based on your performance in this ODA session, your ILR proficiency level is <b>3 or higher</b> (Current Level).
  <br />
  <br /><br />
  <b><i>Note:</i></b> The primary purpose of ODA is to provide you with formative feedback - feedback to help you in the learning process. The ILR level estimate you are given here is intended to function as a reference for charting your progress toward higher proficiency.  You may or may not receive the same level on an official test.
  <br /><br />
  The goal is to work incrementally toward your target proficiency level, by learning more about the content and the lexical, syntactical, and discourse aspects that you have not yet mastered. 
  <br /><br />
  You have demonstrated the ability to understand the highest level of content that the ODA system offers. The current version of ODA does not assess beyond the ability to understand texts on general abstract topics.  Therefore, this section on "what you NEED to do next" may contain little or no feedback.
  </>);}

