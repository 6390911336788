import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const MainPanelDisplay = (props) => {
  const [getAll, setGetAll] = useState(null);

  const data = props.content;
  let itemCount = 0;
  const mod = props.mod;
  const isData = props.isData;
  const checkAPI = props.checkAPI;
  const firstCheck = props.firstCheck;

  if (isData && Array.isArray(data)) {
    data.sort((a, b) => {
      if (a.user.email < b.user.email) {
        return -1;
      } else if (a.user.email > b.user.email) {
        return 1;
      }
      // If emails are the same, sort by date in descending order
      if (a.endtime < b.endtime) {
        return 1;
      } else if (a.endtime > b.endtime) {
        return -1;
      }
      // Move unfinished assessments to the bottom of the list
      if(a.endtime != null && b.endtime == null){
        return -1;
      }
      else if(a.endtime == null && b.endtime != null){
        return 1;
      }
      return 0; // If both email and date are the same, maintain the current order
    });

    itemCount = data.length;
  }

  const navigateExternal = (target, options) => {
    if (options.state) {
      localStorage.setItem("state", JSON.stringify(options.state));
    }
    window.open(target, "_blank", "noreferrer");
  };

  const userDP = (userEmail, itemID, userID, endtime) => {
    if(endtime == null){
      return 0;
    }
    const user = { userId: userID };
    if (mod === "grammar") {
      axios
        .get("/api/sessionGR/diagProfileTotal", {
          params: user,
        })
        .then((res) => {
          //setState({ ...state, getAll: res.data });
          setGetAll(res.data);

          navigateExternal("/profileGrammarAdmin", {
            // state: { from: "external navigation" }
            state: { grID: itemID, getGrTotal: res.data, mod: mod },
          });
        })
        .catch((err) => {
          console.log("error");
        });
    }

    if (mod === "reading") {
      axios
        .get("/api/sessionRC/diagProfileTopLevelInfo", {
          params: user,
        })
        .then((res) => {
          //setState({ ...state, getAll: res.data });
          setGetAll(res.data);

          navigateExternal("/profileAdmin", {
            // state: { from: "external navigation" }
            state: { getID: itemID, getAll: res.data, mod: mod },
          });
        })
        .catch((err) => {
          console.log("error");
        });
    }
    if (mod === "listening") {
      axios
        .get("/api/sessionLC/diagProfileTopLevelInfo", {
          params: user,
        })
        .then((res) => {
          //setState({ ...state, getAll: res.data });
          setGetAll(res.data);

          navigateExternal("/profileAdmin", {
            // state: { from: "external navigation" }
            state: { getID: itemID, getAll: res.data, mod: mod },
          });
        })
        .catch((err) => {
          console.log("error");
        });
    }
  };

  const Tracking = (mod, id) => {
    const items = [mod, id];
    localStorage.setItem("trackItems", JSON.stringify(items));

    const url = "/adminreport/main/itemtrack";
    window.open(url, "_blank");
  };

  if ((!isData && checkAPI) || (!isData && !checkAPI && firstCheck)) {
    return <h3>No data was found</h3>;
  }

  if (isData && Array.isArray(data)) {
    return (
      <div>
        <h3>
          {itemCount} Item{itemCount !== 1 ? "s" : ""} Found
        </h3>
        <div
          className="table"
          style={{
            display: "flex",
            flexDirection: "column",
            borderCollapse: "collapse",
          }}
        >
          <div
            className="row header"
            style={{
              display: "flex",
              borderBottom: "1px solid #ccc",
              marginBottom: "8px",
              fontWeight: "bold",
            }}
          >
            <div className="cell" style={{ flex: "1", padding: "8px" }}>
              Email & Item Track
            </div>
            <div className="cell" style={{ flex: "1", padding: "8px" }}>
              Date
            </div>
            <div className="cell" style={{ flex: "1", padding: "8px" }}>
              Target Level & DP Link
            </div>
          </div>

          {data.map((item, index) => (
            <div key={item._id}>
              {index > 0 &&
                data[index - 1].user.email !== item.user.email && (
                  <div
                    style={{
                      borderBottom: "2px solid #187680",
                      marginBottom: "8px",
                    }}
                  ></div>
                )}
              <div
                className="row"
                style={{
                  display: "flex",
                  borderBottom: "1px solid #ccc",
                  marginBottom: "8px",
                }}
              >
                <div
                  className="cell"
                  style={{ flex: "1", padding: "8px", cursor: "pointer" }}
                  onClick={() => Tracking(mod, item._id)}
                >
                  {item.user.email.split("@")[0]}
                  <br />@{item.user.email.split("@")[1]}
                </div>
                <div className="cell" style={{ flex: "1", padding: "8px" }}>
                  {item.endtime != null ? new Date(item.endtime).toLocaleString("en-US", {
                    month: "long",
                    day: "numeric",
                    year: "numeric",
                    hour: "numeric",
                    minute: "numeric",
                    weekday: "long",
                  })
                : "In Progress"}
                </div>
                <div
                  className="cell"
                  style={{ flex: "1", padding: "8px", cursor: "pointer" }}
                  onClick={() =>
                    userDP(
                      item.user.email,
                      item._id,
                      item.user._id,
                      item.endtime
                    )
                  }
                >
                  {item.targetLevel === null
                    ? "Above 3"
                    : item.targetLevel}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  } else {
    return <h3>No data was found</h3>;
  }
};

export default MainPanelDisplay;
