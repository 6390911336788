import React from "react";
import Question from "../components/Question";
import AnswerField from "../components/AnswerField";
import QuestionPassage from "../components/QuestionPassage";
import Next from "../../../../../components/testlet/Next_RC";

export default function Test(props) {
  return (
    <div className="templateInner HTO" key={props.actID}>
      <QuestionPassage
        passage={props.passage}
        actID={props.actID}
        dir={props.dir}
        lang={props.lang}
        ongetHTOData={props.ongetHTOData}
      />
      <Question questionText={props.questionText} />
      <AnswerField dir={props.dir} />
      <Next
        clickCounter={props.clickCounter}
        navLen={props.navLen}
        navCrrtItem={props.navCrrtItem}
        onClickNextBtn={props.onClickNextBtn}
        onSubmit={props.onSubmit}
        onEvaluation={props.onEvaluation}
      />
    </div>
  );
}
