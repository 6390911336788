import React, { useEffect, useState } from "react";
import FontSize from "../../../../../components/fontsize/FontSize";

export default function AssessmentPassage(props) {
  const [clickCounter, setClickCounter] = useState(props.clickCounter);

  const num = props.optionNumber;
  const testitem = props.testitem;
  const answerFieldsID = props.answerFieldsID;
  const instruction = props.instruction;

  const RTL = props.dir;
  const dir = RTL ? "RTL" : "";

  const questionPassage = props.optionPassage[0];
  let newPassage = questionPassage.replace(/<u>/gi, '<span class="textHighlight">');
  newPassage = newPassage.replace(/<\/u>/gi, "</span>");
  newPassage = '<span class="paragraphNumber">' + (num + 1) + ". </span> " + newPassage;
  newPassage = newPassage
    .replace(/\r\n/g, "<br />")
    .replace(/\n\r/g, "<br />")
    .replace(/\r/g, "<br />")
    .replace(/\n/g, "<br />")
    .replace(/<br \/><br \/>$/g, "")
    .replace(/<br \/><br \/>\s*$/g, "")
    .replace(/\s*$/g, "")
    .replace(/<p><\/p>$/g, "")
    .replace(/<p>\s*<\/p>$/g, "");
  newPassage = newPassage
    .replace(/<P>/g, "")
    .replace(/<p>/g, "")
    .replace(/<\/P>/g, "<br /><br />")
    .replace(/<\/p>/g, "<br /><br />")
    .replace(/(<br \/>\s*){3,4}/g, "<br /><br />").replace(/^(<br \/>\s*){2,4}/g,"");

  const answerContent = props.optionChoice;
  const contNum = answerContent.length;

  //moved into useEffect to reset positions in functional component
  useEffect(() => {
    document.querySelectorAll(".radioButton").forEach(function (event) {
      event.style.display = "none";
    });

    let gridItems = document.querySelectorAll(".GMCB .answerSection .gridItem");
    gridItems.forEach(function (gridItem) {
      gridItem.addEventListener("click", function (event) {
        let answerId = this.parentElement.parentElement.parentElement.closest(".answerField").id;
        answerId = "#" + answerId + " .gridItem";
        document.querySelectorAll(answerId).forEach(function (event) {
          event.classList.remove("selectedAnswer");
        });
        this.classList.add("selectedAnswer");
      });
    });

    setClickCounter(props.clickCounter);
  }, []);
  useEffect(() => {
    setClickCounter(props.clickCounter);
  }, [props]);

  useEffect(() => {
    if (clickCounter === 0) {
      document.querySelectorAll(".fstAttempt").forEach(function (event) {
        event.style.display = "inline";
      });
      document.querySelectorAll(".sndAttempt").forEach(function (event) {
        event.style.display = "none";
      });
    } else {
      document.querySelectorAll(".fstAttempt").forEach(function (event) {
        event.style.display = "none";
      });
      document.querySelectorAll(".sndAttempt").forEach(function (event) {
        event.style.display = "inline";
      });
    }
  }, [props, clickCounter]);

  const createVal = () => {
    let val = [];
    for (let i = 0; i < contNum; i++) {
      val.push(i);
    }
    return val;
  };

  const createText = () => {
    let txt = [];
    for (let i = 0; i < contNum; i++) {
      txt.push(answerContent[i].value);
    }
    return txt;
  };

  const itemVal = createVal();
  const itemTxt = createText();

  const radioID_fst = answerFieldsID + "_0";
  const radioID_snd = answerFieldsID + "_1";

  const anwerFieldNum = "answer_" + num;

  const createRadioButton = () => {
    let radioButton = [];
    for (let i = 0; i < contNum; i++) {
      const letter = String.fromCharCode(i + "A".charCodeAt(0));
      const mykey = props.actID + i;
      const mykey2 = props.actID + i + 0;
      radioButton.push(
        <div className="answerField" key={letter} id={anwerFieldNum}>
          <div key={mykey} className="fstAttempt">
            <input
              type="radio"
              className="radioButton"
              name={answerFieldsID}
              data-testitem={testitem}
              id={radioID_fst + i}
              placeholder={radioID_fst}
              value={itemVal[i]}
              onChange={props.onAnswerSelected}
            />
            <label htmlFor={radioID_fst + i}>
              <div className="gridItem">
                <span>{letter}: </span>
                {itemTxt[i]}
              </div>
            </label>
          </div>

          <div key={mykey2} className="sndAttempt">
            <input
              type="radio"
              className="radioButton"
              autoComplete="off"
              name={answerFieldsID}
              data-testitem={testitem}
              id={radioID_snd + i}
              placeholder={radioID_snd}
              value={itemVal[i]}
              onChange={props.onAnswerSelectedMod}
            />
            <label htmlFor={radioID_snd + i}>
              <div className="gridItem">
                <span>{letter}: </span>
                {itemTxt[i]}
              </div>
            </label>
          </div>
        </div>
      );
    }
    return radioButton;
  };

  const buildup = (
    <div className="answerSection noSelect">
      <div className="gridContainer">{createRadioButton()}</div>
    </div>
  );

  return (
    <div>
      {num === 0 ? (
        <div className={[dir, "textPassage noSelect"].join(" ")}>
          <FontSize />
          <div
            dangerouslySetInnerHTML={{
              __html: newPassage
            }}
          />
          <br />
        </div>
      ) : (
        <div className={[dir, "textPassage noSelect"].join(" ")}>
          <div
            dangerouslySetInnerHTML={{
              __html: newPassage
            }}
          />
          <br />
        </div>
      )}
      <h3 className={[dir, "instructions"].join(" ")}>{instruction}</h3>
      <br />
      {buildup}
    </div>
  );
}
