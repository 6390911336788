import React from "react";
import dompurify from "dompurify";

const DisplayData = (props) => {
  const sanitizer = dompurify.sanitize;
  const data = props.content;
  const firstSel = props.firstSelection;
  const secondSel = props.secondSelection;
  const thirdSel = props.thirdSelection;
  const isButtonClicked = props.isButtonClicked;

  const tag = props.tag;
  const topic = props.topic;

  const sortItems = (items) => {
    return items.sort((a, b) => a._id.localeCompare(b._id));
  };

  if (topic === true) {
    const SubtopicList = ({ subtopics }) => {
      return (
        <ul>
          {subtopics.map((subtopic, index) => (
            <li key={index}>{subtopic}</li>
          ))}
        </ul>
      );
    };

    const TopicList = ({ topics }) => {
      return (
        <div>
          {topics.map((topic) => (
            <div key={topic._id}>
              <h4>{topic._id.charAt(0).toUpperCase() + topic._id.slice(1)}</h4>
              {topic.subtopics && <SubtopicList subtopics={topic.subtopics} />}
            </div>
          ))}
        </div>
      );
    };

    return (
      <div style={{ textAlign: "left" }}>
        <h3 style={{ fontWeight: "bold" }}>{data[0].language} </h3>
        <TopicList topics={data.slice(1)} />
      </div>
    );
  }

  if (tag === true) {
    if (
      firstSel === "level" &&
      secondSel === "function"
      // &&
      // thirdSel === "competence"
    ) {
      return (
        <div style={{ textAlign: "left" }}>
          {isButtonClicked && (
            <h3>
              {data.language}:&nbsp;&nbsp;
              {data.modality.charAt(0).toUpperCase() + data.modality.slice(1)}
            </h3>
          )}
          {isButtonClicked &&
            Object.keys(data)
              .slice(2)
              .map((proficiencyLevel) => (
                <div key={proficiencyLevel}>
                  <span
                    style={{
                      fontWeight: "bold",
                      fontSize: "18px",
                    }}
                  >
                    {" "}
                    <hr style={{ border: "1px solid" }} />
                    Proficiency Level: {proficiencyLevel.substring(3)}
                    <hr style={{ border: "1px solid" }} />
                  </span>
                  {Object.keys(data[proficiencyLevel]).map((functionKey) => (
                    <div key={functionKey}>
                      <hr />
                      <ul>
                        <span
                          style={{
                            fontWeight: "bold",
                            fontSize: "18px",
                            textDecoration: "underline",
                          }}
                        >
                          Function: {functionKey}
                        </span>
                        {Object.keys(data[proficiencyLevel][functionKey]).map(
                          (competence) => (
                            <div key={competence}>
                              <br />
                              <span style={{ fontWeight: "bold" }}>
                                Competence: {competence}
                              </span>
                              {Object.keys(
                                data[proficiencyLevel][functionKey][competence]
                              ).map((tagID) => (
                                <div key={tagID}>
                                  {sortItems(
                                    data[proficiencyLevel][functionKey][
                                      competence
                                    ][tagID].items
                                  ).map((item) => (
                                    <div key={item._id}>
                                      <br />
                                      {item._id}
                                      {item.statements.map(
                                        (statement, index) => (
                                          <li
                                            key={index}
                                            style={{ marginLeft: "15px" }}
                                          >
                                            <span
                                              dangerouslySetInnerHTML={{
                                                __html: sanitizer(statement),
                                              }}
                                            />
                                          </li>
                                        )
                                      )}
                                      <br />
                                    </div>
                                  ))}
                                </div>
                              ))}
                            </div>
                          )
                        )}
                      </ul>
                    </div>
                  ))}
                </div>
              ))}
        </div>
      );
    }
    if (
      firstSel === "level" &&
      secondSel === "competence"
      // &&
      // thirdSel === "function"
    ) {
      return (
        <div style={{ textAlign: "left" }}>
          {isButtonClicked && (
            <h3>
              {data.language}:&nbsp;
              {data.modality.charAt(0).toUpperCase() + data.modality.slice(1)}
            </h3>
          )}
          {isButtonClicked &&
            Object.keys(data)
              .slice(2)
              .map((proficiencyLevel) => (
                <div key={proficiencyLevel}>
                  <span
                    style={{
                      fontWeight: "bold",
                      fontSize: "18px",
                    }}
                  >
                    {" "}
                    <hr style={{ border: "1px solid" }} />
                    Proficiency Level: {proficiencyLevel.substring(3)}
                    <hr style={{ border: "1px solid" }} />
                  </span>
                  {Object.keys(data[proficiencyLevel]).map((competence) => (
                    <div key={competence}>
                      <hr />
                      <ul>
                        <span
                          style={{
                            fontWeight: "bold",
                            fontSize: "18px",
                            textDecoration: "underline",
                          }}
                        >
                          Competence: {competence}
                        </span>
                        {Object.keys(data[proficiencyLevel][competence]).map(
                          (functionKey) => (
                            <div key={functionKey}>
                              <br />
                              <span style={{ fontWeight: "bold" }}>
                                Function: {functionKey}
                              </span>
                              {Object.keys(
                                data[proficiencyLevel][competence][functionKey]
                              ).map((tagID) => (
                                <div key={tagID}>
                                  {sortItems(
                                    data[proficiencyLevel][competence][
                                      functionKey
                                    ][tagID].items
                                  ).map((item) => (
                                    <div key={item._id}>
                                      <br />
                                      {item._id}
                                      {item.statements.map(
                                        (statement, index) => (
                                          <li
                                            key={index}
                                            style={{ marginLeft: "15px" }}
                                          >
                                            <span
                                              dangerouslySetInnerHTML={{
                                                __html: sanitizer(statement),
                                              }}
                                            />
                                          </li>
                                        )
                                      )}
                                      <br />
                                    </div>
                                  ))}
                                </div>
                              ))}
                            </div>
                          )
                        )}
                      </ul>
                    </div>
                  ))}
                </div>
              ))}
        </div>
      );
    }
    if (
      firstSel === "function" &&
      secondSel === "level"
      // &&
      // thirdSel === "competence"
    ) {
      return (
        <div style={{ textAlign: "left" }}>
          {isButtonClicked && (
            <h3>
              {data.language}:&nbsp;
              {data.modality.charAt(0).toUpperCase() + data.modality.slice(1)}
            </h3>
          )}
          {isButtonClicked &&
            Object.keys(data)
              .slice(2)
              .map((functionKey) => (
                <div key={functionKey}>
                  <span
                    style={{
                      fontWeight: "bold",
                      fontSize: "18px",
                    }}
                  >
                    {" "}
                    <hr style={{ border: "1px solid" }} />
                    Function: {functionKey}
                    <hr style={{ border: "1px solid" }} />
                  </span>
                  {Object.keys(data[functionKey]).map((proficiencyLevel) => (
                    <div key={proficiencyLevel}>
                      <hr />
                      <ul>
                        <span
                          style={{
                            fontWeight: "bold",
                            fontSize: "18px",
                            textDecoration: "underline",
                          }}
                        >
                          Proficiency Level: {proficiencyLevel.substring(3)}
                        </span>
                        {Object.keys(data[functionKey][proficiencyLevel]).map(
                          (competence) => (
                            <div key={competence}>
                              <br />
                              <span style={{ fontWeight: "bold" }}>
                                Competence: {competence}
                              </span>
                              {Object.keys(
                                data[functionKey][proficiencyLevel][competence]
                              ).map((tagID) => (
                                <div key={tagID}>
                                  {sortItems(
                                    data[functionKey][proficiencyLevel][
                                      competence
                                    ][tagID].items
                                  ).map((item) => (
                                    <div key={item._id}>
                                      <br />
                                      {item._id}
                                      {item.statements.map(
                                        (statement, index) => (
                                          <li
                                            key={index}
                                            style={{ marginLeft: "15px" }}
                                          >
                                            <span
                                              dangerouslySetInnerHTML={{
                                                __html: sanitizer(statement),
                                              }}
                                            />
                                          </li>
                                        )
                                      )}
                                      <br />
                                    </div>
                                  ))}
                                </div>
                              ))}
                            </div>
                          )
                        )}
                      </ul>
                    </div>
                  ))}
                </div>
              ))}
        </div>
      );
    }
    if (
      firstSel === "function" &&
      secondSel === "competence"
      //  &&
      // thirdSel === "level"
    ) {
      return (
        <div style={{ textAlign: "left" }}>
          {isButtonClicked && (
            <h3>
              {data.language}:&nbsp;
              {data.modality.charAt(0).toUpperCase() + data.modality.slice(1)}
            </h3>
          )}

          {isButtonClicked &&
            Object.keys(data)
              .slice(2)
              .map((functionKey) => (
                <div key={functionKey}>
                  <span
                    style={{
                      fontWeight: "bold",
                      fontSize: "18px",
                    }}
                  >
                    {" "}
                    <hr style={{ border: "1px solid" }} />
                    Function: {functionKey}
                    <hr style={{ border: "1px solid" }} />
                  </span>
                  {Object.keys(data[functionKey]).map((competence) => (
                    <div key={competence}>
                      <hr />
                      <ul>
                        <span
                          style={{
                            fontWeight: "bold",
                            fontSize: "18px",
                            textDecoration: "underline",
                          }}
                        >
                          Competence: {competence}
                        </span>
                        {Object.keys(data[functionKey][competence]).map(
                          (proficiencyLevel) => (
                            <div key={proficiencyLevel}>
                              <br />
                              <span style={{ fontWeight: "bold" }}>
                                Proficiency Level:{" "}
                                {proficiencyLevel.substring(3)}
                              </span>
                              {Object.keys(
                                data[functionKey][competence][proficiencyLevel]
                              ).map((tagID) => (
                                <div key={tagID}>
                                  {sortItems(
                                    data[functionKey][competence][
                                      proficiencyLevel
                                    ][tagID].items
                                  ).map((item) => (
                                    <div key={item._id}>
                                      <br />
                                      {item._id}
                                      {item.statements.map(
                                        (statement, index) => (
                                          <li
                                            key={index}
                                            style={{ marginLeft: "15px" }}
                                          >
                                            {/* tagID: {item._id} - Statement: {statement} */}
                                            <span
                                              dangerouslySetInnerHTML={{
                                                __html: sanitizer(statement),
                                              }}
                                            />
                                          </li>
                                        )
                                      )}
                                      <br />
                                    </div>
                                  ))}
                                  {/* </ul> */}
                                </div>
                              ))}
                            </div>
                          )
                        )}
                      </ul>
                    </div>
                  ))}
                </div>
              ))}
        </div>
      );
    }
    if (
      firstSel === "competence" &&
      secondSel === "level"
      // &&
      // thirdSel === "function"
    ) {
      return (
        <div style={{ textAlign: "left" }}>
          {isButtonClicked && (
            <h3>
              {data.language}:&nbsp;
              {data.modality.charAt(0).toUpperCase() + data.modality.slice(1)}
            </h3>
          )}
          {isButtonClicked &&
            Object.keys(data)
              .slice(2)
              .map((competenceX) => (
                <div key={competenceX}>
                  <span
                    style={{
                      fontWeight: "bold",
                      fontSize: "18px",
                    }}
                  >
                    {" "}
                    <hr style={{ border: "1px solid" }} />
                    Competence: {competenceX}
                    <hr style={{ border: "1px solid" }} />
                  </span>
                  {Object.keys(data[competenceX]).map((proficiencyLevel) => (
                    <div key={proficiencyLevel}>
                      <hr />
                      <ul>
                        <span
                          style={{
                            fontWeight: "bold",
                            fontSize: "18px",
                            textDecoration: "underline",
                          }}
                        >
                          Proficiency Level: {proficiencyLevel.substring(3)}
                        </span>
                        {Object.keys(data[competenceX][proficiencyLevel]).map(
                          (functionKey) => (
                            <div key={functionKey}>
                              <br />
                              <span style={{ fontWeight: "bold" }}>
                                Function: {functionKey}
                              </span>
                              {Object.keys(
                                data[competenceX][proficiencyLevel][functionKey]
                              ).map((tagID) => (
                                <div key={tagID}>
                                  {sortItems(
                                    data[competenceX][proficiencyLevel][
                                      functionKey
                                    ][tagID].items
                                  ).map((item) => (
                                    <div key={item._id}>
                                      <br />
                                      {item._id}
                                      {item.statements.map(
                                        (statement, index) => (
                                          <li
                                            key={index}
                                            style={{ marginLeft: "15px" }}
                                          >
                                            <span
                                              dangerouslySetInnerHTML={{
                                                __html: sanitizer(statement),
                                              }}
                                            />
                                          </li>
                                        )
                                      )}
                                      <br />
                                    </div>
                                  ))}
                                </div>
                              ))}
                            </div>
                          )
                        )}
                      </ul>
                    </div>
                  ))}
                </div>
              ))}
        </div>
      );
    }
    if (
      firstSel === "competence" &&
      secondSel === "function"
      // &&
      // thirdSel === "level"
    ) {
      return (
        <div style={{ textAlign: "left" }}>
          {isButtonClicked && (
            <h3>
              {data.language}:&nbsp;
              {data.modality.charAt(0).toUpperCase() + data.modality.slice(1)}
            </h3>
          )}
          {isButtonClicked &&
            Object.keys(data)
              .slice(2)
              .map((competenceX) => (
                <div key={competenceX}>
                  <span
                    style={{
                      fontWeight: "bold",
                      fontSize: "18px",
                    }}
                  >
                    {" "}
                    <hr style={{ border: "1px solid" }} />
                    Competence: {competenceX}
                    <hr style={{ border: "1px solid" }} />
                  </span>
                  {Object.keys(data[competenceX]).map((functionKeyx) => (
                    <div key={functionKeyx}>
                      <hr />
                      <ul>
                        <span
                          style={{
                            fontWeight: "bold",
                            fontSize: "18px",
                            textDecoration: "underline",
                          }}
                        >
                          Function: {functionKeyx}
                        </span>
                        {Object.keys(data[competenceX][functionKeyx]).map(
                          (competence) => (
                            <div key={competence}>
                              <br />
                              <span style={{ fontWeight: "bold" }}>
                                Proficiency Level: {competence.substring(3)}
                              </span>
                              {Object.keys(
                                data[competenceX][functionKeyx][competence]
                              ).map((tagID) => (
                                <div key={tagID}>
                                  {sortItems(
                                    data[competenceX][functionKeyx][competence][
                                      tagID
                                    ].items
                                  ).map((item) => (
                                    <div key={item._id}>
                                      <br />
                                      {item._id}
                                      {item.statements.map(
                                        (statement, index) => (
                                          <li
                                            key={index}
                                            style={{ marginLeft: "15px" }}
                                          >
                                            <span
                                              dangerouslySetInnerHTML={{
                                                __html: sanitizer(statement),
                                              }}
                                            />
                                          </li>
                                        )
                                      )}
                                      <br />
                                    </div>
                                  ))}
                                </div>
                              ))}
                            </div>
                          )
                        )}
                      </ul>
                    </div>
                  ))}
                </div>
              ))}
        </div>
      );
    }
  }
};

export default DisplayData;
