import React from "react";

export default function LoadingSpinner(props) {
  let showMsg = decodeURIComponent(encodeURIComponent(props.msg));

  //catch malformed inputs
  if (showMsg === null || showMsg === "" || showMsg === undefined) {
    //showMsg = "Please Wait...";
    showMsg = "";
  }

  return (
    <React.Fragment>
      <div style={{display:'flex',flexDirection:'column', justifyContent:'center', margin:'30px'}}>
      {props.size === "small" ? (
        <h4>{showMsg}</h4>
      ) : props.size === "newAssessment" ? (
        <div>
          <h3 className="spinnerHeadline">{showMsg}</h3>
        </div>
      ) : (
        <div>
          <h3 className="spinnerHeadline">{showMsg}</h3>
      </div>
      )}
      <div className="" style={{display:'flex', justifyContent:'center', margin:'50px'}}>
        <div className="loading-spinner" />
      </div>
      </div>
    </React.Fragment>
  );
}
