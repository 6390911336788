import dompurify from "dompurify";

const ODATestletInventoryDisplay = (props) => {
  const sanitizer = dompurify.sanitize;
  const data = props.content;
  const mod = props.mod;
  const sortedData = data.sort((a, b) => {
    if (a.proficiencyLevel !== b.proficiencyLevel) {
      return a.proficiencyLevel.localeCompare(b.proficiencyLevel);
    }
    return a._id.localeCompare(b._id);
  });

  const RCLC = () => {
    {
      let value = [];
      if (sortedData.length) {
        value.push(
          <div key={sortedData.length}>
            <h3 style={{ textAlign: "left" }}>
              {sortedData.length} Items Found
            </h3>
            <hr />
          </div>
        );
        value.push(
          sortedData.map((item) => (
            <div key={item._id}>
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "auto 1fr",
                  columnGap: "1rem",
                }}
              >
                <div>
                  <h4>{item._id}</h4>
                  <p>{item.proficiencyLevel}</p>
                </div>
                <div>
                  <p>{item.metadata[0].titleInEnglish}</p>
                  <p>{item.metadata[0].title}</p>
                </div>
              </div>
              <hr />
            </div>
          ))
        );
        return value;
      }
    }
  };

  const GR = () => {
    let value = [];
    if (sortedData.length) {
      value.push(
        <div key={sortedData.length}>
          <h3 style={{ textAlign: "left" }}>{sortedData.length} Items Found</h3>
          <hr />
        </div>
      );
      value.push(
        <div key={mod}>
          {sortedData.map((item) => (
            <div key={item._id}>
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "auto 1fr",
                  columnGap: "1rem",
                  rowGap: "1rem",
                  textAlign: "left",
                }}
              >
                <div>{item._id}</div>
                <div>{item.grammarLevel[0].description}</div>
                <div>{item.tag._id}</div>
                <div>{item.tag.topic}</div>
                <div></div>
                <div
                  dangerouslySetInnerHTML={{
                    __html: sanitizer(item.tag.statement),
                  }}
                />
              </div>
              <hr />
            </div>
          ))}
        </div>
      );
      return value;
    }
  };

  let selectedMod = "";
  if (mod === "reading" || mod === "listening") {
    selectedMod = RCLC();
  }
  if (mod === "grammar") {
    selectedMod = GR();
  }
  return <div>{selectedMod}</div>;
};

export default ODATestletInventoryDisplay;
