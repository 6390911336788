import React, {useRef } from "react";
import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";
import dompurify from "dompurify";
import { IoPlay } from "react-icons/io5";
import { IoPause } from "react-icons/io5";
import { GiSpeaker } from "react-icons/gi";
import { IoIosRewind } from "react-icons/io";
import { IoMdFastforward } from "react-icons/io";

export default function Audio(props) {
  const sanitizer = dompurify.sanitize;
  const LO = props.LO;
  const lang = props.lang;
  const mainAudio = props.mainAudio;
  const modifiedAudio = props.modifiedAudio;
  const main = "audio/" + lang + "/" + LO + "/" + mainAudio;
  const mod = "audio/" + lang + "/" + LO + "/" + modifiedAudio;
  const questionText = props.questionText;
  const audioPlayer = useRef(null);

  return (
    <div>
      <AudioPlayer
        id="ODAPlayer2"
        autoPlay={false}
        autoPlayAfterSrcChange={false}
        header={
          <div className="rhap_textCenter">
            Main Passage {props.toggleState ? "(Modified)" : ""}
            <span className="modifiedFlag" />
          </div>
        }
        footer={null}
        customAdditionalControls={[]}
        ref={audioPlayer.main}
        src={props.toggleState ? mod : main}
                customIcons={{
                //   play: <path fill="pink" d="M10 16.5v-9l6 4.5M12 2A10 10 0 0 0 2 12a10 10 0 0 0 10 10a10 10 0 0 0 10-10A10 10 0 0 0 12 2"/>,
                //   rewind: <IoIosRewind />,
                //   forward: <IoMdFastforward />,
                //   volume: <GiSpeaker />,
                // 
                play: <IoPlay />,
                pause: <IoPause />,
                rewind: <IoIosRewind />,
                forward: <IoMdFastforward />,
                volume: <GiSpeaker />,
                }}
        // onPlay={(e) => console.log("onPlay")}
      />
      <h3>
        <div className="audioQ">
          <span
            key={questionText[0]}
            dangerouslySetInnerHTML={{
              __html: sanitizer(questionText[0])
            }}
          />
          {questionText.length === 3 && (
            <>
              <AudioPlayer
                autoPlay={false}
                autoPlayAfterSrcChange={false}
                // header={props.toggleState ? "(modified)" : null}
                header={null} //no inline modified audio for LSA?
                footer={null}
                hasDefaultKeyBindings={false}
                customAdditionalControls={[]}
                customVolumeControls={[]}
                ref={audioPlayer.inline0}
                src={"audio/" + lang + "/" + LO + "/" + questionText[1]}
                showJumpControls={false}
                showDownloadProgress={false}
                showFilledProgress={false}
                customIcons={{
                  play: <IoPlay />,
                  pause: <IoPause />,
                  rewind: <IoIosRewind />,
                  forward: <IoMdFastforward />,
                  volume: <GiSpeaker />,
                }}
                // onPlay={(e) => console.log("onPlay")}
              />
              <span
                key={questionText[2]}
                dangerouslySetInnerHTML={{
                  __html: sanitizer(questionText[2])
                }}
              />
            </>
          )}
        </div>
      </h3>
    </div>
  );
}
