import React, { useState, useEffect, useContext, useCallback } from "react";
import Test from "./components/Test";
import Footer from "../../../../components/footer/Footer";
import "../../../../css/index.css";
//import fontsizeFunctions from "../../../../functions/fontsizeFunctions";
import draggableFunctions from "../../../../functions/draggableFunctions";
import { AssessmentContext } from "../../../../context/AssessmentProvider";
import { useScoreNLP } from "../../../../hooks/useScoreNLP";
import { checkEnglishInput } from "../../../../functions/genericFunctions";

const CMT = (props) => {
  const testData = props.testData;
  const [state, setState] = useState({
    actID: testData.testitems[props.counter]._id,
    modality: testData.modality,
    template: testData.testitems[props.counter].template,
    skill: testData.proficiencyLevel,
    passage: testData.testitems[props.counter].mainPassage,
    questionText: testData.testitems[props.counter].questionText,
    question: testData.testitems[props.counter].questions,
    dir: testData.language.isRightToLeft,
    userAnswer: [],
    scoredAnswer: [],
    correctAnswer: testData.testitems[props.counter].answerFields,
    answerField: testData.testitems[props.counter].answerFields
  });
  const [scoredAnswer, setScoredAnswer] = useState([]);
  state.userAnswer.length = testData.testitems[props.counter].answerFields.length;
  const [assessmentState] = useContext(AssessmentContext);
  const [runScoreNLP, setRunScoreNLP] = useState(false);
  const [eventNLP, setEventNLP] = useState(false);
  const [storeNLPScores, setStoreNLPScores] = useState([]);
  const [showNonENTextError, setShowNonENTextError] = useState([]);

  useEffect(() => {
    //fontSizeFunctions();
    draggableFunctions();
  }, []);

  useEffect(() => {
    //disable next/submit until previous api call is complete; prevents overrunning api calls to change same data
    if (props.buttonWaiting) {
      const apiWaitButtons = document.querySelectorAll(".nextBtn, .submitBtn");
      const titleChanger = document.querySelector(".nextSubmitParentDiv");
      apiWaitButtons.forEach((item, index) => {
        item.classList.add("waitingForAPI");
        if (item.classList.contains("disabled")) {
          titleChanger.title =
            "Please answer the questions above before proceeding. You will also need to wait for the system to record your previous answer before proceeding.";
        } else {
          titleChanger.title = "Please wait for your assessment to save your previous answer before proceeding.";
        }
      });
      titleChanger.classList.remove("nextSubmitParentDivUnanswered");
      titleChanger.classList.add("nextSubmitParentDivWaiting");
    } else {
      const titleChanger = document.querySelector(".nextSubmitParentDiv");
      const apiWaitButtons = document.querySelectorAll(".nextBtn, .submitBtn");
      apiWaitButtons.forEach((item, index) => {
        item.classList.remove("waitingForAPI");
        if (item.classList.contains("disabled")) {
          titleChanger.title = "Please attempt to answers all questions before proceeding.  Note: If you feel your assessment is stuck and you can not proceed, you may skip to the next question by holding SHIFT and clicking this button.";
        } else {
          titleChanger.title = "Click to submit your answer(s).";
        }
      });
      titleChanger.classList.remove("nextSubmitParentDivWaiting");
      titleChanger.classList.add("nextSubmitParentDivUnanswered");
    }
  }, [props.buttonWaiting]);

  const handleClick = useCallback(
    (event) => {
      const id = event.currentTarget.id;
      const index = event.currentTarget.id.substr(-1);
      const value = event.currentTarget.value;

      let new_state = Object.assign({}, state);
      let a = new_state.userAnswer;
      let z = new_state.scoredAnswer;

      a[index] = {
        userAnswer: value
      };

      /*test english input*/
      /* set up a dynamic array in state to track which elements have invalid characters */
      if (state.correctAnswer[index].isKeywordInEnglish) {
        if (!checkEnglishInput(value)) {
          let showNonENTextErrorTemp = showNonENTextError;
          if (
            !showNonENTextErrorTemp.find((thisEl) => thisEl === id + "_" + index) ||
            !showNonENTextErrorTemp.find((thisEl) => thisEl === id)
          ) {
            /*if invalid & doesnt yet exist; add it to error array */
            showNonENTextErrorTemp.push(id + "_" + index);
            showNonENTextErrorTemp.push(id);
            setShowNonENTextError(showNonENTextErrorTemp);
          } else {
            //do nothing
          }
        } else {
          let showNonENTextErrorTemp = showNonENTextError;
          if (showNonENTextErrorTemp.find((thisEl) => thisEl === id + "_" + index)) {
            /*if valid & exist; remove it from error array */
            let remIndex = (subEl) => subEl === id + "_" + index;
            let thisIndex = showNonENTextErrorTemp.findIndex(remIndex);
            showNonENTextErrorTemp.splice(thisIndex, 1);
          }
          if (showNonENTextErrorTemp.find((thisEl) => thisEl === id)) {
            /*if valid & exist; remove it from error array */
            let remIndex = (subEl) => subEl === id;
            let thisIndex = showNonENTextErrorTemp.findIndex(remIndex);
            showNonENTextErrorTemp.splice(thisIndex, 1);
          }
          setShowNonENTextError(showNonENTextErrorTemp);
        }
      }

      // judgeAnswers
      let getAnswer = false;
      let keywordMatch = [];
      let matching;
      let answerLen = 0;
      let itemScore = 0;

      const passingScore = JSON.stringify(state.correctAnswer[index].passingScore);
      if (passingScore == "100") {
        const answerKeywordLen = state.correctAnswer[index].answerKeywords.length;
        for (let i = 0; i < answerKeywordLen; i++) {
          const patternLen = state.correctAnswer[index].answerKeywords[i].matchingPatterns.length;
          for (let j = 0; j < patternLen; j++) {
            const crrtAns = state.correctAnswer[index].answerKeywords[i].matchingPatterns[j] + ".*";
            matching = value.toLowerCase().match(crrtAns.toLowerCase());

            if (matching !== null) {
              answerLen = answerLen + 1;
              keywordMatch.push(crrtAns.toLowerCase());
              break;
            }
          }
        }
        if (answerLen == answerKeywordLen) {
          getAnswer = true;
        } else {
          getAnswer = false;
        }
      } else {
        const answerKeywordLen = state.correctAnswer[index].answerKeywords.length;
        for (let i = 0; i < answerKeywordLen; i++) {
          let individualScore = state.correctAnswer[index].answerKeywords[i].individualScore;
          const patternLen = state.correctAnswer[index].answerKeywords[i].matchingPatterns.length;
          for (let j = 0; j < patternLen; j++) {
            const crrtAns = state.correctAnswer[index].answerKeywords[i].matchingPatterns[j] + ".*";
            matching = value.toLowerCase().match(crrtAns.toLowerCase());
            if (matching !== null) {
              itemScore = itemScore + individualScore;
              keywordMatch.push(crrtAns.toLowerCase());
              break;
            }
          }
        }
        if (itemScore >= passingScore) {
          getAnswer = true;
        } else {
          getAnswer = false;
        }
      }
      //End of judgeAnswers

      z[index] = {
        uiControlID: id,
        testlet: testData._id,
        testitem: state.actID,
        answerField: state.answerField[index]._id,
        keywordMatches: keywordMatch,
        userAnswer: value,
        isPassed: getAnswer
      };

      setState({
        ...state,
        userAnswer: a,
        scoredAnswer: z
      });

      const len = state.userAnswer.length;
      let num = 0;
      for (let i = 0; i < len; i++) {
        if (state.userAnswer[i] !== undefined) {
          num++;
          if (state.userAnswer[i].length === 0) {
            num--;
          }
        }
      }
      if (num === len) {
        document.querySelector(".nextBtn").classList.remove("disabled");
        const titleChanger = document.querySelector(".nextSubmitParentDiv");
        titleChanger.classList.remove("nextSubmitParentDivUnanswered");
        if (titleChanger.classList.contains("nextSubmitParentDivWaiting")) {
          titleChanger.title = "Please wait for your assessment to save your previous answer before proceeding.";
        } else {
          titleChanger.title = "Click to submit your answers.";
        }
      } else {
        document.querySelector(".nextBtn").classList.add("disabled");
        const titleChanger = document.querySelector(".nextSubmitParentDiv");
        titleChanger.classList.add("nextSubmitParentDivUnanswered");
        if (titleChanger.classList.contains("nextSubmitParentDivWaiting")) {
          titleChanger.title = "Please wait for your assessment to save your previous answer before proceeding.";
        } else {
          titleChanger.title = "Please attempt to answer all questions before proceeding.";
        }
      }
    },
    [state, showNonENTextError]
  );

  const handleEval = useCallback((name) => {
    const btnName = name;
    props.parentCallback(JSON.stringify(state.scoredAnswer), btnName);
  });

  // ** Issue #163 - disable NLP calls until post-launch period
  // //** customHook & effects to score from NLP API */
  // const [nlpScoreData, nlpScoreRunning, nlpScoreError] = useScoreNLP(
  //   eventNLP,
  //   "CMT",
  //   assessmentState.assessmentSessionId,
  //   state.correctAnswer,
  //   runScoreNLP
  // );
  // //only execute once
  // useEffect(() => {
  //   if (runScoreNLP) {
  //     setRunScoreNLP(false); //resets to false after one run
  //   }
  // }, [runScoreNLP]);
  // //initialization call from react component
  // const checkNLP = useCallback((event) => {
  //   setEventNLP(event);
  //   setRunScoreNLP(true);
  // }, []);
  // //store the async results in a static object we can reference at the end of the testlet
  // useEffect(() => {
  //   if (nlpScoreData !== null) {
  //     let tempArr = storeNLPScores;
  //     if (nlpScoreData.testletId !== null && nlpScoreData.nlpScore !== null) {
  //       tempArr[nlpScoreData.testletId] = nlpScoreData.nlpScore;
  //       //setStoreNLPScores(tempArr);
  //     }
  //     //console.log(tempArr);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [nlpScoreData]);
  // useEffect(() => {
  //   if (nlpScoreError !== null) {
  //     console.log("NLP error: " + nlpScoreError);
  //   }
  // }, [nlpScoreError]);

  const renderTest = () => {
    return (
      <>
        <Test
          passage={state.passage}
          actID={state.actID}
          dir={state.dir}
          questionText={state.questionText}
          question={state.question}
          onAnswerSelected={handleClick}
          navLen={props.navLen}
          navCrrtItem={props.navCrrtItem}
          onClickNextBtn={props.clickNextBtn}
          onSubmit={props.clickSubmit}
          onEvaluation={handleEval}
          // ** Issue #163 - disable NLP calls until post-launch period
          //onLoseFocusCheckNLP={checkNLP}
          invalidEnInput={showNonENTextError}
        />
        <Footer actID={state.actID} mod={state.modality} template={state.template} skill={state.skill} />
      </>
    );
  };

  return <div>{renderTest()}</div>;
};

export default CMT;
