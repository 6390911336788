import React from "react";

const ODAUsageDisplay = (props) => {
  const data = props.content;
  const isData = props.isData;
  const checkAPI = props.checkAPI;
  const firstCheck = props.firstCheck;
  const mod = props.mod;


  const modality =
    mod === "grammar" ? "Grammar" : mod === "reading" ? "Reading" : "Listening";

  const sortedData = data.sort((a, b) =>
    a[0].language > b[0].language ? 1 : -1
  );

  if ((!isData && checkAPI) || (!isData && !checkAPI && firstCheck)) {
    return <h3>No data was found</h3>;
  }

  if (isData) {
    return (
      <div>
        <h3 style={{ fontWeight: "bold" }}>{modality} Statistics</h3>
        {sortedData.map((item) => (
          <div key={item[0].language}>
            <h4 style={{ textAlign: "left" }}>{item[0].language}</h4>
            <table>
              <thead>
                <tr>
                  <th
                    style={{
                      backgroundColor: "#ededed",
                      color: "#187680",
                      textAlign: "center",
                    }}
                  >
                    All Users
                  </th>
                  <th
                    style={{
                      backgroundColor: "#ededed",
                      color: "#187680",
                      textAlign: "center",
                    }}
                  >
                    Exactly Once
                  </th>
                  <th
                    style={{
                      backgroundColor: "#ededed",
                      color: "#187680",
                      textAlign: "center",
                    }}
                  >
                    Exactly Twice
                  </th>
                  <th
                    style={{
                      backgroundColor: "#ededed",
                      color: "#187680",
                      textAlign: "center",
                    }}
                  >
                    More than 2 times
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td
                    style={{
                      color: "#67A3AA",
                      fontWeight: "bold",
                      textAlign: "center",
                    }}
                  >
                    {item[0].totalUsers}
                  </td>
                  <td>
                    <table style={{ borderStyle: "none" }}>
                      <tbody>
                        <tr>
                          <td
                            style={{
                              color: "#67A3AA",
                              fontWeight: "normal",
                              textAlign: "center",
                              borderStyle: "none",
                              borderBottomStyle: "solid",
                            }}
                          >
                            All
                          </td>
                          <td
                            style={{
                              color: "#67A3AA",
                              fontWeight: "normal",
                              textAlign: "center",
                              borderStyle: "none",
                              borderLeftStyle: "solid",
                              borderBottomStyle: "solid",
                            }}
                          >
                            With Profile
                          </td>
                        </tr>
                        <tr>
                          <td
                            style={{
                              color: "#67A3AA",
                              fontWeight: "bold",
                              textAlign: "center",
                              borderStyle: "none",
                            }}
                          >
                            {item[2].totalUsers}
                          </td>
                          <td
                            style={{
                              color: "#67A3AA",
                              fontWeight: "bold",
                              textAlign: "center",
                              borderStyle: "none",
                              borderLeftStyle: "solid",
                            }}
                          >
                            {item[1].totalUsers}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                  <td>{item[3].totalUsers}</td>
                  <td>{item[4].totalUsers}</td>
                </tr>
              </tbody>
            </table>
          </div>
        ))}
      </div>
    );
  }
};

export default ODAUsageDisplay;
