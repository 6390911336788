import { useState, useEffect } from "react";
import axios from "axios";
import axiosRetry from "axios-retry";

/*
 * useCollectSchoolhouse:
 * a custom hook to gather the available user schoolhouse from the database and return as so:
 *
 * [
 *  id1 : schoolDisplayName1
 *  id2 : schoolDisplayName2
 *  id3 : schoolDisplayName3
 * ]
 *
 * Used in client/contents/EditProfile currently.
 */

export function useCollectSchoolhouse(currentData) {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  axiosRetry(axios, {
    retries: 4, // number of retries

    retryDelay: (...arg) => axiosRetry.exponentialDelay(...arg, 1000), // Exponential delay with backoff of 1000mst
    retryCondition: (err) => {console.log(err.response);
      return (
        axiosRetry.isNetworkError(err) ||
        err.code === "ECONNABORTED" ||
        err.code === "ENOTFOUND" ||
        err.code === "ETIMEDOUT" ||
        (err.response && err.response.status >= 500 && err.response.status <= 599)
      );
    }
  });

  useEffect(() => {
    if (currentData === null) {
      axios
        //grab the affiliations from API
        // .get("api/school", { params: { isActive: true, _sort: { sequence: 1 } } })
        .get("api/school")
        .then((res) => {
          //parse output into an associative array
          const affilArr = Object.entries(res.data).reduce(function (accum, current) {
            const [, value] = current;
            accum[value._id] = value.displayName;
            return accum;
          }, []);
          setData(affilArr);
        })
        .catch((err) => {
          setError(err);
        })
        .finally();
    }
  }, [currentData]);

  return [data, error];
}
