import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
require("react-datepicker/dist/react-datepicker.css");

const DateRangePicker = ({ onDatesSelected }) => {
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);

  const handleFromDateChange = (date) => {
    setFromDate(date);
    onDatesSelected(date, toDate);
  };

  const handleToDateChange = (date) => {
    setToDate(date);
    onDatesSelected(fromDate, date);
  };

  const fifteenYearsAgo = new Date();
  fifteenYearsAgo.setFullYear(fifteenYearsAgo.getFullYear() - 15);

  return (
    <div>
      <div>
        <label>Start Date:</label>
        <DatePicker
          selected={fromDate}
          onChange={handleFromDateChange}
          dateFormat="MM/dd/yyyy"
          showYearDropdown
          scrollableYearDropdown
          yearDropdownItemNumber={15}
          maxDate={new Date()}
          minDate={fifteenYearsAgo}
        />
      </div>
      <div>
        <label>End Date:</label>
        <DatePicker
          selected={toDate}
          onChange={handleToDateChange}
          dateFormat="MM/dd/yyyy"
          showYearDropdown
          scrollableYearDropdown
          yearDropdownItemNumber={15}
          maxDate={new Date()}
          minDate={fifteenYearsAgo}
        />
      </div>
    </div>
  );
};

export default DateRangePicker;
