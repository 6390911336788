import React, { useEffect } from "react";
import FontSize from "../../../../../components/fontsize/FontSize";

export default function AssessmentPassage(props) {
  const num = props.optionNumber;
  const itemNum = props.optionNumber + 1;
  const testitem = props.testitem;
  const answerFieldsID = props.answerFieldsID;

  const itemID_fst = answerFieldsID + "_0";
  const itemID_snd = answerFieldsID + "_1";

  const instruction = props.instruction;
  const currtInstion = instruction;
  let newInstruction = currtInstion.replace(/underlined/gi, "highlighted");

  const RTL = props.dir;
  const dir = RTL ? "RTL" : "";

  const passage = props.optionContent;
  const len = passage.length;
  let newPassage = "";
  for (let i = 0; i < len; i++) {
    newPassage = passage[i].replace(/<u>/gi, '<span class="textHighlight">');
    newPassage = newPassage.replace(/<\/u>/gi, "</span>");
    if (num === 0) newPassage = '<span class="paragraphNumber">' + itemNum + ". </span> " + newPassage;
    else newPassage = '<br /><span class="paragraphNumber">' + itemNum + ". </span> " + newPassage;
  }
  newPassage = newPassage
    .replace(/\r\n/g, "<br />")
    .replace(/\n\r/g, "<br />")
    .replace(/\r/g, "<br />")
    .replace(/\n/g, "<br />")
    .replace(/<br \/><br \/>$/g, "")
    .replace(/<br \/><br \/>\s*$/g, "")
    .replace(/\s*$/g, "")
    .replace(/<p><\/p>$/g, "")
    .replace(/<p>\s*<\/p>$/g, "");
  newPassage = newPassage
    .replace(/<P>/g, "")
    .replace(/<p>/g, "")
    .replace(/<\/P>/g, "<br /><br />")
    .replace(/<\/p>/g, "<br /><br />")
    .replace(/(<br \/>\s*){3,4}/g, "<br /><br />").replace(/^(<br \/>\s*){2,4}/g,"");

  let clickCounter = props.clickCounter;

  useEffect(() => {
    if (clickCounter === 0) {
      document.querySelectorAll(".fstAttempt").forEach(function (event) {
        event.style.display = "inline";
      });
      document.querySelectorAll(".sndAttempt").forEach(function (event) {
        event.style.display = "none";
      });
    } else {
      document.querySelectorAll(".fstAttempt").forEach(function (event) {
        event.style.display = "none";
      });
      document.querySelectorAll(".sndAttempt").forEach(function (event) {
        event.style.display = "inline";
      });
    }
  }, [clickCounter]);

  const createPassgeTextField = () => {
    let passgeTextField = [];
    passgeTextField.push(
      <div key={itemID_fst}>
        <div className="questionPasssage">
          {num === 0 ? (
            <div className={[dir, "textPassage noSelect"].join(" ")}>
              <FontSize />
              <span
                dangerouslySetInnerHTML={{
                  __html: newPassage
                }}
              />
            </div>
          ) : (
            <div className={[dir, "textPassage noSelect"].join(" ")}>
              <span
                dangerouslySetInnerHTML={{
                  __html: newPassage
                }}
              />
            </div>
          )}
        </div>

        <h3>{newInstruction}</h3>
        <div className="gm_wrapTextarea">
          <form autoComplete="off">
            <span className="fstAttempt">
              <textarea
                className="gm_textareaDisplay"
                name={answerFieldsID}
                autoComplete="off"
                data-testitem={testitem}
                id={itemID_fst}
                onBlur={props.onLoseFocusCheckNLP}
                onChange={props.onAnswerSelected}
                spellCheck={true}
                onPaste={(e) => {
                  e.preventDefault();
                  return false;
                }}
                onCopy={(e) => {
                  e.preventDefault();
                  return false;
                }}
                onCut={(e) => {
                  e.preventDefault();
                  return false;
                }}
              />
            </span>
            <span className="sndAttempt">
              <textarea
                className="gm_textareaDisplay"
                name={answerFieldsID}
                autoComplete="off"
                data-testitem={testitem}
                id={itemID_snd}
                onBlur={props.onLoseFocusCheckNLP}
                onChange={props.onAnswerSelectedMod}
                spellCheck={true}
                onPaste={(e) => {
                  e.preventDefault();
                  return false;
                }}
                onCopy={(e) => {
                  e.preventDefault();
                  return false;
                }}
                onCut={(e) => {
                  e.preventDefault();
                  return false;
                }}
              />
            </span>
          </form>
        </div>
      </div>
    );
    // }
    return passgeTextField;
  };
  return (
    <div>
      <div>{createPassgeTextField()}</div>
    </div>
  );
}
