/**
 *  Intro.js specific non-state funtions should live here
 *
 */
import { offset } from "./genericFunctions";

// ** Loads non-state javascript in component onready in Intro.js **
export default function nonFunctions() {
  //document.addEventListener("DOMContentLoaded", function () {
  let selEl = document.querySelectorAll(".learnMore");
  for (let i = 0; i < selEl.length; i++) {
    selEl[i].addEventListener("click", (e) => {
      let infoTop = offset(".info");
      window.scroll({
        top: infoTop.top - 50,
        left: 0,
        behavior: "smooth"
      });
    });
  }
  //});
}
