import React, { useState, useEffect } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import axios from "axios";
import DateRangePicker from "./adminComponents/DateRangePicker";
import AdminFooter from "./adminComponents/AdminFooter";
import ItemItemDisplay from "./adminDisplay/ItemItemDisplay";

const ItemItemCorrelation = () => {
  const initialState = {
    contents: [],
    language: [],
    selectedLanguage: "",
    selectedModality: "",
    selectedSite: "",
    selectedUserLevel: "",
    selectedTestlet: "",
  };

  const [state, setState] = useState(initialState);
  const [isRecordsFetched, setIsRecordsFetched] = useState(false);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);

  const date = new Date("1970-01-01");
  const isoDate = date.toISOString().slice(0, 10);

  function refreshPage() {
    setIsRecordsFetched(false);
    window.location.reload(false);
  }

  const {
    language,
    selectedSite,
    selectedLanguage,
    selectedModality,
    selectedUserLevel,
    selectedTestlet,
  } = state;

  useEffect(() => {
    axios
      .get('/api/language?modalities__ne=[]&_sort={"sequence": 1}')
      .then((res) => {
        setState({ ...state, language: res.data });
      });
  }, []);

  let langDropdown = language.map((item, index) => (
    <option key={index} value={item._id}>
      {item.displayName}
    </option>
  ));

  const handleLanguageChange = (event) => {
    setState({ ...state, selectedLanguage: event.target.value });
  };

  const handleModalityChange = (event) => {
    setState({ ...state, selectedModality: event.target.value });
  };

  const handleSiteChange = (event) => {
    setState({ ...state, selectedSite: event.target.value });
  };

  const handleUserLevelChange = (event) => {
    setState({ ...state, selectedUserLevel: event.target.value });
  };
  const handleTestletChange = (event) => {
    setState({ ...state, selectedTestlet: event.target.value });
  };

  const handleDatesSelected = (fromDate, toDate) => {
    const pickFrom = new Date(fromDate);
    const isoFrom = pickFrom.toISOString().slice(0, 10);

    const pickTo = new Date(toDate);
    const isoTo = pickTo.toISOString().slice(0, 10);
    setFromDate(isoFrom);
    setToDate(isoTo);
  };

  const getValues = () => {
    setIsRecordsFetched(true);
    const user = {
      site: selectedSite,
      lang: selectedLanguage,
      mod: selectedModality,
      userLvl: selectedUserLevel,
      testlet: selectedTestlet,
      startDate: fromDate,
      endDate: toDate,
    };

    const valuesAreValid =
      selectedSite !== "Select" &&
      selectedLanguage !== "Select" &&
      selectedModality !== "Select" &&
      selectedUserLevel !== "Select" &&
      selectedSite !== "" &&
      selectedLanguage !== "" &&
      selectedModality !== "" &&
      selectedUserLevel !== "" &&
      selectedTestlet !== "" &&
      fromDate !== isoDate &&
      toDate !== isoDate &&
      fromDate !== null &&
      toDate !== null;

    if (valuesAreValid) {
      console.log("values=", {
        site: selectedSite,
        language: selectedLanguage,
        modality: selectedModality,
        userLevel: selectedUserLevel,
        testlet: selectedTestlet,
        startDate: fromDate,
        endDate: toDate,
      });
      axios
        .get("/api/admin/getItemItem", { params: user })
        .then((res) => {
          setState({ ...state, contents: res.data });
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.status >= 400 && error.response.status < 600) {
            }
          }
        });
    } else {
      console.log("No value can be null");
      alert("Select all items");
    }
  };

  return (
    <div className="adminMainPanel">
      <div className="infoTitle adminInfoTitle">
        <div>ODA Item-Item Correlation</div>
      </div>
      <div>
        Site:
        <select key="site" value={selectedSite} onChange={handleSiteChange}>
          <option>Select</option>
          <option value="oda">ODA</option>
        </select>
      </div>
      <div>
        Language:
        <select
          key="language"
          value={selectedLanguage}
          onChange={handleLanguageChange}
        >
          <option>Select</option>
          {langDropdown}
        </select>
      </div>
      <div>
        Modality:
        <select
          key="modality"
          value={selectedModality}
          onChange={handleModalityChange}
        >
          <option>Select</option>
          <option value="reading">Reading</option>
          <option value="listening">Listening</option>
        </select>
      </div>
      <div>
        User Level:
        <select
          key="userLevel"
          value={selectedUserLevel}
          onChange={handleUserLevelChange}
        >
          <option>Select</option>
          <option value="0">Below 1</option>
          <option value="1">1</option>
          <option value="1+">1+</option>
          <option value="2">2</option>
          <option value="2+">2+</option>
          <option value="3">3</option>
          <option value="all">All Levels</option>
        </select>
      </div>

      <label htmlFor="testlet">
        Testlet_ID (eg. chn_ecn103):
        <input id="testlet" onChange={handleTestletChange} />
      </label>
      <DateRangePicker onDatesSelected={handleDatesSelected} />
      <div className="buttonGroup">
        <div onClick={getValues} className="searchButton">
          Get Records
        </div>
        <div onClick={refreshPage} className="searchButton">
          Reset Options
        </div>
      </div>
      <div className="adminContentBox" style={{ overflow: "auto" }}>
        {isRecordsFetched && (
          <ItemItemDisplay
            mod={state.selectedModality}
            lang={state.selectedLanguage}
            testlet={state.selectedTestlet}
            level={state.selectedUserLevel}
            fromDate={fromDate}
            toDate={toDate}
            // site=''
          />
        )}
      </div>

      <AdminFooter />
    </div>
  );
};

export default ItemItemCorrelation;
