import React from "react";
import dompurify from "dompurify";

export default function Question(props) {
  const sanitizer = dompurify.sanitize;
  const questionText = "<h3>" + props.questionText + "</3>";

  return (
    <div
      dangerouslySetInnerHTML={{
        __html: sanitizer(questionText),
      }}
    />
  );
}
