import dompurify from "dompurify";

const PasageByProficiencyLevelDisplay = (props) => {
  const sanitizer = dompurify.sanitize;
  const data = props.content;
  const mod = props.mod;
  const sortedData = data.sort((a, b) => a._id.localeCompare(b._id));

  console.log("test=", JSON.stringify(sortedData));
  return (
    <div>
      {sortedData.length !== 0 && (
        <div style={{ textAlign: "left" }}>
          <h3>{`${sortedData.length} Items Found`}</h3>
          <br />
          {sortedData.map((item) => (
            <div key={item._id}>
              <strong>{item._id.toUpperCase()}</strong>
              <br />
              <br />
              <div key={item.data.mainProposition.description}>
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "1fr 1fr",
                    gap: "30px",
                    textAlign: "left",
                  }}
                >
                  <div>
                    {props.mod === "reading" ? (
                      <div>
                        <div
                          style={{ fontWeight: "bold" }}
                          dangerouslySetInnerHTML={{
                            __html: sanitizer(item.data.title),
                          }}
                        />
                        <div
                          dangerouslySetInnerHTML={{
                            __html: sanitizer(item.data.mainText),
                          }}
                        />
                      </div>
                    ) : (
                      <div>
                        <div
                          style={{ fontWeight: "bold" }}
                          dangerouslySetInnerHTML={{
                            __html: sanitizer(item.data.title),
                          }}
                        />
                        <br />
                        <div
                          dangerouslySetInnerHTML={{
                            __html: sanitizer(item.data.transcript),
                          }}
                        />
                      </div>
                    )}
                  </div>

                  <div>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: sanitizer(
                          item.data.mainProposition.description
                        ),
                      }}
                    />
                    <ul>
                      {item.data.mainProposition.supportingPropositions.map(
                        (support, index) => (
                          <li key={index}>
                            <div
                              dangerouslySetInnerHTML={{
                                __html: sanitizer(support),
                              }}
                            />
                          </li>
                        )
                      )}
                    </ul>
                  </div>
                </div>
                <hr />
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default PasageByProficiencyLevelDisplay;
