import { useContext, useState } from "react";
import { ErrorContext } from "../context/ErrorProvider";

/**
 * @hook useErrorState
 * @description custom hook to handle error state & minimize inline code
 **/

export default function useErrorState(error, errorType = "UPDATE_ERROR_STATE_DETAILS", userMessage = "New Error") {
  const [modalErrorState, errorDispatch] = useContext(ErrorContext);
  const [dispatchedError, setDispatchedError] = useState(null);

  if (
    modalErrorState?.errorUserMessage === "" ||
    modalErrorState?.errorUserMessage === null ||
    modalErrorState?.errorUserMessage === undefined
  ) {
    if (error && error.hasOwnProperty("response") && dispatchedError !== true) {
      console.log("useErrorState error:");
      console.log(error);
      if (error.response) {
        if (error.response.status >= 400 && error.response.status < 600) {
          //global error dispatch call to show error modal if error received during api call
          let details = "";
          if (error.response.data.message === undefined) {
            details = JSON.stringify(error.response.data.errors);
          } else {
            details = error.response.data.message;
          }
          let errorPayload = {
            type: errorType,
            payload: {
              errorStatusCode: error.response.status,
              errorUserMessage: userMessage,
              errorUserMessageDetails: error.code + ": " + details,
              errorDevData: error.response
            }
          };
          console.log("dispatching error from useErrorState, this should trigger the error modal");
          console.log(errorPayload);
          errorDispatch(errorPayload);
          setDispatchedError(true); // need to expand this to ensure only one modal pops up
          // TODO: this would be a great location to append errors to a persistent log file via a backend call
        }
      }
    }
  }
  if (modalErrorState.errorStatusCode !== null) {
    console.log(modalErrorState);
  }
  return null;
}
