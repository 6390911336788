import React, { useState, useContext, useCallback } from "react";
import { Link } from "react-router-dom";
// import Dropdown from "../../../components/menubar/Dropdown";
import { CgMenu } from "react-icons/cg";
import { CgClose } from "react-icons/cg";
import { AssessmentContext } from "../../../context/AssessmentProvider";

import Auth from "../../../contents/Auth";
import Signout from "../../../components/signout/Signout";
import header from "../../../img/logos/headerLogo.svg";
import headerSmall from "../../../img/logos/headerLogoSmall.svg";
import accountIcon from "../../../img/icons/account.svg";

export default function Header() {
  //Global State Context Calls, links to context/AssessmentProvider.js & context/ErrorProvider.js
  const [assessmentState] = useContext(AssessmentContext);

  const logout = () => {
    Auth.logout(() => {
      // this.props.history.push("/");
      // window.location.reload();
      window.location.href = "/";
    });
  };
  const [click, setClick] = useState(false);
  //const [homeClick, setHomeClick] = useState(false);
  // const [dropdown, setDropdown] = useState(false);

  const handleClick = useCallback(() => {
    setClick(!click);
  }, [click]);

  // const closeMobileMenu = useCallback(() => {
  //   document.querySelector(".bodyItems").style.display = "none";
  //   document.querySelector(".assessmentFooter").style.visibility = "hidden";
  //   setClick(false);
  // },[]);

  // const showItems = useCallback(() => {
  //   document.querySelector(".bodyItems").style.display = "inline";
  //   document.querySelector(".assessmentFooter").style.visibility = "visible   ";
  //   setClick(false);
  // },[]);
  // const onMouseEnter = useCallback(() => {
  //   setDropdown(true);
  // },[]);

  // const onMouseLeave = useCallback(() => {
  //   setDropdown(false);
  // },[]);

  const openIcon = (
    <CgMenu className="cgHamberger cgHamberger-DP" size="25px" color="green" />
  );
  const closeIcon = (
    <CgClose className="cgHamberger cgHamberger-DP" size="25px" color="green" />
  );

  // const handleDropDown = useCallback((e) => {
  //   if (e.style.display === "none") {
  //     document.getElementById("signout-content").style.display = "block";
  //   } else {
  //     document.getElementById("signout-content").style.display = "none";
  //   }
  // },[]);

  return (
    <header className="profileHeaderA">
      <Link to="/dashboard">
        <img
          className="logo"
          src={header}
          alt="Online Diagnostic Assessment Logo"
          title="Click here to return to your assessment dashboard."
        />
        <img className="logoSmall" src={headerSmall} alt="Online Diagnostic Assessment Logo" />
      </Link>
      <Signout>
        <div className="account">
          <img id="accountIcon" src={accountIcon} alt="Account Logo" />
        </div>

        <div className="signout-content" id="signout-content">
          <div onClick={logout}>
            Signed in as <br /> {assessmentState.assessmentUserEmail}. <br />
            <br /> Logout?
          </div>
        </div>
      </Signout>
      <nav className="menuNav">
        <ul className={click ? "nav-menu active nav-menu-DP" : "nav-menu"}>
          <li className="nav-item linkAA">
            <Link to="/dashboard" className="nav-links">
              Assessment Dashboard
            </Link>
          </li>
          <li className="nav-item linkAA">
            <Link to="/surveyRCLC" className="nav-links">
              Survey
            </Link>
          </li>
        </ul>
      </nav>
      <div className="menu-icon" onClick={handleClick}>
        {click ? closeIcon : openIcon}
      </div>
    </header>
  );
}
