import React, { Component } from "react";

export default class None extends Component {
  constructor() {
    super();
    this.state = {
      name: "React",
    };
  }

  render() {
    return (
      <div className="assessment noAssessments">
        <h4>You have not completed any assessments yet.</h4>
      </div>
    );
  }
}
