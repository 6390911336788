import React from "react";
import Passage from "../test/Passage";
import AnswerOption from "../test/AnswerOption";
import Instruction from "../test/Instruction";
import Next from "../../../../../components/testlet/Next";

export default function Test(props) {
  const renderQuestion = (options, index) => {
    const number = options.multipleChoiceAns[0].map((option, index) => {
      return index;
    });

    const questions = options.questions.map((option, index) => {
      return option;
    });

    const ddList = options.multipleChoiceAns[0].map((option, index) => {
      return option;
    });

    const answerField = options.answerFields.map((option, index) => {
      return option._id;
    });

    return (
      <AnswerOption
        key={index}
        questionWord={questions}
        ddList={ddList}
        optionNumber={number}
        answerFieldsID={answerField}
        testitem={props.question[0]._id}
        actID={props.actID}
        value={props.value}
        clickCounter={props.clickCounter}
        onAnswerSelected={props.onAnswerSelected}
        onAnswerSelectedMod={props.onAnswerSelectedMod}
      />
    );
  };

  return (
    <div className="templateInner GMCI">
      <Passage passage={props.passage} dir={props.dir} />
      <Instruction instruction={props.instruction} />
      {props.question.map(renderQuestion)}
      <Next
        onEvaluation={props.onEvaluation}
        score={props.score}
        hint={props.hint}
        clickCounter={props.clickCounter}
        navLen={props.navLen}
        navCrrtItem={props.navCrrtItem}
      />
    </div>
  );
}
