import React from "react";
import Question from "../components/Question";
import AnswerOption from "../components/AnswerOption";
import QuestionPassage from "../components/QuestionPassage";
import Next from "../../../../../components/testlet/Next_RC";

export default function Test(props) {
  return (
    <div className="templateInner CIM" key={props.actID}>
      <QuestionPassage passage={props.passage} dir={props.dir} />
      <Question questionText={props.questionText} />
      <div className="answerSection noSelect">
        <AnswerOption
          actID={props.actID}
          dir={props.dir}
          selValue={props.selValue}
          question={props.question}
          dropdownOptions={props.dropdownOptions}
          onAnswerSelected={props.onAnswerSelected}
        />
      </div>
      <Next
        clickCounter={props.clickCounter}
        navLen={props.navLen}
        navCrrtItem={props.navCrrtItem}
        onClickNextBtn={props.onClickNextBtn}
        onSubmit={props.onSubmit}
        onEvaluation={props.onEvaluation}
      />
    </div>
  );
}
