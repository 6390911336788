import React, { useEffect, useCallback, useContext, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Intro from "./homes/Intro";
import Login from "./homes/Login";
import Register from "./homes/Register";
import EvalInfo from "./homes/EvalInfo";
import Languages from "./homes/Languages";
import Auth from "../contents/Auth";
import JWT from "../contents/JWT";
import ErrorModal from "../components/error/ErrorModal";
import { ErrorContext } from "../context/ErrorProvider";
import Footer from "../components/footer/Footer";

/**
 * @component Home
 * @description creates basic body content for landing page; helps process login
 * @todo clean up left over code and add comments
 **/

const showMessage = () => {
  alert("Your account successfully created.");
};

const Home = (props) => {
  let navigate = useNavigate();
  const [modalErrorState, errorDispatch] = useContext(ErrorContext);
  const location = useLocation();
  const [token, setToken] = useState(JSON.parse(localStorage.getItem("token")));
  const [isThursdayShowMessage, setIsThursdayShowMessage] = useState(false);

  useEffect(() => {
    if (!token) {
      setToken(JSON.parse(localStorage.getItem("token")));
    }
    //eslint-disable-next-line
  }, [location]);

  useEffect(() => {
    //componentDidMount
    // check if its Thursday 12:01am to 8:59pm to display the message
    const todayDate = new Date();
    let todayDateDay = todayDate.getDay();
    let todayDateHour = todayDate.getHours();    
    if (todayDateDay == 4 && todayDateHour <= 20) {
      setIsThursdayShowMessage(true);
    }

    let currentTime = new Date().getTime();
    let timer = setInterval(function () {
      const accessToken = localStorage.getItem("token");
      if (accessToken !== null) {
        const base64Url = accessToken.split(".")[1];
        const base64 = base64Url.replace("-", "+").replace("_", "/");
        const iatJWT = JSON.parse(window.atob(base64)).iat;
        const expJWT = JSON.parse(window.atob(base64)).exp;
        const expDuration = expJWT - iatJWT;
        if (new Date().getTime() >= currentTime + expDuration) {
          localStorage.removeItem("token");
          // console.log(new Date().getTime());
          // console.log(currentTime);
          // console.log(expDuration);
          // console.log("deleted token");
        }
      } else {
        clearInterval(timer);
      }
    }, 1000);
  }, []);

  const returnToDash = () => {
    navigate("/dashboard");
  };

  const handleSuccessfulLogIn = useCallback(
    (data) => {
      JWT.fetchData()
        .then((result) => {
          if (result) {
            Auth.login(() => {
              navigate("/dashboard");
            });
          }
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.status >= 400 && error.response.status < 600) {
              //global error dispatch call to show error modal if error received during api call
              errorDispatch({
                type: "UPDATE_ERROR_STATE",
                payload: {
                  errorStatusCode: error.response.status,
                  errorUserMessage: "New Error",
                  errorDevData: error.response
                }
              });
            }
            // console.log("error");
          }
        });
    },
    [navigate, errorDispatch]
  );

  return (
    <div>
      {isThursdayShowMessage && (
        <h4 className="bannerNotification">
          The ODA website undergoes scheduled maintenance every Thursday, from 1700-2100 (5:00 PM - 9:00 PM) Pacific
          time. There will be intermittent interruptions of service during this maintenance window. Please plan
          accordingly.
        </h4>
      )}
      <div className="bodyItems1">
        <Intro />
        <div>
          {!token ? (
            <div className="centerWrap">
              <Login handleSuccessfulLogIn={handleSuccessfulLogIn} />
              <Register showMessage={showMessage} />
            </div>
          ) : (
            <div className="returnDash">
              <br />
              <button onClick={returnToDash}>Return to Your Assessments Dashboard →</button>
              <br />
            </div>
          )}
        </div>
      </div>
      <div className="bodyItems2">
        <div className="evalInfo">
          <EvalInfo />
        </div>
      </div>
      <div className="bodyItems3">
        <Languages />
      </div>
      <Footer showReportProblem={false} />
      <ErrorModal
        errorStatusCode={modalErrorState.errorStatusCode}
        errorUserMessage={modalErrorState.errorUserMessage}
        errorDevData={modalErrorState.errorDevData}
      />
    </div>
  );
};

export default Home;
