import React, { useState, useEffect, useContext } from "react";
import { ErrorContext } from "../context/ErrorProvider";
import Footer from "../components/footer/Footer";
import axios from "axios";
import axiosRetry from "axios-retry";
import LoadingSpinner from "../components/spinner/LoadingSpinner";
import { useParams, useNavigate } from "react-router-dom";

/**
 * @component VerifyEmail
 * @description handles email verification codes
 * @todo add comments
 **/

const VerifyEmail = (props) => {
  const [verifyCheck, setVerifyCheck] = useState("incomplete");
  const [, errorDispatch] = useContext(ErrorContext);
  const [verifyCheckStatus, setVerifyCheckStatus] = useState(null);
  const { verifyToken } = useParams();
  const navigate = useNavigate();
  axiosRetry(axios, {
    retries: 4, // number of retries

    retryDelay: (...arg) => axiosRetry.exponentialDelay(...arg, 1000), // Exponential delay with backoff of 1000mst
    retryCondition: (err) => {
      console.log(err.response);
      return (
        axiosRetry.isNetworkError(err) ||
        err.code === "ECONNABORTED" ||
        err.code === "ENOTFOUND" ||
        err.code === "ETIMEDOUT" ||
        (err.response && err.response.status >= 500 && err.response.status <= 599)
      );
    }
  });

  //console.log(verifyToken);

  useEffect(() => {
    //console.log(verifyCheck);
    //console.log(verifyCheckStatus);
  }, [verifyCheck, verifyCheckStatus]);

  //run initial check
  useEffect(() => {
    if (verifyCheck === "incomplete" && verifyCheckStatus === null) {
      axios
        .get("/api/user/verify/" + verifyToken)
        .then((res) => {
          //console.log(res);
          if (res.status === 200) {
            setVerifyCheckStatus("valid");
            setVerifyCheck("complete");
          } else {
            setVerifyCheckStatus("invalid");
            setVerifyCheck("complete");
          }
        })
        .catch((error) => {
          //console.log(error);
          setVerifyCheckStatus("invalid");
          setVerifyCheck("complete");
          // if (error.response) {
          //   if (error.response.status >= 400 && error.response.status < 600) {
          //     //global error dispatch call to show error modal if error received during api call
          //     errorDispatch({
          //       type: "UPDATE_ERROR_STATE",
          //       payload: {
          //         errorStatusCode: error.response.status,
          //         errorUserMessage: "New Error",
          //         errorDevData: error.response
          //       }
          //     });
          //   }
          // }
        });
    }
  }, [verifyCheck, verifyCheckStatus]);

  //reroute if verified after 5 seconds
  useEffect(() => {
    if (verifyCheck === "complete" && verifyCheckStatus === "valid") {
      setTimeout(() => {
        navigate("/home", { replace: true });
      }, 5000);
    }
  }, [verifyCheck, verifyCheckStatus]);

  return (
    <React.Fragment>
      <div className="signUp">
        <span className="cardTitle">Email Verification</span>
        <br />
        {verifyCheck === "incomplete" && (
          <LoadingSpinner msg="Please wait while we verify your token..." size="small" />
        )}
        {verifyCheck === "complete" && verifyCheckStatus === "valid" && (
          <div>
            <h4>Email Verified!</h4> <br />
            <br /> <LoadingSpinner msg="Returning you to the ODA login page in 5 seconds..." size="small" />
          </div>
        )}
        {verifyCheck === "complete" && verifyCheckStatus === "invalid" && (
          <div>
            <h5 style={{ color: "red", textAlign: "center" }}>Email Verification Failed </h5>
            <h5>
              <b>
                <u>Important</u>
              </b>
              : This email account may have already be verified <u>or</u> the email account has not been created. <br />
              <br /> Try{" "}
              <a href="#" onClick={() => navigate("/home")}>
                logging in
              </a>{" "}
              using your credentials. If that fails, try to create a new account.
              <br />
              <br />
              Otherwise, please click{" "}
              <a href="#" onClick={() => navigate("/contact")}>
                here
              </a>{" "}
              to manually request assistance from the ODA Team.{" "}
            </h5>
          </div>
        )}
      </div>
      <Footer showReportProblem={false} />
    </React.Fragment>
  );
};

export default VerifyEmail;
