import React, { useState } from "react";
import axios from "axios";
import AdminFooter from "./adminComponents/AdminFooter";
import UserAnswerSearchPanelDisplay from "./adminDisplay/UserAnswerSearchPanelDisplay";

const UserAnswerSearchPanel = () => {
  const [state, setState] = useState({
    content: [],
    selectedSearchWord: "",
    selectedSite: "oda",
    firstCheck: false, //check to print out "No such a word found" when the very first word not matching.
  });

  const { selectedSearchWord, selectedSite } = state;

  const handleSiteChange = (event) => {
    setState({ ...state, selectedSite: event.target.value });
  };

  const handleKeywordChange = (event) => {
    const newSearchWord = event.target.value;
    if (newSearchWord === "") {
      // Clear the data if the search word is empty
      setState({
        ...state,
        content: [],
        selectedSearchWord: newSearchWord,
        firstCheck: false,
      });
    } else {
      setState({ ...state, selectedSearchWord: newSearchWord });
    }
  };

  const getValues = () => {
    const initial = {
      firstCheck: false,
    };
    setState({
      ...state,
      ...initial,
    });

    const user = {
      userAns: selectedSearchWord,
      site: selectedSite,
    };

    if (selectedSearchWord !== "") {
      axios
        .get("/api/adminReport/userAnsReport", { params: user })
        .then((res) => {
          setState((prevState) => ({
            ...prevState,
            content: res.data,
            firstCheck: true,
          }));

          console.log("data=", JSON.stringify(res.data));
        })
        .catch((error) => {
          setState((prevState) => ({
            ...prevState,
          }));
          if (error.response) {
            if (error.response.status >= 400 && error.response.status < 600) {
            }
          }
        });
    } else {
      console.log("No value can be null");
      alert("Enter a word to search for");
    }
  };

  return (
    <div className="adminMainPanel">
      <div className="infoTitle adminInfoTitle">
        <div>ODA User Answer Search Panel</div>
      </div>
      <label htmlFor="wordSearch">
        Enter a word to search:
        <input id="wordSearch" onChange={handleKeywordChange} />
      </label>

      <div>
        Site:
        <select key="site" value={selectedSite} onChange={handleSiteChange}>
          <option value="oda">ODA 1</option>
        </select>
      </div>
      <div className="buttonGroup">
        <div className="searchButton" onClick={getValues}>
          Search Database
        </div>
      </div>
      <div className="adminContentBox" style={{ overflow: "auto" }}>
        <UserAnswerSearchPanelDisplay
          content={state.content}
          searchWord={state.selectedSearchWord}
          firstCheck={state.firstCheck}
        />
      </div>
      <AdminFooter />
    </div>
  );
};

export default UserAnswerSearchPanel;
