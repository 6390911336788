import React, { useState, useCallback } from "react";
import { Link } from "react-router-dom";
import DiagnosticProfileModal from "../../components/learnmore/DiagnosticProfileModal";
import FeedbackModal from "../../components/learnmore/FeedbackModal";
import AdaptiveAnalysisModal from "../../components/learnmore/AdaptiveAnalysisModal";
import LevelPredictionModal from "../../components/learnmore/LevelPredictionModal";
import StudyPlanModal from "../../components/learnmore/StudyPlanModal";
import OneMoreModal from "../../components/learnmore/OneMoreModal";

export default function EvalInfo() {
  const [state, setState] = useState({
    accountCreatedModal: false,
    diagnosticModal: false,
    feedbackModal: false,
    adaptiveModal: false,
    levelModal: false,
    planModal: false,
    moreModal: false,
  });

  const [, setClick] = useState(false); //TODO: (Review-->click is never used, is this intended?)
  const closeMobileMenu = useCallback(() => {
    document.querySelector(".bodyItems1").style.display = "none";
    // document.querySelector(".bodyItems2").style.display = "none";
    document.querySelector(".bodyItems3").style.display = "none";
    document.querySelector(".evalInfo").style.display = "none";
    document.querySelector(".switch").style.display = "inline";
    setClick(false);
  }, []);

  const handleDiagnosticModalState = useCallback(
    (e) => {
      setState({ ...state, diagnosticModal: false });
    },
    [state]
  );

  const handleFeedbackModalState = useCallback(
    (e) => {
      setState({ ...state, feedbackModal: false });
    },
    [state]
  );

  const handleAdaptiveModalState = useCallback(
    (e) => {
      setState({ ...state, adaptiveModal: false });
    },
    [state]
  );

  const handleLevelModalState = useCallback(
    (e) => {
      setState({ ...state, levelModal: false });
    },
    [state]
  );

  const handleStudyPlanModalState = useCallback(
    (e) => {
      setState({ ...state, planModal: false });
    },
    [state]
  );

  const handleMoreModalState = useCallback(
    (e) => {
      setState({ ...state, moreModal: false });
    },
    [state]
  );

  const diagnostic = () => {
    setState({ ...state, diagnosticModal: true });
  };
  const feedback = () => {
    setState({ ...state, feedbackModal: true });
  };
  const adaptive = () => {
    setState({ ...state, adaptiveModal: true });
  };
  const level = () => {
    setState({ ...state, levelModal: true });
  };

  const plan = () => {
    setState({ ...state, planModal: true });
  };

  const more = () => {
    setState({ ...state, moreModal: true });
  };

  return (
    <div>
      <div className="info">
        <p className="infoTitle">
          Evaluate and manage
          <br />
          your learning
        </p>
        <p className="infoTagline">
          Identify your current language strengths and the skills you require to
          advance to a higher&nbsp;proficiency
        </p>
      </div>

      <div className="gridContainer homeGrid">
        <div className="gridItem">
          <div id="DPicon" />
          <h4 className="gridTitle">Diagnostic Profile</h4>
          <span>
            Complete an assessment to get a personalized Diagnostic Profile—a
            snapshot of what specific areas you excel in and what areas are most
            challenging.
            <br />
            <span className="more" onClick={diagnostic}>
              learn more&#8230;
            </span>
            {state.diagnosticModal && (
              <DiagnosticProfileModal
                handleDiagnosticModalState={handleDiagnosticModalState}
              />
            )}
          </span>
        </div>

        <div className="gridItem">
          <div id="FBicon" />
          <h4 className="gridTitle">Feedback</h4>
          <span>
            Receive individualized feedback based on your abilities across a
            variety of levels, topics, tasks, and specific language features.
            <br />
            <span className="more" onClick={feedback}>
              learn more&#8230;
            </span>
            {state.feedbackModal && (
              <FeedbackModal
                handleFeedbackModalState={handleFeedbackModalState}
              />
            )}
          </span>
        </div>

        <div className="gridItem">
          <div id="AAicon" />
          <h4 className="gridTitle">What to Expect During Assessment</h4>
          <span>
            ODA Reading and Listening are adaptive assessments. Your answers
            determine the level of subsequent questions. Providing correct
            answers will result in more challenging questions as you move to
            higher proficiency levels.
            <br />
            <span className="more" onClick={adaptive}>
              learn more&#8230;
            </span>
            {state.adaptiveModal && (
              <AdaptiveAnalysisModal
                handleAdaptiveModalState={handleAdaptiveModalState}
              />
            )}
          </span>
        </div>

        <div className="gridItem">
          <div id="ILRicon" />
          <h4 className="gridTitle">Understanding Proficiency</h4>
          <span>
            Each assessment item is either selected or adapted from genuine
            sources, aligning with the level descriptions provided by the
            Interagency Language Roundtable (ILR).
            <br />
            <span className="more" onClick={level}>
              learn more&#8230;
            </span>
            {state.levelModal && (
              <LevelPredictionModal
                handleLevelModalState={handleLevelModalState}
              />
            )}
          </span>
        </div>

        <div className="gridItem">
          <div id="studyIcon" />
          <h4 className="gridTitle">Learning Plans (Coming Soon)</h4>
          <span>
            Obtain tailored lesson plans designed to improve your proficiency in
            the areas that require the most improvement. Identify GLOSS lessons
            that are most relevant for your current level, areas that need
            improvement, and mode of learning.
            <br />
            <span className="more" onClick={plan}>
              learn more&#8230;
            </span>
            {state.planModal && (
              <StudyPlanModal
                handleStudyPlanModalState={handleStudyPlanModalState}
              />
            )}
          </span>
        </div>

        <div className="gridItem">
          <div id="anotherIcon" />
          <h4 className="gridTitle">How to Answer the Question</h4>
          <span>
            Assessments consist of multiple-choice and open-ended questions that
            test your comprehension of passages and understanding of language
            structure. Some questions also require the identification of
            linguistic elements in the text.
            <br />
            <span className="more" onClick={more}>
              learn more&#8230;
            </span>
            {state.moreModal && (
              <OneMoreModal handleMoreModalState={handleMoreModalState} />
            )}
          </span>
        </div>
      </div>
    </div>
  );
}
