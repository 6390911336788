import "../../css/index.css";
/**
 * @component RegisterAccountModal
 * @description
 * @param {object} props
 **/

export default function OneMoreModal(props) {
  const afterCreation = (e) => {
    props.handleMoreModalState(e);
  };

  return (
    <div className="modalDialog" id="RegisterAccountModal">
      <div className="RegisterAccountModal">
        <div
          className="feedbackHeader"
          id="modalHeader"
          style={{ fontSize: "16px" }}
        >
          How to Answer the Question
        </div>
        <h5
          style={{
            fontSize: "16px",
            fontWeight: "normal",
            overflow: "auto",
          }}
        >
          Both the reading and the listening assessments contain two categories
          of questions. <br />
          <br />
          The first category of questions evaluates your understanding of the
          written or audio passages. The second category assesses your grasp of
          vocabulary, sentence structure, and text structure, which are all
          integral to the main content of the passage.
          <br />
          <br />
          The assessments include multiple-choice questions and open-ended
          questions, which require typing your answer. For these open-ended
          questions, <strong>ensure your responses are in English</strong> and
          are as detailed as the question requires.{" "}
          <strong>Avoid being overly brief or excessively wordy</strong>.
          <br />
          <br /> In the reading assessment, some questions will prompt you to
          identify a linguistic component in the target language text, such as a
          subject or an object. For these questions, you will need to select the
          correct word or phrase in the text so that your selections will be
          displayed in the answer field.
        </h5>

        <button
          className="goLogin outlined"
          style={{
            fontSize: "16px",
            top: "6px",
            padding: "3px 7px",
          }}
          onClick={(e) => afterCreation(e)}
        >
          Close
        </button>
        <br />
        <br />
      </div>
    </div>
  );
}
