import React from "react";
import dompurify from "dompurify";

export default function Question(props) {
  const sanitizer = dompurify.sanitize;
  // return <h3>{props.questionText}</h3>;
  return (
    <h3>
      <div
        dangerouslySetInnerHTML={{
          __html: sanitizer(props.questionText),
        }}
      />
    </h3>
  );
}
