import React, { useState, useEffect } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import axios from "axios";
import DateRangePicker from "./adminComponents/DateRangePicker";
import AdminFooter from "./adminComponents/AdminFooter";
import ItemUserDisplay from "./adminDisplay/ItemUserDisplay";

const ItemUserCorrelation = () => {
  const initialState = {
    content: [],
    language: [],
    languageGR: [],
    selectedLanguage: "",
    selectedModality: "",
    // selectedSite: "",
    selectedLevel: "",
    selectedUserLevel: "null",
    selectedType: "All",
  };

  const [state, setState] = useState(initialState);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [clickGetValBtn, setClickGetValBtn] = useState(false);

  const navigate = useNavigate();

  const date = new Date("1970-01-01");
  const isoDate = date.toISOString().slice(0, 10);

  function refreshPage() {
    window.location.reload(false);
  }

  const resetValues = () => {
    setState({
      ...state,
      content: [],
      selectedLanguage: "",
      selectedModality: "",
      // selectedSite: "",
      selectedLevel: "",
      selectedUserLevel: "null",
      selectedType: "",
    });

    document.querySelector(".adminContentBox").innerHTML = null;
  };

  const {
    language,
    languageGR,
    // selectedSite,
    selectedLanguage,
    selectedModality,
    selectedLevel,
    selectedUserLevel,
    selectedType,
  } = state;

  useEffect(() => {
    if (selectedModality === "reading" || selectedModality === "listening") {
      axios
        .get('/api/language?modalities__ne=[]&_sort={"sequence": 1}')
        .then((res) => {
          setState({ ...state, language: res.data });
        });
    }

    if (selectedModality === "grammar") {
      axios
        .get('/api/language?modalities="grammar"&_sort={"sequence": 1}')
        .then((res) => {
          setState({ ...state, languageGR: res.data });
        });
    }
  }, [selectedModality]);

  let langDropdown = language.map((item, index) => (
    <option key={index} value={item._id}>
      {item.displayName}
    </option>
  ));

  let langDropdownGR = languageGR.map((item, index) => (
    <option key={index} value={item._id}>
      {item.displayName}
    </option>
  ));

  const typeRC = ["CIM", "CMT", "HTO", "MCT", "QSA"];

  const typeLC = ["AMC2", "LCIA", "LCM", "LSA", "LSA2", "TMC2"];

  const typeGR = [
    "GMCA",
    "GMCB",
    "GMCI",
    "GMPG",
    "GMSA",
    "GMSG",
    "GLCB",
    "GLCC",
    "GLCI",
    "GLSA",
    "GLSB",
  ];

  let typeDropdownRC = typeRC.map((item, index) => (
    <option key={item} value={item.toLowerCase()}>
      {item}
    </option>
  ));

  let typeDropdownLC = typeLC.map((item, index) => (
    <option key={item} value={item.toLowerCase()}>
      {item}
    </option>
  ));

  let typeDropdownGR = typeGR.map((item, index) => (
    <option key={item} value={item.toLowerCase()}>
      {item}
    </option>
  ));

  const handleLanguageChange = (event) => {
    setState({ ...state, selectedLanguage: event.target.value });
  };

  const handleModalityChange = (event) => {
    const initial = {
      selectedLanguage: "",
      // selectedSite: "",
      selectedLevel: "",
      selectedUserLevel: "null",
    };
    setState({
      ...state,
      selectedModality: event.target.value,
      ...initial,
    });
  };

  // const handleSiteChange = (event) => {
  //   setState({ ...state, selectedSite: event.target.value });
  // };

  const handleLevelChange = (event) => {
    setState({ ...state, selectedLevel: event.target.value });
  };

  const handleUserLevelChange = (event) => {
    setState({ ...state, selectedUserLevel: event.target.value });
  };

  const handleTypeChange = (event) => {
    setState({ ...state, selectedType: event.target.value });
  };

  const handleDatesSelected = (fromDate, toDate) => {
    const pickFrom = new Date(fromDate);
    const isoFrom = pickFrom.toISOString().slice(0, 10);

    const pickTo = new Date(toDate);
    const isoTo = pickTo.toISOString().slice(0, 10);
    setFromDate(isoFrom);
    setToDate(isoTo);
  };

  useEffect(() => {
    setState((prevState) => ({ ...prevState, selectedLanguage: "Select" }));
  }, [selectedModality]);

  useEffect(() => {
    setState((prevState) => ({ ...prevState, selectedType: "All" }));
  }, [selectedModality]);

  useEffect(() => {
    setState((prevState) => ({ ...prevState, selectedLevel: "Select" }));
  }, [selectedModality]);

  useEffect(() => {
    if (selectedModality === "grammar") {
      setState((prevState) => ({ ...prevState, selectedUserLevel: "null" }));
    } else {
      setState((prevState) => ({ ...prevState, selectedUserLevel: "Select" }));
    }
  }, [selectedModality]);

  const getValues = () => {
    setClickGetValBtn(true);
    const encodeItemLevel = (itemLevel) => {
      if (itemLevel.includes("/")) {
        return itemLevel.replace("+", "%2b").replace("/", "");
      }
      return itemLevel;
    };

    const user = {
      // site: selectedSite,
      lang: selectedLanguage,
      mod: selectedModality,
      itemLvl: encodeItemLevel(selectedLevel),
      userLvl: selectedModality === "grammar" ? "all" : selectedUserLevel,
      // type: selectedModality === "grammar" ? "all" : selectedType,
      //type: selectedType,
      type: selectedType === "All" ? "all" : selectedType.toLowerCase(), // Change here

      startDate: fromDate,
      endDate: toDate,
    };

    const valuesAreValid =
      //selectedSite !== "Select" &&
      selectedLanguage !== "Select" &&
      selectedModality !== "Select" &&
      selectedLevel !== "Select" &&
      selectedUserLevel !== "Select" &&
      // selectedType !== "Select" &&
      //selectedSite !== "" &&
      selectedLanguage !== "" &&
      selectedModality !== "" &&
      selectedLevel !== "" &&
      selectedUserLevel !== "" &&
      // selectedType !== "" &&
      fromDate !== isoDate &&
      toDate !== isoDate &&
      fromDate !== null &&
      toDate !== null;

    if (valuesAreValid) {
      console.log("values=", {
        //site: selectedSite,
        lang: selectedLanguage,
        mod: selectedModality,
        itemLvl: selectedLevel,
        userLvl: selectedUserLevel,
        type: selectedType,
        startDate: fromDate,
        endDate: toDate,
      });

      console.log("user=" + JSON.stringify(user));
      // axios
      //   .get("/api/adminReport/itemUserCorrelationSummary", {
      //     params: user,
      //   })

      axios
        .get(
          "/api/adminReport/itemUserCorrelationSummary?lang=" +
            user.lang +
            "&mod=" +
            user.mod +
            "&itemLvl=" +
            user.itemLvl +
            "&userLvl=" +
            user.userLvl +
            "&type=" +
            user.type +
            "&startDate=" +
            user.startDate +
            "&endDate=" +
            user.endDate
        )

        .then((res) => {
          setState({ ...state, content: res.data });
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.status >= 400 && error.response.status < 600) {
            }
          }
        });
    } else {
      console.log("No value can be null");
      alert("Select all items");
    }
  };

  return (
    <div className="adminMainPanel">
      <div className="infoTitle adminInfoTitle">
        <div>ODA Item-User Correlation</div>
      </div>
      <div>
        Modality:
        <select
          key="modality"
          value={selectedModality}
          onChange={handleModalityChange}
        >
          <option>Select</option>
          <option value="reading">Reading</option>
          <option value="listening">Listening</option>
          <option value="grammar">Grammar</option>
        </select>
      </div>
      <div>
        Language:
        <select
          key="language"
          value={selectedLanguage}
          onChange={handleLanguageChange}
        >
          {selectedModality === "reading" ||
          selectedModality === "listening" ? (
            <>
              <option>Select</option>
              {langDropdown}
            </>
          ) : selectedModality === "grammar" ? (
            <>
              <option>Select</option>
              {langDropdownGR}
            </>
          ) : (
            <option>Select</option>
          )}
        </select>
      </div>
      {/* <div>
        Site:
        <select key="site" value={selectedSite} onChange={handleSiteChange}>
          <option>Select</option>
          <option value="oda">ODA</option>
        </select>
      </div> */}
      {selectedModality === "reading" || selectedModality === "listening" ? (
        <div>
          Item Level:
          <select
            key="level"
            value={selectedLevel}
            onChange={handleLevelChange}
          >
            <option>Select</option>
            <option value="1">1</option>
            <option value="1+">1+</option>
            <option value="2">2</option>
            <option value="2+">2+</option>
            <option value="3">3</option>
          </select>
        </div>
      ) : selectedModality === "grammar" ? (
        <div>
          Item Level:
          <select
            key="level"
            value={selectedLevel}
            onChange={handleLevelChange}
          >
            <option>Select</option>
            <option value="1">1</option>
            <option value="1+/2">1+/2</option>
            <option value="2+/3">2+/3</option>
          </select>
        </div>
      ) : (
        <div>
          Item Level:
          <select
            key="level"
            value={selectedLevel}
            onChange={handleLevelChange}
          >
            <option>Select</option>
          </select>
        </div>
      )}
      {selectedModality === "reading" || selectedModality === "listening" ? (
        <div>
          User Current Level:
          <select
            key="userLevel"
            value={selectedUserLevel}
            onChange={handleUserLevelChange}
          >
            <option>Select</option>
            <option value="0">Below 1</option>
            <option value="1">1</option>
            <option value="1+">1+</option>
            <option value="2">2</option>
            <option value="2+">2+</option>
            <option value="3">3</option>
            <option value="all">All Levels</option>
          </select>
        </div>
      ) : null}
      <div>
        Item Type:
        <select key="name" value={selectedType} onChange={handleTypeChange}>
          {selectedModality === "reading" ? (
            <>
              <option value="all">All</option>
              {typeDropdownRC}
            </>
          ) : selectedModality === "listening" ? (
            <>
              <option value="all">All</option>
              {typeDropdownLC}
            </>
          ) : selectedModality === "grammar" ? (
            <>
              <option value="all">All</option>
              {typeDropdownGR}
            </>
          ) : (
            <option value="all">All</option>
          )}
        </select>
      </div>
      <DateRangePicker onDatesSelected={handleDatesSelected} />
      <div className="buttonGroup">
        <div onClick={getValues} className="searchButton">
          Get Records
        </div>
        <div onClick={refreshPage} className="searchButton">
          {/* <div onClick={resetValues} className="searchButton"> */}
          Reset Options
        </div>
      </div>
      <div className="adminContentBox" style={{ overflow: "auto" }}>
        <ItemUserDisplay
          content={state.content}
          mod={state.selectedModality}
          lang={state.selectedLanguage}
          itemLevel={state.selectedLevel}
          userLevel={state.selectedUserLevel}
          type={state.selectedType}
          fromDate={fromDate}
          toDate={toDate}
          click={clickGetValBtn}
        />
      </div>

      <AdminFooter />
    </div>
  );
};

export default ItemUserCorrelation;
