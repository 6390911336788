import React, { useRef } from "react";
import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";
import { IoPlay } from "react-icons/io5";
import { IoPause } from "react-icons/io5";
import { GiSpeaker } from "react-icons/gi";
import { IoIosRewind } from "react-icons/io";
import { IoMdFastforward } from "react-icons/io";

export default function Audio(props) {
  const lang = props.lang;
  const LO = props.actID;
  const instruction = props.instruction;
  const mp3 = props.assessment[0].questionTexts[1];
  const main = "audio/" + lang + "/" + LO + "/" + mp3;
  const audioPlayer = useRef(null);

  return (
    <div>
      <h2>Listen to the passage:</h2>
      <AudioPlayer
        id="ODAPlayer2"
        autoPlay={false}
        autoPlayAfterSrcChange={false}
        header={
          <div className="rhap_textCenter">
            Main Passage
            <span className="modifiedFlag" />
          </div>
        }
        footer={null}
        customAdditionalControls={[]}
        ref={audioPlayer.main}
        src={props.toggleState ? null : main}
                customIcons={{
                  play: <IoPlay />,
                  pause: <IoPause />,
                  rewind: <IoIosRewind />,
                  forward: <IoMdFastforward />,
                  volume: <GiSpeaker />,
                }}
        // onPlay={(e) => console.log("onPlay")}
      />

      {/* <audio src={main} id="mainAudio" />
      <audio src="" id="dynamicAudio" /> */}
      <h3>{instruction}</h3>
    </div>
  );
}
