/**
 *  non-state functions used by 2 or more different components go here
 *
 */
import { slideUp, slideDown, next, offset } from "./genericFunctions";

export default function nonFunctions() {
  //document.addEventListener("DOMContentLoaded", function () {
  // let selEl = document.querySelectorAll(".tabLabel");
  // if (selEl.length > 0) {
  //   for (let i = 0; i < selEl.length; i++) {
  //     selEl[i].addEventListener("click", (e) => {
  //       let id = e.target.attributes.id.value;
  //       let selEl2 = document.querySelectorAll(".tabLabel, .tabContent");
  //       for (let j = 0; j < selEl2.length; j++) {
  //         selEl2[j].classList.remove("active");
  //       }
  //       selEl[i].classList.add("active");
  //       let selEl3 = document.querySelector("." + id);
  //       for (let k = 0; k < selEl3.length; k++) {
  //         selEl3[k].classList.add("active");
  //       }
  //     });
  //   }
  // } else {
  //   console.log("no nodes found to add listener");
  // }

  window.onscroll = function () {
    stickyTabs();
  };

  let stickyPos;
  let footerPos;

  if (document.querySelectorAll(".tabs").length != 0 && document.querySelectorAll(".callout").length != 0) {
    stickyPos = offset(document.querySelector(".tabs")).top;
    footerPos = offset(document.querySelector(".callout")).top - 60;
  }

  function stickyTabs() {
    if (window.pageYOffset >= stickyPos && window.pageYOffset <= footerPos) {
      let selEl = document.querySelectorAll(".tabs");
      selEl.forEach((el) => {
        el.classList.add("sticky");
      });
    } else {
      let selEl = document.querySelectorAll(".tabs");
      selEl.forEach((el) => {
        el.classList.remove("sticky");
      });
    }
  }

  //this code was redundant -- see genericFunctions implementation instead
  // let selEl2 = document.querySelectorAll(".accordionTrigger");
  // selEl2.forEach((el) => {
  //   el.addEventListener("click", (e) => {
  //     if (el.classList.contains("open")) {
  //       el.classList.remove("open");
  //       el.classList.add("closed");
  //       slideUp(next(el));
  //       //el.next(".accordion").slideUp("fast");
  //     } else {
  //       el.classList.remove("closed");
  //       el.classList.add("open");
  //       slideDown(next(el));
  //       //el.next(".accordion").slideDown("fast");
  //     }
  //   });
  // });
  //});
}
