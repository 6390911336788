import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import RolesContent from "./adminDisplay/MasterPanelDisplay";
import AdminFooter from "./adminComponents/AdminFooter";
import { debounce } from "lodash"; // Import debounce from lodash

const MasterAdmin = () => {
  const [getList, setGetList] = useState([]);
  const [adminMembers, setAdminMembers] = useState([]);
  const [state, setState] = useState({
    showRoles: false,
    activePanels: [],
    newUserInfo: [],
    newId: "",
  });
  const { activePanels, newUserInfo, newId } = state;
  const [showRoles, setShowRoles] = useState(false);
  const [selectedAdmin, setSelectedAdmin] = useState(null);
  const [newEmail, setNewEmail] = useState(""); // State for the new email input field

  const emailRegEx = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  useEffect(() => {
    axios
      .get("/api/adminPanel/adminPanelUsers?isActive=true")
      .then((res) => {
        setGetList(res.data);
        setAdminMembers(res.data);
      })
      .catch((err) => {
        console.log("error message =" + JSON.stringify(err));
      });
  }, []);

  useEffect(() => {
    axios
      .get('/api/adminPanel?isActive=true&_sort={"sequence": 1}')
      .then((res) => {
        setState((prevState) => ({ ...prevState, activePanels: res.data }));
        console.log("test=", res.data);
      })
      .catch((err) => {
        console.log("error message=" + JSON.stringify(err));
      });
  }, []);

  const emailList = getList.map((member) => member.email);

  const fetchUserInfo = useCallback(
    debounce((email) => {
      if (emailRegEx.test(email)) {
        axios
          .get("/api/user/email/" + email)
          .then((res) => {
            setState((prevState) => ({
              ...prevState,
              newUserInfo: res.data[0],
            }));
          })
          .catch((err) => {
            console.log("Error fetching user info:", err);
          });
      }
    }, 500),
    []
  );

  useEffect(() => {
    fetchUserInfo(newEmail);
  }, [newEmail, showRoles, fetchUserInfo]);

  const selectAdmin = (email) => {
    setSelectedAdmin(email);
    setShowRoles(true);
  };

  const handleRolesSubmit = () => {
    console.log("Add/Remove button clicked for email:", selectedAdmin);
    alert("Add/Remove roles for email: " + selectedAdmin);
    setShowRoles(true);
  };

  const handleRolesCancel = () => {
    console.log("Cancel button clicked for email:", selectedAdmin);
    setShowRoles(false);
  };

  const handleAddAdmin = () => {
    if (newUserInfo && emailRegEx.test(newEmail)) {
      if (!emailList.includes(newEmail)) {
        const newId = newUserInfo._id;
        const newAdmin = {
          _id: newId,
          email: newEmail,
          adminPanels: [],
        };

        setAdminMembers((prevMembers) => [...prevMembers, newAdmin]);
        setNewEmail(""); // Clear the new email input field
      } else {
        alert("Admin account already exists.");
      }
    } else {
      alert("Invalid Credentials");
    }
  };

  const handleCheckboxChange = async (adminId, isChecked) => {
    setAdminMembers((prevMembers) =>
      prevMembers.map((admin) =>
        admin._id === adminId
          ? { ...admin, roles: isChecked ? ["sysadmin"] : [] }
          : admin
      )
    );

    const url = `/api/user/${
      isChecked ? "addUserRole" : "deleteUserRole"
    }/${adminId}`;
    const roleAction = isChecked ? "add" : "delete";

    try {
      const response = await axios.put(url, { role: "sysadmin" });
      console.log(
        `Role "${roleAction}" for admin with ID ${adminId} successful. Response:`,
        response.data
      );
    } catch (error) {
      console.error(`Error updating role for admin with ID ${adminId}:`, error);
    }
  };

  return (
    <div className="adminMainPanel">
      <div className="infoTitle adminInfoTitle">
        <div>Master Admin Panel</div>
      </div>
      <label htmlFor="">
        New Admin User Email:
        <input
          value={newEmail}
          onChange={(e) => setNewEmail(e.target.value)}
          placeholder="Enter new email"
        />
      </label>

      <div className="buttonGroup">
        <div className="searchButton" onClick={handleAddAdmin}>
          Add
        </div>
      </div>

      <div>
        <Link to="/adminreport">
          <div className="masterlink">Main Menu</div>
        </Link>
      </div>

      <div className="adminContentBox" style={{ overflow: "auto" }}>
        <div className="adminFlexRow" style={{ fontWeight: "bold" }}>
          <div className="adminFlexItem adminName">User Email</div>
          <div className="adminFlexItem editRole">Edit Permission</div>
          <div className="adminFlexItem editRole">Current Permission</div>
          <div className="adminFlexItem adminStatus">System Admin</div>
        </div>
        <br />
        {adminMembers.map((admin) => (
          <div key={admin._id}>
            <div className="adminFlexRow" key={admin.email}>
              <div className="adminFlexItem adminName">
                {admin.email.split("@")[0]}
                <br />@{admin.email.split("@")[1]}
              </div>
              <div className="adminFlexItem editRole">
                {showRoles && selectedAdmin === admin.email ? (
                  <RolesContent
                    id={admin._id}
                    email={admin.email}
                    adminRole={admin.roles}
                    adminPanel={admin.adminPanels}
                    onSubmit={handleRolesSubmit}
                    onCancel={handleRolesCancel}
                  />
                ) : (
                  <div onClick={() => selectAdmin(admin.email)}>
                    <div className="masterBtns">Edit</div>
                  </div>
                )}
              </div>
              <div className="adminFlexItem editRole">
                {admin.adminPanels
                  .slice()
                  .sort((a, b) => a._id - b._id)
                  .map(
                    (panel) =>
                      panel.displayName !== "Master Admin" && (
                        <div key={panel.displayName}>{panel.displayName}</div>
                      )
                  )}
              </div>
              <div className="adminFlexItem adminStatus">
                <input
                  type="checkbox"
                  name="systemAdmin"
                  id={admin._id}
                  checked={admin.roles && admin.roles.includes("sysadmin")}
                  onChange={(e) =>
                    handleCheckboxChange(admin._id, e.target.checked)
                  }
                />
                SA
              </div>
            </div>
            <hr />
          </div>
        ))}
      </div>
      <AdminFooter />
    </div>
  );
};
export default MasterAdmin;

// import React, { useState, useEffect } from "react";
// import axios from "axios";
// import { Link } from "react-router-dom";
// import RolesContent from "./adminDisplay/MasterPanelDisplay";
// import AdminFooter from "./adminComponents/AdminFooter";

// const MasterAdmin = () => {
//   const [getList, setGetList] = useState([]);

//   useEffect(() => {
//     axios
//       .get("/api/adminPanel/adminPanelUsers?isActive=true")
//       .then((res) => {
//         setGetList(res.data);
//         // Move the setAdminMembers inside this block to ensure it gets the correct data
//         setAdminMembers(res.data);
//       })
//       .catch((err) => {
//         console.log("error message =" + JSON.stringify(err));
//       });
//   }, []); // Empty dependency array to run only once

//   const [adminMembers, setAdminMembers] = useState(getList);

//   const [state, setState] = useState({
//     showRoles: false,
//     activePanels: [],
//     newUserInfo: [],
//     newId: "",
//   });

//   const { activePanels, newUserInfo, newId } = state;

//   useEffect(() => {
//     axios
//       .get('/api/adminPanel?isActive=true&_sort={"sequence": 1}')
//       .then((res) => {
//         setState({ ...state, activePanels: res.data });
//         console.log("test=", res.data);
//       })
//       .catch((err) => {
//         console.log("error message=" + JSON.stringify(err));
//       });
//   }, []);

//   const emailList = getList.map((member) => member.email);
//   const [showRoles, setShowRoles] = useState(false);
//   const [selectedAdmin, setSelectedAdmin] = useState(null);
//   const [newEmail, setNewEmail] = useState(""); // State for the new email input field

//   const emailRegEx = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

//   useEffect(() => {
//     if (emailRegEx.test(newEmail)) {
//       axios.get("/api/user/email/" + newEmail).then((res) => {
//         setState({ ...state, newUserInfo: res.data[0] });
//       });
//     }
//   }, [newEmail, showRoles]);

//   const selectAdmin = (email) => {
//     setSelectedAdmin(email);
//     setShowRoles(true);
//   };

//   const handleRolesSubmit = () => {
//     console.log("Add/Remove button clicked for email:", selectedAdmin);
//     alert("Add/Remove roles for email: " + selectedAdmin);
//     setShowRoles(true);
//   };

//   const handleRolesCancel = () => {
//     console.log("Cancel button clicked for email:", selectedAdmin);
//     setShowRoles(false);
//   };

//   const handleAddAdmin = () => {
//     if (newUserInfo != undefined && emailRegEx.test(newEmail)) {
//       if (!emailList.includes(newEmail)) {
//         const newId = newUserInfo._id;
//         const newAdmin = {
//           _id: newId,
//           email: newEmail,
//           adminPanels: [],
//         };

//         setAdminMembers((prevMembers) => [...prevMembers, newAdmin]);
//         setNewEmail(""); // Clear the new email input field
//       } else {
//         alert("Admin account already exits.");
//       }
//     } else {
//       alert("Invalid Credentials");
//     }
//   };

//   const handleCheckboxChange = async (adminId, isChecked) => {
//     // Update the local state immediately
//     setAdminMembers((prevMembers) =>
//       prevMembers.map((admin) =>
//         admin._id === adminId
//           ? { ...admin, roles: isChecked ? ["sysadmin"] : [] }
//           : admin
//       )
//     );

//     // Perform the API call
//     const url = `/api/user/${
//       isChecked ? "addUserRole" : "deleteUserRole"
//     }/${adminId}`;
//     const roleAction = isChecked ? "add" : "delete";

//     try {
//       const response = await axios.put(url, { role: "sysadmin" });
//       console.log(
//         `Role "${roleAction}" for admin with ID ${adminId} successful. Response:`,
//         response.data
//       );
//     } catch (error) {
//       console.error(`Error updating role for admin with ID ${adminId}:`, error);
//     }
//   };

//   return (
//     <div className="adminMainPanel">
//       <div className="infoTitle adminInfoTitle">
//         <div>Master Admin Panel</div>
//       </div>
//       <label htmlFor="">
//         New Admin User Email:
//         <input
//           value={newEmail}
//           onChange={(e) => setNewEmail(e.target.value)}
//           placeholder="Enter new email"
//         />
//       </label>

//       <div className="buttonGroup">
//         <div className="searchButton" onClick={handleAddAdmin}>
//           Add
//         </div>
//       </div>

//       <div>
//         <Link to="/adminreport">
//           <div className="masterlink">Main Menu</div>
//         </Link>
//       </div>

//       <div className="adminContentBox" style={{ overflow: "auto" }}>
//         <div className="adminFlexRow" style={{ fontWeight: "bold" }}>
//           <div className="adminFlexItem adminName">User Email</div>
//           <div className="adminFlexItem editRole">Edit Permission</div>
//           <div className="adminFlexItem editRole">Current Permission</div>
//           <div className="adminFlexItem adminStatus">System Admin</div>
//         </div>
//         <br />
//         {adminMembers.map((admin) => {
//           console.log(
//             "aaaa admin.adminPanels=",
//             JSON.stringify(admin.adminPanels)
//           );
//           return (
//             <div key={Math.random()}>
//               <div className="adminFlexRow" key={admin.email}>
//                 <div className="adminFlexItem adminName">
//                   {admin.email.split("@")[0]}
//                   <br />@{admin.email.split("@")[1]}
//                 </div>
//                 <div className="adminFlexItem editRole">
//                   {showRoles && selectedAdmin === admin.email ? (
//                     <RolesContent
//                       id={admin._id}
//                       email={admin.email}
//                       adminRole={admin.roles}
//                       adminPanel={admin.adminPanels}
//                       onSubmit={handleRolesSubmit}
//                       onCancel={handleRolesCancel}
//                     />
//                   ) : (
//                     <div onClick={() => selectAdmin(admin.email)}>
//                       <div className="masterBtns">Edit</div>
//                     </div>
//                   )}
//                 </div>
//                 <div className="adminFlexItem editRole">
//                   {admin.adminPanels
//                     .slice()
//                     .sort((a, b) => a._id - b._id)
//                     .map(
//                       (panel) =>
//                         panel.displayName !== "Master Admin" && (
//                           <div key={panel.displayName}>{panel.displayName}</div>
//                         )
//                     )}
//                 </div>
//                 <div className="adminFlexItem adminStatus">
//                   <input
//                     type="checkbox"
//                     name="systemAdmin"
//                     id={admin._id}
//                     checked={admin.roles && admin.roles.includes("sysadmin")}
//                     onChange={(e) =>
//                       handleCheckboxChange(admin._id, e.target.checked)
//                     }
//                   />
//                   SA
//                 </div>
//               </div>
//               <hr />
//             </div>
//           );
//         })}
//       </div>
//       <AdminFooter />
//     </div>
//   );
// };
// export default MasterAdmin;
