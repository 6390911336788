import "../../css/index.css";

/** 
 * @component EmailSentModal
 * @description 
 * @param {object} props
**/

export default function EmailSentModal(props) {
  return (    
    <div className="modalDialog" id="emailConfirmDialog">
      <div className="emailSentModal">
        <div className="feedbackHeader" id="modalHeader">
        ODA Diagnostic Profile Sent!
        </div>
        <br />
        <h4>Your {props.lang} {props.mod} diagnostic profile has successfuly been sent to {props.emailTarget}!</h4>          
                          
        <button className="outlined" onClick={e => props.refModalState(e)}>Close</button>                  
      </div>      
    </div>      
  );
}