import React, { useState, useCallback } from "react";
import AnswerField from "../components/AnswerField";
// import Audio from "../components/Audio";
import Audio from "../components/NewAudio";
import Next from "../../../../../components/testlet/Next_LC";

//http://localhost:3000/displaySingleTestlet/LC/kor_ecn511 (q4)

export default function Test(props) {
  const [toggleState, setToggleState] = useState(false);
  const toggleTheState = useCallback(
    (input) => {
      if (toggleState === false && input !== "not") {
        setToggleState(true);
      } else {
        setToggleState(false);
      }
    },
    [toggleState]
  );

  return (
    <div className="templateInner LSA2" key={props.anchorKey}>
      <Audio
        key={props.audKey}
        LO={props.LO}
        mainAudio={props.mainAudio}
        modifiedAudio={props.hasOwnProperty("modifiedAudio")? props.modifiedAudio : null}
        itemInContextAudio={props.itemInContextAudio}
        questionText={props.questionText}
        lang={props.lang}
        toggleStateFn={toggleTheState}
        toggleState={toggleState}
      />
      <AnswerField
        actID={props.actID}
        onAnswerSelected={props.onAnswerSelected}
        onLoseFocusCheckNLP={props.onLoseFocusCheckNLP}
        invalidEnInput={props.invalidEnInput}
        toggleStateFn={toggleTheState}
        toggleState={toggleState}
      />
      <Next
        clickCounter={props.clickCounter}
        navLen={props.navLen}
        navCrrtItem={props.navCrrtItem}
        onClickNextBtn={props.onClickNextBtn}
        onSubmitBtn={props.onSubmitBtn}
        onEvaluation={props.onEvaluation}
      />
    </div>
  );
}
