import React, { useRef, useState, useEffect } from "react";
import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";
import dompurify from "dompurify";
import { IoPlay } from "react-icons/io5";
import { IoPause } from "react-icons/io5";
import { GiSpeaker } from "react-icons/gi";
import { IoIosRewind } from "react-icons/io";
import { IoMdFastforward } from "react-icons/io";

export default function PassageAnswerOption(props) {
  const [clickCounter, setClickCounter] = useState(props.clickCounter);

  const sanitizer = dompurify.sanitize;
  const lang = props.lang;
  const LO = props.actID;
  const num = props.optionNumber;
  const testitem = props.testitem;
  const answerFieldsID = props.answerFieldsID;
  const number = num + 1;
  const passageTitle = "Passage " + number;
  const passageLen = props.optionLen;
  const audioPlayerAns = useRef(null);

  //moved into useEffect to reset positions in functional component
  useEffect(() => {
    document.querySelectorAll(".radioButton").forEach(function (event) {
      event.style.display = "none";
    });

    let gridItems = document.querySelectorAll(".GLCB .answerSection .gridItem");
    gridItems.forEach(function (gridItem) {
      gridItem.addEventListener("click", function (event) {
        let answerId = this.parentElement.parentElement.parentElement.closest(".answerField").id;
        answerId = "#" + answerId + " .gridItem";
        document.querySelectorAll(answerId).forEach(function (event) {
          event.classList.remove("selectedAnswer");
        });
        this.classList.add("selectedAnswer");
      });
    });

    setClickCounter(props.clickCounter);
  }, []);
  useEffect(() => {
    setClickCounter(props.clickCounter);
  }, [props]);

  useEffect(() => {
    if (clickCounter === 0) {
      document.querySelectorAll(".fstAttempt").forEach(function (event) {
        event.style.display = "inline";
      });
      document.querySelectorAll(".sndAttempt").forEach(function (event) {
        event.style.display = "none";
      });
    } else {
      document.querySelectorAll(".fstAttempt").forEach(function (event) {
        event.style.display = "none";
      });
      document.querySelectorAll(".sndAttempt").forEach(function (event) {
        event.style.display = "inline";
      });
    }
  }, [props, clickCounter]);

  const createAudioPassage = () => {
    let main = [];
    let audioTitle = (
      <span>
        Audio Passage
        {number}
      </span>
    );
    main.push(
      <span key={number} id={number} onClick={props.onPlayMainAudio}>
        <span className="audPassage">{audioTitle} </span>
      </span>
    );
    return main;
  };

  const questionText = <span>Assessment Item {number}</span>;

  const questionItem = props.optionPassage.map((options, index) => {
    let items = [];
    let mp3 = options.substr(-4);

    if (mp3 === ".mp3" && index === passageLen - 1) {
      let fn = "audio/" + lang + "/" + LO + "/" + options;
      items.push(
        // <span key={fn} className="inlinePlay paused" file={fn}>
        //   <span className="modifiedFlag">{questionText}</span>
        // </span>
        <div className="audioQ ">
          <AudioPlayer
            autoPlay={false}
            autoPlayAfterSrcChange={false}
            // header={props.toggleState ? "(modified)" : null}
            header={questionText}
            footer={null}
            hasDefaultKeyBindings={false}
            customAdditionalControls={[]}
            customVolumeControls={[]}
            ref={audioPlayerAns["inline-" + index]}
            src={fn}
            showJumpControls={false}
            showDownloadProgress={false}
            showFilledProgress={false}
            customIcons={{
              play: <IoPlay />,
              pause: <IoPause />,
              rewind: <IoIosRewind />,
              forward: <IoMdFastforward />,
              volume: <GiSpeaker />,
            }}
            // onPlay={(e) => console.log("onPlay")}
          />
        </div>
      );
    }
    return items;
  });

  const answerContent = props.optionChoice;
  const contNum = answerContent.length;
  const createText = () => {
    let txt = [];
    for (let i = 0; i < contNum; i++) {
      txt.push(
        <span
          className="noStyle"
          dangerouslySetInnerHTML={{
            __html: sanitizer(answerContent[i].value)
          }}
        />
      );
    }
    return txt;
  };

  const itemTxt = createText();

  const radioID_fst = answerFieldsID + "_0";
  const radioID_snd = answerFieldsID + "_1";
  const anwerFieldNum = "answer_" + num;

  const createRadioButton = () => {
    let radioButton = [];
    for (let i = 0; i < contNum; i++) {
      const letter = String.fromCharCode(i + "A".charCodeAt(0));
      const mykey = props.actID + i;
      const mykey2 = props.actID + i + 0;
      radioButton.push(
        <div key={letter} id={anwerFieldNum} className="answerField">
          <div key={mykey} className="fstAttempt">
            <input
              type="radio"
              className="radioButton"
              name={answerFieldsID}
              data-testitem={testitem}
              id={radioID_fst + i}
              placeholder={radioID_fst}
              value={i}
              onChange={props.onAnswerSelected}
            />
            <label htmlFor={radioID_fst + i}>
              <div className="gridItem">
                <span>
                  {letter}:{"  "}
                </span>
                {itemTxt[i]}
              </div>
            </label>
          </div>
          <div key={mykey2} className="sndAttempt">
            <input
              type="radio"
              className="radioButton"
              name={answerFieldsID}
              data-testitem={testitem}
              id={radioID_snd + i}
              placeholder={radioID_snd}
              value={i}
              onChange={props.onAnswerSelectedMod}
            />
            <label htmlFor={radioID_snd + i}>
              <div className="gridItem">
                <span>
                  {letter}: {"  "}
                </span>
                {itemTxt[i]}
              </div>
            </label>
          </div>
        </div>
      );
    }
    return radioButton;
  };

  const buildup = (
    <div>
      <div>
        <div className="answerSection noSelect">
          <div className="gridContainer">{createRadioButton()}</div>
        </div>
      </div>
    </div>
  );
  return (
    <div>
      <hr />
      <h2>{passageTitle}</h2>
      <h3>
        <div className="GRLC_content">
          <div className="GRLC_item">{createAudioPassage()}</div>
          <div className="GRLC_item secondItem">{questionItem}</div>
        </div>
      </h3>
      {buildup}
    </div>
  );
}
