import React, { useState, useEffect } from "react";
import AdminFooter from "./adminComponents/AdminFooter";

const SingleTestlet = () => {
  const initialState = {
    selectedModality: "",
    selectedLO: "",
  };

  const [state, setState] = useState(initialState);

  const { selectedModality, selectedLO } = state;

  const handleLOChange = (event) => {
    setState({ ...state, selectedLO: event.target.value });
  };

  const handleModalityChange = (event) => {
    setState({
      ...initialState,
      selectedModality: event.target.value,
    });
  };

  const getValues = () => {
    if (selectedLO === "" || selectedModality === "") {
      alert("Please fill out all fields");
    } else {
      const target =
        "/displaySingleTestlet/" + selectedModality + "/" + selectedLO;
      window.open(target, "_blank", "noreferrer");
    }
  };

  const refreshPage = () => {
    window.location.reload(false);
  };

  return (
    <div className="adminMainPanel">
      <div className="infoTitle adminInfoTitle">
        <div>Single Testlet</div>
      </div>
      <div>
        Modality:&nbsp;&nbsp;
        <select
          key="modality"
          value={selectedModality}
          onChange={handleModalityChange}
        >
          <option>Select</option>
          <option value="RC">Reading</option>
          <option value="LC">Listening</option>
          <option value="GR">Grammar</option>
        </select>
      </div>
      <div>
        <label htmlFor="keyword">
          Enter LO name:&nbsp;&nbsp;
          <input id="keyword" value={selectedLO} onChange={handleLOChange} />
        </label>
      </div>
      <div className="buttonGroup">
        <div onClick={getValues} className="searchButton">
          Get a Testlet
        </div>
        <div onClick={refreshPage} className="searchButton">
          {/* <div onClick={resetValues} className="searchButton"> */}
          Reset
        </div>
      </div>

      <div style={{ height: "200px" }}></div>

      <AdminFooter />
    </div>
  );
};

export default SingleTestlet;
