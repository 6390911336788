import "../../css/index.css";
/**
 * @component RegisterAccountModal
 * @description
 * @param {object} props
 **/

export default function AdaptiveAnalysisModal(props) {
  const afterCreation = (e) => {
    props.handleAdaptiveModalState(e);
  };

  return (
    <div className="modalDialog" id="RegisterAccountModal">
      <div className="RegisterAccountModal">
        <div
          className="feedbackHeader"
          id="modalHeader"
          style={{ fontSize: "16px" }}
        >
          What to Expect During Assessment
        </div>
        <h5
          style={{
            fontSize: "16px",
            fontWeight: "normal",
            overflow: "auto",
          }}
        >
          The ODA is made up of three components: reading, listening, and
          grammar. <br />
          <br />
          In the Reading Assessment, you will navigate through three (or more)
          sets, each of which contains three passages. Each passage is
          accompanied by four to six questions. A single passage is displayed at
          a time, with questions presented sequentially. Once you progress to
          the next question, you will be unable to revisit previous questions.
          After completing each set of three passages, you will be prompted to
          submit your answers. The quantity and difficulty levels of the
          three-passage sets adapt to your language capabilities.
          <br />
          <br /> The structure of the Listening Assessment is similar: you will
          listen to three (or more) sets of three audio passages, each followed
          by four to six questions. For certain levels, a modified version of
          the original recording is available.{" "}
          <strong>
            Use the modified version only if you cannot understand the authentic
            version.
          </strong>{" "}
          Like the Reading Assessment, the number of three-passage sets is
          adaptive, varying based on your performance.
          <br />
          <br />
          The Grammar Assessment follows a different structure. It consists of
          three non-adaptive sets (Level 1, 1+/2, and 2+/3) except for the
          English Grammar Assessment, which has two sets (1+/2, and 2+/3).
          <br />
        </h5>

        <button
          className="goLogin outlined"
          style={{
            fontSize: "16px",
            top: "6px",
            padding: "3px 7px",
          }}
          onClick={(e) => afterCreation(e)}
        >
          Close
        </button>
        <br />
        <br />
      </div>
    </div>
  );
}
