import React from "react";

export default function EdgeCaseBelow(props) {
  //console.log(props);

  return (<>Based on your performance in this ODA session, your ILR proficiency level is <b>{props.language === "eng" ? "below 1+" : "below 1"}</b> (Current Level).
  <br /><br />
  Your goal is to work toward proficiency level <b>{props.language === "eng" ? "1+" : "1"}</b> (Target Level).
  <br /><br />
  <b><i>Note:</i></b> The primary purpose of ODA is to provide you with formative feedback - feedback to help you in the learning process. The ILR level estimate you are given here is intended to function as a reference for charting your progress toward higher proficiency.  You may or may not receive the same level at an official test.
  <br /><br />
  The goal is to work incrementally toward your target proficiency level, by learning more about the content and the lexical, syntactical, and discourse aspects that you have not yet mastered. 
  <br /><br />
  You have not demonstrated the ability to understand basic short sentences in the target language. The current version of ODA does not assess the ability to understand isolated words and phrases.  Therefore, this section on "what you CAN do now" may contain little or no feedback.
  </>);
}

