import React, { useState, useEffect } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import axios from "axios";
import DateRangePicker from "./adminComponents/DateRangePicker";
import AdminFooter from "./adminComponents/AdminFooter";
import GroupDPDisplay from "./adminDisplay/GroupDPDisplay";

const GroupDP = () => {
  const initialState = {
    content: [],
    language: [],
    languageGR: [],
    selectedLanguage: "",
    selectedModality: "",
    selectedSite: "",
    excludeAdmin: false,
  };

  const [state, setState] = useState(initialState);

  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);

  const navigate = useNavigate();

  const date = new Date("1970-01-01");
  const isoDate = date.toISOString().slice(0, 10);

  function refreshPage() {
    window.location.reload(false);
  }

  const resetValues = () => {
    setState({
      ...state,
      content: [],
      selectedLanguage: "",
      selectedModality: "",
      selectedSite: "",
      excludeAdmin: false,
    });
    document.querySelector(".adminContentBox").innerHTML = null;
  };

  const {
    language,
    languageGR,
    selectedLanguage,
    selectedModality,
    selectedSite,
    excludeAdmin,
  } = state;

  useEffect(() => {
    if (selectedModality === "reading" || selectedModality === "listening") {
      axios
        .get('/api/language?modalities__ne=[]&_sort={"sequence": 1}')
        .then((res) => {
          setState({ ...state, language: res.data });
        });
    }
    if (selectedModality === "grammar") {
      axios
        .get('/api/language?modalities="grammar"&_sort={"sequence": 1}')
        .then((res) => {
          setState({ ...state, languageGR: res.data });
        });
    }
  }, [selectedModality]);

  let langDropdown = language.map((item, index) => (
    <option key={index} value={item._id}>
      {item.displayName}
    </option>
  ));

  let langDropdownGR = languageGR.map((item, index) => (
    <option key={index} value={item._id}>
      {item.displayName}
    </option>
  ));

  const handleLanguageChange = (event) => {
    setState({ ...state, selectedLanguage: event.target.value });
  };

  const handleModalityChange = (event) => {
    const initial = {
      selectedLanguage: "",
      selectedSite: "",
    };
    setState({
      ...state,
      selectedModality: event.target.value,
      ...initial,
    });
  };

  const handleSiteChange = (event) => {
    setState({ ...state, selectedSite: event.target.value });
  };

  const handleDatesSelected = (fromDate, toDate) => {
    const pickFrom = new Date(fromDate);
    const isoFrom = pickFrom.toISOString().slice(0, 10);

    const pickTo = new Date(toDate);
    const isoTo = pickTo.toISOString().slice(0, 10);
    setFromDate(isoFrom);
    setToDate(isoTo);
  };

  const handleExcludeAdmin = (event) => {
    setState({ ...state, excludeAdmin: event.target.checked 
    });
  };

  useEffect(() => {
    setState({ ...state, selectedLanguage: "Select" });
  }, [selectedModality]);

  const getValues = () => {
    const user = {
      lang: selectedLanguage,
      mod: selectedModality,
      site: selectedSite,
      startDate: fromDate,
      endDate: toDate,
      excludeAdmin: excludeAdmin,
    };

    const valuesAreValid =
      selectedLanguage !== "Select" &&
      selectedModality !== "Select" &&
      selectedSite !== "Select" &&
      selectedLanguage !== "" &&
      selectedModality !== "" &&
      selectedSite !== "" &&
      fromDate !== isoDate &&
      toDate !== isoDate &&
      fromDate !== null &&
      toDate !== null &&
      excludeAdmin !== null;

    if (valuesAreValid) {
      console.log("values=", {
        language: selectedLanguage,
        modality: selectedModality,
        site: selectedSite,
        startDate: fromDate,
        endDate: toDate,
        excludeAdmin: excludeAdmin,
      });
      axios
        .get("/api/adminReport/groupProfile", { params: user })
        .then((res) => {
          setState({ ...state, content: res.data });
          console.log("test=", res.data);
          console.log("test=", JSON.stringify(res.data));
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.status >= 400 && error.response.status < 600) {
            }
          }
        });
    } else {
      console.log("No value can be null");
      alert("Select all items");
    }
  };

  return (
    <div className="adminMainPanel">
      <div className="infoTitle adminInfoTitle">
        <div>ODA Group/Class DP Panel</div>
      </div>
      <div>
        Modality:
        <select
          key="modality"
          value={selectedModality}
          onChange={handleModalityChange}
        >
          <option>Select</option>
          <option value="reading">Reading</option>
          <option value="listening">Listening</option>
          <option value="grammar">Grammar</option>
        </select>
      </div>
      <div>
        Language:
        <select
          key="language"
          value={selectedLanguage}
          onChange={handleLanguageChange}
        >
          {selectedModality === "reading" ||
          selectedModality === "listening" ? (
            <>
              <option>Select</option>
              {langDropdown}
            </>
          ) : selectedModality === "grammar" ? (
            <>
              <option>Select</option>
              {langDropdownGR}
            </>
          ) : (
            <option>Select</option>
          )}
        </select>
      </div>

      <div>
        Site:
        <select key="site" value={selectedSite} onChange={handleSiteChange}>
          <option>Select</option>
          <option value="oda1">ODA 1</option>
        </select>
      </div>
      <DateRangePicker onDatesSelected={handleDatesSelected} />
      <div>
        Exclude Admin Assessments:
        <input type="checkbox" onChange={handleExcludeAdmin} />
      </div>
      <div className="buttonGroup">
        <div onClick={getValues} className="searchButton">
          Get Records
        </div>
        <div onClick={refreshPage} className="searchButton">
          {/* <div onClick={resetValues} className="searchButton"> */}
          Reset Options
        </div>
      </div>
      <div className="adminContentBox" style={{ overflow: "auto" }}>
        <GroupDPDisplay
          content={state.content}
          lang={state.selectedLanguage}
          mod={state.selectedModality}
          //fromDate={state.fromDate}
          //toDate={state.toDate}
          // isData={state.isData}
          // checkAPI={state.checkAPI}
          // firstCheck={state.firstCheck}
        />
      </div>

      <AdminFooter />
    </div>
  );
};

export default GroupDP;
