import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { ErrorContext } from "../../../context/ErrorProvider";
import { AssessmentContext } from "../../../context/AssessmentProvider";
import axios from "axios";
import axiosRetry from "axios-retry";
import SurveyQuestion from "./surveyComponent/SurveyQuestion";

const SurveyFormGrammar = () => {
  const [, errorDispatch] = useContext(ErrorContext);
  const [assessmentState] = useContext(AssessmentContext);
  axiosRetry(axios, {
    retries: 4, // number of retries

    retryDelay: (...arg) => axiosRetry.exponentialDelay(...arg, 1000), // Exponential delay with backoff of 1000mst
            retryCondition: (err) => {return axiosRetry.isNetworkError(err) || err.code === "ECONNABORTED" || err.code === "ENOTFOUND" || err.code === "ETIMEDOUT" || (err.response && err.response.status >= 500 && err.response.status <= 599) }


  });
  let navigate = useNavigate();

  const initialState = {
    language: [],
    selectedLanguage: "Select"
  };
  const [state, setState] = useState(initialState);

  const [responses, setResponses] = useState({
    sg1: "0",
    sg2: "0",
    sg3: "0",
    sg4: "0",
    sg5: "0",
    sg6: "0",
    sg7: "0",
    sg8: "0",
    sg9: "0",
    sg10: "" // Adding sg10 for Other Comments
  });

  const { language, selectedLanguage } = state;

  useEffect(() => {
    axios
      .get('/api/language?modalities="grammar"&_sort={"sequence": 1}')
      .then((res) => {
        setState({ ...state, language: res.data });
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status >= 400 && error.response.status < 600) {
            errorDispatch({
              type: "UPDATE_ERROR_STATE",
              payload: {
                errorStatusCode: error.response.status,
                errorUserMessage: "New Error",
                errorDevData: error.response
              }
            });
          }
        }
      });
  }, []);

  let langDropdown = state.language.map((item, index) => (
    <option key={index} value={item._id}>
      {item.displayName}
    </option>
  ));

  window.history.pushState(null, null, window.location.href);
  window.addEventListener("popstate", function (event) {
    this.window.history.pushState(null, null, this.location.href);
  });

  let checkLevel = "-1";
  switch (assessmentState.assessmentModuleRef) {
    case "GR-1":
      checkLevel = "1";
      break;
    case "GR-1+/2":
      checkLevel = "1+2";
      break;
    case "GR-2+/3":
      checkLevel = "2+3";
      break;
    default:
      // Do nothing, checkLevel remains "-1"
      break;
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    const user = {
      userId: assessmentState.assessmentUserId,
      langId: selectedLanguage,
      response: responses
    };
    if(checkLevel !== "-1") {
      user.lvl = checkLevel;
    }

    const valuesAreValid = selectedLanguage !== "" && selectedLanguage !== "Select";
    if (valuesAreValid) {
      axios({
        method: "post",
        headers: { "content-type": "application/json" },
        url: "/api/surveyGR/",
        data: user
      }).catch((error) => {
        if (error.response) {
          if (error.response.status >= 400 && error.response.status < 600) {
            errorDispatch({
              type: "UPDATE_ERROR_STATE",
              payload: {
                errorStatusCode: error.response.status,
                errorUserMessage: "New Error",
                errorDevData: error.response
              }
            });
          }
        }
      });
      alert("Your feedback is appreciated. Thank you!");
      navigate("/dashboard");
    } else {
      alert("Please select a language");
    }
  };

  const handleLanguageChange = (event) => {
    setState({ ...state, selectedLanguage: event.target.value });
  };

  const handleResponseChange = (id, value) => {
    setResponses({ ...responses, [id]: value });
  };

  const handleCommentsChange = (event) => {
    setResponses({ ...responses, sg10: event.target.value });
  };

  const surveyQuestions = [
    {
      id: "sg1",
      text: "1. The ODA Grammar instructions are clear and easy to understand.",
      value: responses.sg1,
      setValue: (value) => handleResponseChange("sg1", value)
    },
    {
      id: "sg2",
      text: "2. The length of the assessment is reasonable.",
      value: responses.sg2,
      setValue: (value) => handleResponseChange("sg2", value)
    },
    {
      id: "sg3",
      text: "3. The texts are of appropriate length.",
      value: responses.sg3,
      setValue: (value) => handleResponseChange("sg3", value)
    },
    {
      id: "sg4",
      text: "4. The answer options force me to apply grammatical knowledge rather than to guess.",

      value: responses.sg4,
      setValue: (value) => handleResponseChange("sg4", value)
    },
    {
      id: "sg5",
      text: "5. The data in the profile is useful.",
      value: responses.sg5,
      setValue: (value) => handleResponseChange("sg5", value)
    },
    {
      id: "sg6",
      text: "6. The data in the profile is easy to understand.",
      value: responses.sg6,
      setValue: (value) => handleResponseChange("sg6", value)
    },
    {
      id: "sg7",
      text: "7. My ODA Grammar score accurately reflects my grammatical ability.",

      value: responses.sg7,
      setValue: (value) => handleResponseChange("sg7", value)
    },
    {
      id: "sg8",
      text: "8. I would retake this assessment in the future to check my progress.",
      value: responses.sg8,
      setValue: (value) => handleResponseChange("sg8", value)
    },
    {
      id: "sg9",
      text: "9. The listening items accurately reflect my grammatical ability.",
      value: responses.sg9,
      setValue: (value) => handleResponseChange("sg9", value)
    }
  ];

  return (
    <div className="adminMainPanel">
      <h2>User Survey Questionnaire (Grammar)</h2>
      <p>
        Please indicate your response to the following statements and questions. We appreciate your taking a moment to
        complete this questionnaire. Thank you.
      </p>
      <form onSubmit={handleSubmit}>
        <div className="surveyText">
          Language:
          <select key="language" value={selectedLanguage} onChange={handleLanguageChange}>
            <option>Select</option>
            {langDropdown}
          </select>
        </div>

        {surveyQuestions.map((question) => (
          <SurveyQuestion
            key={question.id}
            id={question.id}
            text={question.text}
            value={question.value}
            setValue={question.setValue}
          />
        ))}
        <div style={{ margin: "20px 0px 20px 12px" }}>10. Other Comments:</div>
        <textarea className="QSAAnswer surveyComment" onChange={handleCommentsChange} name="sg10" />
        <div>
          <button className="surveySubmitBtn">Submit Questionnaire</button>
        </div>
      </form>
    </div>
  );
};

export default SurveyFormGrammar;
