import React, { useState, useCallback, useEffect, useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import Dropdown from "../../components/menubar/Dropdown";
import { CgMenu } from "react-icons/cg";
import { CgClose } from "react-icons/cg";
import Auth from "../../contents/Auth";
import header from "../../img/logos/headerLogo.svg";
import Signout from "../../components/signout/Signout";
import headerSmall from "../../img/logos/headerLogoSmall.svg";
import accountIcon from "../../img/icons/account.svg";
import querySelectors from "../../functions/headerFunctions";
import { AssessmentContext } from "../../context/AssessmentProvider";

export default function Header() {
  const location = useLocation();
  const [token, setToken] = useState(JSON.parse(localStorage.getItem("token")));
  useEffect(() => {
    if (!token) {
      setToken(JSON.parse(localStorage.getItem("token")));
    }
  }, [location, token]);

  const [assessmentState] = useContext(AssessmentContext);

  useEffect(() => {
    const footerLogo = document.querySelector(".footerLogo");
    footerLogo.style.top = "10px";
    footerLogo.style.height = "67px";

    querySelectors();
  }, []);

  const status = "home";
  const logout = () => {
    Auth.logout(() => {
      //console.log("clearing local storage");
      window.localStorage.clear();
      localStorage.removeItem("token");
      window.location.href = "/";
    });
  };
  const [click, setClick] = useState(false);
  const [dropdown, setDropdown] = useState(false);

  const handleClick = useCallback(() => {
    setClick(!click);
  }, [click]);

  // const closeMobileMenu = useCallback(() => {
  //   document.querySelector(".bodyItems1").style.display = "none";
  //   document.querySelector(".bodyItems2").style.display = "none";
  //   document.querySelector(".bodyItems3").style.display = "none";
  //   document.querySelector(".evalInfo").style.display = "none";
  //   document.querySelector(".switch").style.display = "none";
  //   setClick(false);
  // }, []);

  // const showItems = useCallback(() => {
  //   document.querySelector(".bodyItems1").style.display = "inline";
  //   document.querySelector(".bodyItems2").style.display = "inline";
  //   document.querySelector(".bodyItems3").style.display = "inline";
  //   document.querySelector(".evalInfo").style.display = "inline";
  //   document.querySelector(".switch").style.display = "none";
  //   setClick(false);
  // }, []);

  const onMouseEnter = useCallback(() => {
    setDropdown(true);
  }, []);

  const onMouseLeave = useCallback(() => {
    setDropdown(false);
  }, []);

  const openIcon = (
    <CgMenu className="cgHamberger cgHambergerMain" size="25px" color="green" />
  );
  const closeIcon = (
    <CgClose
      className="cgHamberger cgHambergerMain"
      size="25px"
      color="green"
    />
  );

  // const handleDropDown = useCallback((e) => {
  //   if (e.style.display === "none") {
  //     document.getElementById("signout-content").style.display = "block";
  //   } else {
  //     document.getElementById("signout-content").style.display = "none";
  //   }
  // }, []);

  // "/" => onClick={showItems}
  // "Signout" => onClick={handleDropDown}
  // "dashboard" => onClick={showItems}
  // "about" => onClick={closeMobileMenu}

  return (
    <header>
      <Link to="/">
        <img
          className="logo"
          src={header}
          alt="Online Diagnostic Assessment Logo"
          title="Click here to return to the main home page."
        />
        <img className="logoSmall" src={headerSmall} alt="Online Diagnostic Assessment Logo" />
      </Link>
      {token && (
        <Signout>
          <div className="account">
            <img id="accountIcon" src={accountIcon} alt="Account Logo" />
          </div>

          <div className="signout-content accountSlideOutPane" id="signout-content">
            <b>
              <u>Account</u>
            </b>
            <br />
            {assessmentState.assessmentUserEmail}
            <br />
            <br />
            <Link to="/editProfile" state={{ accountName: assessmentState.assessmentUserEmail }}>
              <span>Account Details</span>
            </Link>
            <br />
            <br />
            <div onClick={logout}>
              <u>Logout</u>
            </div>
          </div>
        </Signout>
      )}
      <nav className="menuNav">
        <ul className={click ? "nav-menu active" : "nav-menu"}>
          {token && (
            <li className="nav-item">
              <Link to="/dashboard" className="nav-links">
                Your Assessments
              </Link>
            </li>
          )}
          <li className="nav-item" onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
            <Link to="/about" className="nav-links home-dropdown">
              About
            </Link>
            {dropdown && <Dropdown onMenu={handleClick} status={status} />}
          </li>

          <li className="nav-item">
            <Link to="/contact" className="nav-links home-dropdown">
              Contact
            </Link>
          </li>
          {!token && (
            <li className="nav-item">
              <Link to="/" className="nav-links home-dropdown signInNav">
                Sign In
              </Link>
            </li>
          )}
          {!token && (
            <li className="nav-item">
              <Link to="/" className="signUpNav">
                Sign Up
              </Link>
            </li>
          )}
        </ul>
      </nav>
      <div className="menu-icon" onClick={handleClick}>
        {click ? closeIcon : openIcon}
      </div>
    </header>
  );
}
