import React, { useState, useEffect, useContext, useCallback } from "react";
import Test from "./components/Test";
import Footer from "../../../../components/footer/Footer";
import "../../../../css/index.css";
//import fontsizeFunctions from "../../../../functions/fontsizeFunctions";
import draggableFunctions from "../../../../functions/draggableFunctions";
import { AssessmentContext } from "../../../../context/AssessmentProvider";
import { useScoreNLP } from "../../../../hooks/useScoreNLP";
import { checkEnglishInput } from "../../../../functions/genericFunctions";

const LSA2 = (props) => {
  const testData = props.testData;
  const [state, setState] = useState({
    LO: testData._id,
    actID: testData.testitems[props.counter]._id,
    modality: testData.testitems[props.counter].modality,
    template: testData.testitems[props.counter].template,
    skill: testData.proficiencyLevel,

    mainAudio: testData.testitems[props.counter].mainAudio,
    itemInContextAudio: testData.testitems[props.counter].itemInContextAudio,
    questionText: testData.testitems[props.counter].questionTexts,
    lang: testData.language._id,
    userAnswer: [],
    checkAnswer: [],
    matchAnswer: [],
    scoredAnswer: [],
    correctAnswer: testData.testitems[props.counter].answerFields,
    answerField: testData.testitems[props.counter].answerFields,
    audioKey: Math.random(),
    anchorKey: new Date(),
  });
  // const [scoredAnswer, setScoredAnswer] = useState([]);
  const [assessmentState] = useContext(AssessmentContext);
  const [runScoreNLP, setRunScoreNLP] = useState(false);
  const [eventNLP, setEventNLP] = useState(false);
  const [storeNLPScores, setStoreNLPScores] = useState([]);
  const [finalChecking, setFinalChecking] = useState(false);
  const [showNonENTextError, setShowNonENTextError] = useState([]);

  state.userAnswer.length =
    testData.testitems[props.counter].answerFields.length;
  state.checkAnswer.length =
    testData.testitems[props.counter].answerFields.length;
  state.matchAnswer.length =
    testData.testitems[props.counter].answerFields.length;

  useEffect(() => {
    //fontSizeFunctions();
    draggableFunctions();

    return () => {};
  }, []);

  useEffect(() => {
    //disable next/submit until previous api call is complete; prevents overrunning api calls to change same data
    if (props.buttonWaiting) {
      const apiWaitButtons = document.querySelectorAll(".nextBtn, .submitBtn");
      const titleChanger = document.querySelector(".nextSubmitParentDiv");
      apiWaitButtons.forEach((item, index) => {
        item.classList.add("waitingForAPI");
        if (item.classList.contains("disabled")) {
          titleChanger.title =
            "Please answer the questions above before proceeding. You will also need to wait for the system to record your previous answer before proceeding.";
        } else {
          titleChanger.title = "Please wait for your assessment to save your previous answer before proceeding.";
        }
      });
      titleChanger.classList.remove("nextSubmitParentDivUnanswered");
      titleChanger.classList.add("nextSubmitParentDivWaiting");
    } else {
      const titleChanger = document.querySelector(".nextSubmitParentDiv");
      const apiWaitButtons = document.querySelectorAll(".nextBtn, .submitBtn");
      apiWaitButtons.forEach((item, index) => {
        item.classList.remove("waitingForAPI");
        if (item.classList.contains("disabled")) {
          titleChanger.title = "Please attempt to answers all questions before proceeding.  Note: If you feel your assessment is stuck and you can not proceed, you may skip to the next question by holding SHIFT and clicking this button.";
        } else {
          titleChanger.title = "Click to submit your answer(s).";
        }
      });
      titleChanger.classList.remove("nextSubmitParentDivWaiting");
      titleChanger.classList.add("nextSubmitParentDivUnanswered");
    }
  }, [props.buttonWaiting]);

  const judgeAnswers = useCallback(
    (index, value) => {
      const itemId = index;
      const userAns = value;
      let matching;
      let itemScore = 0;
      let countAnswer = 0;
      let matchAnswer = [];
      let checkAnswer = false;

      const passingScore = state.correctAnswer[itemId].passingScore;
      if (userAns !== undefined) {
        if (passingScore == "100") {
          const answerKeywordLen =
            state.correctAnswer[itemId].answerKeywords.length;

          for (let j = 0; j < answerKeywordLen; j++) {
            const patternLen =
              state.correctAnswer[itemId].answerKeywords[j].matchingPatterns
                .length;

            for (let k = 0; k < patternLen; k++) {
              const crrtAns =
                state.correctAnswer[itemId].answerKeywords[j].matchingPatterns[
                  k
                ] + ".*";

              matching = userAns.toLowerCase().match(crrtAns.toLowerCase());
              if (matching !== null) {
                countAnswer++;
                matchAnswer.push(crrtAns.toLowerCase());
                break;
              }
            }
          }

          if (countAnswer == answerKeywordLen) {
            checkAnswer = true;
          }
          return { checkAnswer, matchAnswer };
        } else {
          const answerKeywordLen =
            state.correctAnswer[itemId].answerKeywords.length;

          for (let j = 0; j < answerKeywordLen; j++) {
            let individualScore =
              state.correctAnswer[itemId].answerKeywords[j].individualScore;

            const patternLen =
              state.correctAnswer[itemId].answerKeywords[j].matchingPatterns
                .length;

            for (let k = 0; k < patternLen; k++) {
              const crrtAns =
                state.correctAnswer[itemId].answerKeywords[j].matchingPatterns[
                  k
                ] + ".*";

              matching = userAns.toLowerCase().match(crrtAns.toLowerCase());
              if (matching !== null) {
                itemScore = itemScore + individualScore;
                matchAnswer.push(crrtAns.toLowerCase());
                break;
              }
            }
          }
          if (itemScore >= passingScore) {
            checkAnswer = true;
          }
          return { checkAnswer, matchAnswer };
        }
      }
    },
    [state]
  );

  const handleClick = useCallback(
    (event) => {
      const id = event.currentTarget.id;
      const index = event.currentTarget.id.substr(-1);
      const value = event.currentTarget.value;

      let new_state = Object.assign({}, state);
      let a = new_state.userAnswer;
      let b = new_state.checkAnswer;
      let c = new_state.matchAnswer;
      let z = new_state.scoredAnswer;

      let cnt = 0;
      let finalCheck = null;

      const answerCheck = judgeAnswers(index, value).checkAnswer;
      const answerMatch = judgeAnswers(index, value).matchAnswer;

      /*test english input*/
      /* set up a dynamic array in state to track which elements have invalid characters */
      if (state.correctAnswer[index].isKeywordInEnglish) {
        if (!checkEnglishInput(value)) {
          let showNonENTextErrorTemp = showNonENTextError;
          if (
            !showNonENTextErrorTemp.find((thisEl) => thisEl === id + "_" + index) ||
            !showNonENTextErrorTemp.find((thisEl) => thisEl === id)
          ) {
            /*if invalid & doesnt yet exist; add it to error array */
            showNonENTextErrorTemp.push(id + "_" + index);
            showNonENTextErrorTemp.push(id);
            setShowNonENTextError(showNonENTextErrorTemp);
          } else {
            //do nothing
          }
        } else {
          let showNonENTextErrorTemp = showNonENTextError;
          if (showNonENTextErrorTemp.find((thisEl) => thisEl === id + "_" + index)) {
            /*if valid & exist; remove it from error array */
            let remIndex = (subEl) => subEl === id + "_" + index;
            let thisIndex = showNonENTextErrorTemp.findIndex(remIndex);
            showNonENTextErrorTemp.splice(thisIndex, 1);
          }
          if (showNonENTextErrorTemp.find((thisEl) => thisEl === id)) {
            /*if valid & exist; remove it from error array */
            let remIndex = (subEl) => subEl === id;
            let thisIndex = showNonENTextErrorTemp.findIndex(remIndex);
            showNonENTextErrorTemp.splice(thisIndex, 1);
          }
          setShowNonENTextError(showNonENTextErrorTemp);
        }
      }

      a[index] = value;
      b[index] = answerCheck;
      c[index] = answerMatch;
      z[index] = {
        uiControlID: id,
        testlet: testData._id,
        testitem: state.actID,
        answerField: state.answerField[index]._id,
        keywordMatches: answerMatch,
        userAnswer: value,
        isPassed: answerCheck,
        isModifiedAudioPlayed: false,
        isTranscriptShown: false
      };
      setState({
        ...state,
        userAnswer: a,
        checkAnswer: b,
        matchAnswer: c,
        scoredAnswer: z
      });

      const ansLen = state.checkAnswer.length;
      for (let i = 0; i < ansLen; i++) {
        if (state.checkAnswer[i] === true) {
          cnt++;
        }
      }
      if (cnt === state.correctAnswer.length) {
        finalCheck = true;
      } else {
        finalCheck = false;
      }

      const len = state.userAnswer.length;
      let num = 0;
      for (let i = 0; i < len; i++) {
        if (state.userAnswer[i] !== undefined) {
          num++;
          if (state.userAnswer[i].length === 0) {
            num--;
          }
        }
      }
      if (num === len) {
        document.querySelector(".nextBtn").classList.remove("disabled");
        const titleChanger = document.querySelector(".nextSubmitParentDiv");
        titleChanger.classList.remove("nextSubmitParentDivUnanswered");
        if (titleChanger.classList.contains("nextSubmitParentDivWaiting")) {
          titleChanger.title = "Please wait for your assessment to save your previous answer before proceeding.";
        } else {
          titleChanger.title = "Click to submit your answers.";
        }
      } else {
        document.querySelector(".nextBtn").classList.add("disabled");
        const titleChanger = document.querySelector(".nextSubmitParentDiv");
        titleChanger.classList.add("nextSubmitParentDivUnanswered");
        if (titleChanger.classList.contains("nextSubmitParentDivWaiting")) {
          titleChanger.title = "Please wait for your assessment to save your previous answer before proceeding.";
        } else {
          titleChanger.title = "Please attempt to re-answer at least one incorrect question before proceeding.";
        }
      }
    },
    [state]
  );

  const handleEval = useCallback((name) => {
    const btnName = name;
    props.parentCallback(JSON.stringify(state.scoredAnswer), btnName);
  });

  // ** Issue #163 - disable NLP calls until post-launch period
  // //** customHook & effects to score from NLP API */
  // const [nlpScoreData, nlpScoreRunning, nlpScoreError] = useScoreNLP(
  //   eventNLP,
  //   "LSA2",
  //   assessmentState.assessmentSessionId,
  //   state.correctAnswer,
  //   runScoreNLP
  // );
  // //only execute once
  // useEffect(() => {
  //   if (runScoreNLP) {
  //     setRunScoreNLP(false); //resets to false after one run
  //   }
  // }, [runScoreNLP]);
  // //initialization call from react component
  // const checkNLP = useCallback((event) => {
  //   setEventNLP(event);
  //   setRunScoreNLP(true);
  // }, []);
  // //store the async results in a static object we can reference at the end of the testlet
  // useEffect(() => {
  //   if (nlpScoreData !== null) {
  //     let tempArr = storeNLPScores;
  //     if (nlpScoreData.testletId !== null && nlpScoreData.nlpScore !== null) {
  //       tempArr[nlpScoreData.testletId] = nlpScoreData.nlpScore;
  //       //setStoreNLPScores(tempArr);
  //     }
  //     //console.log(tempArr);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [nlpScoreData]);
  // useEffect(() => {
  //   if (nlpScoreError !== null) {
  //     console.log("NLP error: " + nlpScoreError);
  //   }
  // }, [nlpScoreError]);

  const renderTest = () => {
    return (
      <>
        <Test
          LO={state.LO}
          actID={state.actID}
          lang={state.lang}
          audKey={state.audioKey}
          anchorKey={state.anchorKey}
          mainAudio={state.mainAudio}
          itemInContextAudio={state.itemInContextAudio}
          questionText={state.questionText}
          onAnswerSelected={handleClick}
          navLen={props.navLen}
          navCrrtItem={props.navCrrtItem}
          onClickNextBtn={props.clickNextBtn}
          onSubmitBtn={props.submitBtn}
          onEvaluation={handleEval}
          // ** Issue #163 - disable NLP calls until post-launch period
          //onLoseFocusCheckNLP={checkNLP}
          invalidEnInput={showNonENTextError}
        />
        <Footer
          actID={state.actID}
          mod={state.modality}
          template={state.template}
          skill={state.skill}
        />
      </>
    );
  };
  return <div>{renderTest()}</div>;
};
export default LSA2;
