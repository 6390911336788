import React, { useEffect, useState } from "react";

export default function AnswerField(props) {
  const num = props.optionNumber;
  const testitem = props.testitem;
  const answerFieldsID = props.answerFieldsID;
  const instruction = props.instruction;
  const questionItem = props.optionPassage;
  const answerContent = props.optionChoice;
  const contNum = answerContent.length;
  const RTL = props.dir;
  const dir = RTL ? "RTL" : "";
  const [clickCounter, setClickCounter] = useState(props.clickCounter);

  const questionCombined = "<strong>" + (num + 1) + ". </strong>" + instruction + "<br />" + questionItem;

  //moved into useEffect to reset positions in functional component
  useEffect(() => {
    document.querySelectorAll(".radioButton").forEach(function (event) {
      event.style.display = "none";
    });

    let gridItems = document.querySelectorAll(".GMCA .answerSection .gridItem");
    gridItems.forEach(function (gridItem) {
      gridItem.addEventListener("click", function (event) {
        let answerId = this.parentElement.parentElement.parentElement.closest(".answerField").id;
        answerId = "#" + answerId + " .gridItem";
        document.querySelectorAll(answerId).forEach(function (event) {
          event.classList.remove("selectedAnswer");
        });
        this.classList.add("selectedAnswer");
      });
    });

    setClickCounter(props.clickCounter);
  }, []);
  useEffect(() => {
    setClickCounter(props.clickCounter);
  }, [props]);

  useEffect(() => {
    if (clickCounter === 0) {
      document.querySelectorAll(".fstAttempt").forEach(function (event) {
        event.style.display = "inline";
      });
      document.querySelectorAll(".sndAttempt").forEach(function (event) {
        event.style.display = "none";
      });
    } else {
      document.querySelectorAll(".fstAttempt").forEach(function (event) {
        event.style.display = "none";
      });
      document.querySelectorAll(".sndAttempt").forEach(function (event) {
        event.style.display = "inline";
      });
    }
  }, [props, clickCounter]);

  const createVal = () => {
    let val = [];
    for (let i = 0; i < contNum; i++) {
      val.push(i);
    }
    return val;
  };

  const createText = () => {
    let txt = [];
    for (let i = 0; i < contNum; i++) {
      txt.push(answerContent[i].value);
    }
    return txt;
  };
  const itemVal = createVal();
  const itemTxt = createText();

  const radioID_fst = answerFieldsID + "_0";
  const radioID_snd = answerFieldsID + "_1";

  const anwerFieldNum = "answer_" + num;

  const createRadioButton = () => {
    let radioButton = [];
    for (let i = 0; i < contNum; i++) {
      const letter = String.fromCharCode(i + "A".charCodeAt(0));
      const mykey = props.actID + i;
      const mykey2 = props.actID + i + 0;
      radioButton.push(
        <div className="answerField" key={letter} id={anwerFieldNum}>
            <div key={mykey} className="fstAttempt">
              <input
                type="radio"
                className="radioButton"
                name={answerFieldsID}
                data-testitem={testitem}
                id={radioID_fst + i}
                placeholder={radioID_fst}
                value={itemVal[i]}
                onChange={props.onAnswerSelected}
              />
              <label htmlFor={radioID_fst + i}>
                <div className="gridItem">
                  <span>{letter}: </span>
                  {itemTxt[i]}
                </div>
              </label>
            </div>

            <div key={mykey2} className="sndAttempt">
              <input
                type="radio"
                className="radioButton"
                name={answerFieldsID}
                data-testitem={testitem}
                id={radioID_snd + i}
                placeholder={radioID_snd}
                value={itemVal[i]}
                onChange={props.onAnswerSelectedMod}
              />
              <label htmlFor={radioID_snd + i}>
                <div className="gridItem">
                  <span>{letter}: </span>
                  {itemTxt[i]}
                </div>
              </label>
            </div>
        </div>
      );
    }
    return radioButton;
  };

  const buildup = (
    <div className="answerSection noSelect">
      <div className="gridContainer">{createRadioButton()}</div>
    </div>
  );

  return (
    <div>
      <h3>
        {" "}
        <div
          className={[dir, "instructions"].join(" ")}
          dangerouslySetInnerHTML={{
            __html: questionCombined
          }}
        />
      </h3>
      {buildup}
    </div>
  );
}
