import { useState, useCallback, useContext } from "react";
import { ErrorContext } from "../../context/ErrorProvider";
import axios from "axios";
import axiosRetry from "axios-retry";
import "../../css/index.css";

/**
 * @component EmailNotVerifiedModal
 * @description
 * @param {object} props
 **/

export default function EmailNotVerifiedModal(props) {
  const [, errorDispatch] = useContext(ErrorContext);
  const [thisEmail, setThisEmail] = useState(
    props?.parentState?.input?.email !== undefined ? props.parentState.input.email : null
  );
  const [requestedNew, setRequestedNew] = useState(false);
  axiosRetry(axios, {
    retries: 4, // number of retries

    retryDelay: (...arg) => axiosRetry.exponentialDelay(...arg, 1000), // Exponential delay with backoff of 1000mst
    retryCondition: (err) => {     
      return (
        axiosRetry.isNetworkError(err) ||
        err.code === "ECONNABORTED" || err.code === "ENOTFOUND" || err.code === "ETIMEDOUT" ||
        (err.response && err.response.status >= 500 && err.response.status <= 599)
      );
    }
  });

  const resendEmail = useCallback(() => {
    //send email data object to API
    axios
      .post("/api/user/verify?email=" + thisEmail)
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          setRequestedNew("success");
        } else {
          setRequestedNew("failed");
        }
      })
      .catch((error) => {
        setRequestedNew("failed");
        // console.log("Failed to re-request verification email for " + thisEmail + "!");
        // console.log(error);
        if (error.response) {
          if (error.response.status >= 400 && error.response.status < 600) {
            //global error dispatch call to show error modal if error received during api call
            errorDispatch({
              type: "UPDATE_ERROR_STATE",
              payload: {
                errorStatusCode: error.response.status,
                errorUserMessage: "New Error",
                errorDevData: error.response
              }
            });
          }
        }
      });
  }, [thisEmail]);

  return (
    <div className="modalDialog" id="modalDialog">
      <div className="reportIssueModal">
        <div className="feedbackHeader" id="modalHeader">
          Email Not Verified
        </div>
        <h4>Your email address is not verified!</h4>
        <h5>
          Your account exists, but it is not yet verified. <br />
          <br /> An email should have been dispatched to your mailbox, possibly to your spam folder, with a link to
          verify your account. <br />
          <br /> Please click the "Verify" link in order to proceed. <br />
          <br />
          {thisEmail !== null && requestedNew === false && (
            <div>
              Click{" "}
              <u>
                <b>
                  <a className="infoLink" onClick={resendEmail}>
                    here
                  </a>
                </b>
              </u>{" "}
              to re-send your verification email.
            </div>
          )}
          {thisEmail !== null && requestedNew === "success" && (
            <div>
              Sent a new verification email to {thisEmail}!<br />
              Can't find it? Check your spam folder.
            </div>
          )}
          {requestedNew === "failed" && (
            <div>
              Failed to send a new verification email to {thisEmail}!<br />
              Please ty again later.
            </div>
          )}
        </h5>
        <button className="outlined" onClick={(e) => props.refModalState(e)}>
          Close
        </button>
        <br />
        <br />
        <br />
      </div>
    </div>
  );
}
