import React, { useRef, useState } from "react";
import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";
import { IoPlay } from "react-icons/io5";
import { IoPause } from "react-icons/io5";
import { GiSpeaker } from "react-icons/gi";
import { IoIosRewind } from "react-icons/io";
import { IoMdFastforward } from "react-icons/io";

export default function AnswerOption(props) {
  const LO = props.LO;
  const lang = props.lang;
  const actID = props.actID;
  const numberLetter = ["one", "two", "three", "four", "five"];
  const ddList = props.dropdownOptions;
  const selValue = props.value;
  const audioPlayerAns = useRef(null);

  let audioQuestion = "";
  let dropdown = "";

  dropdown = ddList[0].map((item, index) => (
    <option key={item} value={index}>
      {item}
    </option>
  ));

  const createAnswerField = () => {
    const len = props.question.length;
    let items = [];
    for (let i = 0; i < len; i++) {
      audioQuestion = props.question[i];
      audioQuestion = "audio/" + lang + "/" + LO + "/" + audioQuestion.value.replace(/\s/g, "_");

      items.push(
        <div className="categorizeAnswer" key={i}>
          <p className="question">
            {/* <span className="inlinePlay paused" file={audioQuestion}>
              Audio {numberLetter[i]}
            </span> */}
            <div className="audioQ ">
              <AudioPlayer
                autoPlay={false}
                autoPlayAfterSrcChange={false}
                // header={props.toggleState ? "(modified)" : null}
                header={"Audio " + numberLetter[i]}
                footer={null}
                hasDefaultKeyBindings={false}
                customAdditionalControls={[]}
                customVolumeControls={[]}
                ref={audioPlayerAns.inline0}
                src={audioQuestion}
                showJumpControls={false}
                showDownloadProgress={false}
                showFilledProgress={false}
                customIcons={{
                  play: <IoPlay />,
                  pause: <IoPause />,
                  rewind: <IoIosRewind />,
                  forward: <IoMdFastforward />,
                  volume: <GiSpeaker />,
                }}
                // onPlay={(e) => console.log("onPlay")}
              />
            </div>
          </p>
          <select name="" defaultValue={selValue} id={actID + "_" + i} onChange={props.onAnswerSelected}>
            <option value={selValue} disabled>
              Select
            </option>
            {dropdown}
          </select>
        </div>
      );
    }
    return items;
  };
  return <div className="categorizeAnswer">{createAnswerField()}</div>;
}
