import { useState, useCallback, useContext, useEffect } from "react";
import { AssessmentContext } from "../../context/AssessmentProvider";
import { ErrorContext } from "../../context/ErrorProvider";
import axios from "axios";
import axiosRetry from "axios-retry";
import moveImg from "../../img/icons/move.svg";
import "../../css/index.css";
import draggableFunctions from "../../functions/draggableFunctions";

/**
 * @component ReportProblemModal
 * @description This component creates a Report Issue Modal that pops up when called,
 * and allows the user to share an issue they run into via email with additional diagnostic
 * information being supplied.
 * @param {object} props
 **/

export default function ReportProblemModal(props) {
  useEffect(() => {
    draggableFunctions();
  }, []);

  const [userIssue, setUserIssue] = useState(null);
  const [autoData, setAutoData] = useState(props);
  const [assessmentState] = useContext(AssessmentContext);
  const [modalErrorState, errorDispatch] = useContext(ErrorContext);
  const [emailSent, setEmailSent] = useState(false);
  const [issueType, setIssueType] = useState(null);
  let randomTicketNumber = Math.floor(Math.random() * 10000000);
  axiosRetry(axios, {
    retries: 4, // number of retries

    retryDelay: (...arg) => axiosRetry.exponentialDelay(...arg, 1000), // Exponential delay with backoff of 1000mst
            retryCondition: (err) => {return axiosRetry.isNetworkError(err) || err.code === "ECONNABORTED" || err.code === "ENOTFOUND" || err.code === "ETIMEDOUT" || (err.response && err.response.status >= 500 && err.response.status <= 599) }


  });

  const handleIssueType = (e) => {
    setIssueType(e.target.value);
  };

  //function to generate an issue report from the user's report problem model
  const generateReport = useCallback(
    (e) => {

      if (autoData.modality !== "grammar" && autoData.modality !== "reading") {
        setAutoData({...autoData, modality: "listening"});
      }

      //setup some metatext (date) for optional display
      let today = new Date();
      today = today + "";
      let sanitizedUserIssue = encodeURIComponent(userIssue);   
      if (userIssue !== null && userIssue !== "Enter your issue here..." && emailSent === false) {
        let minimizedAssessmentState = JSON.parse(JSON.stringify(assessmentState));
        if (minimizedAssessmentState.hasOwnProperty("assessmentModulesRCLC")) {
          minimizedAssessmentState.assessmentModulesRCLC = null;
        }
        try {
          sanitizedUserIssue = (decodeURIComponent(sanitizedUserIssue));
          //send email data object to API
          axios
            .post("/api/emailDevelopersIssueReport", {
              autoData,
              sanitizedUserIssue,
              randomTicketNumber,
              assessmentState: minimizedAssessmentState,
              today,
              issueType,
              errConsoleLog: modalErrorState.errorConsoleLogErrors
            })
            .catch((error) => {
              if (error.response) {
                if (error.response.status >= 400 && error.response.status < 600) {
                  //global error dispatch call to show error modal if error received during api call
                  errorDispatch({
                    type: "UPDATE_ERROR_STATE",
                    payload: {
                      errorStatusCode: error.response.status,
                      errorUserMessage: "New Error",
                      errorDevData: error.response
                    }
                  });
                }
              }
              //console.log("Failed to submit report via axios/email.");
            });
          //close the modal
          //autoData.refModalState();
          //show "email sent" modal and hide "issue report modal"
          autoData.refModalStateConfirm(e);
          setEmailSent(true);
        } catch (error) {
          if (error.response) {
            if (error.response.status >= 400 && error.response.status < 600) {
              //global error dispatch call to show error modal if error received during api call
              errorDispatch({
                type: "UPDATE_ERROR_STATE",
                payload: {
                  errorStatusCode: error.response.status,
                  errorUserMessage: "New Error",
                  errorDevData: error.response
                }
              });
            }
          }
          //console.log("Axios try block caught error.");
        }
      }
    },
    [autoData, userIssue, randomTicketNumber, assessmentState, emailSent, errorDispatch, issueType]
  );

  return (
    <div className="modalDialog" id="modalDialog">
      <div id="reportIssueModal">
        <div className="feedbackHeader" id="modalHeader">
          Report Issue
          <img src={moveImg} alt="Move" id="move" draggable="false" />
        </div>
        <div className="feedbackBody">
          <h4>Help us improve ODA!</h4>
          <h5>
            Issue Type:&nbsp;&nbsp;
            <select name="reportProblemIssueSelect" onChange={handleIssueType}>
              <option value="technicaldifficulties">Technical Difficulties</option>
              <option value="contentissue">Content Issues</option>
              <option value="featurerequestrequest">Feature Requests</option>
              <option value="webpageissue">Web Page Feedback</option>
              <option value="otherissue">Other Issues</option>
            </select>
            <br />
            <br />
            <div style={{ textAlign: "left" }}>
              Help us improve ODA by supplying a brief description of the issue you encounted in the form below.{" "}
            </div>
          </h5>
          <textarea
            className="focused reportProblemTextArea"
            autoComplete="off"
            placeholder="Enter your issue here..."
            onFocus={(e) => (e.target.placeholder = "")}
            onBlur={(e) => (e.target.placeholder = "Enter your issue here...")}
            onChange={(e) => setUserIssue(e.target.value)}
          />
          <br />
        </div>
        <button className="outlined margin5" onClick={generateReport}>
          Submit
        </button>

        <button className="outlined margin5" onClick={(e) => props.refModalState(e)}>
          Cancel
        </button>
        <br />
        <br />
        <br />
      </div>
    </div>
  );
}
