// import React, { Component } from "react";
import { Link } from "react-router-dom";
import header from "../../img/logos/headerLogo.svg";
import headerSmall from "../../img/logos/headerLogoSmall.svg";

/**
 * @component HeaderNull
 * @description this function makes an empty header for use on the static 404 page
 * 
 */

export default function HeaderNull() {
  return (
    <header>
      <Link to="/dashboard">
        <img
          className="logo"
          src={header}
          alt="Online Diagnostic Assessment Logo"
          title="Click here to return to your assessment dashboard if you are logged in or the main home page if not."
        />
        <img className="logoSmall" src={headerSmall} alt="Online Diagnostic Assessment Logo" />
      </Link>
    </header>
  );
}
