import React, { useState, useEffect } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import axios from "axios";
import AdminFooter from "./adminComponents/AdminFooter";
import QuestionByPrimaryTagDisplay from "./adminDisplay/QuestionByPrimaryTagDisplay";

const QuestionByPrimayTag = () => {
  const initialState = {
    content: [],
    language: [],
    selectedLanguage: "",
    selectedModality: "",
    selectedSite: "",
    selectedLevel: "",
    selectedTag: "",
    firstCheck: false,
    isData: false,
    checkAPI: false,
  };

  const [state, setState] = useState(initialState);
  const {
    language,
    selectedLanguage,
    selectedModality,
    selectedSite,
    selectedLevel,
    selectedTag,
    firstCheck,
    isData,
  } = state;

  useEffect(() => {
    if (selectedModality === "reading") {
      axios
        .get('/api/language?modalities="reading"&_sort={"sequence": 1}')
        .then((res) => {
          setState({ ...state, language: res.data });
        });
    } else if (selectedModality === "listening") {
      axios
        .get('/api/language?modalities="listening"&_sort={"sequence": 1}')
        .then((res) => {
          setState({ ...state, language: res.data });
        });
    }
  }, [selectedModality]);

  let langDropdown = language.map((item, index) => (
    <option key={index} value={item._id}>
      {item.displayName}
    </option>
  ));

  const handleLanguageChange = (event) => {
    setState({ ...state, selectedLanguage: event.target.value });
  };

  const handleModalityChange = (event) => {
    const initial = {
      selectedLanguage: "",
      selectedSite: "",
      selectedLevel: "",
      selectedTag: "",
      content: [],
      firstCheck: false,
      isData: false,
      checkAPI: false,
    };
    setState({
      ...state,
      selectedModality: event.target.value,
      ...initial,
    });
  };

  const handleSiteChange = (event) => {
    setState({ ...state, selectedSite: event.target.value });
  };

  const handleLevelChange = (event) => {
    setState({ ...state, selectedLevel: event.target.value });
  };

  const handleItemChange = (event) => {
    setState({ ...state, selectedTag: event.target.value });
  };

  const getValues = () => {
    const user = {
      site: selectedSite,
      lang: selectedLanguage,
      mod: selectedModality,
      lvl: selectedLevel,
      tag: selectedTag,
    };

    const valuesAreValid =
      selectedLanguage !== "Select" &&
      selectedModality !== "Select" &&
      selectedSite !== "Select" &&
      selectedLevel !== "Select" &&
      selectedTag !== "Select" &&
      selectedLanguage !== "" &&
      selectedModality !== "" &&
      selectedSite !== "" &&
      selectedLevel !== "" &&
      selectedTag !== "";

    if (valuesAreValid) {
      console.log("values=", {
        site: selectedSite,
        lang: selectedLanguage,
        mod: selectedModality,
        lvl: selectedLevel,
        tag: selectedTag,
      });

      axios
        .get("/api/adminReport/primaryTagReport", { params: user })
        .then((res) => {
          setState((prevState) => ({
            ...prevState,
            content: res.data,
            isData: true,
            checkAPI: true,
          }));
        })
        .catch((error) => {
          console.log("error");
          setState((prevState) => ({
            ...prevState,
            isData: false,
            firstCheck: true,
          }));
          if (error.response) {
            if (error.response.status >= 400 && error.response.status < 600) {
            }
          }
        });
    } else {
      console.log("No value can be null");
      alert("Select all items");
    }
  };

  const resetValues = () => {
    setState(initialState);
  };

  return (
    <div className="adminMainPanel">
      <div className="infoTitle adminInfoTitle">
        <div>Question By Primary Tag</div>
      </div>
      <div>
        Modality:
        <select
          key="modality"
          value={selectedModality}
          onChange={handleModalityChange}
        >
          <option>Select</option>
          <option value="reading">Reading</option>
          <option value="listening">Listening</option>
        </select>
      </div>
      <div>
        Language:
        <select
          key="language"
          value={selectedLanguage}
          onChange={handleLanguageChange}
        >
          <option>Select</option>
          {langDropdown}
        </select>
      </div>
      <div>
        Site:
        <select key="site" value={selectedSite} onChange={handleSiteChange}>
          <option>Select</option>
          <option value="oda">ODA</option>
        </select>
      </div>
      <div>
        Level:
        <select key="level" value={selectedLevel} onChange={handleLevelChange}>
          {selectedLanguage === "eng" ? (
            <>
              <option>Select</option>

              <option value="1+">1+</option>
              <option value="2">2</option>
              <option value="2+">2+</option>
              <option value="3">3</option>
            </>
          ) : (
            <>
              <option>Select</option>
              <option value="1">1</option>
              <option value="1+">1+</option>
              <option value="2">2</option>
              <option value="2+">2+</option>
              <option value="3">3</option>
            </>
          )}
        </select>
      </div>
      <div>
        Item Tag:
        <select key="tag" value={selectedTag} onChange={handleItemChange}>
          <option>Select</option>
          <option value="mainProposition">Main Proposition</option>
          <option value="supportingProposition1">
            Supporting Propsition 1
          </option>
          <option value="supportingProposition2">
            Supporting Propsition 2
          </option>
          <option value="lexicon">Lexical</option>
          <option value="structure">Structure</option>
          <option value="discourse">Discourse</option>
        </select>
      </div>
      <div className="buttonGroup">
        <div onClick={getValues} className="searchButton">
          Get Questions
        </div>
        <div onClick={resetValues} className="searchButton">
          Reset Options
        </div>
      </div>
      <div className="adminContentBox" style={{ overflow: "auto" }}>
        <QuestionByPrimaryTagDisplay
          content={state.content}
          mod={state.selectedModality}
          firstCheck={state.firstCheck}
          isData={state.isData}
          checkAPI={state.checkAPI}
        />
      </div>

      <AdminFooter />
    </div>
  );
};

export default QuestionByPrimayTag;
