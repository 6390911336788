import React, { useState, useContext, useEffect, useCallback } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import axiosRetry from "axios-retry";
import "../../css/index.css";
import { AssessmentContext } from "../../context/AssessmentProvider";
import { ErrorContext } from "../../context/ErrorProvider";
import { attemptODAScoreRouting } from "../../functions/attemptODAScoreRouting";
import { attemptODAModuleRouting } from "../../functions/attemptODAModuleRouting";
import NewTestTipsModal from "../../components/modals/NewTestTipsModal";
import HelpTooltip from "../../components/modals/helptooltip/HelpTooltip.json";
import ErrorModal from "../../components/error/ErrorModal";
import LoadingSpinner from "../../components/spinner/LoadingSpinner";
import { odaDecrypt } from "../../functions/genericFunctions";

import headLogo from "../../img/logos/headerLogo.svg";
import headLogoSM from "../../img/logos/headerLogoSmall.svg";
import instructionIcon from "../../img/icons/instruction.svg";

import AMC2 from "./templates/AMC2/AMC2";
import LCIA from "./templates/LCIA/LCIA";
import LCM from "./templates/LCM/LCM";
import LSA from "./templates/LSA/LSA";
import LSA2 from "./templates/LSA2/LSA2";
import TMC2 from "./templates/TMC2/TMC2";

const ListeningTest = (props) => {
  //console.log("------------NEW LOAD------------");

  //Setup Navigation & Data Sharing Across Pages
  const location = useLocation();
  const navigate = useNavigate();
  axiosRetry(axios, {
    retries: 4, // number of retries

    retryDelay: (...arg) => axiosRetry.exponentialDelay(...arg, 1000), // Exponential delay with backoff of 1000ms
    retryCondition: (err) => {
      console.log(err.response);
      return (
        axiosRetry.isNetworkError(err) ||
        err.code === "ECONNABORTED" ||
        err.code === "ENOTFOUND" ||
        err.code === "ETIMEDOUT" ||
        (err.response && err.response.status >= 500 && err.response.status <= 599)
      );
    }
  });

  //Global State Context Calls, links to context/AssessmentProvider.js & context/ErrorProvider.js
  const [assessmentState, dispatch] = useContext(AssessmentContext);
  const [modalErrorState, errorDispatch] = useContext(ErrorContext);
  const [useAES] = useState(process.env.REACT_APP_USE_AES.toLowerCase() === "true" ? true : false);
  const [error, setError] = useState(null);
  const [state, setState] = useState({
    counter: null,
    questionId: null,
    currentItem: null,
    template: null,
    language: null,
    modal: null,
    proficiencyLevel: null,
    levelRange: null,
    testData: null,
    showChild: null,
    getAnswers: [],
    attempt: null,
    profileDB: "profileDB",
    assessmentModuleIteration: null
  });
  const [testletDB, setTestletDB] = useState(location.state.testlet);
  const [resumePage, setResumePage] = useState(
    location.state.pageNum !== undefined && location.state.pageNum > 0 ? parseInt(location.state.pageNum) : 0
  );
  const [buttonWaiting, setButtonWaiting] = useState(false);
  //console.log("------------RESUME " + resumePage + "-----------");
  const [moduleLinear, setModuleLinear] = useState(null);
  // - Current Module || in assessmentState already
  // - Current Module Testlet, ie Testlet 0,1,2
  const [moduleLinearPosMain, setModuleLinearPosMain] = useState(null);
  // - Current Module TestItem, ie TestItem 0,1,2,3,4,5
  const [moduleLinearPosSub, setModuleLinearPosSub] = useState(null);
  // - Current Module Linear Value, ie item 0-17
  const [moduleLinearCount, setModuleLinearCount] = useState(null);
  // - Resuming Page Number, ie 0-99
  //assigned earlier as resumePage/setResumePage
  // - Number of TestItems per Previously Completed Modules, ie 0-99
  const [completedModuleTestItemCount, setCompletedModuleTestItemCount] = useState(null);
  // - Number of TestItems per each Testlet in Current Module, ie, 1-6
  const [Xlength, setXlength] = useState(testletDB[0].testitems.length);
  const [Ylength, setYlength] = useState(testletDB[1].testitems.length);
  const [Zlength, setZlength] = useState(testletDB[2].testitems.length);
  const [questionStartSeconds, setQuestionStartSeconds] = useState(null);

  //In order to properly progress through a full assessment & resume at any point
  //Several variables should be calculate on each click of the Next or Submit Button
  //These variables need to account for:
  // - Current Module
  // - Current Testlet
  // - Current TestItem
  // - Resuming Page Number
  // - Number of TestItems per Previously Completed Modules
  // - Number of TestItems per each Testlet in Current Module
  //
  // - Example:
  //  Imagine a 3 Testlet by 5 TestItem matrix for a given module
  //   A A A A A  [testlet 0, length 5 = A.length]
  //   B B B B B  [testlet 1, length 5 = B.length]
  //   C C C C C  [testlet 2, length 5 = C.length]
  //  This can also be written as
  //   a a a a a b b b b b c c c c c, with a separate variable to track testlet number based on known lengths of testlets
  //  Assuming a starting point of PageNum < A.length:
  //   a = PageNum where PageNum = 0 when starting a new assessment.  Current Testlet is then 0.
  //  Assuming a starting point of PageNum >= A.length && PageNum < B.length:
  //   b = PageNum - A.length.   Current Testlet is then 1.
  //   c = PageNum - A.length - B.length.  Current Testlet is then 2.
  //
  // In the event that a previous module has been completed, then the previous number of unique testItems should be deducted as well
  //  Imagine ABC represent testlets from the previous completed module.  Then, XYZ will represent testlets from the current module.
  // Q = A.length + B.length + C.length (if one previous module; other  SumQ=N for additional modules farther below)
  //  For the new Module, Imagine a 3 Testlet by 4 TestItem matrix XYZ
  //   X X X X  [testlet 0, length 4 = X.length]
  //   Y Y Y Y  [testlet 1, length 4 = Y.length]
  //   Z Z Z Z  [testlet 2, length 4 = Z.length]
  //  This can also be written as
  //   x x x x y y y y z z z z, with a separate variable to track testlet number based on known lengths of testlets
  //  In addition, we know there are also Q test items that exist previously of length at least >= PageNum (if resuming):
  //   Q x x x x y y y y z z z z
  //    which is equivalent to
  //   a a a a a b b b b b c c c c c x x x x y y y y z z z z
  //    and also
  //               module1     module2
  //  testitem     0 1 2 3 4   0 1 2 3
  //  testlet  0   A A A A A   X X X X
  //  testlet  1   B B B B B   Y Y Y Y
  //  testlet  2   C C C C C   Z Z Z Z
  //
  //  So, the previous logic will repeat with the exception that Q must also be subtracted out to find the current modules testlet and testitem, ie:
  //  Assuming a starting point of PageNum >= Q && PageNum < X.length:
  //   x = PageNum - Q.  Current Testlet is then 0.
  //  Assuming a starting point of PageNum >= (X.length+Q) && PageNum < (Q+Y.length):
  //   y = PageNum - X.length - Q.   Current Testlet is then 1.
  //   z = PageNum - X.length - Y.length - Q.  Current Testlet is then 2.
  //
  //  For 2 or more completed modules, similarly, we can use N = sum of all available Qs in place of a singular Q.
  //   x = PageNum - N
  //   y = PageNum - N - X.length
  //   z = PageNum - N - X.length - Y.length,
  //    where N is the length of all completed testlets or the number of unique test items completed (either case works here).
  //
  // Visually, this appears as
  //   Q1 Q2 x x x x y y y y z z z z
  //    which is equivalent to
  //   N x x x x y y y y z z z z
  //    which is equivalent to
  //   a1 a1 a1 a1 a1 b1 b1 b1 b1 b1 c1 c1 c1 c1 c1 a2 a2 a2 a2 a2 b2 b2 b2 b2 b2 c2 c2 c2 c2 c2 x x x x y y y y z z z z
  //    and also
  //              moduleQ1         moduleQ2       moduleCurrent
  //  testitem    0  1  2  3  4    0  1  2  3     0  1  2  3  4  5
  //  testlet  0  A1 A1 A1 A1 A1   A2 A2 A2 A2    X  X  X  X  X  X
  //  testlet  1  B1 B1 B1 B1 B1   B2 B2 B2 B2    Y  Y  Y  Y  Y  Y
  //  testlet  2  C1 C1 C1 C1 C1   C2 C2 C2 C2    Z  Z  Z  Z  Z  Z
  //
  // So, to determine the matrix location of resume value pageNum 30:
  // pageNum = 30
  // 30 > moduleQ1.length = 15, add next module sum
  // 30 > moduleQ1.length = 15 + moduleQ2.length = 12 = 27
  // no additional modules, so calculate pageNum minus above (30 - 27)...
  // 30 - N[moduleQ1.length=15 + moduleQ2.length=12] = 30 - 27 = 3
  // 3 < moduleCurrentX.length(6); therefore:
  // PageNum/Linear (30) is equivalent to Matrix [Testlet: 0, Item: 3] in the current Module
  //
  //              moduleQ1=15      moduleQ2=12    moduleCurrent=i+(15+12=27)
  //  testitem    0  1  2  3  4    0  1  2  3     0  1  2  3  4  5
  //  testlet  0  0  _  _  _  _    15 _  _  _     27 28 29 30 _  _
  //  testlet  1  _  _  _  _  _    _  _  _  _     _  _  _  _  _  _
  //  testlet  2  _  _  _  _  14   _  _  _  26    _  _  _  _  _  _
  //
  // These calculations allow both the matrix & linear tracking of testlet number and testitem number for use
  //  in the progress bars and for serving testletDB data.
  //

  useEffect(() => {
    // console.log(assessmentState);
    //console.log(testletDB);
    if (assessmentState.assessmentModuleRef !== undefined && assessmentState.assessmentModuleRef !== null) {
      // Step 1 - Determine if there are any previous modules
      // Step 1.1 - determine current module
      //console.log(assessmentState.assessmentModuleRef);
      const curRef = assessmentState.assessmentModuleRef.split(".");
      const curLvl = curRef[1];
      const curMod = parseInt(curRef[2].charAt(1) - 1);
      const curModScore = assessmentState.assessmentScoreMatrix[curLvl][curMod];
      //BOOLEAN: does current module have a score?
      //console.log(assessmentState.assessmentScoreMatrix);
      const curModScoreBool = curModScore === -999 ? 0 : 1;
      //console.log(curModScoreBool);
      //console.log(assessmentState.assessmentModuleData);

      // Step 1.2 - collect possible keys to cycle through
      let testletMatrixKeys = Object.keys(assessmentState.assessmentTestletMatrix);
      testletMatrixKeys.pop(); //remove unnecessary _id key
      //collect unique test items in all completed modules
      let collectUniqueTestItemsCompleted = [];
      let newResumePage = resumePage;
      Object.entries(assessmentState.assessmentScoreMatrix).forEach((item, index) => {
        if (item[0] !== "_id") {
          //dont check _id
          if (item[1][0] !== -999 && !(item[0] === curLvl && curMod === 0)) {
            //skip if it is current module or if module is -999
            //console.log("path a");
            let internalTestletName = assessmentState.assessmentTestletMatrix[testletMatrixKeys[index]][0][0];
            assessmentState.assessmentModulesRCLC.forEach((item2, index2) => {
              let nextStep = item2.questionItemAns;
              let anyItemMatch = nextStep.filter((i) => i.testitem);
              Object.entries(anyItemMatch).forEach((item3, index3) => {
                if (!collectUniqueTestItemsCompleted.includes(item3[1].testitem)) {
                  collectUniqueTestItemsCompleted.push(item3[1].testitem);
                }
              });
            });
          }
          if (item[1][1] !== -999 && !(item[0] === curLvl && curMod === 1)) {
            //console.log("path b");
            let internalTestletName = assessmentState.assessmentTestletMatrix[testletMatrixKeys[index]][1][0];
            assessmentState.assessmentModulesRCLC.forEach((item2, index2) => {
              let nextStep = item2.questionItemAns;
              let anyItemMatch = nextStep.filter((i) => i.testitem);
              Object.entries(anyItemMatch).forEach((item3, index3) => {
                if (!collectUniqueTestItemsCompleted.includes(item3[1].testitem)) {
                  collectUniqueTestItemsCompleted.push(item3[1].testitem);
                }
              });
            });
          }
        }
      });
      // Step 2 - Determine the total length of all testitems not in the current module
      //  a. get current module id
      //     accounted for already in Step1
      //console.log(assessmentState.assessmentModuleRef);
      //console.log(assessmentState.assessmentNextModule);
      //console.log(assessmentState.assessmentTestletMatrix);
      //console.log(curLvl);
      //console.log(curMod);
      //  b. get all current module testlets and store them in an array
      const currentModulesTestlets = assessmentState.assessmentTestletMatrix[curLvl][curMod];
      //console.log(currentModulesTestlets);
      //  c. count the number of unique completed testlets NOT in the current module
      //console.log(assessmentState.assessmentModulesRCLC);
      //console.log(collectUniqueTestItemsCompleted);
      //  c.1 -- need to ensure no testlets from current module are in the array
      let collectUniqueTestItemsCompletedNoCurrent = [];
      collectUniqueTestItemsCompleted.forEach((item, index) => {
        if (
          !collectUniqueTestItemsCompleted[index].includes(currentModulesTestlets[0]) &&
          !collectUniqueTestItemsCompleted[index].includes(currentModulesTestlets[1]) &&
          !collectUniqueTestItemsCompleted[index].includes(currentModulesTestlets[2])
        ) {
          collectUniqueTestItemsCompletedNoCurrent.push(item);
        }
      });
      //console.log(collectUniqueTestItemsCompleted);
      //console.log(collectUniqueTestItemsCompletedNoCurrent);
      collectUniqueTestItemsCompleted = collectUniqueTestItemsCompletedNoCurrent; //fix issue with N including current testlets
      //console.log("N=" + collectUniqueTestItemsCompleted.length);
      // Step 3 - Remove value 2c (unique completed testitems in other modules) from the PageNum
      //console.log("PageNum=" + newResumePage); // if undefined set to 0 as it is a new assessment
      let internalModuleLinearLocation = parseInt(newResumePage - collectUniqueTestItemsCompleted.length);
      //console.log("Step3Value/CurrentModuleLinearPosition=" + internalModuleLinearLocation);
      // Step 4 - determine the current testlet and testitem number based on resultant value & testlet0-2.lengths per logic above
      //console.log("X.length=" + testletDB[0].testitems.length);
      //console.log("Y.length=" + testletDB[1].testitems.length);
      //console.log("Z.length=" + testletDB[2].testitems.length);
      // ie:
      //   x = Step3Value IF Step3Value<X.length
      //   y = Step3Value - X.length IF Step3Value>=X.length && Step3Value<X.Length+Y.Length
      //   z = Step3Value - X.length - Y.length IF Step3Value>=Step3Value<X.Length+Y.Length
      let matrixTestlet;
      let matrixTestItem;
      while (true) {
        if (internalModuleLinearLocation >= 0 && internalModuleLinearLocation < testletDB[0].testitems.length) {
          matrixTestlet = 0;
          matrixTestItem = internalModuleLinearLocation;
          break;
        } else if (
          internalModuleLinearLocation >= testletDB[0].testitems.length &&
          internalModuleLinearLocation < testletDB[0].testitems.length + testletDB[1].testitems.length
        ) {
          matrixTestlet = 1;
          matrixTestItem = internalModuleLinearLocation - testletDB[0].testitems.length;
          break;
        } else if (
          internalModuleLinearLocation >= testletDB[0].testitems.length + testletDB[1].testitems.length &&
          internalModuleLinearLocation <
            testletDB[0].testitems.length + testletDB[1].testitems.length + testletDB[1].testitems.length
        ) {
          matrixTestlet = 2;
          matrixTestItem = internalModuleLinearLocation - testletDB[0].testitems.length - testletDB[1].testitems.length;
          break;
        } else {
          internalModuleLinearLocation--;
          if (internalModuleLinearLocation < 0) {
            //console.log("Error calculating matrix position from page number given.");
            break;
          }
        }
      }
      //console.log("Linear Location of All Modules: " + newResumePage);
      //console.log("Count of Completed Module TestItems: " + collectUniqueTestItemsCompleted.length);
      // console.log(
      //   "Linear Location of Current Module: " +
      //     newResumePage +
      //     "-" +
      //     collectUniqueTestItemsCompleted.length +
      //     "=" +
      //     internalModuleLinearLocation
      // );
      // console.log(
      //   "Matrix Location of Current Module: [Testlet " + matrixTestlet + ", TestItem " + matrixTestItem + "]"
      // );
      // Step 5 - update all state variables (linear & matrix tracking) based on updates
      // - Collection of all Linear Modules
      let collectAllModuleTestItems = [];
      testletDB.forEach((item, index) => {
        item.testitems.forEach((item2, index2) => {
          collectAllModuleTestItems.push(item2);
        });
      });

      //only update if it is valid; sometimes there is a desync between react reloads between module shifts
      if (newResumePage >= collectUniqueTestItemsCompleted.length && !useODARouting) {
        // Step 6 - update progress bar based on state variables

        if (document.querySelectorAll(".progressUnit").length === 0) {
          setTimeout(() => {
            let progUnits = document.querySelectorAll(".progressUnit");
            progUnits.forEach((item, index) => {
              item.classList.remove("current");
              item.classList.remove("complete");
              if (index < matrixTestItem) {
                item.classList.add("complete");
              } else if (index === matrixTestItem) {
                item.classList.add("complete");
                item.classList.add("current");
              }
            });
          }, 100);
        } else {
          let progUnits = document.querySelectorAll(".progressUnit");
          progUnits.forEach((item, index) => {
            item.classList.remove("current");
            item.classList.remove("complete");
            if (index < matrixTestItem) {
              item.classList.add("complete");
            } else if (index === matrixTestItem) {
              item.classList.add("complete");
              item.classList.add("current");
            }
          });
        }

        let setProgUnits = document.querySelectorAll(".setProgressUnit");
        setProgUnits.forEach((item, index) => {
          item.classList.remove("current");
          item.classList.remove("complete");
          if (index < matrixTestlet) {
            item.classList.add("complete");
          } else if (index === matrixTestlet) {
            item.classList.add("complete");
            item.classList.add("current");
          }
        });

        setModuleLinear(collectAllModuleTestItems);
        setModuleLinearPosMain(matrixTestlet);
        setModuleLinearPosSub(matrixTestItem);
        setModuleLinearCount(internalModuleLinearLocation);
        setCompletedModuleTestItemCount(collectUniqueTestItemsCompleted.length);
        // some of these may only need to be set initially, ie assessmentModuleIteration
        //console.log("setting state...");
        setState({
          counter: matrixTestItem,
          questionId: matrixTestItem + 1,
          currentItem: matrixTestItem + 1,
          template: testletDB[matrixTestlet].testitems[matrixTestItem].template,
          language: testletDB[matrixTestlet].language.description,
          modal: testletDB[matrixTestlet].modality,
          proficiencyLevel: testletDB[matrixTestlet].proficiencyLevel,
          levelRange: testletDB[matrixTestlet].levelRange,
          testData: testletDB[matrixTestlet],
          showChild: true,
          getAnswers: [],
          attempt: matrixTestlet,
          profileDB: "profileDB",
          assessmentModuleIteration: 1
        });
      }
    } else {
      //console.log("Error: assessmentState is malformed... returning to dashboard.");
      navigate("/dashboard");
    }
  }, [resumePage, assessmentState, testletDB]);

  //Finish setting up state
  const [useODARouting, setUseODARouting] = useState(false);
  const [completedAPICall, setCompletedAPICall] = useState(null);
  const [loadingNewModule, setLoadingNewModule] = useState(false);
  const [clickNextComplete, setClickNextComplete] = useState(null);
  const [setNextComplete, setSetNextComplete] = useState(null);
  const [spinnerStatus, setSpinnerStatus] = useState(false);
  const [spinnerStatusAltMessage, setSpinnerStatusAltMessage] = useState(false);
  const [showTestTipsStart, setShowTestTipsStart] = useState(false);

  ////console.log watch for changes in (global) assessmentState
  useEffect(() => {
    //console.log("assessmentState=", assessmentState);
  }, [assessmentState]);
  ////console.log watch for changes in (local) state
  useEffect(() => {
    //console.log("state=", state);
  }, [state]);
  ////console.log watch for changes in resumePage value
  useEffect(() => {
    //console.log("resumePage=", resumePage);
  }, [resumePage]);
  ////console.log watch for changes in buttonWaiting
  // useEffect(() => {
  //   //console.log("buttonWaiting=", buttonWaiting);
  //   //console.log("buttonWaitingDate=", Date("now"));
  // }, [buttonWaiting]);

  useEffect(() => {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
    window.scrollTo(0, 0);
    window.addEventListener("popstate", (e) => {
      window.location.reload();
    });

    //show generic test tips modal to the user
    // if (state.counter === 0 && !(resumePage > 0)) {
    if (!(resumePage > 0)) {
      setShowTestTipsStart(true);
    }

    //componentWillUnmount
    return () => {
      let allsuspects = document.querySelectorAll("script");
      for (let i = allsuspects.length; i >= 0; i--) {
        if (
          allsuspects[i] &&
          allsuspects[i].getAttribute("src") !== null &&
          allsuspects[i].getAttribute("src").indexOf("js/navigate.js") !== -1
        ) {
          allsuspects[i].parentNode.removeChild(allsuspects[i]);
        }
      }
    };
  }, []); // ,[] means this useEffect will only run once

  const handleRefModalTestTips = useCallback(() => {
    setShowTestTipsStart(false);
  }, []);

  useEffect(() => {
    //handle preferred fonts
    if (assessmentState.assessmentLanguage !== null) {
      const thisLangId = assessmentState.assessmentLanguage._id;
      let forcedFont = null;
      if (thisLangId === "ara") {
        forcedFont = "Noto Naskh Arabic";
      } else if (thisLangId === "tur") {
        forcedFont = "Times New Roman";
      } else if (thisLangId === "kor" || thisLangId === "nkr") {
        forcedFont = "'Noto Sans KR Variable', sans-serif";
      } else if (thisLangId === "cmn") {
        forcedFont = "'Noto Sans TC Variable', sans-serif";
      } else {
        forcedFont = "'Verdana, Tahoma, Arial, sans-serif'!important";
        // forcedFont = "'Open Sans', sans-serif !important"
      }
      if (forcedFont !== null) {
        let tempInnerEls = document.querySelectorAll("div.templateInner div.textPassage");
        if (tempInnerEls.length === 1) {
          tempInnerEls[0].style.fontFamily = forcedFont;
        } else if (tempInnerEls.length > 1) {
          tempInnerEls.forEach((item, index) => {
            item.style.fontFamily = forcedFont;
          });
        }
      }
    }
  });

  //handles data coming from the child templates so it can be fed into parent state (here)
  const callbackFunction = useCallback(
    (childData, inputName) => {
      //console.log("read root user anser=", childData);
      setCompletedAPICall(false);
      const sessionID = assessmentState.assessmentSessionId;
      if ((inputName === "next" || inputName === "submit") && childData !== null && childData !== "{}") {
        //console.log("callBack next/submit=", childData);
        let parsedData = JSON.parse(childData);

        //handle item by item test scoring (currently POST route is not finished)

        let sessionLC_POST_DATA = { questionItemAns: [] };
        //calculate the total duration of the most recently completed question and append it
        const questionTotalSeconds = (Date.now() - questionStartSeconds) / 1000;
        setQuestionStartSeconds(null);
        sessionLC_POST_DATA.questionTotalSeconds = questionTotalSeconds;

        parsedData.forEach((item, index) => {
          let newAnsObj = {
            uiControlID: item.uiControlID,
            testlet: item.testlet,
            testitem: item.testitem,
            answerField: item.answerField,
            keywordMatches: item.keywordMatches,
            userAnswer: item.userAnswer,
            isPassed: item.isPassed,
            isTranscriptShown: item.isTranscriptShown,
            isModifiedAudioPlayed: item.isModifiedAudioPlayed,
            _id: parsedData._id
          };
          sessionLC_POST_DATA.questionItemAns.push(newAnsObj);
        });

        // console.log("---start LC [Next] button click debug section---");
        // console.log(sessionID);
        // console.log(inputName);
        // console.log(childData);
        // console.log(parsedData);
        // console.log(sessionLC_POST_DATA);
        // console.log("---end debug section---");

        axios({
          method: "post",
          headers: { "content-type": "application/json" },
          url: "/api/sessionLC/" + sessionID,
          data: sessionLC_POST_DATA
        })
          .then((res) => {
            // console.log("LC itemAns sent", res.data);
            //console.log("nlp=", JSON.stringify(sessionLC_POST_DATA));
            //handle routing scoring (content questions only for LC/LC)
            let isContentQuestion = false;
            // //console.log(state.testData.testitems);
            // //console.log(parseInt(state.questionId - 1));
            // //console.log(state.testData.testitems[parseInt(state.questionId - 1)].primaryTag.topic);
            if (
              state.testData?.testitems[parseInt(state.questionId - 1)]?.primaryTag?.topic?.includes(
                "Main Proposition"
              ) ||
              state.testData?.testitems[parseInt(state.questionId - 1)]?.primaryTag?.topic?.includes(
                "Supporting Proposition"
              )
            ) {
              isContentQuestion = true;
            }
            //need to also check if its the 1st or 2nd attempt because we only give credit for 1st
            let questionUIIDcheck = parsedData[parsedData.length - 1].uiControlID.slice(-1);
            if (isContentQuestion === true && questionUIIDcheck !== "1") {
              // console.log("isContentQuestion is true & it is the first attempt");
              // //console.log("the payload will be: ");
              // console.log(parsedData[parsedData.length - 1])
              dispatch({
                type: "APPEND_MODULE_CONTENT_SCORE_DATA",
                payload: {
                  data: parsedData[parsedData.length - 1],
                  identifier: parsedData[parsedData.length - 1].uiControlID
                }
              });
            }

            //build sessionLC query to update data
            let sessionLC_PUT_Data = {
              skillFloor: assessmentState.assessmentSkillFloor,
              moduleRef: assessmentState.assessmentModuleRef,
              testComplete: assessmentState.asssessmentTestComplete,
              moduleData: assessmentState.assessmentModuleData,
              scoreMatrix: assessmentState.assessmentScoreMatrix,
              NLPMatrix: assessmentState.assessmentNLPMatrix,
              testletMatrix: assessmentState.assessmentTestletMatrix
            };
            axios({
              method: "put",
              headers: { "content-type": "application/json" },
              url: "/api/sessionLC/" + sessionID,
              data: sessionLC_PUT_Data
            })
              .then((res) => {
                //console.log("assessmentState session synced", res.data);
                //remove waiting class to nextBtn&submitBtn to disable advancing while previous API call is ongoing
                setButtonWaiting(false);
                setCompletedAPICall(true);
              })
              .catch((error) => {
                if (error.response) {
                  if (error.response.status >= 400 && error.response.status < 600) {
                    //global error dispatch call to show error modal if error received during api call
                    errorDispatch({
                      type: "UPDATE_ERROR_STATE",
                      payload: {
                        errorStatusCode: error.response.status,
                        errorUserMessage: "New Error",
                        errorDevData: error.response
                      }
                    });
                  }
                }
              });
          })
          .catch((error) => {
            if (error.response) {
              if (error.response.status >= 400 && error.response.status < 600) {
                //global error dispatch call to show error modal if error received during api call
                errorDispatch({
                  type: "UPDATE_ERROR_STATE",
                  payload: {
                    errorStatusCode: error.response.status,
                    errorUserMessage: "New Error",
                    errorDevData: error.response
                  }
                });
              }
            }
          });
      }
      //DO NOT NEED "SUBMIT" CODE HERE AS IT IS HANDLED IN "ODAROUTING===TRUE" SEGMENT ALREADY.
    },
    [state, questionStartSeconds]
  );

  //handle what happens when a user completes a testlet
  const submitBtn = useCallback(() => {
    setButtonWaiting(true);
    clearInterval(window.audioPlayingScrubberInterval);
    setState({ ...state, showChild: false }); //added on David's request to account for LSA issue switching testlets
    //console.log("Current testlet number: " + moduleLinearPosMain);
    if (moduleLinearPosMain < 2) {
      setResumePage(resumePage + 1);
    } else {
      setSpinnerStatus(true);
      setSpinnerStatusAltMessage("Please wait while your module results are calculated...");
      setUseODARouting(true);
    }
  });

  // -- Controls what happens when a user clicks the submit button and ODARouting is set to true --
  useEffect(() => {
    if (useODARouting === true && completedAPICall === true) {
      //console.log("Current state is...");
      //console.log(state);
      let tempScoreMatrix = assessmentState.assessmentScoreMatrix;
      let thisModuleRef = assessmentState.assessmentModuleRef.split(".");
      let thisILR = thisModuleRef[1];
      let thisMod = thisModuleRef[2];
      if (thisMod === "M1") {
        thisMod = 0;
      } else if (thisMod === "M2") {
        thisMod = 1;
      }
      let totalScored = assessmentState.assessmentModuleData.length;
      let totalCorrect = assessmentState.assessmentModuleData.reduce((a, b) => a + b, 0);
      let totalFailed = totalScored - totalCorrect;
      tempScoreMatrix[thisILR][thisMod] = (totalCorrect / totalScored) * 100;
      //console.log(totalCorrect + "/" + totalScored + " content questions correct.");
      dispatch({
        type: "UPDATE_SCORE_MATRIX",
        payload: tempScoreMatrix
      });
      dispatch({
        type: "RESET_MODULE_CONTENT_SCORE_DATA",
        payload: null
      });
      //console.log("Current module reference is..." + assessmentState.assessmentModuleRef);
      //console.log("Passing in parameters to useODAScoreRouting to provide pass/fail including the score matrix:");
      //console.log(assessmentState.assessmentScoreMatrix);
      let scoreOutput = attemptODAScoreRouting(
        assessmentState.assessmentModuleRef,
        //assessmentState.assessmentScoreMatrix,
        tempScoreMatrix,
        66.5
      );
      //console.log("Passing resultant array into useODAModuleRouting to determine next step...");
      //console.log("Was previous assessment passed?" + scoreOutput[1]);
      let isPassedBoolean = null;
      if (scoreOutput[1]) {
        isPassedBoolean = true;
      } else {
        isPassedBoolean = false;
      }

      //Implement API_LC6 here
      axios.put(
        "api/sessionLC/updateMostRecentModule?sessionId=" +
          assessmentState.assessmentSessionId +
          "&isPassed=" +
          isPassedBoolean
      );

      let routeOutput = attemptODAModuleRouting(
        assessmentState.assessmentModuleRef,
        scoreOutput[1],
        assessmentState.assessmentSkillFloor,
        //assessmentState.assessmentScoreMatrix
        tempScoreMatrix
      );
      if (scoreOutput[1]) {
        dispatch({
          type: "UPDATE_SKILL_FLOOR",
          payload: routeOutput[2]
        });
      }
      //deal with the extreme edge case of failing 1+ English RCLC module1...  :|
      if (
        (routeOutput[1] === "Ref.L1.M1" || routeOutput[1] === "Ref.L1.M2") &&
        assessmentState.assessmentLanguage.displayName === "English" &&
        assessmentState.assessmentScoreMatrix["L1P"][1] === -999
      ) {
        routeOutput[1] = "Ref.L1P.M2"; //move from L1.M1 to L1P.M2 because L1.M1 doesnt exist for English
      } else if (
        (routeOutput[1] === "Ref.L1.M1" || routeOutput[1] === "Ref.L1.M2") &&
        assessmentState.assessmentLanguage.displayName === "English" &&
        assessmentState.assessmentScoreMatrix["L1P"][1] !== -999
      ) {
        routeOutput[0] = true; //end assessment immediately if L1P.M2 already has a score since we cant route back to it
      }

      if (routeOutput[0]) {
        dispatch({
          type: "UPDATE_TEST_COMPLETE",
          payload: routeOutput[0]
        });
      }
      //console.log("Test complete? " + routeOutput[0]);
      //console.log("Next module? " + routeOutput[1]);
      //console.log("Skill floor? " + routeOutput[2]);

      if (!routeOutput[0]) {
        //if test incomplete, call next module
        setSpinnerStatusAltMessage("Please wait while you are transitioned to the next set in your assessment.");
        dispatch({
          type: "UPDATE_NEXT_MODULE",
          payload: routeOutput[1]
        });
        let nextModuleRef = routeOutput[1].split(".");
        let nextILR = nextModuleRef[1];
        let nextMod = nextModuleRef[2];
        if (nextMod === "M1") {
          nextMod = 0;
        } else if (nextMod === "M2") {
          nextMod = 1;
        }
        // let nexttestlets = {
        //   _id: [assessmentState.assessmentTestletMatrix[nextILR][nextMod]]
        // };
        let testletPre = assessmentState.assessmentTestletMatrix[nextILR][nextMod].toString().split(",");
        let testletsString = "/api/testletLC?_id[]=[";
        testletPre.forEach((item, index) => {
          testletsString = testletsString + '"' + item + '"';
          if (index < testletPre.length - 1) {
            testletsString = testletsString + ",";
          }
        });
        testletsString = testletsString + "]";

        let nextProfLevel;
        switch (nextILR) {
          case "L1":
          case "1":
          case 1:
            nextProfLevel = "1";
            break;
          case "L1P":
          case "1+":
            nextProfLevel = "1+";
            break;
          case "L2":
          case "2":
          case 2:
            nextProfLevel = "2";
            break;
          case "L2P":
          case "2+":
            nextProfLevel = "2+";
            break;
          case "L3":
          case "3":
          case 3:
            nextProfLevel = "3";
            break;
          default:
            break;
        }

        //Implement API_LC7
        //console.log("next prof level=" + nextProfLevel);
        axios
          .post(
            "api/sessionLC/createModule?sessionId=" +
              encodeURIComponent(assessmentState.assessmentSessionId) +
              "&proficiencyLevel=" +
              encodeURIComponent(decodeURIComponent(nextProfLevel))
          )
          .then((sessionCompleteData) => {
            let updatedModules;
            if (useAES === "true" || useAES === true) {
              updatedModules = JSON.parse(odaDecrypt(sessionCompleteData.data));
            } else {
              updatedModules = sessionCompleteData.data;
            }
            dispatch({
              type: "UPDATE_RCLC_MODULES",
              payload: updatedModules.modules
            });
            //Implement API_LC3
            axios
              .get(testletsString)
              // .get("/api/testletLC", {
              //   params: nexttestlets,
              //   paramsSerializer: {
              //     serialize: (params) => {
              //       return qs.stringify(params, {
              //         encode: false,
              //         encodeValuesOnly: true,
              //         indices: false,
              //         arrayFormat: "comma",
              //         commaRoundTrip: true,
              //         allowEmptyArrays: true
              //       });
              //     }
              //   }
              // })
              .then((nextData) => {
                const sessionID = assessmentState.assessmentSessionId;
                let decryptedNext;
                if (useAES === "true" || useAES === true) {
                  decryptedNext = JSON.parse(odaDecrypt(nextData.data));
                } else {
                  decryptedNext = nextData.data;
                }
                //due to Studio3T generated out of order testitems, lets re-sort all items here.
                for (let testItemCount = 0; testItemCount < 3; testItemCount++) {
                  let thisTestItemUnsorted = decryptedNext[testItemCount].testitems;
                  let thisTestItemSorted = thisTestItemUnsorted;
                  thisTestItemSorted.sort((a, b) => (a._id > b._id ? 1 : b._id > a._id ? -1 : 0));
                  decryptedNext[testItemCount].testitems = thisTestItemSorted;
                }
                //console.log("skipping to next module...");
                //console.log(assessmentState);
                //console.log(decryptedNext);
                // navigate("/reading", {
                //   state: { testlet: decryptedNext, assessmentModuleIteration: state.assessmentModuleIteration + 1 } //increment the module counter
                // });
                dispatch({
                  type: "UPDATE_MODULE_REF",
                  payload: routeOutput[1]
                });

                //API_LC5
                //re-sync the sessionLC with assessmentState after routing updates here
                let sessionLC_PUT_Data = {
                  skillFloor: routeOutput[2],
                  moduleRef: routeOutput[1],
                  testComplete: routeOutput[0],
                  moduleData: [],
                  scoreMatrix: tempScoreMatrix,
                  NLPMatrix: assessmentState.NLPMatrix
                };
                axios({
                  method: "put",
                  headers: { "content-type": "application/json" },
                  url: "/api/sessionLC/" + sessionID,
                  data: sessionLC_PUT_Data
                })
                  .then((res) => {
                    //console.log("assessmentState routed session synced", res.data);
                    //remove waiting class to nextBtn&submitBtn to disable advancing while previous API call is ongoing
                    setButtonWaiting(false);
                    setSpinnerStatus(false);
                    setSpinnerStatusAltMessage(false);
                    setTestletDB(decryptedNext);
                    setResumePage(resumePage + 1);
                    setState((prevState) => ({
                      ...prevState,
                      currentItem: 1, //need to only set these on submit
                      questionId: 1, //need to only set these on submit
                      counter: 0 //need to only set these on submit
                    }));
                    //on submit only
                    setUseODARouting(false);
                  })
                  .catch((error3) => {
                    if (error3.response) {
                      if (error3.response.status >= 400 && error3.response.status < 600) {
                        //global error dispatch call to show error modal if error received during api call
                        errorDispatch({
                          type: "UPDATE_ERROR_STATE",
                          payload: {
                            errorStatusCode: error3.response.status,
                            errorUserMessage: "New Error",
                            errorDevData: error3.response
                          }
                        });
                      }
                    }
                  });
              })
              .catch((error2) => {
                setError(error2);
              });
          });
      } else {
        //API_LC8
        //close the sessionLC
        setSpinnerStatusAltMessage(false);
        //setSpinnerStatus(true);
        //const endDateObj = new Date();
        //const endDate = endDateObj.getFullYear() + "-" + (parseInt(endDateObj.getMonth()) + 1).toString() + "-" + endDateObj.getDate() + " " + endDateObj.toTimeString;
        const endDate = new Date().toISOString();
        //console.log(endDate);
        let currentLevel;
        let targetLevel;
        //console.log(routeOutput[2]);
        //NOTE: the current level output is -999, 0, 1, 2, 3, or 4 which corresponds to => failed 1, 1, 1+, 2, 2+, 3
        switch (routeOutput[2]) {
          case "-999":
          case -999:
            currentLevel = null;
            targetLevel = "1";
            break;
          case 0:
          case "0":
            currentLevel = "1";
            targetLevel = "1+";
            break;
          case "1":
          case 1:
            currentLevel = "1+";
            targetLevel = "2";
            break;
          case "2":
          case 2:
            currentLevel = "2";
            targetLevel = "2+";
            break;
          case 3:
          case "3":
            currentLevel = "2+";
            targetLevel = "3";
            break;
          case "4":
          case 4:
            currentLevel = "3";
            targetLevel = null;
            break;
          default:
            break;
        }

        //handle english failing edge case target level -- issue #192
        if (assessmentState.assessmentLanguage._id === "eng" && currentLevel === null) {
          targetLevel = "1+";
        }

        let sessionLC_FINALPUT_Data = {
          endtime: endDate,
          currentLevel: currentLevel,
          targetLevel: targetLevel,
          skillFloor: routeOutput[2],
          moduleRef: routeOutput[1],
          testComplete: routeOutput[0],
          moduleData: [],
          scoreMatrix: tempScoreMatrix,
          NLPMatrix: assessmentState.assessmentNLPMatrix
        };
        //console.log("final LC put...");
        //console.log(sessionLC_FINALPUT_Data);
        //NOV 15 2023 LC CONNECT TO Marcos new Diagnostic Profile API
        axios({
          method: "put",
          headers: { "content-type": "application/json" },
          url: "/api/sessionLC/" + encodeURIComponent(assessmentState.assessmentSessionId),
          data: sessionLC_FINALPUT_Data
        }).then((res) => {
          //remove waiting class to nextBtn&submitBtn to disable advancing while previous API call is ongoing
          setButtonWaiting(false);
          //console.log("TEST FINISHED --> Route to diagnostic profile for LC");
          //console.log(res.data);
          //console.log("inside the redirect for diagnostic profile now...");
          //console.log("api/sessionLC/diagProfileTopLevelInfo/" + assessmentState.assessmentSessionId);
          axios
            //.get("api/sessionGR/diagProfileTotal", { params: { email: assessmentState.assessmentUserEmail } })
            .get("api/sessionLC/diagProfileTopLevelInfo/" + encodeURIComponent(assessmentState.assessmentSessionId))
            .then((res2) => {
              //console.log(res2.data);
              let profLevelAdj = routeOutput[2] === -999 ? null : routeOutput[2];
              setCompletedAPICall(null);
              setSpinnerStatus(false); //disable spinner, but it shouldn't matter at this point
              setState({ ...state, getRcTotal: res2.data });
              navigate("/profile", {
                state: {
                  lcID: assessmentState.assessmentSessionId,
                  lcTotal: res2.data
                }
              });
            });
        });
      }
    }
  }, [useODARouting, completedAPICall]);

  //make necessary state changes to retrieve next question
  const setNextQuestion = useCallback(() => {
    const counter = state.counter + 1;
    const questionId = state.questionId + 1;
    const currentItem = state.currentItem + 1;
    setResumePage(resumePage + 1);
    setState({
      ...state,
      counter: counter,
      currentItem: currentItem,
      questionId: questionId,
      //testletDB[0].testitems[0].template
      template: state.testData.testitems[counter].template,
      showChild: false
    });
  }, [state, resumePage]);

  //merged with handleNext function
  const clickNextButton = useCallback(() => {
    let wrapperTop =
      document.querySelector(".wrapper").getBoundingClientRect() !== null
        ? document.querySelector(".wrapper").getBoundingClientRect()
        : { top: 0, right: 0, bottom: 0, left: 0 };
    window.scrollTo(0, wrapperTop.top);
    if (state.questionId < state.testData.testitems.length) {
      // document.querySelector(".nextBtn").classList.add("disabled");
      //add waiting class to nextBtn&submitBtn to disable advancing while previous API call is ongoing
      setButtonWaiting(true);
      setNextQuestion();
    }
  }, [state.questionId, state.testData, setNextQuestion]);

  useEffect(() => {
    if (
      moduleLinearPosMain !== null &&
      testletDB !== null &&
      moduleLinearPosSub <= testletDB[moduleLinearPosMain].testitems.length - 1 &&
      !useODARouting
    ) {
      //console.log("route b");
      //console.log("setCurrrentItem=" + moduleLinearPosMain);

      let attempt = state.attempt + 1;
      //console.log(testletDB);
      const updatedDB = testletDB[moduleLinearPosMain];
      //console.log(updatedDB);
      //console.log(moduleLinearPosSub);
      //console.log(updatedDB.testitems);
      //console.log(updatedDB.testitems[moduleLinearPosSub]);
      //console.log(updatedDB.testitems[moduleLinearPosSub].template);

      //only call setState once during an instantaneous moment
      setState((prevState) => ({
        ...prevState,
        attempt: attempt,
        getAnswers: [],
        testData: updatedDB,
        template: updatedDB.testitems[moduleLinearPosSub].template,
        language: updatedDB.language.description,
        modal: updatedDB.modality,
        proficiencyLevel: updatedDB.proficiencyLevel,
        levelRange: updatedDB.levelRange
      }));
    }
  }, [moduleLinearPosMain, moduleLinearPosSub, testletDB]);

  //useEffect watching state.counter for changes to state.showChild
  //which (then) renders templates below if true
  useEffect(() => {
    if (state.counter > 0) {
      setState((prevState) => ({ ...prevState, showChild: true }));
    }
  }, [state.counter]);

  const createModuleButton = () => {
    if (
      assessmentState.assessmentModulesRCLC !== null &&
      assessmentState.assessmentModulesRCLC !== undefined &&
      assessmentState.assessmentModulesRCLC.hasOwnProperty("length")
    ) {
      const curModDisplay =
        assessmentState.assessmentModulesRCLC.length !== 0 ? assessmentState.assessmentModulesRCLC.length : 1;
      let dynamicModDisplay = "";
      //new logic from Davids post in chatroom for displaying module progress in assessment RCLC
      // 1st - "Starting Assessment" - 1st module
      // 2nd - "Current Level" - after first level complete, except 3 passed or 1 fail
      // 3rd - "Target Level" - after current level established except edge cases
      // 4th - "Creating Profile" -- when creating profile
      if (spinnerStatus && !spinnerStatusAltMessage) {
        dynamicModDisplay = "Creating Profile";
      } else {
        if (curModDisplay === 1) {
          dynamicModDisplay = "Starting Assessment";
        } else if (curModDisplay >= 2) {
          //edge case exception 1, F, F
          if (assessmentState.assessmentSkillFloor === -999 && assessmentState.assessmentModuleRef.includes("L1.M2")) {
            dynamicModDisplay = "Determining Target Level";
          } else if (
            assessmentState.assessmentSkillFloor === -999 ||
            assessmentState.assessmentModuleRef.includes("M1")
          ) {
            dynamicModDisplay = "Establishing Current Level";
          } else {
            dynamicModDisplay = "Determining Target Level";
          }
        }
      }

      return (
        <div className="moduleDisplay">
          <span>{dynamicModDisplay}</span>
        </div>
      );
    } else {
      return <></>;
    }
  };

  const createTestSet = () => {
    let setNum = [];
    for (let i = 0; i < 3; i++) {
      if (i === 0) {
        setNum.push(
          (setNum = [
            <div className="setProgressUnit complete current" key={i + 20}>
              <span>Testlet 1</span>
            </div>
          ])
        );
      } else {
        setNum.push(
          <div className="setProgressUnit" key={i + 20}>
            <span>Testlet {i + 1}</span>
          </div>
        );
      }
    }
    return setNum;
  };

  //creates initial progress bar grid at top
  const createProgGrid = () => {
    let item = [];
    if (state.testData !== null && state.testData.hasOwnProperty("testitems")) {
      for (let i = 1; i < state.testData.testitems.length + 1; i++) {
        if (i === 1) {
          item.push(
            (item = [
              <div className="progressUnit complete current" key={i}>
                <span>1</span>
              </div>
            ])
          );
        } else {
          item.push(
            <div className="progressUnit" key={i}>
              <span>{i}</span>
            </div>
          );
        }
      }
      return item;
    } else {
      item.push(
        <div className="progressUnit complete current" key={0}>
          <span>1</span>
        </div>
      );
    }
  };

  //Capitalize the first letter of a string
  const capitalizeName = (name) => {
    if (typeof name === "string") {
      return name.replace(/\b(\w)/g, (s) => s.toUpperCase());
    } else {
      return "";
    }
  };

  //determine which question template to render to the user
  const renderTemplate = () => {
    if (state.showChild) {
      if (questionStartSeconds === null) {
        setQuestionStartSeconds(Date.now());
      }
    }
    if (state.template === "AMC2" && state.showChild) {
      //console.log("rendering template for... " + state.template);
      return (
        <AMC2
          counter={state.counter}
          testData={state.testData}
          parentCallback={callbackFunction}
          navLen={state.testData.testitems.length}
          navCrrtItem={state.currentItem}
          clickNextBtn={clickNextButton}
          submitBtn={submitBtn}
          buttonWaiting={buttonWaiting}
        />
      );
    } else if (state.template === "LCIA" && state.showChild) {
      //console.log("rendering template for... " + state.template);
      return (
        <LCIA
          counter={state.counter}
          testData={state.testData}
          parentCallback={callbackFunction}
          navLen={state.testData.testitems.length}
          navCrrtItem={state.currentItem}
          clickNextBtn={clickNextButton}
          submitBtn={submitBtn}
          buttonWaiting={buttonWaiting}
        />
      );
    } else if (state.template === "LCM" && state.showChild) {
      //console.log("rendering template for... " + state.template);
      return (
        <LCM
          counter={state.counter}
          testData={state.testData}
          parentCallback={callbackFunction}
          navLen={state.testData.testitems.length}
          navCrrtItem={state.currentItem}
          clickNextBtn={clickNextButton}
          submitBtn={submitBtn}
          buttonWaiting={buttonWaiting}
        />
      );
    } else if (state.template === "LSA" && state.showChild) {
      //console.log("rendering template for... " + state.template);
      return (
        <LSA
          counter={state.counter}
          testData={state.testData}
          parentCallback={callbackFunction}
          navLen={state.testData.testitems.length}
          navCrrtItem={state.currentItem}
          clickNextBtn={clickNextButton}
          submitBtn={submitBtn}
          buttonWaiting={buttonWaiting}
        />
      );
    } else if (state.template === "LSA2" && state.showChild) {
      //console.log("rendering template for... " + state.template);
      return (
        <LSA2
          counter={state.counter}
          testData={state.testData}
          parentCallback={callbackFunction}
          navLen={state.testData.testitems.length}
          navCrrtItem={state.currentItem}
          clickNextBtn={clickNextButton}
          submitBtn={submitBtn}
          buttonWaiting={buttonWaiting}
        />
      );
    } else if (state.template === "TMC2" && state.showChild) {
      //console.log("rendering template for... " + state.template);
      return (
        <TMC2
          counter={state.counter}
          testData={state.testData}
          parentCallback={callbackFunction}
          navLen={state.testData.testitems.length}
          navCrrtItem={state.currentItem}
          clickNextBtn={clickNextButton}
          submitBtn={submitBtn}
          buttonWaiting={buttonWaiting}
        />
      );
    } else return null;
  };

  return (
    <div>
      <div className="assessmentHeader">
        <div className="assHeaderTop">
          <Link to="/dashboard">
            <img
              className="logo"
              src={headLogo}
              alt="Online Diagnostic Assessment Logo"
              title="Click here to return to your assessment dashboard."
            />
          </Link>
        </div>
        <Link to="/dashboard">
          <img
            className="logo"
            src={headLogoSM}
            alt="Online Diagnostic Assessment Logo"
            title="Click here to return to your assessment dashboard."
          />
        </Link>
        <h3>
          {assessmentState.assessmentLanguage.description !== null
            ? assessmentState.assessmentLanguage.description
                .replace(
                  /^(North_Korean|North Korean|Korean\/North Korean|Korean\/North_Korean)$/i,
                  "Korean (South & North)"
                )
                .replace(/^Korean$/i, "Korean (South)")
                .replace("_", " ")
            : ""}{" "}
          {capitalizeName(state.modal)} Assessment
        </h3>
        {!spinnerStatus && (
          <p>
            <span className="hoverinstruction" data-hover={HelpTooltip[0][state.template]}>
              <span className="account" style={{ float: "right", marginTop: "-40px" }}>
                <img id="instrcutionIcon" src={instructionIcon} />
              </span>
            </span>
          </p>
        )}
      </div>
      <div className="setModuleCount">{createModuleButton()}</div>
      <div className="setProgressGrid">{createTestSet()}</div>
      <br />
      {!spinnerStatus && <div className="progressGrid">{createProgGrid()}</div>}
      {spinnerStatus && !spinnerStatusAltMessage ? (
        <LoadingSpinner msg="Please wait while your personalized diagnostic profile is created..." size="small" />
      ) : spinnerStatus && spinnerStatusAltMessage !== false ? (
        <LoadingSpinner msg={spinnerStatusAltMessage} size="small" />
      ) : state.showChild ? (
        renderTemplate()
      ) : null}
      <div className="clearFix" />

      {showTestTipsStart && (
        <NewTestTipsModal handleRefModalTestTips={handleRefModalTestTips} template={state.template} />
      )}
      <ErrorModal
        errorStatusCode={modalErrorState.errorStatusCode}
        errorUserMessage={modalErrorState.errorUserMessage}
        errorDevData={modalErrorState.errorDevData}
      />
    </div>
  );
};

export default ListeningTest;
