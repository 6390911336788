import { useState, useContext, useCallback, useEffect } from "react";
import { ErrorContext } from "../../../context/ErrorProvider";
import { AssessmentContext } from "../../../context/AssessmentProvider";
import Footer from "../../footer/Footer";
import ContactFormSentModal from "../../modals/ContactFormSentModal";
import LoadingSpinner from "../../spinner/LoadingSpinner";
import axios from "axios";
import axiosRetry from "axios-retry";

const Contact = () => {
  const [, errorDispatch] = useContext(ErrorContext);
  const [assessmentState] = useContext(AssessmentContext);
  const [emailSent, setEmailSent] = useState(false);
  const [status, setStatus] = useState("Submit");
  axiosRetry(axios, {
    retries: 4, // number of retries

    retryDelay: (...arg) => axiosRetry.exponentialDelay(...arg, 1000), // Exponential delay with backoff of 1000mst
    retryCondition: (err) => {
      // console.log(err.response);
      return (
        axiosRetry.isNetworkError(err) ||
        err.code === "ECONNABORTED" ||
        err.code === "ENOTFOUND" ||
        err.code === "ETIMEDOUT" ||
        (err.response && err.response.status >= 500 && err.response.status <= 599)
      );
    }
  });

  let defaultEmail = "";
  if (assessmentState.assessmentUserEmail !== null) {
    defaultEmail = assessmentState.assessmentUserEmail;
  }

  function sanitizeString(string) {
    const map = {
      "&": "&amp;",
      "<": "&lt;",
      ">": "&gt;",
      '"': "&quot;",
      "'": "&#x27;",
      "/": "&#x2F;",
      "`": "&grave;"
    };
    const reg = /[&<>"'/]/gi;
    return string.replace(reg, (match) => map[match]);
  }

  const refModalState = useCallback(() => {
    setEmailSent(false);
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    setStatus("Sending...");
    let today = new Date();
    today = today + "";
    const { name, email, message } = e.target.elements;
    let details = {
      name: sanitizeString(name.value),
      email: sanitizeString(email.value),
      message: sanitizeString(encodeURI(message.value)),
      today: today
    };

    try {
      //send email data object to API
      axios
        .post("/api/emailContactForm", {
          details
        })
        .then(function (response) {
          // console.log(response);
          setEmailSent(true);
          setStatus("Submit");
        })
        .catch((error) => {
          // console.log(error);
          if (error.response) {
            if (error.response.status >= 400 && error.response.status < 600) {
              //global error dispatch call to show error modal if error received during api call
              errorDispatch({
                type: "UPDATE_ERROR_STATE",
                payload: {
                  errorStatusCode: error.response.status,
                  errorUserMessage: "New Error",
                  errorDevData: error.response
                }
              });
            }
          }
          console.log("Failed to send contact email.");
        });
    } catch (error) {
      // console.log(error);
      if (error.response) {
        if (error.response.status >= 400 && error.response.status < 600) {
          //global error dispatch call to show error modal if error received during api call
          errorDispatch({
            type: "UPDATE_ERROR_STATE",
            payload: {
              errorStatusCode: error.response.status,
              errorUserMessage: "New Error",
              errorDevData: error.response
            }
          });
        }
      }
      console.log("Axios try block caught error.");
    }
  };

  //show+hide the Modal
  useEffect(() => {
    const el = document.getElementById("contactModal");
    if (emailSent === true && el) {
      el.style.display = "block";
    } else if (emailSent === false && el) {
      el.style.display = "none";
    }
  }, [emailSent]);

  return (
    <div className="contact">
      <h1>Contact Us</h1>
      {status === "Sending..." ? (
        <LoadingSpinner msg="Attempting to send your message..." size="small" />
      ) : (
        <form className="contactForm" onSubmit={handleSubmit}>
          <label htmlFor="name">
            Name:
            <input id="name" type="text" className="contactInput" />
          </label>
          <label htmlFor="email">
            Email:
            <input type="email" id="email" className="contactInput" defaultValue={defaultEmail} required />
          </label>

          <label htmlFor="message">
            Message:
            <textarea id="message" className="textPassage contactMessage" required />
          </label>

          <button type="submit">{status}</button>
        </form>
      )}
      {emailSent && <ContactFormSentModal refModalState={refModalState} />}
      <br />
      <br />
      <br />
      <Footer showReportProblem={false} />
    </div>
  );
};

export default Contact;
