import React from "react";
import dompurify from "dompurify";
import Footer from "../../footer/Footer";
import menu from "./menucontents/MenuContents.json";

export default function Proficiency() {
  const sanitizer = dompurify.sanitize;
  const proficiency = menu[0].proficiency;

  return (
    <>
      <h4 className="proficiency">UNDERSTANDING PROFICIENCY</h4>
      <h4>
        <div
          style={{ fontWeight: "normal", fontSize: "16px" }}
          dangerouslySetInnerHTML={{
            __html: sanitizer(proficiency),
          }}
        />
      </h4>
      <br />
      <Footer showReportProblem={false} />
    </>
  );
}
