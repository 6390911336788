import React, { useState, useEffect } from "react";
import axios from "axios";
import AdminFooter from "./adminComponents/AdminFooter";
import PasageByProficiencyLevelDisplay from "./adminDisplay/PasageByProficiencyLevelDisplay";

const PasageByProficiencyLevel = () => {
  const initialState = {
    content: [],
    language: [],
    selectedLanguage: "",
    selectedModality: "",
    selectedSite: "",
    selectedLevel: "",
  };

  const [state, setState] = useState(initialState);
  const {
    language,
    selectedLanguage,
    selectedModality,
    selectedSite,
    selectedLevel,
  } = state;

  useEffect(() => {
    if (selectedModality === "reading") {
      axios
        .get('/api/language?modalities="reading"&_sort={"sequence": 1}')
        .then((res) => {
          setState({ ...state, language: res.data });
        });
    } else if (selectedModality === "listening") {
      axios
        .get('/api/language?modalities="listening"&_sort={"sequence": 1}')
        .then((res) => {
          setState({ ...state, language: res.data });
        });
    }
  }, [selectedModality]);

  let langDropdown = language.map((item, index) => (
    <option key={index} value={item._id}>
      {item.displayName}
    </option>
  ));

  const handleLanguageChange = (event) => {
    setState({ ...state, selectedLanguage: event.target.value });
  };

  const handleModalityChange = (event) => {
    const initial = {
      selectedLanguage: "",
      selectedSite: "",
      selectedLevel: "",
      content: [],
    };
    setState({
      ...state,
      selectedModality: event.target.value,
      ...initial,
    });
  };

  const handleSiteChange = (event) => {
    setState({ ...state, selectedSite: event.target.value });
  };

  const handleLevelChange = (event) => {
    setState({ ...state, selectedLevel: event.target.value });
  };

  const getValues = () => {
    const user = {
      site: selectedSite,
      lang: selectedLanguage,
      mod: selectedModality,
      lvl: selectedLevel,
    };

    const valuesAreValid =
      selectedLanguage !== "Select" &&
      selectedModality !== "Select" &&
      selectedSite !== "Select" &&
      selectedLevel !== "Select" &&
      selectedLanguage !== "" &&
      selectedModality !== "" &&
      selectedSite !== "" &&
      selectedLevel !== "";

    if (valuesAreValid) {
      console.log("values=", {
        site: selectedSite,
        lang: selectedLanguage,
        mod: selectedModality,
        lvl: selectedLevel,
      });

      axios
        .get("/api/adminReport/PassageProficiencyReport", { params: user })
        .then((res) => {
          setState({ ...state, content: res.data });
        })

        .catch((error) => {
          if (error.response) {
            if (error.response.status >= 400 && error.response.status < 600) {
            }
          }
        });
    } else {
      console.log("No value can be null");
      alert("Select all items");
    }
  };

  const resetValues = () => {
    setState(initialState);
  };

  return (
    <div className="adminMainPanel">
      <div className="infoTitle adminInfoTitle">
        <div>Passage Rendition by Proficiency Level</div>
      </div>
      <div>
        Modality:
        <select
          key="modality"
          value={selectedModality}
          onChange={handleModalityChange}
        >
          <option>Select</option>
          <option value="reading">Reading</option>
          <option value="listening">Listening</option>
        </select>
      </div>
      <div>
        Language:
        <select
          key="language"
          value={selectedLanguage}
          onChange={handleLanguageChange}
        >
          <option>Select</option>
          {langDropdown}
        </select>
      </div>
      <div>
        Site:
        <select key="site" value={selectedSite} onChange={handleSiteChange}>
          <option>Select</option>
          <option value="oda">ODA</option>
        </select>
      </div>
      <div>
        Level:
        <select key="level" value={selectedLevel} onChange={handleLevelChange}>
          {selectedLanguage === "eng" ? (
            <>
              <option>Select</option>

              <option value="1+">1+</option>
              <option value="2">2</option>
              <option value="2+">2+</option>
              <option value="3">3</option>
            </>
          ) : (
            <>
              <option>Select</option>
              <option value="1">1</option>
              <option value="1+">1+</option>
              <option value="2">2</option>
              <option value="2+">2+</option>
              <option value="3">3</option>
            </>
          )}
        </select>
      </div>

      <div className="buttonGroup">
        <div onClick={getValues} className="searchButton">
          Get Passages
        </div>
        <div onClick={resetValues} className="searchButton">
          Reset Options
        </div>
      </div>
      <div className="adminContentBox" style={{ overflow: "auto" }}>
        <PasageByProficiencyLevelDisplay
          content={state.content}
          mod={state.selectedModality}
        />
      </div>

      <AdminFooter />
    </div>
  );
};

export default PasageByProficiencyLevel;
