import { useEffect, useCallback, useState, useContext } from "react";
import { ErrorContext } from "../../context/ErrorProvider";
import axios from "axios";
import axiosRetry from "axios-retry";
import rcIcon from "../../img/icons/reading_icon_material.svg";
import lcIcon from "../../img/icons/listening_icon_material.svg";
import grIcon from "../../img/icons/grammar_icon_material.svg";
import sharedFunctions from "../../functions/sharedFunctions";

export default function ProfileAll(props) {
  const [, errorDispatch] = useContext(ErrorContext);
  const [selectedProfile, setSelectedProfile] = useState("GR");
  const grTotal = props.getGrTotal || [];
  const rcTotal = props.getRcTotal || [];
  const lcTotal = props.getLcTotal || [];
  const allTotal = props.getAllTotal || [];
  const allTotalLen = allTotal.length;
  const grTotalLen = grTotal.length;
  const rcTotalLen = rcTotal.length;
  const lcTotalLen = lcTotal.length;

  const targetLevelKey = { 0: "1+", 1: "2", 2: "2+", 3: "3", 4: "N/A" };
  //** Reformatted to display new UI with tabs, functionality the same  **/

  axiosRetry(axios, {
    retries: 4,
    retryDelay: axiosRetry.exponentialDelay,
    retryCondition: (err) =>
      axiosRetry.isNetworkError(err) ||
      err.code === "ECONNABORTED" ||
      err.code === "ENOTFOUND" ||
      err.code === "ETIMEDOUT" ||
      (err.response && err.response.status >= 500 && err.response.status <= 599)
  });

  useEffect(() => {
    sharedFunctions();
  }, []);

  useEffect(() => {
    if (grTotal.length > 0) {
      setSelectedProfile("GR");
    } else if (rcTotal.length > 0) {
      setSelectedProfile("RC");
    } else if (lcTotal.length > 0) {
      setSelectedProfile("LC");
    }
  }, [grTotal.length, rcTotal.length, lcTotal.length]);

  const groupDataByLanguage = (data) => {
    let groupedData = {};
    data.forEach((item) => {
      const lang = item.language.description;
      if (!groupedData[lang]) {
        groupedData[lang] = [];
      }
      groupedData[lang].push(item);
    });
    return groupedData;
  };

  const renderAssessmentItem = (item, profileType, index, i) => {
    let readableDate = Date.parse(item.endtime);
    readableDate = new Date(readableDate);
    readableDate = readableDate.toLocaleString("default", {
      month: "short",
      day: "numeric",
      year: "numeric"
    });

    let targetLevel =
      item.skillFloor !== null && item.skillFloor !== -999
        ? targetLevelKey[item.skillFloor]
        : item.language.description === "English"
        ? "1+"
        : "1";

    let key = "levelndate_" + item.language.description + "_" + selectedProfile + "_" + i;
    //add in details from async queries as they arrive
    if (selectedProfile === "LC" || selectedProfile === "RC") {
      if (
        document.getElementById(key + "_linguisticScore") !== null &&
        document.getElementById(key + "_contentScore") !== null
      ) {
        if (
          document.getElementById(key + "_contentScore").innerHTML === "Loading..." ||
          document.getElementById(key + "_linguisticScore").innerHTML === "Loading..."
        ) {
          axios
            .get(
              "api/session" +
                selectedProfile +
                "/diagProfileByProfLevel?sessionId=" +
                encodeURIComponent(item._id) +
                "&proficiencyLevel=" +
                encodeURIComponent(decodeURIComponent(item.currentLevel)) +
                "&language=" +
                encodeURIComponent(item.language._id)
            )
            .then((res3) => {
              if (JSON.stringify(res3.data) !== "[]" && res3.data.length > 0) {
                document.getElementById(key + "_linguisticScore").innerHTML = res3.data[0].linguisticPercentage;
                document.getElementById(key + "_contentScore").innerHTML = res3.data[0].contentPercentage;
              } else {
                document.getElementById(key + "_contentScore").innerHTML = "N/A";
                document.getElementById(key + "_linguisticScore").innerHTML = "N/A";
              }
            })
            .catch((error) => {
              if (error.response) {
                if (error.response.status >= 400 && error.response.status < 600) {
                  //global error dispatch call to show error modal if error received during api call
                  errorDispatch({
                    type: "UPDATE_ERROR_STATE",
                    payload: {
                      errorStatusCode: error.response.status,
                      errorUserMessage: "New Error",
                      errorDevData: error.response
                    }
                  });
                }
              }
            });
        }
      } else {
        setTimeout(() => {
          if (
            document.getElementById(key + "_linguisticScore") !== null &&
            document.getElementById(key + "_contentScore") !== null &&
            (document.getElementById(key + "_contentScore").innerHTML === "Loading..." ||
              document.getElementById(key + "_linguisticScore").innerHTML === "Loading...")
          ) {
            axios
              .get(
                "api/session" +
                  selectedProfile +
                  "/diagProfileByProfLevel?sessionId=" +
                  encodeURIComponent(item._id) +
                  "&proficiencyLevel=" +
                  encodeURIComponent(decodeURIComponent(item.currentLevel)) +
                  "&language=" +
                  encodeURIComponent(item.language._id)
              )
              .then((res3) => {
                if (JSON.stringify(res3.data) !== "[]" && res3.data.length > 0) {
                  let scores = [
                    res3.data[0].contentPercentage.toFixed(0),
                    res3.data[0].linguisticPercentage.toFixed(0)
                  ];
                  document.getElementById(key + "_contentScore").innerHTML = scores[0] + "%";
                  document.getElementById(key + "_linguisticScore").innerHTML = scores[1] + "%";
                } else {
                  document.getElementById(key + "_contentScore").innerHTML = "N/A";
                  document.getElementById(key + "_linguisticScore").innerHTML = "N/A";
                }
              })
              .catch((error) => {
                if (error.response) {
                  if (error.response.status >= 400 && error.response.status < 600) {
                    //global error dispatch call to show error modal if error received during api call
                    errorDispatch({
                      type: "UPDATE_ERROR_STATE",
                      payload: {
                        errorStatusCode: error.response.status,
                        errorUserMessage: "New Error",
                        errorDevData: error.response
                      }
                    });
                  }
                }
              });
          }
        }, 250);
      }
    }

    return (
      <div key={item._id} id={`levelndate${index}${i}`}>
        <ul>
          <li>
            <div
              className="assessmentResults ar-grid-container"
              id={item._id}
              onClick={
                profileType === "Reading"
                  ? props.readingProfile
                  : profileType === "Listening"
                  ? props.listeningProfile
                  : props.grammarProfile
              }
              title="Click to view this diagnostic assessment."
            >
              <div className="ar-grid-date">{readableDate}</div>
              <div className="ar-grid-level1">
                {profileType === "Grammar" ? item.grammarLevel.displayName : item.currentLevel || "N/A"}
              </div>
              {profileType !== "Grammar" && <div className="ar-grid-level2">{targetLevel}</div>}
              <div className="ar-grid-duration">{`${Math.floor(item.timeSpent / 60)}h ${item.timeSpent % 60}m`}</div>
              {profileType === "Reading" || profileType === "Listening" ? (
                <>
                  <div className="ar-grid-contentScore" id={key + "_contentScore"}>
                    Loading...
                  </div>
                  <div className="ar-grid-linguisticScore" id={key + "_linguisticScore"}>
                    Loading...
                  </div>
                </>
              ) : (
                <>
                  <div className="ar-grid-correct">
                    {item.totQuestionPassed} / {item.totQuestionCounted}
                  </div>
                  <div className="ar-grid-score">{item.overallCorrectPercentage}%</div>
                </>
              )}
            </div>
          </li>
        </ul>
      </div>
    );
  };

  const renderProfileData = useCallback(
    (data, profileType, profileIcon) => {
      const groupedData = groupDataByLanguage(data);
      if (Object.keys(groupedData).length === 0) return null;

      return Object.keys(groupedData).map((lang, index) => (
        <div key={lang}>
          <div className="assessments">
            <div className="gridContainerTitle">
              <div className="gridItem">
                <span className="cardTitle">
                  <img src={profileIcon} alt={`${profileType} Assessments`} />
                  {profileType}
                </span>
                <div className="ar-grid-container">
                  <div className="ar-grid-date ar-grid-header">Date</div>
                  <div className="ar-grid-level1 ar-grid-header">Level</div>
                  {profileType !== "Grammar" && <div className="ar-grid-level2 ar-grid-header">Target Level</div>}
                  <div className="ar-grid-duration ar-grid-header">Duration</div>
                  {profileType === "Reading" || profileType === "Listening" ? (
                    <>
                      <div className="ar-grid-contentScore ar-grid-header">Level Content Score</div>
                      <div className="ar-grid-linguisticScore ar-grid-header">Level Linguistic Score</div>
                    </>
                  ) : (
                    <>
                      <div className="ar-grid-correct ar-grid-header">Correct</div>
                      <div className="ar-grid-score ar-grid-header">Score</div>
                    </>
                  )}
                </div>
                <div className="dpResultsHover">
                  {groupedData[lang].map((item, i) => renderAssessmentItem(item, profileType, index, i))}
                </div>
                <p className="dpHelp">
                  Click on a completed assessment above to view your detailed diagnostic profile.
                </p>
              </div>
            </div>
          </div>
        </div>
      ));
    },
    [renderAssessmentItem]
  );

  const LangList = useCallback(() => {
    let profileIcon, profileType, profileData;
    if (selectedProfile === "RC") {
      profileIcon = rcIcon;
      profileType = "Reading";
      profileData = rcTotal;
    } else if (selectedProfile === "LC") {
      profileIcon = lcIcon;
      profileType = "Listening";
      profileData = lcTotal;
    } else if (selectedProfile === "GR") {
      profileIcon = grIcon;
      profileType = "Grammar";
      profileData = grTotal;
    }

    const individualLang_ALL = Array.from(new Set(profileData.map((item) => item.language.description)));

    return individualLang_ALL.map((targetLang) => {
      const filteredData = profileData.filter((item) => item.language.description === targetLang);

      if (filteredData.length === 0) return null;

      return (
        <div key={targetLang}>
          <div className="assessments">
            <div className="gridContainerTitle">
              <h4 style={{ marginTop: 0 }}>
                Your{" "}
                {targetLang
                  .replace(
                    /^(North_Korean|North Korean|Korean\/North Korean|Korean\/North_Korean)$/i,
                    "Korean (South & North)"
                  )
                  .replace(/^Korean$/i, "Korean (South)")
                  .replace("_", " ")}{" "}
                Language Diagnostic Profiles
              </h4>
              {renderProfileData(filteredData, profileType, profileIcon)}
            </div>
          </div>
        </div>
      );
    });
  }, [selectedProfile, rcTotal, lcTotal, grTotal]);

  const handleTabChange = (tab) => {
    setSelectedProfile(tab);
  };

  return (
    <div style={{ marginTop: "60px" }}>
      <h3 style={{ textAlign: "center", padding: 0 }}>Diagnostic Profiles</h3>
      <div className="tab-container">
        {rcTotal.length > 0 && (
          <button
            className={`tabProfile ${selectedProfile === "RC" ? "active" : ""}`}
            onClick={() => handleTabChange("RC")}
          >
            Reading
          </button>
        )}
        {lcTotal.length > 0 && (
          <button
            className={`tabProfile ${selectedProfile === "LC" ? "active" : ""}`}
            onClick={() => handleTabChange("LC")}
          >
            Listening
          </button>
        )}
        {grTotal.length > 0 && (
          <button
            className={`tabProfile ${selectedProfile === "GR" ? "active" : ""}`}
            onClick={() => handleTabChange("GR")}
          >
            Grammar
          </button>
        )}
      </div>
      {LangList()}
    </div>
  );
}
