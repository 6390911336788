import React, { useState, useEffect, useContext, useCallback } from "react";
import Test from "./components/Test";
import Footer from "../../../../components/footer/Footer";
import "../../../../css/index.css";
//import fontsizeFunctions from "../../../../functions/fontsizeFunctions";
import draggableFunctions from "../../../../functions/draggableFunctions";
import { AssessmentContext } from "../../../../context/AssessmentProvider";

const CIM = (props) => {
  const testData = props.testData;
  const [state, setState] = useState({
    actID: testData.testitems[props.counter]._id,
    modality: testData.modality,
    template: testData.testitems[props.counter].template,
    skill: testData.proficiencyLevel,
    passage: testData.testitems[props.counter].mainPassage,
    questionText: testData.testitems[props.counter].questionText,
    question: testData.testitems[props.counter].questions,
    dropdownOptions: testData.testitems[props.counter].multipleChoiceAns[0],
    selValue: "Select",
    dir: testData.language.isRightToLeft,
    userAnswer: [],
    scoredAnswer: [],
    correctAnswer: testData.testitems[props.counter].answerFields,
    answerField: testData.testitems[props.counter].answerFields
  });
  //const [scoredAnswer, setScoredAnswer] = useState([]);
  const [assessmentState] = useContext(AssessmentContext);
  state.userAnswer.length = testData.testitems[props.counter].answerFields.length;

  useEffect(() => {
    //fontSizeFunctions();
    draggableFunctions();
  }, []);

  useEffect(() => {
    //disable next/submit until previous api call is complete; prevents overrunning api calls to change same data
    if (props.buttonWaiting) {
      const apiWaitButtons = document.querySelectorAll(".nextBtn, .submitBtn");
      const titleChanger = document.querySelector(".nextSubmitParentDiv");
      apiWaitButtons.forEach((item, index) => {
        item.classList.add("waitingForAPI");
        if (item.classList.contains("disabled")) {
          titleChanger.title =
            "Please answer the questions above before proceeding. You will also need to wait for the system to record your previous answer before proceeding.";
        } else {
          titleChanger.title = "Please wait for your assessment to save your previous answer before proceeding.";
        }
      });
      titleChanger.classList.remove("nextSubmitParentDivUnanswered");
      titleChanger.classList.add("nextSubmitParentDivWaiting");
    } else {
      const titleChanger = document.querySelector(".nextSubmitParentDiv");
      const apiWaitButtons = document.querySelectorAll(".nextBtn, .submitBtn");
      apiWaitButtons.forEach((item, index) => {
        item.classList.remove("waitingForAPI");
        if (item.classList.contains("disabled")) {
          titleChanger.title = "Please attempt to answers all questions before proceeding.  Note: If you feel your assessment is stuck and you can not proceed, you may skip to the next question by holding SHIFT and clicking this button.";
        } else {
          titleChanger.title = "Click to submit your answer(s).";
        }
      });
      titleChanger.classList.remove("nextSubmitParentDivWaiting");
      titleChanger.classList.add("nextSubmitParentDivUnanswered");
    }
  }, [props.buttonWaiting]);

  const judgeAnswers = useCallback(
    (index) => {
      let checkAnswer = false;
      let userAns;
      userAns = state.userAnswer;
      const crrtAns = state.correctAnswer;
      if (userAns[index] !== undefined) {
        if (parseInt(userAns[index]) === parseInt(crrtAns[index].multipleChoiceAnsNums)) {
          checkAnswer = true;
        }
      }
      return { checkAnswer };
    },
    [state]
  );

  const handleClick = useCallback(
    (event) => {
      const id = event.currentTarget.id;
      const index = event.currentTarget.id.substr(-1);

      const value = event.currentTarget.value;
      let new_state = Object.assign({}, state);
      let a = new_state.userAnswer;
      let z = new_state.scoredAnswer;

      const len = state.userAnswer.length;

      a[index] = parseInt(value);

      const answerCheck = judgeAnswers(index).checkAnswer;

      z[index] = {
        uiControlID: id,
        testlet: testData._id,
        testitem: state.actID,
        answerField: state.answerField[index]._id,
        keywordMatches: [],
        userAnswer: value,
        isPassed: answerCheck
      };

      setState({ ...state, userAnswer: a, scoredAnswer: z });

      let num = 0;
      for (let i = 0; i < len; i++) {
        if (state.userAnswer[i] !== undefined) {
          num++;
        }
      }
      if (num === len) {
        document.querySelector(".nextBtn").classList.remove("disabled");
        const titleChanger = document.querySelector(".nextSubmitParentDiv");
        titleChanger.classList.remove("nextSubmitParentDivUnanswered");
        if (titleChanger.classList.contains("nextSubmitParentDivWaiting")) {
          titleChanger.title = "Please wait for your assessment to save your previous answer before proceeding.";
        } else {
          titleChanger.title = "Click to submit your answers.";
        }
      } else {
        document.querySelector(".nextBtn").classList.add("disabled");
        const titleChanger = document.querySelector(".nextSubmitParentDiv");
        titleChanger.classList.add("nextSubmitParentDivUnanswered");
        if (titleChanger.classList.contains("nextSubmitParentDivWaiting")) {
          titleChanger.title = "Please wait for your assessment to save your previous answer before proceeding.";
        } else {
          titleChanger.title = "Please attempt to re-answer at least one incorrect question before proceeding.";
        }
      }
    },
    [state, judgeAnswers]
  );

  const handleEval = useCallback((name) => {
    const btnName = name;

    //console.log("scored=", state.scoredAnswer);

    props.parentCallback(JSON.stringify(state.scoredAnswer), btnName);
  });

  const renderTest = () => {
    return (
      <>
        <Test
          passage={state.passage}
          actID={state.actID}
          dir={state.dir}
          selValue={state.selValue}
          dropdownOptions={state.dropdownOptions}
          questionText={state.questionText}
          question={state.question}
          onAnswerSelected={handleClick}
          navLen={props.navLen}
          navCrrtItem={props.navCrrtItem}
          onClickNextBtn={props.clickNextBtn}
          onSubmit={props.clickSubmit}
          onEvaluation={handleEval}
        />
        <Footer actID={state.actID} mod={state.modality} template={state.template} skill={state.skill} />
      </>
    );
  };

  return <div>{renderTest()}</div>;
};

export default CIM;
