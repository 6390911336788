import React from "react";
import dompurify from "dompurify";

export default function AnswerOption(props) {
  const sanitizer = dompurify.sanitize;

  const MCRTL = props.mcdir;
  const mcRB = MCRTL ? "radioButtonRTL" : "radioButton";
  const mcLBL = MCRTL ? "radioLabelRTL" : "radioLabel";

  const radioGroup = "radioGroup_" + props.actID + "_0";
  const answer = props.answer;
  const len = props.answer.length;

  const itemCheck = (event) => {
    for (let i = 0; i < len; i++) {
      document.querySelectorAll(".gridItem")[i].classList.remove("selectedAnswer");
    }
    event.currentTarget.classList.add("selectedAnswer");
  };

  const createAnswerField = () => {
    let items = [];
    for (let i = 0; i < len; i++) {
      const option = answer[i].value;
      const radioID = props.actID + i;
      const value = i;
      const letter = String.fromCharCode(i + "A".charCodeAt(0));
      items.push(
        <div key={radioID}>
            <input
              type="radio"
              className={mcRB}
              style={{ display: "none" }}
              name={radioGroup}
              id={radioID}
              value={value}
              onChange={props.onAnswerSelected}
            />
          <label className={mcLBL} htmlFor={radioID}>
            <div className="gridItem" onClick={itemCheck}>
              <span>{letter}: </span>
              <div
                style={{ display: "inline" }}
                dangerouslySetInnerHTML={{
                  __html: sanitizer(option)
                }}
              />
            </div>
          </label>
        </div>
      );
    }
    return items;
  };
  return createAnswerField();
}
