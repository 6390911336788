import "../../css/index.css";
/**
 * @component RegisterAccountModal
 * @description
 * @param {object} props
 **/

export default function DiagnosticProfileModal(props) {
  const afterCreation = (e) => {
    props.handleDiagnosticModalState(e);
  };

  return (
    <div className="modalDialog" id="RegisterAccountModal">
      <div className="RegisterAccountModal">
        <div
          className="feedbackHeader"
          id="modalHeader"
          style={{ fontSize: "16px" }}
        >
          Diagnostic Profile
        </div>
        <h5
          style={{
            fontSize: "16px",
            fontWeight: "normal",
            overflow: "auto",
          }}
        >
          The Online Diagnostic Assessment results are utilized to generate a
          personalized “Diagnostic Profile.” This profile highlights not only
          the specific tasks you can accomplish but also pinpoints the areas
          that pose the greatest challenges for you.
          <br />
          <br />
          The Diagnostic Profile offers estimates of both your current and
          target proficiency levels, based on the ILR scale. These estimates
          serve as benchmarks in tracking your progress toward improved
          proficiency.
          <br />
          <br />
        </h5>

        <button
          className="goLogin outlined"
          style={{
            fontSize: "16px",
            top: "6px",
            padding: "3px 7px",
          }}
          onClick={(e) => afterCreation(e)}
        >
          Close
        </button>

        <br />
        <br />
      </div>
    </div>
  );
}
