export const DropdownItems = [
  {
    title: "Test Descriptions",
    path: "/testdescriptions",
    cName: "dropdown-link",
  },
  {
    title: "Understanding Proficiency",
    path: "/proficiency",
    cName: "dropdown-link",
  },
  {
    title: "FAQs",
    path: "/faqs",
    cName: "dropdown-link",
  },
  {
    title: "Privacy Policy",
    path: "/policy",
    cName: "dropdown-link",
  },
];
