import React, { useState, useEffect, useContext } from "react";
import { ErrorContext } from "../../../context/ErrorProvider";
import { useNavigate, useLocation } from "react-router-dom";
import { AssessmentContext } from "../../../context/AssessmentProvider";
import axios from "axios";
import axiosRetry from "axios-retry";
import SurveyQuestion from "./surveyComponent/SurveyQuestion";

const SurveyFormRCLC = () => {
  const [, errorDispatch] = useContext(ErrorContext);
  const [assessmentState] = useContext(AssessmentContext);
  axiosRetry(axios, {
    retries: 4, // number of retries

    retryDelay: (...arg) => axiosRetry.exponentialDelay(...arg, 1000), // Exponential delay with backoff of 1000mst
            retryCondition: (err) => {return axiosRetry.isNetworkError(err) || err.code === "ECONNABORTED" || err.code === "ENOTFOUND" || err.code === "ETIMEDOUT" || (err.response && err.response.status >= 500 && err.response.status <= 599) }


  });

  let navigate = useNavigate();
  const initialState = {
    language: [],
    selectedLanguage: "Select"
  };
  const [state, setState] = useState(initialState);
  const [responses, setResponses] = useState({
    sa1: "-1", //not available
    sa2: "-1", //not available
    sa3: "0",
    sb1: "0",
    sb2: "0",
    sb3: "0",
    sb4: "0",
    sc1: "0",
    sc2: "0",
    sc3: "0",
    sc4: "0",
    sc5: "0",
    sc6: "0",
    sd1: "0",
    sd2: "0",
    sd3: "0",
    se1: "0",
    se2: "0",
    se3: "" // Adding se3 for Other Comments
  });

  const { language, selectedLanguage } = state;

  useEffect(() => {
    axios
      .get('/api/language?modalities__ne=[]&_sort={"sequence": 1}')
      .then((res) => {
        setState({ ...state, language: res.data });
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status >= 400 && error.response.status < 600) {
            //global error dispatch call to show error modal if error received during api call
            errorDispatch({
              type: "UPDATE_ERROR_STATE",
              payload: {
                errorStatusCode: error.response.status,
                errorUserMessage: "New Error",
                errorDevData: error.response
              }
            });
          }
        }
      });
  }, []);

  let langDropdown = language.map((item, index) => (
    <option key={index} value={item._id}>
      {item.displayName}
    </option>
  ));

  window.history.pushState(null, null, window.location.href);
  // Listen for the 'popstate' event, which is triggered when the user navigates
  // back or forward in history, and push another state to stay on the same page
  window.addEventListener("popstate", function (event) {
    this.window.history.pushState(null, null, this.location.href);
  });

  let checkLevel;

  switch (assessmentState.assessmentSkillFloor) {
    case 0:
      checkLevel = "1";
      break;
    case 1:
      checkLevel = "1+";
      break;
    case 2:
      checkLevel = "2";
      break;
    case 3:
      checkLevel = "2+";
      break;
    case 4:
      checkLevel = "3";
      break;
    default:
      if (assessmentState.assessmentSkillFloor === -999) {
        checkLevel = "-1";
      }
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    const user = {
      userId: assessmentState.assessmentUserId,
      langId: selectedLanguage,
      response: responses
    };
    if(checkLevel !== "-1") {
      user.lvl = checkLevel;
    }


    const valuesAreValid = selectedLanguage != "" && selectedLanguage != "Select";
    if (valuesAreValid) {
      axios({
        method: "post",
        headers: { "content-type": "application/json" },
        url: "/api/survey/",
        data: user
      }).catch((error) => {
        if (error.response) {
          if (error.response.status >= 400 && error.response.status < 600) {
            errorDispatch({
              type: "UPDATE_ERROR_STATE",
              payload: {
                errorStatusCode: error.response.status,
                errorUserMessage: "New Error",
                errorDevData: error.response
              }
            });
          }
        }
      });

      alert("Your feedback is appreciated. Thank you!");
      navigate("/dashboard");
    } else {
      alert("Please select a language");
    }
  };

  const handleLanguageChange = (event) => {
    setState({ ...state, selectedLanguage: event.target.value });
  };

  const handleResponseChange = (id, value) => {
    setResponses({ ...responses, [id]: value });
  };

  const handleCommentsChange = (event) => {
    setResponses({ ...responses, se3: event.target.value });
  };

  const surveyQuestionsUI = [
    {
      id: "sa3",
      text: "1. The site is user-friendly.",
      value: responses.sa3,
      setValue: (value) => handleResponseChange("sa3", value)
    }
  ];
  const surveyQuestionsRC = [
    {
      id: "sb1",
      text: "1. The questions for each reading passage were clear and easy to understand.",
      value: responses.sb1,
      setValue: (value) => handleResponseChange("sb1", value)
    },
    {
      id: "sb2",
      text: "2. The options or choices for each question, when applicable, were clear.",
      value: responses.sb2,
      setValue: (value) => handleResponseChange("sb2", value)
    },
    {
      id: "sb3",
      text: "3. The questions that addressed grammatical points were clear and easy to understand.",
      value: responses.sb3,
      setValue: (value) => handleResponseChange("sb3", value)
    },
    {
      id: "sb4",
      text: "4. The length of the reading passages was reasonable.",
      value: responses.sb4,
      setValue: (value) => handleResponseChange("sb4", value)
    }
  ];

  const surveyQuestionsLC = [
    {
      id: "sc1",
      text: "1. The quality of the audio of the main passages was reasonable.",
      value: responses.sc1,
      setValue: (value) => handleResponseChange("sc1", value)
    },
    {
      id: "sc2",
      text: "2. The questions for each listening passage were clear and easy to understand.",
      value: responses.sc2,
      setValue: (value) => handleResponseChange("sc2", value)
    },
    {
      id: "sc3",
      text: "3. The options or choices for each question, when applicable, were clear.",
      value: responses.sc3,
      setValue: (value) => handleResponseChange("sc3", value)
    },
    {
      id: "sc4",
      text: "4. The questions that addressed grammatical points were clear and easy to understand.",
      value: responses.sc4,
      setValue: (value) => handleResponseChange("sc4", value)
    },
    {
      id: "sc5",
      text: "5. The questions that addressed vocabulary items were clear and easy to understand.",
      value: responses.sc5,
      setValue: (value) => handleResponseChange("sc5", value)
    },
    {
      id: "sc6",
      text: "6. The length of the listening passages was reasonable.",
      value: responses.sc6,
      setValue: (value) => handleResponseChange("sc6", value)
    }
  ];

  const surveyFeedback = [
    {
      id: "sd1",
      text: "1. The feedback offered in the Diagnostic Profile is useful.",
      value: responses.sd1,
      setValue: (value) => handleResponseChange("sd1", value)
    },
    {
      id: "sd2",
      text: "2. The language used in the Diagnostic Profile is clear and easy to understand.",
      value: responses.sd2,
      setValue: (value) => handleResponseChange("sd2", value)
    },
    {
      id: "sd3",
      text: "3. The Performance Report of the Diagnostic Profile accurately reflects my abilities in the language.",
      value: responses.sd3,
      setValue: (value) => handleResponseChange("sd3", value)
    }
  ];

  const surveyOVerall = [
    {
      id: "se1",
      text: "1. The length of the entire ODA session was reasonable.",
      value: responses.se1,
      setValue: (value) => handleResponseChange("se1", value)
    },
    {
      id: "se2",
      text: "2. My experience with the ODA session was positive.",
      value: responses.se2,
      setValue: (value) => handleResponseChange("se2", value)
    }
  ];

  return (
    <div className="adminMainPanel">
      <h2>User Survey Questionnaire (RC/LC)</h2>
      <p>
        Please indicate your response to the following statements and questions. We appreciate your taking a moment to
        complete this questionnaire. Thank you.
      </p>
      <form onSubmit={handleSubmit}>
        <div className="surveyText">
          Language:
          <select key="language" value={selectedLanguage} onChange={handleLanguageChange}>
            <option>Select</option>
            {langDropdown}
          </select>
        </div>

        <h4>Section A - Interface and Instructions</h4>
        <div style={{ backgroundColor: "#e8eeee" }}>
          {surveyQuestionsUI.map((question) => (
            <SurveyQuestion
              key={question.id}
              id={question.id}
              text={question.text}
              value={question.value}
              setValue={question.setValue}
            />
          ))}
        </div>

        <h4>Section B - Reading Assessment</h4>
        <span>
          <i>
            Please skip this section, if you have <u>NOT</u> taken Reading Assessment.
          </i>
        </span>

        <div style={{ backgroundColor: "#e8eeee" }}>
          {surveyQuestionsRC.map((question) => (
            <SurveyQuestion
              key={question.id}
              id={question.id}
              text={question.text}
              value={question.value}
              setValue={question.setValue}
            />
          ))}
        </div>

        <h4>Section C - Listening Assessment</h4>
        <span>
          <i>
            Please skip this section, if you have <u>NOT</u> taken listening Assessment.
          </i>
        </span>

        <div style={{ backgroundColor: "#e8eeee" }}>
          {surveyQuestionsLC.map((question) => (
            <SurveyQuestion
              key={question.id}
              id={question.id}
              text={question.text}
              value={question.value}
              setValue={question.setValue}
            />
          ))}
        </div>

        <h4>Section D - Feedback, Diagnostic Profile</h4>
        <div style={{ backgroundColor: "#e8eeee" }}>
          {surveyFeedback.map((question) => (
            <SurveyQuestion
              key={question.id}
              id={question.id}
              text={question.text}
              value={question.value}
              setValue={question.setValue}
            />
          ))}
        </div>
        <h4>Section E - Overall</h4>
        <div style={{ backgroundColor: "#e8eeee" }}>
          {surveyOVerall.map((question) => (
            <SurveyQuestion
              key={question.id}
              id={question.id}
              text={question.text}
              value={question.value}
              setValue={question.setValue}
            />
          ))}

          <div style={{ margin: "20px 0px 20px 15px" }}>3. Other Comments:</div>
          <textarea className="QSAAnswer surveyComment" onChange={handleCommentsChange} name="se3" />
        </div>
        <div>
          <button className="surveySubmitBtn">Submit Questionnaire</button>
        </div>
      </form>
    </div>
  );
};

export default SurveyFormRCLC;
