import { useState, useEffect, useContext } from "react";
import { AssessmentContext } from "../context/AssessmentProvider";
import { useNavigate } from "react-router-dom";
import JWT from "../contents/JWT";
import useErrorState from "./useErrorState";
import { odaDecrypt } from "../functions/genericFunctions";
import axios from "axios";
import axiosRetry from "axios-retry";

export function useCollectCompleteTestFromDb(currentData) {
  const [allData, setAllData] = useState(null);
  const [completeGR, setCompleteGR] = useState(null);
  const [completeLC, setCompleteLC] = useState(null);
  const [completeRC, setCompleteRC] = useState(null);
  const [data, setData] = useState(null);
  const [assessmentState, dispatch] = useContext(AssessmentContext);
  const [useAES] = useState(process.env.REACT_APP_USE_AES.toLowerCase() === "true" ? true : false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  axiosRetry(axios, {
    retries: 4, // number of retries

    retryDelay: (...arg) => axiosRetry.exponentialDelay(...arg, 1000), // Exponential delay with backoff of 1000ms
             retryCondition: (err) => {return axiosRetry.isNetworkError(err) || err.code === "ECONNABORTED" || err.code === "ENOTFOUND" || err.code === "ETIMEDOUT" || (err.response && err.response.status >= 500 && err.response.status <= 599) }


  });

  useEffect(() => {
    if (currentData === null && !isLoading) {
      setIsLoading(true);
      //GR FETCH
      JWT.fetchData()
        .then((result) => {
          if (result) {
            const userId = { userId: result };
            axios
              .get("api/sessionGR/diagProfileTotal", { params: userId })
              .then((res) => {
                setCompleteGR(res.data);
              })
              .catch((err) => {
                setError(err);
              });
          } else {
            navigate("/");
          }
        })
        .catch((err) => {
          setError(err);
        })
        .finally(() => {});

      //RC FETCH
      JWT.fetchData()
        .then((result) => {
          if (result) {
            const userId = { userId: result };
            axios
              .get("api/sessionRC/diagProfileTopLevelInfo", { params: userId })
              .then((res) => {
                setCompleteRC(res.data);
              })
              .catch((err) => {
                setError(err);
              });
          } else {
            navigate("/");
          }
        })
        .catch((err) => {
          setError(err);
        })
        .finally(() => {});

      //LC FETCH
      JWT.fetchData()
        .then((result) => {
          if (result) {
            const userId = { userId: result };
            axios
              .get("api/sessionLC/diagProfileTopLevelInfo", { params: userId })
              .then((res) => {
                setCompleteLC(res.data);
              })
              .catch((err) => {
                setError(err);
              });
          } else {
            navigate("/");
          }
        })
        .catch((err) => {
          setError(err);
        })
        .finally(() => {});
    }
  }, [navigate, currentData]);

  //collect and combine complete dataset once all are available
  useEffect(() => {
    if (
      completeGR !== undefined &&
      completeGR !== null &&
      completeRC !== undefined &&
      completeRC !== null &&
      completeLC !== undefined &&
      completeLC !== null
    ) {
      let collectCompletes = [];
      collectCompletes["GR"] = completeGR;
      collectCompletes["RC"] = completeRC;
      collectCompletes["LC"] = completeLC;
      collectCompletes["ALL"] = [].concat(collectCompletes["GR"], collectCompletes["RC"], collectCompletes["LC"]);
      setAllData(collectCompletes);
    }
  }, [completeGR, completeLC, completeRC]);

  return [allData, isLoading, error];
}
