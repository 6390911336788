import "../../css/index.css";
import { fadeIn, fadeOut } from "../../functions/genericFunctions";

/**
 * @component RegisterAccountModal
 * @description
 * @param {object} props
 **/

export default function ReadingSurveyModal(props) {
  const afterCreation = (e) => {
    props.handleReadingSurveyModalState(e);
  };

  return (
    <div className="modalDialog" id="RegisterAccountModal">
      <div className="RegisterAccountModal">
        <div
          className="feedbackHeader"
          id="modalHeader"
          style={{ fontSize: "16px" }}
        >
          Reading
        </div>
        <h5
          style={{
            fontSize: "16px",
            fontWeight: "normal",
            overflow: "auto",
            color: "#187680",
          }}
        >
          Your feedback is important to us. Please consider clicking on the
          survey button located in the top right corner to share your thoughts
          with us.
        </h5>

        <button
          className="goLogin outlined"
          style={{
            fontSize: "14px",
            top: "6px",
            padding: "3px 7px",
          }}
          onClick={(e) => afterCreation(e)}
        >
          Close
        </button>
        <br />
        <br />
      </div>
    </div>
  );
}
