import React, { useState, useEffect } from "react";
import axios from "axios";
import AdminFooter from "./adminComponents/AdminFooter";
import KeywordDisplay from "./adminDisplay/KeywordDisplay";

const KeywordPanel = () => {
  const [state, setState] = useState({
    content: [],
    selectedKeyword: "",
    selectedSite: "oda",
    isData: false,
    checkAPI: false,
    firstCheck: false, //check to print out "no keyword found" when the very first word not matching.
  });

  const { selectedKeyword, selectedSite, isData, firstCheck } = state;

  const handleSiteChange = (event) => {
    setState({ ...state, selectedSite: event.target.value });
  };

  const handleKeywordChange = (event) => {
    setState({ ...state, selectedKeyword: event.target.value });
  };
  const getValues = () => {
    const initial = {
      isData: false,
      checkAPI: false,
      firstCheck: false,
    };
    setState({
      ...state,
      ...initial,
    });

    const user = {
      keyword: selectedKeyword,
      site: selectedSite,
    };

    if (selectedKeyword !== "") {
      axios
        .get("/api/adminReport/keywordReport", { params: user })
        .then((res) => {
          setState((prevState) => ({
            ...prevState,
            content: res.data,
            isData: true,
            checkAPI: true,
          }));
          console.log("list=" + JSON.stringify(res.data));
        })
        .catch((error) => {
          setState((prevState) => ({
            ...prevState,
            isData: false,
            firstCheck: true,
          }));
          if (error.response) {
            if (error.response.status >= 400 && error.response.status < 600) {
              // Handle error
            }
          }
        });
    } else {
      console.log("No value can be null");
      alert("Enter keyword to search for");
    }
  };

  return (
    <div className="adminMainPanel">
      <div className="infoTitle adminInfoTitle">
        <div>ODA Keyword Search Panel</div>
      </div>
      <label htmlFor="keyword">
        Enter keyword to search:
        <input id="keyword" onChange={handleKeywordChange} />
      </label>

      <div>
        Site:
        <select key="site" value={selectedSite} onChange={handleSiteChange}>
          <option value="oda">ODA</option>
        </select>
      </div>
      <div className="buttonGroup">
        <div className="searchButton" onClick={getValues}>
          Search Database
        </div>
      </div>
      <div className="adminContentBox" style={{ overflow: "auto" }}>
        <KeywordDisplay
          content={state.content}
          isData={state.isData}
          keyword={state.selectedKeyword}
          checkAPI={state.checkAPI}
          firstCheck={state.firstCheck}
        />
      </div>
      <AdminFooter />
    </div>
  );
};

export default KeywordPanel;
