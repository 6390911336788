import React, { useState, useEffect } from "react";
import axios from "axios";
import AdminFooter from "./adminComponents/AdminFooter";
import ODATestletInventoryDisplay from "./adminDisplay/ODATestletInventoryDisplay";

const ODATestletInventory = () => {
  const initialState = {
    content: [],
    language: [],
    // languageGR: [],
    selectedLanguage: "",
    selectedModality: "",
    selectedSite: "",
    selectedLevel: "all",
  };

  const [state, setState] = useState(initialState);

  const {
    language,
    // languageGR,
    selectedLanguage,
    selectedModality,
    selectedSite,
    selectedLevel,
  } = state;

  useEffect(() => {
    if (selectedModality === "reading") {
      axios
        .get('/api/language?modalities="reading"&_sort={"sequence": 1}')
        .then((res) => {
          setState({ ...state, language: res.data });
        });
    } else if (selectedModality === "listening") {
      axios
        .get('/api/language?modalities="listening"&_sort={"sequence": 1}')
        .then((res) => {
          setState({ ...state, language: res.data });
        });
    } else if (selectedModality === "grammar") {
      axios
        .get('/api/language?modalities="grammar"&_sort={"sequence": 1}')
        .then((res) => {
          setState({ ...state, language: res.data });
        });
    }
  }, [selectedModality]);

  let langDropdown = language.map((item, index) => (
    <option key={index} value={item._id}>
      {item.displayName}
    </option>
  ));

  const handleLanguageChange = (event) => {
    setState({ ...state, selectedLanguage: event.target.value });
  };

  const handleModalityChange = (event) => {
    const initial = {
      selectedLanguage: "",
      selectedSite: "",
      content: [],
      selectedLevel: "all",
    };
    setState({
      ...state,
      selectedModality: event.target.value,
      ...initial,
    });
  };

  const handleSiteChange = (event) => {
    setState({ ...state, selectedSite: event.target.value });
  };

  const handleLevelChange = (event) => {
    setState({ ...state, selectedLevel: event.target.value });
  };

  const getValues = () => {
    const user = {
      site: selectedSite,
      lang: selectedLanguage,
      mod: selectedModality,
      lvl: selectedLevel,
    };

    console.log("level=", selectedLevel);

    const valuesAreValid =
      selectedLanguage !== "Select" &&
      selectedModality !== "Select" &&
      selectedSite !== "Select" &&
      selectedLevel !== "Select" &&
      selectedLanguage !== "" &&
      selectedModality !== "" &&
      selectedSite !== "" &&
      selectedLevel !== "";

    if (valuesAreValid) {
      axios
        .get("/api/adminReport/testletInventory", { params: user })
        .then((res) => {
          setState({ ...state, content: res.data });
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.status >= 400 && error.response.status < 600) {
            }
          }
        });
    } else {
      console.log("No value can be null");
      alert("Select all items");
    }
  };

  const resetValues = () => {
    setState(initialState);
  };

  return (
    <div className="adminMainPanel">
      <div className="infoTitle adminInfoTitle">
        <div>ODA Testlet Inventory</div>
      </div>
      <div>
        Modality:
        <select
          key="modality"
          value={selectedModality}
          onChange={handleModalityChange}
        >
          <option>Select</option>
          <option value="reading">Reading</option>
          <option value="listening">Listening</option>
          <option value="grammar">Grammar</option>
        </select>
      </div>
      <div>
        Language:
        <select
          key="language"
          value={selectedLanguage}
          onChange={handleLanguageChange}
        >
          <option>Select</option>
          {langDropdown}
        </select>
      </div>

      <div>
        Site:
        <select key="site" value={selectedSite} onChange={handleSiteChange}>
          <option>Select</option>
          <option value="oda">ODA</option>
        </select>
      </div>

      {selectedModality === "reading" || selectedModality === "listening" ? (
        <div>
          Proficiency Level:
          <select
            key="level"
            value={selectedLevel}
            onChange={handleLevelChange}
          >
            {selectedLanguage === "eng" ? (
              <>
                <option value="all">All</option>
                <option value="1+">1+</option>
                <option value="2">2</option>
                <option value="2+">2+</option>
                <option value="3">3</option>
              </>
            ) : (
              <>
                <option value="all">All</option>
                <option value="1">1</option>
                <option value="1+">1+</option>
                <option value="2">2</option>
                <option value="2+">2+</option>
                <option value="3">3</option>
              </>
            )}
          </select>
        </div>
      ) : selectedModality === "grammar" ? (
        <div>
          Proficiency Level:
          <select
            key="level"
            value={selectedLevel}
            onChange={handleLevelChange}
          >
            {selectedLanguage === "eng" ? (
              <>
                <option value="all">All</option>
                <option value="1+/2">1+/2</option>
                <option value="2+/3">2+/3</option>
              </>
            ) : selectedLanguage === "rus" ? (
              <>
                <option value="all">All</option>
                <option value="1">1</option>
                <option value="1+/2">1+/2</option>
              </>
            ) : (
              <>
                <option value="all">All</option>
                <option value="1">1</option>
                <option value="1+/2">1+/2</option>
                <option value="2+/3">2+/3</option>
              </>
            )}
          </select>
        </div>
      ) : (
        <div>
          Proficiency Level:
          <select
            key="level"
            value={selectedLevel}
            onChange={handleLevelChange}
          >
            <option value="all">All</option>
          </select>
        </div>
      )}
      <div className="buttonGroup">
        <div onClick={getValues} className="searchButton">
          Get Testlet
        </div>
        <div onClick={resetValues} className="searchButton">
          Reset Choices
        </div>
      </div>
      <div className="adminContentBox" style={{ overflow: "auto" }}>
        <ODATestletInventoryDisplay
          content={state.content}
          mod={state.selectedModality}
        />
      </div>
      <AdminFooter />
    </div>
  );
};

export default ODATestletInventory;
