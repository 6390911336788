import React, { useRef, useState, useEffect } from "react";
import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";
import { IoPlay } from "react-icons/io5";
import { IoPause } from "react-icons/io5";
import { GiSpeaker } from "react-icons/gi";
import { IoIosRewind } from "react-icons/io";
import { IoMdFastforward } from "react-icons/io";

export default function PassageAnswerField(props) {
  const lang = props.lang;
  const LO = props.actID;
  const num = props.optionNumber;
  const answerFieldsID = props.answerFieldsID;
  const testitem = props.testitem;
  const audioPlayerAns = useRef(null);
  const number = num + 1;
  const passageTitle = "Passage " + number;
  const passageLen = props.optionLen;

  const actID_fst = answerFieldsID + "_0";
  const actID_snd = answerFieldsID + "_1";

  let clickCounter = props.clickCounter;
  if (clickCounter === 0) {
    document.querySelectorAll(".fstAttempt").forEach(function (event) {
      event.style.display = "inline";
    });
    document.querySelectorAll(".sndAttempt").forEach(function (event) {
      event.style.display = "none";
    });
  } else {
    document.querySelectorAll(".fstAttempt").forEach(function (event) {
      event.style.display = "none";
    });
    document.querySelectorAll(".sndAttempt").forEach(function (event) {
      event.style.display = "inline";
    });
  }

  const createAudioPassage = () => {
    let main = [];
    let audioTitle = "Audio Passage " + number;
    main.push(
      <div key={number} id={number} onClick={props.onPlayMainAudio}>
        <div className="audPassage">{audioTitle} </div>
      </div>
    );
    return main;
  };

  const questionText = <span>Assessment Item {number}</span>;
  const questionItem = props.optionPassage.map((options, index) => {
    let items = [];
    let mp3 = options.substr(-4);
    if (mp3 === ".mp3" && index === passageLen - 1) {
      let fn = "audio/" + lang + "/" + LO + "/" + options;
      items.push(
        // <span key={fn} className="inlinePlay paused" file={fn}>
        //   <span className="modifiedFlag">{questionText}</span>
        // </span>
        <div className="audioQ ">
          <AudioPlayer
            autoPlay={false}
            autoPlayAfterSrcChange={false}
            header={questionText}
            footer={null}
            hasDefaultKeyBindings={false}
            customAdditionalControls={[]}
            customVolumeControls={[]}
            ref={audioPlayerAns["inline-" + index]}
            src={fn}
            showJumpControls={false}
            showDownloadProgress={false}
            showFilledProgress={false}
            customIcons={{
              play: <IoPlay />,
              pause: <IoPause />,
              rewind: <IoIosRewind />,
              forward: <IoMdFastforward />,
              volume: <GiSpeaker />,
            }}
            // onPlay={(e) => console.log("onPlay")}
          />
        </div>
      );
    }
    return items;
  });

  const createAnswerField = () => {
    let field = "field" + num;
    let txtField = [];
    txtField.push(
      <div className="" key={field}>
        <form autoComplete="off">
          <div className="answerSection fstAttempt">
            <label htmlFor="userInputQSA">
              Your Answer
              <textarea
                autoComplete="off"
                className="QSAAnswer"
                name={answerFieldsID}
                data-testitem={testitem}
                id={actID_fst}
                onBlur={props.onLoseFocusCheckNLP}
                onChange={props.onAnswerSelected}
                spellCheck={true}
                onPaste={(e) => {
                  e.preventDefault();
                  return false;
                }}
                onCopy={(e) => {
                  e.preventDefault();
                  return false;
                }}
                onCut={(e) => {
                  e.preventDefault();
                  return false;
                }}
              />
            </label>
          </div>
          <div className="answerSection sndAttempt">
            <label htmlFor="userInputQSA">
              Your Answer
              <textarea
                autoComplete="off"
                style={{ border: "1px solid #73b1da" }}
                className="QSAAnswer"
                name={answerFieldsID}
                data-testitem={testitem}
                id={actID_snd}
                onBlur={props.onLoseFocusCheckNLP}
                onChange={props.onAnswerSelectedMod}
                spellCheck={true}
                onPaste={(e) => {
                  e.preventDefault();
                  return false;
                }}
                onCopy={(e) => {
                  e.preventDefault();
                  return false;
                }}
                onCut={(e) => {
                  e.preventDefault();
                  return false;
                }}
              />
            </label>
          </div>
        </form>
      </div>
    );
    return txtField;
  };

  return (
    <div>
      <br /> <br />
      <h2>{passageTitle}</h2>
      <h3>
        <div className="GRLC_content">
          <div className="GRLC_item">{createAudioPassage()}</div>
          <div className="GRLC_item secondItem">{questionItem}</div>
        </div>
      </h3>
      {createAnswerField()}
    </div>
  );
}
