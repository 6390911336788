import React from "react";
import FontSize from "../../../../../components/fontsize/FontSize";

export default function Passage(props) {
  const RTL = props.dir;
  const dir = RTL ? "dir" : "";
  const len = props.answerLength;

  const curPassage = props?.passage !== null ? props.passage : "";
  let newPassage = curPassage.replace(/<u>/gi, '<span class="textHighlight">');
  newPassage = newPassage.replace(/<\/u>/gi, "</span>");
  newPassage = newPassage
    .replace(/\r\n/g, "<br />")
    .replace(/\n\r/g, "<br />")
    .replace(/\r/g, "<br />")
    .replace(/\n/g, "<br />")
    .replace(/<br \/><br \/>$/g, "")
    .replace(/<br \/><br \/>\s*$/g, "")
    .replace(/\s*$/g, "")
    .replace(/<p><\/p>$/g, "")
    .replace(/<p>\s*<\/p>$/g, "");
  newPassage = newPassage
    .replace(/<P>/g, "")
    .replace(/<p>/g, "")
    .replace(/<\/P>/g, "<br /><br />")
    .replace(/<\/p>/g, "<br /><br />")
    .replace(/(<br \/>\s*){3,4}/g, "<br /><br />").replace(/^(<br \/>\s*){2,4}/g,"");

  /* Please rewrite the following scripts in a better way (with regEx?) */
  let search = /<span class="textHighlight">/g,
    str = newPassage;
  let indexes = [];
  let match = "";
  while ((match = search.exec(str)) != null) {
    indexes.push(match.index);
  }

  let i = len;
  while (i > 0) {
    if (i === len) {
      var targetPassage = newPassage;
      var numbering = '<span class="textHighlight"><span class="paragraphNumber">' + i + ". </span>";
      var position = indexes[i - 1];
      newPassage = [targetPassage.slice(0, position), numbering, targetPassage.slice(position)].join("");
    }
    if (i === len - 1) {
      targetPassage = newPassage;
      numbering = '<span class="textHighlight"><span class="paragraphNumber">' + i + ". </span>";
      position = indexes[i - 1];
      newPassage = [targetPassage.slice(0, position), numbering, targetPassage.slice(position)].join("");
    }

    if (i === len - 2) {
      targetPassage = newPassage;
      numbering = '<span class="textHighlight"><span class="paragraphNumber">' + i + ". </span>";
      position = indexes[i - 1];
      newPassage = [targetPassage.slice(0, position), numbering, targetPassage.slice(position)].join("");
    }
    i--;
  }

  return (
    <div className={[dir, "textPassage noSelect"].join(" ")}>
      <FontSize />
      <div
        dangerouslySetInnerHTML={{
          __html: newPassage
        }}
      />
    </div>
  );
}
