import { useNavigate } from "react-router-dom";

const FAQLinks = () => {
    //** Helper Links for FAQLinks  **/
  const navigate = useNavigate();
  const sendToPage = (url) => {
    navigate(url);
  };

  return (
    <div className="faqLinks">
      <div>
        <a onClick={() => sendToPage("/testdescriptions")} className="infoLink">
          What to expect
        </a>
      </div>
      <div>
        <a onClick={() => sendToPage("/proficiency")} className="infoLink">
          Understanding proficiency
        </a>
      </div>
      <div>
        <a onClick={() => sendToPage("/faqs")} className="infoLink">
          FAQs
        </a>
      </div>
    </div>
  );
};

export default FAQLinks;
