import React from "react";
import PassageAnswerField from "../test/PassageAnswerField";
import Next from "../../../../../components/testlet/Next2";
import Audio from "../../../../../components/testlet/Audio";

export default function Test(props) {
  //console.log(props);
  const renderAssessment = (options, index) => {
    const passages = options.questionTexts;
    const item = options._id;

    return (
      <PassageAnswerField
        key={index}
        optionPassage={passages}
        testitem={item}
        optionNumber={index}
        actID={props.actID}
        answerFieldsID={options.answerFields[0]._id}
        lang={props.lang}
        dir={props.dir}
        clickCounter={props.clickCounter}
        onLoseFocusCheckNLP={props.onLoseFocusCheckNLP}
        onAnswerEnSelected={props.onAnswerEnSelected}
        onAnswerEnSelectedMod={props.onAnswerEnSelectedMod}
        invalidEnInput={props.invalidEnInput}
        onAnswerTlSelected={props.onAnswerTlSelected}
        onAnswerTlSelectedMod={props.onAnswerTlSelectedMod}
        onPlayMainAudio={props.onPlayMainAudio}
      />
    );
  };

  return (
    <div className="templateInner GLSB" key={props.anchorKey}>
      <Audio
        key={props.audKey}
        audioFN1={props.audioFile1}
        audioFN2={props.audioFile2}
        audioFN3={props.audioFile3}
        instruction={props.instruction}
        audSequence={props.audSequence}
        lang={props.lang}
        actID={props.actID}
      />
      <div className="passageDiv">{props.assessment.map(renderAssessment)}</div>
      <Next
        onEvaluation={props.onEvaluation}
        scoreEN={props.scoreEN}
        scoreTL={props.scoreTL}
        hint={props.hint}
        clickCounter={props.clickCounter}
        navLen={props.navLen}
        navCrrtItem={props.navCrrtItem}
      />
    </div>
  );
}
