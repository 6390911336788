/**
 * function to analyze current module reference"s pass or fail rating, user floor level, and the score matrix to determine next module to route user to, if any
 * valid only for reading and listening asessements as grammar does not have the up/down shifting
 * @param {string} moduleReference
 * @param {boolean} boolPassedNotFailed
 * @param {integer} userSessFloor
 * @param {array} scoreMatrix
 * @returns {array} [{boolean} testCompleteBoolean, {string} nextModuleReference, {integer} userSkillFloor]
 * @author drew
 */
export function attemptODAModuleRouting(
  moduleReference, //current module reference
  boolPassedNotFailed, //pass or fail boolean for the current module
  userSessFloor, // users current floor ILR
  scoreMatrix // score matrix
) {
  //declare constants
  const mappedLevels = ["L1", "L1P", "L2", "L2P", "L3"];

  //validate inputs
  if (typeof moduleReference === "string" || typeof moduleReference === String) {
    //regex check here for Ref.XXX.XXX
  } else {
    throw new Error("Malformed module reference string.");
  }

  if (typeof boolPassedNotFailed === "boolean" || typeof moduleReference === Boolean) {
    //validated, continue
  } else {
    throw new Error("Malformed pass/fail boolean.");
  }

  if (
    (typeof userSessFloor === "number" || typeof userSessFloor === Number) &&
    (userSessFloor === -999 || (userSessFloor >= 0 && userSessFloor <= 4))
  ) {
    //validated, continue
  } else {
    throw new Error("Malformed ILR skill level.");
  }

  if (typeof scoreMatrix === "object" || typeof scoreMatrix === Object) {
    for (let f = 0; f <= 4; f++) {
      for (let g = 0; g <= 1; g++) {
        if (typeof scoreMatrix[mappedLevels[f]][g] === "number" || typeof scoreMatrix[mappedLevels[f]][g] === Number) {
          if (
            scoreMatrix[mappedLevels[f]][g] === -999 ||
            (scoreMatrix[mappedLevels[f]][g] >= 0 && scoreMatrix[mappedLevels[f]][g] <= 100)
          ) {
            // was <= 4 for some reason, changed to 100
            //validated, continue
          } else {
            throw new Error("Malformed score matrix array error 3.");
          }
        } else {
          throw new Error("Malformed score matrix array error 1.");
        }
      }
    }
  } else {
    throw new Error("Malformed score matrix array error 2.");
  }

  // example: Ref.L2P.M1
  let referenceSplit = moduleReference.split("."); //split the reference

  //prepare new reference string
  let newReference = null;

  // get the currentILR index
  const findKeys = (obj, val) => Object.keys(obj).filter((key) => obj[key] === val);
  let indexCurrSessILR = parseInt(findKeys(mappedLevels, referenceSplit[1]));

  //handle edgeCases
  //edgeCase passedL3
  if (referenceSplit[1] === "L3" && boolPassedNotFailed) {
    //Session Complete, proceed to ODA session output
    //diagnostic at limit, user is L3
    return [true, null, 4];
  }
  //edgeCase failedL1
  if (referenceSplit[1] === "L1" && !boolPassedNotFailed) {
    if (referenceSplit[2] === "M1") {
      //proceed to Ref.L1.M2 if on Ref.L1.M1
      return [false, "Ref.L1.M2", -999];
    } else if (referenceSplit[2] === "M2" && !boolPassedNotFailed) {
      //Session Complete, proceed to ODA session output if on Ref.L1.M2 already
      //diagnostic inconclusive, user is currently below L1, target level is L1
      return [true, null, -999];
    }
  }

  //logic to handle bulk of cases (non-edge case)
  //PASSED MODULE 1, L1-L2+ (excludes L3 edge case)
  if (referenceSplit[1] !== "L3" && referenceSplit[2] === "M1" && boolPassedNotFailed) {
    //determine next module/set to send user to (ILR+1.M1, unless M1 has a score, then M2)

    //set the current session floor to passed ILR
    userSessFloor = indexCurrSessILR;

    //increase ILR for passing module if possible
    indexCurrSessILR++;

    // check for score at next reference module/set
    if (scoreMatrix[mappedLevels[indexCurrSessILR]][0] === -999) {
      //user has not yet attempted ILR+1 Module1, go there.
      //Ref.L2.M1 => Ref.L2P.M1
      newReference = "Ref." + mappedLevels[indexCurrSessILR] + ".M1";
      return [false, newReference, userSessFloor];
    } else if (scoreMatrix[mappedLevels[indexCurrSessILR]][0] !== -999) {
      //user has already attempted ILR+1 Module1, so build reference for M2
      newReference = "Ref." + mappedLevels[indexCurrSessILR] + ".M2";
      return [false, newReference, userSessFloor];
    }
    //FAILED MODULE 1, L1+-L3
  } else if (referenceSplit[1] !== "L1" && referenceSplit[2] === "M1" && !boolPassedNotFailed) {
    //if no floor has been determine, automatically drop down one level if M1 is failedL1
    if (userSessFloor === -999) {
      //move the user ILR down one as no floor is yet established
      indexCurrSessILR--;

      //build a new reference for the next module
      newReference = "Ref." + mappedLevels[indexCurrSessILR] + ".M1";
      let referenceSplitNew = newReference.split("."); //split the reference

      //ensure the score is -999 at new reference, ie never tested this module
      if (scoreMatrix[referenceSplitNew[1]][0] === -999) {
        //send user to new reference at ILR -1, M1
        return [false, newReference, userSessFloor];
      } else {
        //this should never happen, but send to M2 if it does... add DEBUG message because this should not trigger
        //send a debug email here
        //send user to new reference at ILR-1, M2
        newReference = "Ref." + mappedLevels[indexCurrSessILR] + ".M2";
        return [false, newReference, userSessFloor];
      }
      //if a floor has been established, then do not automatically drop user down level, instead continue to module B in current ILR
    } else if (userSessFloor !== -999) {
      //keep the user on the current ILR and send them to reference module2, assuming no score has been set there yet
      if (scoreMatrix[referenceSplit[1]][1] === -999) {
        newReference = "Ref." + mappedLevels[indexCurrSessILR] + ".M2";
        return [false, newReference, userSessFloor];
      } else {
        //otherwise end test
        return [true, null, userSessFloor];
      }
    }

    //COMPLETED MODULE2 ON ILR
  } else if (referenceSplit[2] === "M2") {
    //pass fail here is now determined on the score of (ILR.M1 + ILR.M2), not just M2
    if (!boolPassedNotFailed && userSessFloor === -999) {
      //IF the floor has not yet been set, drop the ILR level
      indexCurrSessILR--;
      //ensure the score is -999 at new reference
      if (scoreMatrix[referenceSplit[1]][0] === -999) {
        //send user to new reference at ILR -1, M1
        newReference = "Ref." + mappedLevels[indexCurrSessILR] + ".M1";
        return [false, newReference, userSessFloor];
      } else {
        //send user to the new reference at ILR -1, M2... (double check if this can even happen)
        newReference = "Ref." + mappedLevels[indexCurrSessILR] + ".M2";
        return [false, newReference, userSessFloor];
      }
      //user has failed two consecutive probing level ILR modules in a row, assessment is complete
    } else if (!boolPassedNotFailed && userSessFloor !== -999) {
      //target level is current ILR
      //current level is userSessFloor
      return [true, null, userSessFloor];
      //user is NOT hitting edge case (L3), and has passed ILR (Module1+Module2), send them to the next ILR, either M1 or M2, whichever has no score
    } else if (boolPassedNotFailed && referenceSplit[1] !== "L3") {
      //set floor current ILR
      userSessFloor = indexCurrSessILR;

      //move to the next ILR
      indexCurrSessILR++;

      // check for score at next reference module/set
      newReference = "Ref." + mappedLevels[indexCurrSessILR];
      let referenceSplitNew = newReference.split("."); //split the reference
      if (scoreMatrix[referenceSplitNew[1]][0] === -999) {
        //user has not yet attempted ILR+1 Module1, go there.
        newReference = "Ref." + mappedLevels[indexCurrSessILR] + ".M1";
        return [false, newReference, userSessFloor];
      } else if (scoreMatrix[referenceSplitNew[1]][1] === -999) {
        //user has already attempted ILR+1 Module1, so build reference for M2
        newReference = "Ref." + mappedLevels[indexCurrSessILR] + ".M2";
        return [false, newReference, userSessFloor];
      }
    }
  }
}
