import React, { useState } from "react";

const DataTypesSearchDisplay = (props) => {
  const data = props.content;
  const isData = props.isData;
  const checkAPI = props.checkAPI;
  const firstCheck = props.firstCheck;
  console.log("data=" + JSON.stringify(data));
  const totalCount =
    data.find((item) => item.totalTestlets)?.totalTestlets || 0;

  const average = Math.round(100 / totalCount);

  // Item, Times, Percentage
  const [sortBy, setSortBy] = useState(""); // State to keep track of the current sorting column
  const [sortOrder, setSortOrder] = useState("asc"); // State to keep track of the sorting order (ascending or descending)

  // Sort the data based on the selected column and sorting order
  const sortedDataFisrtTable = [...data.slice(0, -2)].sort((a, b) => {
    if (sortBy === "item") {
      if (sortOrder === "asc") {
        return a._id - b._id;
      } else {
        return b._id - a._id;
      }
    } else if (sortBy === "times") {
      if (sortOrder === "asc") {
        return a.count - b.count;
      } else {
        return b.count - a.count;
      }
    } else if (sortBy === "percent") {
      if (sortOrder === "asc") {
        return parseFloat(a.percent) - parseFloat(b.percent);
      } else {
        return parseFloat(b.percent) - parseFloat(a.percent);
      }
    }
    return 0;
  });

  // Function to handle sorting when a column header is clicked
  const sortFisrtTable = (column) => {
    if (sortBy === column) {
      // If the same column is clicked again, toggle the sorting order
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      // If a new column is clicked, set the new sorting column and default to ascending order
      setSortBy(column);
      setSortOrder("asc");
    }
  };

  //Sort by Testlet and Data Type
  const [sortColumn, setSortColumn] = useState(null);
  const [sortDirection, setSortDirection] = useState(1);

  const sortSecondTable = (column) => {
    if (sortColumn === column) {
      setSortDirection(-sortDirection);
    } else {
      setSortColumn(column);
      setSortDirection(1);
    }
  };

  let sortedDataSecondTable = "";
  if (isData) {
    sortedDataSecondTable = [...data[data.length - 1].testletTable].sort(
      (a, b) => {
        if (sortColumn === "testlet") {
          return a.testlet.localeCompare(b.testlet) * sortDirection;
        } else if (sortColumn === "data") {
          return (a.data - b.data) * sortDirection;
        }
        return 0;
      }
    );
  }

  if ((!isData && checkAPI) || (!isData && !checkAPI && firstCheck)) {
    return <h3>No data was found</h3>;
  }

  if (isData && totalCount === 0) {
    return <h3>No data found</h3>;
  }

  if (isData && totalCount !== 0) {
    return (
      <div style={{ textAlign: "left" }}>
        <h4>
          {totalCount} Item{totalCount !== 1 ? "s" : ""} Found
        </h4>

        <div className="table-container">
          <div className="table-row table-header">
            <div
              className="table-column"
              onClick={() => sortFisrtTable("item")}
            >
              Item
            </div>
            <div
              className="table-column"
              onClick={() => sortFisrtTable("times")}
            >
              Times
            </div>
            <div
              className="table-column"
              onClick={() => sortFisrtTable("percent")}
            >
              %
            </div>
          </div>
          {sortedDataFisrtTable.map((item, index) => (
            <div className="table-row" key={item._id}>
              {typeof item._id === "number" ? (
                <div className="table-column">{item._id}</div>
              ) : item._id !== null && item._id.includes("@") ? (
                <div className="table-column">
                  {item._id.split("@")[0]}
                  <br />@{item._id.split("@")[1]}{" "}
                </div>
              ) : (
                <div className="table-column">{item._id}</div>
              )}
              {/* <div className="table-column">{item._id}</div> */}
              <div className="table-column">{item.count}</div>
              <div className="table-column">{item.percent}</div>
            </div>
          ))}
          <div className="table-row">
            <div
              className="table-column"
              style={{ fontWeight: "bold", borderRight: "0px solid #ccc" }}
            >
              Average
            </div>
            <div
              className="table-column"
              style={{ borderLeft: "0px solid #ccc" }}
            ></div>
            <div
              className="table-column"
              style={{ fontWeight: "bold" }}
            >{`${average}%`}</div>
          </div>
        </div>
        <div className="table-container">
          <div className="table-row table-header">
            <div
              className="table-column"
              onClick={() => sortSecondTable("testlet")}
              style={{ cursor: "pointer" }}
            >
              Testlet
            </div>
            <div
              className="table-column"
              onClick={() => sortSecondTable("data")}
              style={{ cursor: "pointer" }}
            >
              Data Type
            </div>
          </div>
          {sortedDataSecondTable.map((item) => (
            <div className="table-row" key={item.testlet}>
              <div className="table-column">{item.testlet}</div>
              {/* <div className="table-column">{item.data}</div> */}
              {typeof item.data === "number" ? (
                <div className="table-column">{item.data}</div>
              ) : item.data !== null && item.data.includes("@") ? (
                <div className="table-column">
                  {item.data.split("@")[0]}
                  <br />@{item.data.split("@")[1]}{" "}
                </div>
              ) : (
                <div className="table-column">{item.data}</div>
              )}
            </div>
          ))}
        </div>
      </div>
    );
  }
};

export default DataTypesSearchDisplay;
