import { useState, useCallback, useContext, useEffect } from "react";
import { AssessmentContext } from "../../context/AssessmentProvider";
import { ErrorContext } from "../../context/ErrorProvider";
import axios from "axios";
import axiosRetry from "axios-retry";
import moveImg from "../../img/icons/move.svg";
import "../../css/index.css";
import draggableFunctions from "../../functions/draggableFunctions";

/**
 * @component RequestDataModal
 * @description This component creates a Request Account Data modal that pops up when called,
 * and allows the user to confirm request of their account data.
 * @param {object} props
 **/

export default function RequestDataModal(props) {
  useEffect(() => {
    draggableFunctions();
  }, []);

  const [userIssue, setUserIssue] = useState(null);
  const [autoData] = useState(props);
  const [, errorDispatch] = useContext(ErrorContext);
  const [emailSent, setEmailSent] = useState(false);
  const [issueType, setIssueType] = useState(null);
  let randomTicketNumber = Math.floor(Math.random() * 10000000);
  axiosRetry(axios, {
    retries: 4, // number of retries

    retryDelay: (...arg) => axiosRetry.exponentialDelay(...arg, 1000), // Exponential delay with backoff of 1000mst
            retryCondition: (err) => {return axiosRetry.isNetworkError(err) || err.code === "ECONNABORTED" || err.code === "ENOTFOUND" || err.code === "ETIMEDOUT" || (err.response && err.response.status >= 500 && err.response.status <= 599) }


  });
  const handleIssueType = (e) => {
    setIssueType(e.target.value);
  };

  //function to generate an issue report from the user's report problem model
  const generateReport = useCallback(
    (e) => {
      //setup some metatext (date) for optional display
      let today = new Date();
      today = today + "";

      try {
        //send email data object to API
        axios
          .post("/api/emailDevelopersRequestData", {
            autoData,
            randomTicketNumber,
            today
          })
          .catch((error) => {
            if (error.response) {
              if (error.response.status >= 400 && error.response.status < 600) {
                //global error dispatch call to show error modal if error received during api call
                errorDispatch({
                  type: "UPDATE_ERROR_STATE",
                  payload: {
                    errorStatusCode: error.response.status,
                    errorUserMessage: "New Error",
                    errorDevData: error.response
                  }
                });
              }
            }
            //console.log("Failed to submit report via axios/email.");
          });
        //close the modal
        //autoData.refModalState();
        //show "email sent" modal and hide "issue report modal"
        autoData.refModalStateConfirm(e);
        setEmailSent(true);
      } catch (error) {
        if (error.response) {
          if (error.response.status >= 400 && error.response.status < 600) {
            //global error dispatch call to show error modal if error received during api call
            errorDispatch({
              type: "UPDATE_ERROR_STATE",
              payload: {
                errorStatusCode: error.response.status,
                errorUserMessage: "New Error",
                errorDevData: error.response
              }
            });
          }
        }
        //console.log("Axios try block caught error.");
      }
    },
    [autoData, userIssue, randomTicketNumber, emailSent, errorDispatch, issueType]
  );

  return (
    <div className="modalDialog" id="modalDialog">
      <div>
        <div className="feedbackHeader" id="modalHeader">
          Request Account Data
          <img src={moveImg} alt="Move" id="move" draggable="false" />
        </div>
        <div className="feedbackBody">
          <h4>Request Your Data </h4>
          <h5>
            Please confirm your data access request by clicking OK below.
            <br />
            <br />
            An ODA developer will retrieve your data as soon as possible upon receipt of your request.{" "}
          </h5>
        </div>
        <button className="outlined margin5" onClick={generateReport}>
          OK
        </button>
        <button className="outlined margin5" onClick={(e) => props.refModalState(e)}>
          Cancel
        </button>

        <br />
        <br />
        <br />
      </div>
    </div>
  );
}
