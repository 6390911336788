import "../../css/index.css";
/**
 * @component RegisterAccountModal
 * @description
 * @param {object} props
 **/

export default function FeedbackModal(props) {
  const afterCreation = (e) => {
    props.handleFeedbackModalState(e);
  };

  return (
    <div className="modalDialog" id="RegisterAccountModal">
      <div className="RegisterAccountModal">
        <div
          className="feedbackHeader"
          id="modalHeader"
          style={{ fontSize: "16px" }}
        >
          Feedback
        </div>
        <h5
          style={{
            fontSize: "16px",
            fontWeight: "normal",
            overflow: "auto",
          }}
        >
          The Diagnostic Profile provides personalized feedback, taking into
          account your abilities across various levels, topics, tasks, and
          specific language features.
          <br />
          <br />
          The objective is to progressively advance toward your target
          proficiency level by gaining a deeper understanding of the content
          areas, as well as the lexical, syntactical, and discourse aspects that
          you have yet to master.
        </h5>

        <button
          className="goLogin outlined"
          style={{
            fontSize: "16px",
            top: "6px",
            padding: "3px 7px",
          }}
          onClick={(e) => afterCreation(e)}
        >
          Close
        </button>
        <br />
        <br />
      </div>
    </div>
  );
}
