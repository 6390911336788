import React, { useState, useContext, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import secureLocalStorage from "react-secure-storage";
import AuthAdmin from "./AuthAdmin";
import AdminLogin from "./admin/AdminLogin";
import JWT from "../contents/JWT";
import { ErrorContext } from "../context/ErrorProvider";
import {Helmet} from "react-helmet";

const AdminHome = (props) => {
  const navigate = useNavigate();
  const [modalErrorState, errorDispatch] = useContext(ErrorContext);

  const handleSuccessfulLogIn = useCallback(
    (data) => {
      const adminToken = localStorage.getItem("admintoken");
      let content = "";
      let role = "";
      let adminPanels = "";
      let adminuser = "";
      let email = "";

      JWT.fetchData()
        .then((result) => {
          if (result) {
            axios
              .get("/api/user/" + result)
              .then((res) => {
                content = res.data;
                role = content.roles;
                adminPanels = content.adminPanels;
                email = content.email;
                adminuser = res.data;

                if (role != "" || adminPanels != "") {
                  secureLocalStorage.setItem("adminuser", {
                    content: adminuser,
                  });
                }

                if (
                  result &&
                  (role != "" || adminPanels != "") &&
                  adminToken != null
                ) {
                  const nameObj = JSON.parse(window.atob(adminToken));
                  if (result === nameObj.value) {
                    //JWT ID matching with ID from adminToken
                    AuthAdmin.login(() => {
                      navigate("/adminreport", {
                        state: {
                          content: content,
                        },
                      });
                    });
                  } else {
                    //JWT ID NOT matching with ID from adminToken
                    AuthAdmin.login(() => {
                      navigate("/entercode", {
                        state: {
                          email: email,
                        },
                      });
                    });
                  }
                } else if (
                  result &&
                  (role != "" || adminPanels != "") &&
                  adminToken === null
                ) {
                  // First time login with access privilege || adminToken for acess privilege expired
                  AuthAdmin.login(() => {
                    navigate("/entercode", {
                      state: {
                        email: email,
                      },
                    });
                  });
                } else {
                  alert("Check your admin authorization with your supervisor");
                }
              })
              .catch((error) => {
                if (error.response) {
                  if (
                    error.response.status >= 400 &&
                    error.response.status < 600
                  ) {
                    //global error dispatch call to show error modal if error received during api call
                    errorDispatch({
                      type: "UPDATE_ERROR_STATE",
                      payload: {
                        errorStatusCode: error.response.status,
                        errorUserMessage: "New Error",
                        errorDevData: error.response,
                      },
                    });
                  }
                }
              });
          }
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.status >= 400 && error.response.status < 600) {
              errorDispatch({
                type: "UPDATE_ERROR_STATE",
                payload: {
                  errorStatusCode: error.response.status,
                  errorUserMessage: "New Error",
                  errorDevData: error.response,
                },
              });
            }
            console.log("error");
          }
        });
    },
    [navigate, errorDispatch]
  );
  return (
    <div>
      <div className="centerWrap">
        <Helmet>
          <meta name="robots" content="noindex,nofollow" />
        </Helmet>
        <AdminLogin handleSuccessfulLogIn={handleSuccessfulLogIn} />
      </div>
    </div>
  );
};

export default AdminHome;
