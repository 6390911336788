import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  ResponsiveContainer,
  Cell,
  LabelList,
} from "recharts";

const GroupDPDisplayCompareGR = () => {
  const [items, setItems] = useState([]);
  const [itemDetails, setItemDetails] = useState(null);
  const [mod, setMod] = useState("");
  const [getAll, setGetAll] = useState(null);

  useEffect(() => {
    const storedItems = localStorage.getItem("selectedItemsGR");
    if (storedItems) {
      const parsedItems = JSON.parse(storedItems);
      setItems(parsedItems);
      setMod(parsedItems[0].mod);
      localStorage.removeItem("selectedItems");
    }
  }, []);

  useEffect(() => {
    if (mod && items.length > 0) {
      const sessionIds = items.map((item) => item.sessionId);
      axios
        .post(
          `/api/adminReport/groupMetrics/?mod=${mod}`,
          { profiles: sessionIds },
          { headers: { "content-type": "application/json" } }
        )
        .then((res) => {
          setItemDetails(res.data);
        })
        .catch((error) => {
          if (error.response) {
            console.error("Error:", error.response);
          }
        });
    }
  }, [mod, items]);

  if (!itemDetails) {
    return <div>Loading...</div>;
  }

  const formatDate = (isoDateString) => {
    const date = new Date(isoDateString);
    return date.toISOString().split("T")[0];
  };

  const handlePrint = () => {
    window.print();
  };

  const navigateExternal = (target, options) => {
    if (options.state) {
      localStorage.setItem("state", JSON.stringify(options.state));
    }
    const newWindow = window.open(target, "_blank", "noreferrer");

    if (newWindow) {
      newWindow.onload = function () {
        newWindow.document.addEventListener("keydown", function (e) {
          const keyCode = e.keyCode || e.which;
          if (keyCode === 116 || (e.ctrlKey && keyCode === 82)) {
            e.preventDefault();
            e.stopPropagation();
            alert("Refresh is disabled");
          }
        });

        // Additionally, try to suppress the browser's default context menu
        // to reduce access to the refresh option via right-click
        newWindow.document.addEventListener("contextmenu", function (e) {
          e.preventDefault();
        });
      };
    }
  };

  const formatPercentage = (percent) => {
    return Math.round(percent);
  };

  const userDP = (itemID, userID) => {
    const user = { userId: userID };
    axios
      .get("/api/sessionGR/diagProfileTotal", {
        params: user,
      })
      .then((res) => {
        setGetAll(res.data);

        navigateExternal("/profileGrammarAdmin", {
          state: { grID: itemID, getGrTotal: res.data, mod: mod },
        });
      })
      .catch((err) => {
        console.log("error");
      });
  };

  const getColor = (level) => {
    switch (level) {
      case "1":
        return "rgba(24, 118, 128, 0.25)"; // Color for L1
      case "1+/2":
        return "rgba(24, 118, 128, 0.50)"; // Color for L1+/2
      case "2+/3":
        return "rgba(24, 118, 128, 0.75)"; // Color for L2+/3
      default:
        return "rgba(24, 118, 128, 1)";
    }
  };

  const cellStyle = {
    width: "80%",
    color: "#4c7b80",
    fontWeight: "normal",
  };

  const cellStyle2 = {
    width: "20%",
    textAlign: "left",
    paddingLeft: "15px",
    color: "#4c7b80",
    fontWeight: "normal",
  };

  const cellStyle3 = {
    textAlign: "left",
    paddingLeft: "15px",
    color: "#000000",
    fontWeight: "normal",
  };
  const cellStyle4 = {
    textAlign: "left",
    paddingLeft: "15px",
    color: "#4c7b80",
    fontWeight: "normal",
    cursor: "pointer",
  };

  function ItemDetail({ item }) {
    return (
      <div>
        {Array.isArray(item) ? (
          item.map((subItem) => (
            <LevelDetail key={subItem._id} subItem={subItem} />
          ))
        ) : (
          <>
            {item.averages && <Averages averages={item.averages} />}
            <IndividualData data={item.individualData} />
          </>
        )}
      </div>
    );
  }

  function Averages({ averages }) {
    const data = averages.map((avg) => ({
      level: avg.lvl,
      average: avg.avg !== "N/A" ? parseFloat(avg.avg) : 0,
      count: avg.count,
    }));

    return (
      <div style={{ width: "100%", textAlign: "left" }}>
        <table>
          <thead>
            <tr>
              <th
                style={{
                  width: "50%",
                  textAlign: "left",
                  paddingLeft: "15px",
                }}
              >
                Level
              </th>
              <th
                style={{
                  width: "50%",
                  textAlign: "left",
                  paddingLeft: "15px",
                }}
              >
                Average
              </th>
            </tr>
          </thead>
          <tbody>
            {averages.map((avg, index) => (
              <tr key={index} style={{ backgroundColor: getColor(avg.lvl) }}>
                <td style={cellStyle3}>{avg.lvl}</td>
                <td style={cellStyle3}>
                  {avg.avg !== "N/A"
                    ? formatPercentage(avg.avg.slice(0, -1)) + "%"
                    : "N/A"}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <br /> <br /> <br />
        <ResponsiveContainer
          width="60%"
          height={300}
          style={{ marginLeft: "20%" }}
        >
          <BarChart
            width={500}
            height={300}
            data={data}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <XAxis dataKey="level" />
            <YAxis
              tick={{ fill: "#4c7b80" }}
              ticks={[25, 50, 75, 100]}
              tickFormatter={(tick) => `${Math.round(tick)}%`}
            />
            <Bar dataKey="average">
              {data.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={getColor(averages[index].lvl)} // Dynamically set color
                />
              ))}
              <LabelList
                dataKey="average"
                position="top"
                formatter={(value) => `${Math.round(value)}%`}
                fill="#4c7b80"
              />
            </Bar>
          </BarChart>
        </ResponsiveContainer>
        <br />
      </div>
    );
  }

  function LevelDetail({ subItem }) {
    return (
      <div>
        <h2 style={{ fontWeight: "bold" }}>LEVEL: {subItem._id}</h2>
        {Object.keys(subItem)
          .filter((key) => key !== "_id" && Array.isArray(subItem[key]))
          .map((section) => (
            <SectionDetail
              key={section}
              section={section}
              data={subItem[section]}
              subItem={subItem}
            />
          ))}
      </div>
    );
  }

  function SectionDetail({ section, data, subItem }) {
    const sectionTitle = section.replace("Specific", "");
    const formattedSectionTitle =
      sectionTitle.charAt(0).toUpperCase() + sectionTitle.slice(1);
    return (
      <div>
        <h3 style={{ margin: "0 0 0 18px", padding: "8px" }}>
          {formattedSectionTitle}:{" "}
          {formatPercentage(subItem[`${sectionTitle}TotalPct`])}%
        </h3>
        <table style={{ width: "100%", borderCollapse: "collapse" }}>
          <tbody>
            {data.map((entry, index) => (
              <tr key={index}>
                <td
                  style={cellStyle}
                  dangerouslySetInnerHTML={{ __html: entry.tag }}
                />
                <td style={cellStyle2}>
                  {formatPercentage(entry.percent.slice(0, -1))}
                  %
                  <br />
                  {entry.totalCorrect}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <br />
      </div>
    );
  }
  // IndividualData component to handle personal performance data
  function IndividualData({ data }) {
    if (!data || !Array.isArray(data)) {
      // Handle the case where data is not available or not an array
      return null;
    }

    return (
      <div>
        <h2 style={{ fontWeight: "bold" }}>Individual Data</h2>
        {data.map((individual, indIdx) => (
          <div key={indIdx}>
            <h3 style={{ margin: "0 0 0 18px", padding: "8px" }}>
              Level: {individual._id}
            </h3>
            <table style={{ width: "100%", borderCollapse: "collapse" }}>
              <thead>
                <tr>
                  <th
                    style={{
                      width: "50%",
                      textAlign: "left",
                      paddingLeft: "15px",
                    }}
                  >
                    Email
                  </th>
                  <th
                    style={{
                      width: "25%",
                      textAlign: "left",
                      paddingLeft: "15px",
                    }}
                  >
                    Score
                  </th>
                  <th
                    style={{
                      width: "25%",
                      textAlign: "left",
                      paddingLeft: "15px",
                    }}
                  >
                    DP Link
                  </th>
                </tr>
              </thead>
              <tbody>
                {individual.sessions.map((session, sessIdx) => (
                  <tr key={sessIdx}>
                    <td style={cellStyle3}>{session.userEmail}</td>
                    <td style={cellStyle3}>
                      {" "}
                      {formatPercentage(session.score.slice(0, -1))}%
                    </td>
                    <td
                      style={cellStyle4}
                      onClick={() => userDP(session.sessionId, session.userId)}
                    >
                      {formatDate(session.endtime)}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <br />
            <br />
          </div>
        ))}
      </div>
    );
  }

  return (
    <div>
      <style>
        {`
          @media print {
            table tr, table th, table td {
  
              background-color: background-color: rgba(24, 118, 128, 0.25); !important;
              -webkit-print-color-adjust: exact;
              color-adjust: exact;
            }

            table th {
              background-color: background-color: rgba(24, 118, 128, 1); !important;
              -webkit-print-color-adjust: exact;
              color-adjust: exact;
            }
          }
        `}
      </style>

      {itemDetails.map((item, idx) => (
        <ItemDetail key={idx} item={item} />
      ))}

      <button style={{ marginRight: "20px" }} onClick={handlePrint}>
        Print this page
      </button>
    </div>
  );
};

export default GroupDPDisplayCompareGR;
