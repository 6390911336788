import React, { useState, useEffect } from "react";
import * as XLSX from "xlsx";

const ODAUserSurveyDisplay = (props) => {
  const data = props.content;

  const isData = props.isData;
  const checkAPI = props.checkAPI;
  const firstCheck = props.firstCheck;
  const mod = props.mod;

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear().toString().substr(-2);
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    return `${month}/${day}/${year}`;
  };

  const [sortConfig, setSortConfig] = useState({
    key: "date",
    direction: "asc",
  });

  const requestSort = (key) => {
    let direction = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key, direction });
  };

  const sortedData = [...data].sort((a, b) => {
    if (a[sortConfig.key] < b[sortConfig.key]) {
      return sortConfig.direction === "asc" ? -1 : 1;
    }
    if (a[sortConfig.key] > b[sortConfig.key]) {
      return sortConfig.direction === "asc" ? 1 : -1;
    }
    return 0;
  });

  if ((!isData && checkAPI) || (!isData && !checkAPI && firstCheck)) {
    return <h3>No data was found</h3>;
  }

  if (mod === "rclc") {
    const exportExcel = () => {
      const wb = XLSX.utils.book_new();
      const wsData = [
        [
          "User ID",
          "Date",
          "Language",
          "Level",
          "sa1",
          "sa2",
          "sa3",
          "sb1",
          "sb2",
          "sb3",
          "sb4",
          "sc1",
          "sc2",
          "sc3",
          "sc4",
          "sc5",
          "sc6",
          "sd1",
          "sd2",
          "sd3",
          "se1",
          "se2",
          "Comment",
        ],
      ];

      sortedData.forEach((item) => {
        wsData.push([
          item.user,
          new Date(item.date).toLocaleDateString(),
          item.language,
          item.currLvl === "Unspecified" ? "n/a" : item.currLvl,
          item.question1,
          item.question2,
          item.question3,
          item.question4,
          item.question5,
          item.question6,
          item.question7,
          item.question8,
          item.question9,
          item.question10,
          item.question11,
          item.question12,
          item.question13,
          item.question14,
          item.question15,
          item.question16,
          item.question17,
          item.question18,
          item.comment === "None" ? "no input" : item.comment,
        ]);
      });

      const ws = XLSX.utils.aoa_to_sheet(wsData);
      XLSX.utils.book_append_sheet(wb, ws, "Survey Data");
      XLSX.writeFile(wb, "survey_data.xlsx");
    };
    if (isData) {
      return (
        <div className="survey-grid" style={{ textAlign: "left" }}>
          <div
            style={{
              fontWeight: "bold",
              fontSize: "16px",
              marginTop: "-10px",
              marginBottom: "15px",
              display: "flex",
              alignItems: "center",
            }}
          >
            <span style={{ marginRight: "10px" }}>
              {data.length} Items Found{" "}
            </span>
            <span>
              <button
                style={{
                  fontSize: "13px",
                  borderRadius: "10px",
                  height: "30px",
                  paddingTop: "7px",
                }}
                onClick={exportExcel}
              >
                Export to Excel
              </button>
            </span>
          </div>

          <div
            style={{
              fontSize: "16px",
              marginBottom: "10px",
              marginTop: "10px",
            }}
          >
            Survey Data
          </div>
          <div className="grid-container-RCLC">
            <div className="grid-header-RCLC">
              <div
                style={{ cursor: "pointer" }}
                onClick={() => requestSort("user")}
              >
                ID
              </div>
              <div
                style={{ cursor: "pointer" }}
                onClick={() => requestSort("date")}
              >
                Date
              </div>
              <div
                style={{ cursor: "pointer" }}
                onClick={() => requestSort("language")}
              >
                Lang
              </div>
              <div>LVL</div>
              <div title="sa1. I used the tutorial available on the main page.">
                sa1
              </div>
              <div title="sa2. The tutorial was helpful.">sa2</div>
              <div title="sa3. The site is user-friendly.">sa3</div>
              <div title="sb1. The questions for each reading passage were clear and easy to understand.">
                sb1
              </div>
              <div title="sb2. The options or choices for each question, when applicable, were clear.">
                sb2
              </div>
              <div title="sb3. The questions that addressed grammatical points were clear and easy to understand.">
                sb3
              </div>
              <div title="sb4. The length of the reading passages was reasonable.">
                sb4
              </div>
              <div title="sc1. The quality of the audio of the main passages was reasonable.">
                sc1
              </div>
              <div title="sc2. The questions for each listening passage were clear and easy to understand.">
                sc2
              </div>
              <div title="sc3. The options or choices for each question, when applicable, were clear.">
                sc3
              </div>
              <div title="sc4. The questions that addressed grammatical points were clear and easy to understand.">
                sc4
              </div>
              <div title="sc5. The questions that addressed vocabulary items were clear and easy to understand.">
                sc5
              </div>
              <div title="sc6. The length of the listening passages was reasonable.">
                sc6
              </div>
              <div title="sd1. The feedback offered in the Diagnostic Profile is useful.">
                sd1
              </div>
              <div title="sd2. The language used in the Diagnostic Profile is clear and easy to understand.">
                sd2
              </div>
              <div title="sd3. The Performance Report of the Diagnostic Profile accurately reflects my abilities in the language.">
                sd3
              </div>
              <div title="se1. The length of the entire ODA session was reasonable.">
                se1
              </div>
              <div title="se2. My experience with the ODA session was positive.">
                se2
              </div>
              <div>Comment</div>
            </div>
            <div className="grid-body">
              {sortedData.map((item, index) => (
                <div key={index} className="grid-row-RCLC">
                  {/* <div>{item.user}</div> */}
                  <div>{String(item.user).slice(-7)}</div>
                  <div>{formatDate(item.date)}</div>
                  <div>{item.language}</div>
                  <div>
                    {item.currLvl === "Unspecified" ? "n/a" : item.currLvl}
                  </div>
                  <div>{item.question1}</div>
                  <div>{item.question2}</div>
                  <div>{item.question3}</div>
                  <div>{item.question4}</div>
                  <div>{item.question5}</div>
                  <div>{item.question6}</div>
                  <div>{item.question7}</div>
                  <div>{item.question8}</div>
                  <div>{item.question9}</div>
                  <div>{item.question10}</div>
                  <div>{item.question11}</div>
                  <div>{item.question12}</div>
                  <div>{item.question13}</div>
                  <div>{item.question14}</div>
                  <div>{item.question15}</div>
                  <div>{item.question16}</div>
                  <div>{item.question17}</div>
                  <div>{item.question18}</div>
                  <div
                    style={{
                      overflow: "auto",
                      maxHeight: "33px",
                      maxWidth: "200px",
                      overflowWrap: "break-word",
                    }}
                  >
                    {item.comment === "None" ? "no input" : item.comment}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      );
    }
  }
  if (mod === "grammar") {
    const exportExcel = () => {
      const wb = XLSX.utils.book_new();
      const wsData = [
        [
          "ID",
          "Date",
          "Language",
          "Level",
          "Q1",
          "Q2",
          "Q3",
          "Q4",
          "Q5",
          "Q6",
          "Q7",
          "Q8",
          "Q9",
          "Comment",
        ],
      ];

      sortedData.forEach((item) => {
        wsData.push([
          item.user,
          new Date(item.date).toLocaleDateString(),
          item.language,
          item.currLvl === "Unspecified" ? "n/a" : item.currLvl,
          item.question1,
          item.question2,
          item.question3,
          item.question4,
          item.question5,
          item.question6,
          item.question7,
          item.question8,
          item.question9,
          item.comment === "None" ? "no input" : item.comment,
        ]);
      });

      const ws = XLSX.utils.aoa_to_sheet(wsData);
      XLSX.utils.book_append_sheet(wb, ws, "Survey Data");
      XLSX.writeFile(wb, "survey_data.xlsx");
    };
    if (isData) {
      return (
        <div className="survey-grid" style={{ textAlign: "left" }}>
          <div
            style={{
              fontWeight: "bold",
              fontSize: "16px",
              marginTop: "-10px",
              marginBottom: "15px",
              display: "flex",
              alignItems: "center",
            }}
          >
            <span style={{ marginRight: "10px" }}>
              {data.length} Items Found{" "}
            </span>
            <span>
              <button
                style={{
                  fontSize: "13px",
                  borderRadius: "10px",
                  height: "30px",
                  paddingTop: "7px",
                }}
                onClick={exportExcel}
              >
                Excel
              </button>
            </span>
          </div>
          <div
            style={{
              fontSize: "16px",
              marginBottom: "10px",
              marginTop: "10px",
            }}
          >
            Survey Data
          </div>
          <div className="grid-container">
            <div className="grid-header">
              <div
                style={{ cursor: "pointer" }}
                onClick={() => requestSort("user")}
              >
                ID
              </div>
              <div
                style={{ cursor: "pointer" }}
                onClick={() => requestSort("date")}
              >
                Date
              </div>
              <div
                style={{ cursor: "pointer" }}
                onClick={() => requestSort("language")}
              >
                Lang
              </div>
              <div>LVL</div>
              <div title="1. The ODA Grammar instructions are clear and easy to understand.">
                Q1
              </div>
              <div title="2. The length of the assessment is reasonable.">
                Q2
              </div>
              <div title="3. The texts are of appropriate length.">Q3</div>
              <div title="4. The answer options force me to apply grammatical knowledge rather than to guess.">
                Q4
              </div>
              <div title="5. The data in the profile is useful.">Q5</div>
              <div title="6. The data in the profile is easy to understand.">
                Q6
              </div>
              <div title="7. My ODA Grammar score accurately reflects my grammatical ability.">
                Q7
              </div>
              <div title="8. I would retake this assessment in the future to check my progress.">
                Q8
              </div>
              <div title="9. The listening items accurately reflect my grammatical ability.">
                Q9
              </div>
              <div>Comment</div>
            </div>
            <div className="grid-body">
              {sortedData.map((item) => (
                <div key={item.user} className="grid-row">
                  {/* <div>{item.user}</div> */}
                  <div>{String(item.user).slice(-7)}</div>
                  <div>{formatDate(item.date)}</div>
                  <div>{item.language}</div>
                  <div>
                    {item.currLvl === "Unspecified" ? "n/a" : item.currLvl}
                  </div>
                  <div>{item.question1}</div>
                  <div>{item.question2}</div>
                  <div>{item.question3}</div>
                  <div>{item.question4}</div>
                  <div>{item.question5}</div>
                  <div>{item.question6}</div>
                  <div>{item.question7}</div>
                  <div>{item.question8}</div>
                  <div>{item.question9}</div>
                  <div
                    style={{
                      overflow: "auto",
                      maxHeight: "33px",
                      maxWidth: "200px",
                      overflowWrap: "break-word",
                    }}
                  >
                    {item.comment === "None" ? "no input" : item.comment}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      );
    }
  }
};

export default ODAUserSurveyDisplay;
