import { useState, useEffect } from "react";
import { useGetSingleTestletDebug } from "../hooks/useGetSingleTestletDebug";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import axiosRetry from "axios-retry";

const DisplaySingleTestlet = () => {
  //!! State and Configuration
  //** Global State Context Calls, links to context/AssessmentProvider.js & context/ErrorProvider.js */
  const { skillType, testletId } = useParams();
  let skill;
  if (skillType === "LC") {
    skill = "listening";
  } else if (skillType === "RC") {
    skill = "reading";
  } else if (skillType === "GR") {
    skill = "grammar";
  }
  const [state, setState] = useState({
    testlet: null,
    testletId: null,
    skill: skill,
    lang: null,
    level: "1",
    pageNum: 0,
    checker: null
  });
  const [runHook, setRunHook] = useState(false);
  const regExTester = /[a-z]{3}_[a-z]{3}[0-9]{3}/;
  const isMatch = regExTester.test(testletId);
  const navigate = useNavigate();
  const location = useLocation();
  axiosRetry(axios, {
    retries: 4, // number of retries
    retryDelay: (...arg) => axiosRetry.exponentialDelay(...arg, 1000), // Exponential delay with backoff of 1000mst
    retryCondition: (err) => {
      console.log(err.response);
      return (
        axiosRetry.isNetworkError(err) ||
        err.code === "ECONNABORTED" ||
        err.code === "ENOTFOUND" ||
        err.code === "ETIMEDOUT" ||
        (err.response && err.response.status >= 500 && err.response.status <= 599)
      );
    }
  });

  //!! Monitoring
  useEffect(() => {
    console.log(state);
  }, [state]);

  //!! Logic
  if (!isMatch) {
    console.error("Not a valid testlet name.");
  } else {
    console.log(testletId);
    if (state.testletId !== testletId) {
      setState({ ...state, testletId: testletId, lang: testletId.substring(0, 3) });
      setRunHook(true);
    }
  }

  //** customHook to handle all logic for collecting data on a new assessment & loading first testlet on demand */
  const [firstTestletHookData, firstTestletHookRunning, firstTestletHookError] = useGetSingleTestletDebug(
    skill,
    testletId.substring(0, 3),
    "1",
    testletId, //the forced testlet id
    runHook
  );
  useEffect(() => {
    if (firstTestletHookData !== null && runHook === true) {
      console.log(firstTestletHookData);
      setState({ ...state, testlet: firstTestletHookData, checker: true });
      setRunHook(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firstTestletHookData, firstTestletHookRunning, runHook]);

  //* routes user to a new assessment based on config */
  let selectTemplateModal = "";
  useEffect(() => {
    if (state.checker && state.testlet !== "" && state.skill !== "") {
      console.log(state.testlet);
      selectTemplateModal = navigate("/" + state.skill, {
        state: { testlet: state.testlet }
      });
    }
  }, [state.checker, state.testlet, state.skill]);
};

export default DisplaySingleTestlet;
