import React from "react";

export default function AnswerField(props) {
  return (
    <div className="answerSection">
      <div id="selectedZone">
        Your answer:
        <div className="HTOAnswer">
          <span className="noSelect">
            <span className="HTOInst" style={{ fontSize: "18px" }}>
              Click the word or phrase in the passage to select your answer
            </span>
          </span>
        </div>
      </div>
    </div>
  );
}
