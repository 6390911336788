import React, { useState, useContext, useEffect, useCallback } from "react";
import { Navigate, useNavigate, useLocation } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import AuthAdmin from "../../contents/AuthAdmin";
import AdminReportBody from "./adminReport/AdminReportBody";
import { Helmet } from "react-helmet";

const AdminReport = ({ initialFromDate, initialToDate }) => {
  const navigate = useNavigate();

  /*
  // original option : if an admin user logs in the main test page and then go to the /adminreport which is a subdirectory, it breaks system.
  const adminuser = secureLocalStorage.getItem("adminuser");
  const content = adminuser.content;

  option one
  const [content, setContent] = useState("");
  const [isLoading, setIsLoading] = useState(true); // New loading state
  let adminuser = "";

  useEffect(() => {
    adminuser = secureLocalStorage.getItem("adminuser");
    if (adminuser === null) {
      setIsLoading(false);
      window.location.href = "/";
    } else {
      content = adminuser.content;
    }
  }, []);
*/

  let content = "";
  const adminuser = secureLocalStorage.getItem("adminuser");
  if (adminuser === null) {
    window.location.href = "/admin";
  } else {
    content = adminuser.content;
  }

  const timer = setInterval(function () {
    if (localStorage.getItem("admintoken")) {
      var name = localStorage.getItem("admintoken");
      const nameObj = JSON.parse(window.atob(name));
      if (new Date().getTime() - nameObj.time >= nameObj.expire) {
        localStorage.removeItem("admintoken");
      }
    } else {
      clearInterval(timer);
    }
  }, 1000);

  /*
  // Need to remove two localStorage data when a browser closed
  window.addEventListener("beforeunload", (e) => {
    // Remove the data from localStorage when a browser closed
    e.preventDefault();
    e.returnValue = ""; // asking leving site if closing || asking reload if reloading
    localStorage.removeItem("token");
    localStorage.removeItem("@secure.j.adminuser");
  });

  window.addEventListener("load", (e) => {
    //reloading from the above beforeunload event when a browser reload button clicked
  });
*/

  window.addEventListener("popstate", (e) => {
    // Remove the data from localStorage when a backword button clicked
    e.preventDefault();
    console.log("clearing local storage from AdminReport");
    localStorage.removeItem("token");
    localStorage.removeItem("@secure.j.adminuser");
    navigate("/admin");
  });

  const logout = () => {
    console.log("clearing local storage from AdminReport");
    //window.localStorage.clear();
    localStorage.removeItem("token");
    localStorage.removeItem("@secure.j.adminuser");
    navigate("/admin");
    // });
  };

  return (
    <div>
      <Helmet>
        <meta name="robots" content="noindex,nofollow" />
      </Helmet>
      <div className="adminReport">
        <div className="infoTitle">ADMIN PANEL</div>
        {/* option one}
        {/* {isLoading ? (
          <div>Loading...</div>
        ) : (
          <AdminReportBody content={content} />
        )} */}
        <AdminReportBody content={content} />
        <div
          onClick={logout}
          style={{
            cursor: "pointer",
            fontSize: "large",
            fontWeight: "bold",
            paddingTop: "20px"
          }}
        >
          <div>Sign Out</div>
        </div>
      </div>
    </div>
  );
};

export default AdminReport;
