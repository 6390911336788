import "../../css/index.css";

/** 
 * @component ReportSentModal
 * @description 
 * @param {object} props
**/

export default function ReportSentModal(props) {
  return (    
    <div className="modalDialog" id="modalDialog">
      <div className="reportIssueModal">
        <div className="feedbackHeader" id="modalHeader">
          Issue Report Sent  
        </div>  
        <h4>Thank you for your feedback!</h4>
        <h5 style={{"text-align":"left"}}>
        We have received your issue report and will review it shortly.
        <br /><br />
        An ODA developer may reach out to you via email if they have questions or comments about your report.
        </h5>                               
        <button className="outlined" onClick={e => props.refModalState(e)}>Close</button>          
        <br /><br /><br />
      </div>      
    </div>      
  );
}