import { offset, fadeIn, fadeOut } from "./genericFunctions";

// ** Loads non-state javascript in component onready in Header.js **
// ** a lot of interaction with the signup & login forms
export default function nonFunctions() {
  // ** Adds a listener to login buttons to scroll to and focus proper login div
  // ** Converted & corrected to vanilla JS from jQuery code in oda.js
  let selEl = document.querySelectorAll(".signInNav, .signUpNav, .signUpBtn, .backToLogin, .goLogin");
  for (let i = 0; i < selEl.length; i++) {
    selEl[i].addEventListener("click", (e) => {
      let selEl2 = document.querySelectorAll('input[name="email"]');
      for (let j = 0; j < selEl2.length; j++) {
        if (
          e.target.classList.contains("signInNav") ||
          e.target.classList.contains("backToLogin") ||
          e.target.classList.contains("goLogin")
        ) {
          selEl2[0].focus();
        } else if (e.target.classList.contains("signUpNav") || e.target.classList.contains("signUpBtn")) {
          selEl2[1].focus();
        }
      }
      let loginTop = offset(".login");
      window.scroll({
        top: loginTop.top - 50,
        left: 0,
        behavior: "smooth"
      });
    });
  }

  // ** Adds .visible to password inputs when something is typed:: but why??
  // ** Converted to vanilla JS from jQuery code in oda.js
  selEl = document.querySelectorAll('input[type="password"]');
  for (let i = 0; i < selEl.length; i++) {
    selEl[i].addEventListener("input", (e) => {
      document.querySelector("#" + e.target.attributes.id.value).classList.add("visible");
    });
  }

  // ** When the eye is clicked, focus the password text box **
  // ** converted to vanilla JS from jQuery code in oda.js
  selEl = document.querySelectorAll(".eye");
  for (let i = 0; i < selEl.length; i++) {
    selEl[i].addEventListener("click", (e) => {
      let label = "#" + e.target.parentElement.attributes.for.value;
      let password = document.querySelector(label);
      password.type = password.type === "password" ? "text" : "password";
      password.focus();
    });
  }

  // ** When caps lock is on and password is being entered then
  // ** show a CAPS LOCK ON span next to the password field
  // ** converted to vanilla JS from jQuery code in oda.js
  selEl = document.querySelectorAll(".password");
  for (let i = 0; i < selEl.length; i++) {
    selEl[i].addEventListener("keyup", (e) => {
      let span = document.createElement("span");
      span.classList.add("capsalert");
      span.innerText = "CapsLock is on!";
      try {
        if (typeof e.getModifierState === "function") {
          if (e.getModifierState("CapsLock")) {
            e.target.after(span);
          } else {
            let removeEl = document.querySelector(".capsalert");
            if (removeEl !== null) {
              removeEl.remove();
            }
          }
        }
      } catch (err) {
        //nothing
      }
    });
  }

  // ** .animated jQuery replacement function in vanilla for this purpose
  // ** used in the functions below
  function move(elem, distance, direction) {
    let left = 0;
    let updatedPosition = 0;
    let endingPosition = 0;
    endingPosition = updatedPosition + distance;

    function frame() {
      if (direction == "left") {
        left++;
      } else {
        left--;
      }
      updatedPosition = updatedPosition + left;
      if (direction == "left" && updatedPosition >= endingPosition) {
        updatedPosition = Math.floor(updatedPosition / 400) * 400;
      } else if (direction == "right" && updatedPosition <= endingPosition) {
        updatedPosition = Math.floor(updatedPosition / -400) * -400;
      }
      elem.style.left = updatedPosition + "px"; // show frame
      if (
        (updatedPosition >= endingPosition && direction === "left") ||
        (updatedPosition <= endingPosition && direction === "right")
      ) {
        // check finish condition
        clearInterval(id);
      }
    }
    var id = setInterval(frame, 500); // draw every 100ms
  }

  // ** slider-esque vanilla jquery replacement from oda.js **
  // ** focuses attention on the create new account form properly
  selEl = document.querySelectorAll(".signUpNav, .signUpBtn");
  for (let i = 0; i < selEl.length; i++) {
    selEl[i].addEventListener("click", (e) => {
      let winWidth = 0;
      if (document.querySelector(".centerWrap") !== null) {
        winWidth = document.querySelector(".centerWrap").clientWidth / 2;
      }

      if (
        document.querySelector(".signUpWrap") !== null &&
        document.querySelector(".loginWrap") !== null &&
        document.querySelector(".info") !== null
      ) {
        if (!document.querySelector(".signUpWrap").classList.contains("focused")) {
          move(document.querySelector(".loginWrap"), winWidth, "right");
          fadeOut(document.querySelector(".loginWrap"));
          fadeIn(document.querySelector(".signUpWrap"));
          document.querySelector(".signUpWrap").style.left = "30%";
          let newFocus = document.querySelector(".signUpWrap > .signUp > form :first-child :nth-child(1)");
          newFocus.focus();
        }
        document.querySelector(".loginWrap").classList.remove("focused");
        document.querySelector(".signUpWrap").classList.add("focused");
        document.querySelector(".info").classList.remove("info-margin-p50");
        document.querySelector(".info").classList.add("info-margin-p150");
      }
    });
  }

  // ** slider-esque vanilla jquery replacement from oda.js **
  // ** focuses attention on the login form properly
  selEl = document.querySelectorAll(".signInNav, .backToLogin, .goLogin");
  for (let i = 0; i < selEl.length; i++) {
    selEl[i].addEventListener("click", (e) => {
      let winWidth = 0;
      if (document.querySelector(".centerWrap") !== null) {
        winWidth = document.querySelector(".centerWrap").clientWidth / 2;
      }

      if (document.querySelector(".loginWrap") !== null) {
        if (!document.querySelector(".loginWrap").classList.contains("focused")) {
          move(document.querySelector(".signUpWrap"), winWidth, "right");
          fadeOut(document.querySelector(".signUpWrap"));
          document.querySelector(".loginWrap").style.left = "30%";
          fadeIn(document.querySelector(".loginWrap"));
          document.querySelector("#userEmail").focus();
        }
      }
      if (
        document.querySelector(".signUpWrap") !== null &&
        document.querySelector(".loginWrap") !== null &&
        document.querySelector(".info") !== null
      ) {
        document.querySelector(".signUpWrap").classList.remove("focused");
        document.querySelector(".loginWrap").classList.add("focused");
        document.querySelector(".info").classList.remove("info-margin-p150");
        document.querySelector(".info").classList.add("info-margin-p50");
      }
    });
  }
}
