import React from "react";
import dompurify from "dompurify";
import Footer from "../../footer/Footer";
import menu from "./menucontents/MenuContents.json";

export default function FAQ() {
  const sanitizer = dompurify.sanitize;
  const faq = menu[0].faq;

  return (
    <>
      <h4 className="faq">FAQs</h4>
      <h4>
        <div
          style={{ fontWeight: "normal", fontSize: "16px" }}
          dangerouslySetInnerHTML={{
            __html: sanitizer(faq),
          }}
        />
      </h4>
      <br />
      <Footer showReportProblem={false} />
    </>
  );
}
