import {useReducer, createContext} from "react";

import {assessmentStateReducer, initialAssessmentState } from "./AssessmentStateReducer";

export const AssessmentContext = createContext({
  state: initialAssessmentState,
  dispatch: () => null
});

export const AssessmentProvider = ({children}) => {
  const [state, dispatch] = useReducer(assessmentStateReducer,initialAssessmentState);

  return (
    <AssessmentContext.Provider value={[state,dispatch]}>
      {children}
    </AssessmentContext.Provider>
  );
};

//how to use:
//wrap the App.js tree in <AssessmentProvider></AssessmentProvider> after importing
//to access global context, add this in a component:
//const [state,dispatch] = useContext(AssessmentContext);
//then call the dispatch function, ie:
//dispatch({type:"UPDATE_SKILL_FLOOR", payload:4})

