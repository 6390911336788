import React, { useState, useEffect } from "react";
import axios from "axios";

const RolesContent = ({
  id,
  email,
  adminRole,
  adminPanel,
  onSubmit,
  onCancel,
}) => {
  const [checkedBoxes, setCheckedBoxes] = useState([]);
  const [unCheckedBoxes, setUnCheckedBoxes] = useState([]);
  const [isAllChecked, setIsAllChecked] = useState(false);
  const [isNoneChecked, setIsNoneChecked] = useState(false);

  // Generate a unique number for each checkbox based on its index
  const getCheckboxNumber = (index) => {
    return index;
  };

  useEffect(() => {
    // Loop through adminPanel and set corresponding checkboxes to true
    const adminRolesIds = adminPanel.map((role) => role._id);
    setCheckedBoxes(adminRolesIds);
    console.log("checkBoxes =", checkedBoxes);

    return () => {
      setUnCheckedBoxes([]);
    };
  }, [adminPanel]);

  const handleCheckboxChange = (event, index) => {
    const checkboxNumber = getCheckboxNumber(index);
    console.log("lll=", event.target.checked);

    if (event.target.checked) {
      setCheckedBoxes((prevChecked) => [...prevChecked, checkboxNumber]);

      console.log("checkBoxes 2=" + checkedBoxes);
    } else {
      setCheckedBoxes((prevChecked) =>
        prevChecked.filter((number) => number !== checkboxNumber)
      );
    }

    if (!event.target.checked) {
      setUnCheckedBoxes((prevUnchecked) => [...prevUnchecked, checkboxNumber]);
    } else {
      setUnCheckedBoxes((prevUnchecked) =>
        prevUnchecked.filter((number) => number !== checkboxNumber)
      );
    }

    if (index === 100) {
      // Checkbox 'All'
      setIsAllChecked(event.target.checked);
      if (event.target.checked) {
        setCheckedBoxes([...Array(15).keys()].slice(1));
        setIsNoneChecked(false); // Uncheck 'None' when 'All' is checked
      } else {
        setCheckedBoxes([]);
      }
    } else if (index === 0) {
      // Checkbox 'None'
      setIsNoneChecked(event.target.checked);
      if (event.target.checked) {
        setCheckedBoxes([]); // Uncheck all other checkboxes when 'None' is checked
        setIsAllChecked(false); // Uncheck 'All' when 'None' is checked
      } else {
        // Handle case when 'None' is unchecked
      }
    } else {
      setIsAllChecked(false);
      setIsNoneChecked(false);
    }
  };

  const handlePermission = () => {
    // Execute only if sysadmin role does not exist
    if (adminRole != "sysadmin") {
      const newMemberEmail = email;
      const newMemberId = id;
      const newMemberRoles = "[" + checkedBoxes.join(", ") + "]";
      const newMemberPanels = '{"permission"' + ":" + newMemberRoles + "}";

      onSubmit();
      alert("memberEmail=" + newMemberEmail);
      alert("permission grant=" + newMemberPanels);
      axios({
        method: "put",
        headers: { "content-type": "application/json" },
        url: "/api/adminPanel/grantUserPermission/" + newMemberId,
        data: newMemberPanels,
      });

      const uncheckedRoles = "[" + unCheckedBoxes.join(", ") + "]";
      const uncheckedPanels = '{"permission"' + ":" + uncheckedRoles + "}";

      if ((unCheckedBoxes.length === 0) & (checkedBoxes.length === 0)) {
        var arr = [...Array(14).keys()].map((x) => x + 1);
        const fakeUncheckedRole = "[" + arr + "]";

        axios({
          method: "put",
          headers: { "content-type": "application/json" },
          url: "/api/adminPanel/revokeUserPermission/" + newMemberId,
          data: '{"permission"' + ":" + fakeUncheckedRole + "}",
        });
      } else {
        axios({
          method: "put",
          headers: { "content-type": "application/json" },
          url: "/api/adminPanel/revokeUserPermission/" + newMemberId,
          data: uncheckedPanels,
        });
      }
    } else {
      // Notify user that sysadmin role already exists
      alert("Cannot modify permissions: sysadmin role already exists");
    }
  };
  const handleCancelRoles = () => {
    console.log("Cancel button clicked for email:", email);
    onCancel();
  };

  return (
    <div
      style={{
        backgroundColor: "#cdd7d7",
        border: "1px dashed #187680",
        borderRadius: "5px",
      }}
    >
      <div>
        <span
          style={{
            marginLeft: "20px",
            marginTop: "5px",
            display: "inline-block",
          }}
        >
          Select permission:
        </span>
        <br />
        <input
          type="checkbox"
          name="all"
          onChange={(event) => handleCheckboxChange(event, 100)}
          checked={isAllChecked}
        />
        All
        <br />
        <input
          type="checkbox"
          name="none"
          onChange={(event) => handleCheckboxChange(event, 0)}
          checked={isNoneChecked}
        />
        None
        <br />
        <input
          type="checkbox"
          name="main"
          onChange={(event) => handleCheckboxChange(event, 1)}
          checked={checkedBoxes.includes(getCheckboxNumber(1))}
        />
        Main Panel
        <br />
        <input
          type="checkbox"
          name="itemusercorrelation"
          onChange={(event) => handleCheckboxChange(event, 2)}
          checked={checkedBoxes.includes(getCheckboxNumber(2))}
        />
        Item-User Correlation
        <br />
        <input
          type="checkbox"
          name="itemuitemcorrelation"
          onChange={(event) => handleCheckboxChange(event, 3)}
          checked={checkedBoxes.includes(getCheckboxNumber(3))}
        />
        Item-Item Correlation
        <br />
        <input
          type="checkbox"
          name="viewgroup"
          onChange={(event) => handleCheckboxChange(event, 4)}
          checked={checkedBoxes.includes(getCheckboxNumber(4))}
        />
        Group/Class DPs
        <br />
        <input
          type="checkbox"
          name="keyword"
          onChange={(event) => handleCheckboxChange(event, 5)}
          checked={checkedBoxes.includes(getCheckboxNumber(5))}
        />
        Keyword Panel
        <br />
        <input
          type="checkbox"
          name="tags"
          onChange={(event) => handleCheckboxChange(event, 6)}
          checked={checkedBoxes.includes(getCheckboxNumber(6))}
        />
        Tags Panel
        <br />
        <input
          type="checkbox"
          name="useranswersearch"
          onChange={(event) => handleCheckboxChange(event, 7)}
          checked={checkedBoxes.includes(getCheckboxNumber(7))}
        />
        User Answer Search
        <br />
        <input
          type="checkbox"
          name="questionbyprimarytag"
          onChange={(event) => handleCheckboxChange(event, 8)}
          checked={checkedBoxes.includes(getCheckboxNumber(8))}
        />
        Question by Primary Tag
        <br />
        <input
          type="checkbox"
          name="passagebyproficiencylevel"
          onChange={(event) => handleCheckboxChange(event, 9)}
          checked={checkedBoxes.includes(getCheckboxNumber(9))}
        />
        Passage by Proficency Level
        <br />
        <input
          type="checkbox"
          name="datatypessearch"
          onChange={(event) => handleCheckboxChange(event, 10)}
          checked={checkedBoxes.includes(getCheckboxNumber(10))}
        />
        Data Types Search
        <br />
        <input
          type="checkbox"
          name="testletusagefrequence"
          onChange={(event) => handleCheckboxChange(event, 11)}
          checked={checkedBoxes.includes(getCheckboxNumber(11))}
        />
        Testlet Usage Frequency
        <br />
        <input
          type="checkbox"
          name="odatestletinventory"
          onChange={(event) => handleCheckboxChange(event, 12)}
          checked={checkedBoxes.includes(getCheckboxNumber(12))}
        />
        Testlet Inventory
        <br />
        <input
          type="checkbox"
          name="odausage"
          onChange={(event) => handleCheckboxChange(event, 13)}
          checked={checkedBoxes.includes(getCheckboxNumber(13))}
        />
        ODA Usage
        <br />
        <input
          type="checkbox"
          name="odausersurvey"
          onChange={(event) => handleCheckboxChange(event, 14)}
          checked={checkedBoxes.includes(getCheckboxNumber(14))}
        />
        User Survey
        <br />
        <input
          type="checkbox"
          name="singletestlet"
          onChange={(event) => handleCheckboxChange(event, 15)}
          checked={checkedBoxes.includes(getCheckboxNumber(15))}
        />
        Single Testlet
        <br />
        {/* <input
          type="checkbox"
          name="masteradmin"
          onChange={(event) => handleCheckboxChange(event, 16)}
          checked={checkedBoxes.includes(getCheckboxNumber(16))}
        />
        Master Admin
        <br /> */}
        <div
          style={{
            marginBottom: "-5px",
            textAlign: "center",
          }}
        >
          <div
            className="masterBtns"
            style={{
              border: "1px solid ",
              borderRadius: "5px",
              textAlign: "center",
              padding: "3px",
              margin: "10px",
            }}
            onClick={handlePermission}
          >
            Save Permission
          </div>
          <div
            className="masterBtns"
            style={{
              border: "1px solid ",
              borderRadius: "5px",
              textAlign: "center",
              padding: "3px",
              margin: "10px",
            }}
            onClick={handleCancelRoles}
          >
            Exit
          </div>
        </div>
      </div>
      <br />
    </div>
  );
};

export default RolesContent;
