export default function SpeechTarget(props) {
  const targetOriAudScore = props.targetOriAudScore;
  const targetModAudScore = props.targetModAudScore;
  const targetScriptScore = props.targetScriptScore;
  const targetAudVocabScore = props.targetAudVocabScore;

  return (
    <div className="profileGridContainer full pageBreak">
      <div className="speechProcessing">
        <h2>Speech Processing</h2>

        <p className="descriptor">
          Comprehension of authentic speech, such as speed, clarity, or accent.
          Depending on the level of the passage, it may also indicate the extent
          to which you needed simplified speech or transcribed vocabulary to
          answer questions.
        </p>

        <div className="profileCategory">
          <h3>Audio Delivery</h3>

          <p className="tag">
            Original Audio, correct/total
            <span className="dataLeftSmall" data="42">
              {targetOriAudScore}
            </span>
          </p>

          <p className="tag">
            Modified, correct/attempted
            <span className="dataLeftSmall" data="100">
              {targetModAudScore}
            </span>
          </p>

          <p className="descriptor">
            For this level, your performance indicates that you are able to:
          </p>

          <ul>
            <li>Understand some speech delivered at a normal rate</li>
          </ul>
        </div>

        <div className="profileCategory">
          <h3>Vocabulary Delivery</h3>

          <p className="tag">
            Vocabulary delivered in audio format, correct/total
            <span className="dataLeftSmall" data="56">
              {targetAudVocabScore}
            </span>
          </p>

          <p className="tag">
            Vocabulary transcriptions, correct/attempts
            <span className="dataLeftSmall" data="86">
              {targetScriptScore}
            </span>
          </p>

          <p className="descriptor">
            For this level, your perfmormance indicates that you are able to:
          </p>

          <ul>
            <li>
              Understand vocabulary items in their audio forms or with
              transcription
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}
