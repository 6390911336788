import React, { useEffect } from "react";
import styled, { keyframes } from "styled-components";

export default function Overview(props) {
  //console.log(props);
  useEffect(() => {});

  const testDate = props.endTime;
  let readableDate = Date.parse(testDate);
  readableDate = new Date(readableDate);
  readableDate = readableDate.toLocaleString("default", {
    month: "short",
    day: "numeric",
    year: "numeric"
  });

  const timeSpent = props.timeSpent;
  const language = props.languageFull;
  const modality = props.modality;
  const contentScore = props.contentScore + 1;

  const linguisticScore = props.linguisticScore + 1;
  const contentAngle = 3.6 * (contentScore - 1);
  const liguisticAngle = 3.6 * (linguisticScore - 1);
  const estimatedLevel = props.estimatedLevel;

  let tomaxposition = "";
  if (estimatedLevel === "3") {
    tomaxposition = "0%";
  } else if (estimatedLevel === "2+") {
    tomaxposition = "15%";
  } else if (estimatedLevel === "2") {
    tomaxposition = "30%";
  } else if (estimatedLevel === "1+") {
    tomaxposition = "45%";
  } else if (estimatedLevel === "1") {
    tomaxposition = "60%";
  } else {
    tomaxposition = "75%";
  }

  const contentArrow = keyframes`
  to {
    transform: rotate(${contentAngle}deg);
  }
`;

  // Here we create a component that will rotate the content questions
  const Rotate = styled.div`
    width: 100px;
    height: 100px;
    transform: rotate(0deg);
    border-radius: 50%;
    display: inline-block;
    z-index: 999;
    top: 11px;
    position: relative;
    -webkit-animation: ${contentArrow}2s ease-out forwards;
    animation: ${contentArrow} 2s ease-out forwards;
  `;

  const linguisticArrow = keyframes`
  to {
    transform: rotate(${liguisticAngle}deg);
  }
`;

  // Here we create a component that will rotate the lingustic questions
  const Rotate2 = styled.div`
    width: 100px;
    height: 100px;
    transform: rotate(0deg);
    border-radius: 50%;
    display: inline-block;
    z-index: 999;
    top: 11px;
    position: relative;
    -webkit-animation: ${linguisticArrow}2s ease-out forwards;
    animation: ${linguisticArrow} 2s ease-out forwards;
  `;

  const capitalizeLetter = (item) => {
    if (typeof item === "string") {
      return item.replace(/\b(\w)/g, (s) => s.toUpperCase());
    } else {
      return "";
    }
  };

  return (
    <div className="pageBreak">
      <h1 className="headingLeft">
        {capitalizeLetter(
          language
            .replace(/^(North_Korean|North Korean|Korean\/North Korean|Korean\/North_Korean)$/i, "Korean (South & North)")
            .replace(/^Korean$/i, "Korean (South)")
            .replace("_", " ")
        )}{" "}
        {capitalizeLetter(modality)} Assessment
      </h1>
      <div className="headingRight">
        <p>Taken: {readableDate}</p>
        <p>Time: {timeSpent} minutes</p>
      </div>

      <div className="clearFix noMargin" />

      <button className="smallBtn print" onClick={props.onPrint}>
        Print
      </button>
      <div className="chartsGridContainer">
        <div className="gridItem">
          <div className="ILRScore">
            <div className="yourScore" tomaxposition={tomaxposition}>
              Current Level<span>{estimatedLevel}</span>
            </div>
            <ul>
              <li>
                LEVEL 3 <br />
                <span>Professional working proficiency</span>
              </li>
              <li>
                LEVEL 2+ <br />
                <span>Working proficiency</span>
              </li>
              <li>
                LEVEL 2 <br />
                <span>Limited working proficiency</span>
              </li>
              <li>
                LEVEL 1+ <br />
                <span>Elementary proficiency</span>
              </li>
              <li>
                LEVEL 1 <br />
                <span>Basic elementary proficiency</span>
              </li>
              <li>
                LEVEL 0 / 0+ <br />
                <span>No Proficiency/Memorized Proficiency</span>
              </li>
            </ul>
          </div>
        </div>

        <div className="gridItem">
          <div className="contentQuestions">
            <h2 id="countContent" score={contentScore}>
              N/A
            </h2>
            <span>Content Questions</span>
            <div className="centerer">
              <Rotate>
                <div className="contentArrow" />
              </Rotate>
              {/* <div className="contentArrow"></div> */}
            </div>
            <svg id="contentChart" width="160" height="160" xmlns="http://www.w3.org/2000/svg">
              <g>
                {/*
                <circle
                  className="Excellent"
                  r="69.85699"
                  cy="81"
                  cx="81"
                  strokeWidth="18"
                  stroke="#91D59C"
                  fill="none"
                />

                <circle
                  className="Pass"
                  r="69.85699"
                  cy="81"
                  cx="81"
                  strokeWidth="18"
                  stroke="#73B1DA"
                  fill="none"
                />

                <circle
                  className="Fail"
                  r="69.85699"
                  cy="81"
                  cx="81"
                  strokeWidth="18"
                  stroke="#d57b7e"
                  fill="none"
                />
                */}
                <path
                  className="Fail"
                  strokeWidth="18"
                  stroke="#d57b7e"
                  fill="none"
                  d=" M 82 11 A 70 70 0 1 1 20 115"
                />
                <path
                  className="Pass"
                  strokeWidth="18"
                  stroke="#73B1DA"
                  fill="none"
                  d=" M 20 115 A 70 70 0 0 1 14 59"
                />
                <path
                  className="Excellent"
                  strokeWidth="18"
                  stroke="#91D59C"
                  fill="none"
                  d=" M 14 59 A 70 70 0 0 1 81 11"
                />
              </g>
            </svg>
            <div className="flags">
              <div className="failFlag">Unsatisfactory</div>
              <div className="passFlag">Satisfactory</div>
              <div className="excellentFlag">Excellent</div>
            </div>
          </div>

          <div className="linguisticQuestions">
            <h2 id="countLinguistic" score={linguisticScore}>
              N/A
            </h2>
            <span>Linguistic Questions</span>
            <div className="centerer">
              {/* <div className="linguisticArrow"></div> */}
              <Rotate2>
                <div className="linguisticArrow" />
              </Rotate2>
            </div>
            <svg id="linguisticChart" width="160" height="160" xmlns="http://www.w3.org/2000/svg">
              <g>
                <defs>
                  <linearGradient id="lgrad" x1="100%" y1="50%" x2="0%" y2="50%">
                    <stop offset="0%" style={{ stopColor: "#BED8DB", stopOpacity: "1" }} />
                    <stop offset="100%" style={{ stopColor: "#368B95", stopOpacity: "1" }} />
                  </linearGradient>
                  <linearGradient id="lgrad2" x1="0%" y1="50%" x2="100%" y2="50%">
                    <stop offset="0%" style={{ stopColor: "#368B95", stopOpacity: "1" }} />
                    <stop offset="100%" style={{ stopColor: "#0E4A51", stopOpacity: "1" }} />
                  </linearGradient>
                </defs>

                <circle
                  id="Linguistic1"
                  r="69.85699"
                  cy="81"
                  cx="81"
                  strokeWidth="18"
                  stroke="url(#lgrad2)"
                  fill="none"
                />
                <circle
                  id="Linguistic2"
                  r="69.85699"
                  cy="81"
                  cx="81"
                  strokeWidth="18"
                  stroke="url(#lgrad)"
                  fill="none"
                />
              </g>
            </svg>
          </div>
        </div>
      </div>
    </div>
  );
}
