import "../../css/index.css";
/**
 * @component RegisterAccountModal
 * @description
 * @param {object} props
 **/

export default function LevelPredictionModal(props) {
  const afterCreation = (e) => {
    props.handleLevelModalState(e);
  };

  return (
    <div className="modalDialog" id="RegisterAccountModal">
      <div className="RegisterAccountModal">
        <div
          className="feedbackHeader"
          id="modalHeader"
          style={{ fontSize: "16px" }}
        >
          Understanding Proficiency
        </div>
        <h5
          style={{
            fontSize: "16px",
            fontWeight: "normal",
            overflow: "auto",
            maxHeight: "470px", // Set a specific height for scrolling
          }}
        >
          Language proficiency refers to the capacity to use a language
          accurately and effectively and measures various aspects such as
          vocabulary, grammar, pronunciation, and comprehension. <br />
          <br />
          In the context of foreign language skills, proficiency can be
          categorized as follows:
          <br />
          <br />{" "}
          <ul>
            <li>
              <b>
                <u>Reading</u>
              </b>
              : The capability to comprehend and interpret written text in a
              foreign language.
              <br />
              <br />
            </li>{" "}
            <li>
              <b>
                <u>Writing</u>
              </b>
              : The skill to write clear and concise text in a foreign language.
              <br />
              <br />
            </li>{" "}
            <li>
              {" "}
              <b>
                <u>Speaking</u>
              </b>
              : The ability to communicate fluently in a foreign language, in
              both formal and informal contexts.
              <br />
              <br />
            </li>{" "}
            <li>
              <b>
                <u>Listening</u>
              </b>
              : The aptitude to understand spoken language in a foreign context.
              <br />
              <br />
            </li>
          </ul>{" "}
          The Online Diagnostic Assessment (ODA) evaluates an individual’s
          proficiency in Reading and Listening based on the Interagency Language
          Roundtable (ILR) proficiency scale. This scale is a set of standards
          for foreign language proficiency utilized by the US government. <br />
          <br />
          ODA is not an official proficiency test. Its primary objective is to
          offer users constructive feedback that aids in their learning journey.
          ODA provides ILR estimates as a benchmark for users to track their
          progress toward improved proficiency.
        </h5>

        <button
          className="goLogin outlined"
          style={{
            fontSize: "16px",
            top: "6px",
            padding: "3px 7px",
          }}
          onClick={(e) => afterCreation(e)}
        >
          Close
        </button>
        <br />
        <br />
      </div>
    </div>
  );
}
