import React, { useState, useContext, useEffect, useCallback } from "react";
import { ErrorContext } from "../../context/ErrorProvider";
import { Navigate, useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import { Helmet } from "react-helmet";

import AuthAdmin from "../../contents/AuthAdmin";
import JWT from "../../contents/JWT";

const EnterCode = (props) => {
  const [, errorDispatch] = useContext(ErrorContext);  
  const location = useLocation();
  const navigate = useNavigate();

  const email = location.state.email;
  const adminToken = localStorage.getItem("admintoken");

  if (email && adminToken === null) {
    const data = { email: email };
    axios({
      method: "POST",
      headers: {
        "content-type": "application/json",
        "X-Auth-Token":
          "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjp7ImlkIjoiNjQ0OTViOTljNmVjZDA3YmM0NWYwNjNlIn0sImlhdCI6MTY4MjYxMTk3NiwiZXhwIjoxNzY5MDExOTc2fQ.vPqRZy3c5WC-OJCQgHJcWxXYs5S_trFBd-NKpAsRbQ4",
      },
      data: data,
      url: "api/user/admin/request/",
    })
      .then((response) => {
        console.log("Request successful:", response.data);
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status >= 400 && error.response.status < 600) {
            //global error dispatch call to show error modal if error received during api call
            errorDispatch({
              type: "UPDATE_ERROR_STATE",
              payload: {
                errorStatusCode: error.response.status,
                errorUserMessage: "New Error",
                errorDevData: error.response
              }
            });
          }
        }
        console.error("Request error:", error);
      });
  }
  const onVerify = () => {
    const token = document.querySelector("#verify").value;

    const data = { token: token, email: email };
    axios({
      method: "POST",
      headers: {
        "content-type": "application/json",
        "X-Auth-Token":
          "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjp7ImlkIjoiNjQ0OTViOTljNmVjZDA3YmM0NWYwNjNlIn0sImlhdCI6MTY4MjYxMTk3NiwiZXhwIjoxNzY5MDExOTc2fQ.vPqRZy3c5WC-OJCQgHJcWxXYs5S_trFBd-NKpAsRbQ4",
      },
      data: data,
      url: "api/user/admin/verify/",
    })
      .then((response) => {
        console.log("Verify successful:", response.data);
        AuthAdmin.login(() => {
          navigate("/adminreport");
          JWT.fetchData().then((result) => {
            if (result) {
              store(
                "admintoken",
                1000 * 60 * 60 * 24 * 30, //1000 * 60 * 60 * 24 * 7 //seven days
                result
              );
            }
          });
        });
      })
      .catch((error) => {
        console.error("Verify error:", error);
        alert("incorrect verification code");
        if (error.response) {
          if (error.response.status >= 400 && error.response.status < 600) {
            //global error dispatch call to show error modal if error received during api call
            errorDispatch({
              type: "UPDATE_ERROR_STATE",
              payload: {
                errorStatusCode: error.response.status,
                errorUserMessage: "New Error",
                errorDevData: error.response
              }
            });
          }
        }
      });
  };

  const resend = () => {
    alert("The code sent again to your email address");
    if (email) {
      const data = { email: email };
      axios({
        method: "POST",
        headers: {
          "content-type": "application/json",
          "X-Auth-Token":
            "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjp7ImlkIjoiNjQ0OTViOTljNmVjZDA3YmM0NWYwNjNlIn0sImlhdCI6MTY4MjYxMTk3NiwiZXhwIjoxNzY5MDExOTc2fQ.vPqRZy3c5WC-OJCQgHJcWxXYs5S_trFBd-NKpAsRbQ4",
        },
        data: data,
        url: "api/user/admin/request/",
      })
        .then((response) => {
          console.log("Request successful:", response.data);
        })
        .catch((error) => {
          console.error("Request error:", error);
          if (error.response) {
            if (error.response.status >= 400 && error.response.status < 600) {
              //global error dispatch call to show error modal if error received during api call
              errorDispatch({
                type: "UPDATE_ERROR_STATE",
                payload: {
                  errorStatusCode: error.response.status,
                  errorUserMessage: "New Error",
                  errorDevData: error.response
                }
              });
            }
          }
        });
    }
  };

  const store = (key, expire, value) => {
    let obj = {
      time: new Date().getTime(),
      expire: expire,
      value: value,
    };
    let objStr = JSON.stringify(obj);
    objStr = window.btoa(objStr);
    localStorage.setItem(key, objStr);
  };

  return (
    <div>
      <Helmet>
        <meta name="robots" content="noindex,nofollow" />
      </Helmet>
      <div>
        <div className="info">
          <p className="infoTitle">Enter Code</p>
          <div>A code has been sent to your email address</div>
          <br />
          <input type="text" name="verify" id="verify" />
          <div>
            <button className="" onClick={onVerify}>
              Verify
            </button>
          </div>
          <p onClick={resend} id="resend" style={{ fontWeight: "bold", cursor: "pointer" }}>
            Resend Code
          </p>
          <br />
        </div>
      </div>
    </div>
  );
};
export default EnterCode;
