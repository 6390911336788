const SurveyQuestion = ({ id, text, value, setValue }) => {
  return (
    <div className="surveyRow">
      <p>{text}</p>
      <div className="surveyQuestionContainer">
        <div className="radioContainer divSpacer">
          <label>
            <input
              type="radio"
              name={id}
              value="5"
              checked={value === "5"}
              onChange={(e) => setValue(e.target.value)}
            />
            strongly agree
          </label>
        </div>

        <div className="radioContainer divSpacer">
          <label>
            <input
              type="radio"
              name={id}
              value="4"
              checked={value === "4"}
              onChange={(e) => setValue(e.target.value)}
            />
            agree
          </label>
        </div>

        <div className="radioContainer divSpacer">
          <label>
            <input
              type="radio"
              name={id}
              value="3"
              checked={value === "3"}
              onChange={(e) => setValue(e.target.value)}
            />
            neutral
          </label>
        </div>

        <div className="radioContainer divSpacer">
          <label>
            <input
              type="radio"
              name={id}
              value="2"
              checked={value === "2"}
              onChange={(e) => setValue(e.target.value)}
            />
            disagree
          </label>
        </div>

        <div className="radioContainer divSpacer">
          <label>
            <input
              type="radio"
              name={id}
              value="1"
              checked={value === "1"}
              onChange={(e) => setValue(e.target.value)}
            />
            strongly disagree
          </label>
        </div>
      </div>
    </div>
  );
};

export default SurveyQuestion;
