import React from "react";
import PassageAnswerOption from "../test/PassageAnswerOption";
import Next from "../../../../../components/testlet/Next";
import Audio from "../../../../../components/testlet/Audio";

export default function Test(props) {
  const renderAssessment = (options, index) => {
    const passages = options.questionTexts;
    const passageLen = passages.length;
    const answerChoices = options.questions;
    const item = options._id;

    return (
      <PassageAnswerOption
        key={index}
        optionPassage={passages}
        optionChoice={answerChoices}
        optionLen={passageLen}
        testitem={item}
        optionNumber={index}
        answerFieldsID={options.answerFields[0]._id}
        actID={props.actID}
        lang={props.lang}
        clickCounter={props.clickCounter}
        onAnswerSelected={props.onAnswerSelected}
        onAnswerSelectedMod={props.onAnswerSelectedMod}
        onPlayMainAudio={props.onPlayMainAudio}
      />
    );
  };

  return (
    <div className="templateInner GLCB" key={props.anchorKey}>
      <Audio
        key={props.audKey}
        audioFN1={props.audioFile1}
        audioFN2={props.audioFile2}
        audioFN3={props.audioFile3}
        instruction={props.instruction}
        audSequence={props.audSequence}
        lang={props.lang}
        actID={props.actID}
      />
      <div className="passageDiv">{props.assessment.map(renderAssessment)}</div>
      <Next
        onEvaluation={props.onEvaluation}
        score={props.score}
        hint={props.hint}
        clickCounter={props.clickCounter}
        actID={props.actID}
        navLen={props.navLen}
        navCrrtItem={props.navCrrtItem}
      />
    </div>
  );
}
