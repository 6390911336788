import { redirect } from "react-router-dom";

/**
 * @component AuthAdmin
 * @description handles authenticating users for basic access pages
 * @todo clean up left over code and add comments
 **/

class AuthAdmin {
  constructor() {
    this.authenticated = false;
  }

  login(cb) {
    this.authenticated = true;
    cb();
  }

  logout(cb) {
    this.authenticated = false;
    //purge window var
    // window.localStorage.clear();
    //purge admintoken
    localStorage.removeItem("admintoken");
    //force reload after logout
    window.location.href = "/admin";
    cb();
  }

  //TODO: AuthAdmin code was preventing the use of back button,
  // below code inside !this.authenticated fixes that, but
  // we likely need to ensure the local storage item is legit
  // before production (JWT check?)

  getToken() {
    return JSON.parse(localStorage.getItem("admintoken"));
  }

  checkToken() {
    const token = JSON.parse(localStorage.getItem("admintoken"));
    if (!token || token === null) {
      return redirect("/admin");
    } else {
      return token;
    }
  }

  isAuthenticated() {
    if (!this.authenticated) {
      const isAuth = JSON.parse(localStorage.getItem("admintoken"));
      if (isAuth && isAuth !== null) {
        this.authenticated = true;
      }
    }
    return this.authenticated;
  }
}

export default new AuthAdmin();
