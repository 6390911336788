// import React, { Component } from "react";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

/**
 * @component RedirectBoundary
 * @description this function redirects to the dashboard upon uncaught error of an element
 * 
 */

export default function RedirectBoundary() {
  const navigate = useNavigate();

  useEffect(()=>{
    navigate("/dashboard", { replace: true });
  },[]);
  
}
