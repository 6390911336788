/**
 *  Details.js specific non-state funtions should live here
 *
 */

export default function nonFunctions() {
  //document.addEventListener("DOMContentLoaded", function () {
  function count(Num, endNumber, counterID) {
    setTimeout(function () {
      Num++;
      if (Num < endNumber) {
        document.querySelector("#" + counterID).textContent = Num + "%";
        count(Num, endNumber, counterID);
      }
    }, 27);
  }

  let initialized = false;

  let selEl = document.querySelector("#targetlevel");
  if (selEl !== null) {
    selEl.click(function () {
      if (!initialized) {
        count(0, 67, "countContentTarget");
        count(0, 50, "countLinguisticTarget");
        // document.querySelector("#duper1").classList.add("contentArrowTarget");
        // document.querySelector("#duper2").classList.add("linguisticArrowTarget");
      }
      initialized = true;
    });
  }
  //});
}
