import React, { useEffect, useState, useCallback } from "react";
import querySelectors from "../../functions/introFunctions";
import AboutModal from "../../components/learnmore/AboutModal";

export default function Intro() {
  /*load the querySelectors from seperate js file*/
  useEffect(() => {
    querySelectors();
  }, []);

  const [, setClick] = useState(false); //TODO: (Review-->click is never used, is this intended?)
  const closeMobileMenu = useCallback(() => {
    document.querySelector(".bodyItems1").style.display = "none";
    // document.querySelector(".bodyItems2").style.display = "none";
    document.querySelector(".bodyItems3").style.display = "none";
    document.querySelector(".evalInfo").style.display = "none";
    document.querySelector(".switch").style.display = "inline";
    setClick(false);
  }, []);

  const [state, setState] = useState({
    aboutModal: false,
  });

  const about = () => {
    setState({ ...state, aboutModal: true });
  };

  const handleAboutModalState = useCallback(
    (e) => {
      setState({ ...state, aboutModal: false });
    },
    [state]
  );

  return (
    <div>
      <div className="hero">
        <div className="left">
          <div className="siteTitle">Assess your language skills</div>
          <div className="siteTagline">
            The Online Diagnostic Assessment identifies your strengths and needs
            in foreign language&nbsp;study.
            <br />
            <span className="learnMore" onClick={about}>
              learn more&#8230;
            </span>
            {state.aboutModal && (
              <AboutModal handleAboutModalState={handleAboutModalState} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
