import { redirect } from "react-router-dom";
import axios from "axios";

/** 
 * @component Auth
 * @description handles authenticating users for basic access pages
 * @todo clean up left over code and add comments
**/

class Auth {
  constructor() {
    this.authenticated = false;
  }

  login(cb) {
    this.authenticated = true;
    cb();
  }

  logout(cb) {    
    this.authenticated = false;    
    //purge window var    
    window.localStorage.clear();
    //purge token
    localStorage.removeItem("token");       
    //force reload after logout
    window.location.href = "/";   
    cb();
  }

  //TODO: Auth code was preventing the use of back button,
  // below code inside !this.authenticated fixes that, but
  // we likely need to ensure the local storage item is legit
  // before production (JWT check?)

  getToken() {
    return JSON.parse(localStorage.getItem("token")); 
  }

  checkToken() {
    const token = JSON.parse(localStorage.getItem("token"));
    if (!token || token === null) {
      return redirect("/");
    } else {
      return token;   
    }    
  }

  isAuthenticated() {
    if (!this.authenticated) {
      const isAuth = JSON.parse(localStorage.getItem("token"));
      if(isAuth && isAuth !== null) {
        this.authenticated = true;
      }    
    }
    return this.authenticated;
  }  
  
}

export default new Auth();