import React, { useState, useEffect, useContext, useCallback } from "react";
import Test from "./components/Test";
import Footer from "../../../../components/footer/Footer";
import "../../../../css/index.css";
//import fontsizeFunctions from "../../../../functions/fontsizeFunctions";
import draggableFunctions from "../../../../functions/draggableFunctions";
import { AssessmentContext } from "../../../../context/AssessmentProvider";

const HTO = (props) => {
  const testData = props.testData;
  const [state, setState] = useState({
    actID: testData.testitems[props.counter]._id,
    modality: testData.modality,
    template: testData.testitems[props.counter].template,
    skill: testData.proficiencyLevel,
    passage: testData.testitems[props.counter].mainPassage,
    questionText: testData.testitems[props.counter].questionText,
    dir: testData.language.isRightToLeft,
    lang: testData.language._id,
    userAnswer: [],
    scoredAnswer: [],
    correctAnswer: testData.testitems[props.counter].answerFields[0].answerKeywords[0].matchingPatterns,
    answerField: testData.testitems[props.counter].answerFields[0]._id
  });
  const [scoredAnswer, setScoredAnswer] = useState([]);
  const [assessmentState] = useContext(AssessmentContext);

  state.userAnswer.length = testData.testitems[props.counter].answerFields.length;

  useEffect(() => {
    //fontSizeFunctions();
    draggableFunctions();
  }, []);

  useEffect(() => {
    //disable next/submit until previous api call is complete; prevents overrunning api calls to change same data
    if (props.buttonWaiting) {
      const apiWaitButtons = document.querySelectorAll(".nextBtn, .submitBtn");
      const titleChanger = document.querySelector(".nextSubmitParentDiv");
      apiWaitButtons.forEach((item, index) => {
        item.classList.add("waitingForAPI");
        if (item.classList.contains("disabled")) {
          titleChanger.title =
            "Please answer the questions above before proceeding. You will also need to wait for the system to record your previous answer before proceeding.";
        } else {
          titleChanger.title = "Please wait for your assessment to save your previous answer before proceeding.";
        }
      });
      titleChanger.classList.remove("nextSubmitParentDivUnanswered");
      titleChanger.classList.add("nextSubmitParentDivWaiting");
    } else {
      const titleChanger = document.querySelector(".nextSubmitParentDiv");
      const apiWaitButtons = document.querySelectorAll(".nextBtn, .submitBtn");
      apiWaitButtons.forEach((item, index) => {
        item.classList.remove("waitingForAPI");
        if (item.classList.contains("disabled")) {
          titleChanger.title = "Please attempt to answers all questions before proceeding.  Note: If you feel your assessment is stuck and you can not proceed, you may skip to the next question by holding SHIFT and clicking this button.";
        } else {
          titleChanger.title = "Click to submit your answer(s).";
        }
      });
      titleChanger.classList.remove("nextSubmitParentDivWaiting");
      titleChanger.classList.add("nextSubmitParentDivUnanswered");
    }
  }, [props.buttonWaiting]);

  const getHTOData = useCallback(
    (IDX, ID, Val) => {
      const index = IDX;
      const value = Val;
      const id = ID;

      const len = state.userAnswer.length;

      let getAnswer = [];

      let new_state = Object.assign({}, state);
      let a = new_state.userAnswer;
      a[index] = value;

      let num = 0;
      if (state.userAnswer[0] !== undefined) {
        num++;
      }
      if (value.length === 0) {
        num--;
      }

      if (num === len) {
        document.querySelector(".nextBtn").classList.remove("disabled");
        const titleChanger = document.querySelector(".nextSubmitParentDiv");
        titleChanger.classList.remove("nextSubmitParentDivUnanswered");
        if (titleChanger.classList.contains("nextSubmitParentDivWaiting")) {
          titleChanger.title = "Please wait for your assessment to save your previous answer before proceeding.";
        } else {
          titleChanger.title = "Click to submit your answers.";
        }

        let passingCheck = false;
        const userAns = a[index];
        const crrtAns = state.correctAnswer;
        const crrtAnsLen = state.correctAnswer.length;

        for (let i = 0; i < crrtAnsLen; i++) {
          if (userAns === crrtAns[i]) {
            passingCheck = true;
            getAnswer.push(userAns);
            break;
          }
        }

        setScoredAnswer([
          {
            uiControlID: id,
            testlet: testData._id,
            testitem: state.actID,
            answerField: state.answerField,
            keywordMatches: getAnswer,
            userAnswer: userAns,
            isPassed: passingCheck
          }
        ]);
      } else {
        document.querySelector(".nextBtn").classList.add("disabled");
        const titleChanger = document.querySelector(".nextSubmitParentDiv");
        titleChanger.classList.add("nextSubmitParentDivUnanswered");
        if (titleChanger.classList.contains("nextSubmitParentDivWaiting")) {
          titleChanger.title = "Please wait for your assessment to save your previous answer before proceeding.";
        } else {
          titleChanger.title = "Please attempt to re-answer at least one incorrect question before proceeding.";
        }
        setScoredAnswer([]);
      }
    },
    [state]
  );

  const handleEval = useCallback((name) => {
    const btnName = name;
    props.parentCallback(JSON.stringify(scoredAnswer), btnName);
  });

  const renderTest = () => {
    return (
      <>
        <Test
          passage={state.passage}
          actID={state.actID}
          dir={state.dir}
          lang={state.lang}
          questionText={state.questionText}
          ongetHTOData={getHTOData}
          navLen={props.navLen}
          navCrrtItem={props.navCrrtItem}
          onClickNextBtn={props.clickNextBtn}
          onSubmit={props.clickSubmit}
          onEvaluation={handleEval}
        />
        <Footer actID={state.actID} mod={state.modality} template={state.template} skill={state.skill} />
      </>
    );
  };

  return <div>{renderTest()}</div>;
};

export default HTO;
