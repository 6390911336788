import React from "react";
import dompurify from "dompurify";
import Footer from "../../footer/Footer";
import menu from "./menucontents/MenuContents.json";

export default function Policy() {
  const sanitizer = dompurify.sanitize;
  const policy = menu[0].policy;

  return (
    <>
      <h4 className="policy">PRIVACY POLICY</h4>
      <h4>
        <div
          style={{ fontWeight: "normal", fontSize: "16px" }}
          dangerouslySetInnerHTML={{
            __html: sanitizer(policy),
          }}
        />
      </h4>
      <br />
      <Footer showReportProblem={false} />
    </>
  );
}
