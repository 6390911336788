import React, { useState, useEffect } from "react";
import axios from "axios";

const ItemItemDisplay = (props) => {
  const [data, setData] = useState([]);

  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: "ascending",
  });

  const mod = props.mod;
  const lang = props.lang;
  const testlet = props.testlet;
  const userLvl = props.level;
  const startDate = props.fromDate;
  const endDate = props.toDate;

  useEffect(() => {
    const user = {
      lang: lang,
      mod: mod,
      testlet: testlet,
      userLvl: userLvl,
      startDate: startDate,
      endDate: endDate,
    };
    axios
      .get("/api/adminReport/itemItemCorrelationReport", {
        params: user,
      })
      .then((res) => {
        setData(res.data);
      })
      .catch((error) => {
        console.log("API request failed");
      });
  }, [lang, mod, testlet, userLvl, startDate, endDate]);

  const listStyle = {
    textAlign: "left",
  };

  const tableStyle = {
    color: "#187680",
    fontWeight: "normal",
  };

  const failStyle = {
    color: "#cb3e3e",
    fontWeight: "normal",
  };

  const totalItems = data[1]?.totalItems || [];
  const passFailData = data[2]?.passFail || [];
  const PBCValsData = data[3]?.PBCVals || [];
  const PCCValsData = data[4]?.PCCVals || [];
  const individualData = data[5]?.individualData || [];

  const sortedData = React.useMemo(() => {
    let sortableData = [...individualData];
    if (sortConfig !== null) {
      sortableData.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === "ascending" ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === "ascending" ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableData;
  }, [individualData, sortConfig]);

  const requestSort = (key) => {
    let direction = "ascending";
    if (
      sortConfig &&
      sortConfig.key === key &&
      sortConfig.direction === "ascending"
    ) {
      direction = "descending";
    }
    setSortConfig({ key, direction });
  };

  const renderPassFailSummary = (data) => {
    return data.map((item, index) => (
      <div
        style={{
          ...listStyle,
          backgroundColor: index % 2 === 0 ? "#d0d0d0" : "#ffffff",
          height: "25px",
          paddingTop: "5px",
          paddingLeft: "10px",
        }}
        key={index}
      >
        A({index}) = {item.itemId}; (Usage Frequency: {item.total} = Pass:
        {item.pass} + Fail: {item.fail})
      </div>
    ));
  };

  const renderPBCSummary = (data) => {
    return data.map((item, index) => (
      <div
        style={{
          ...listStyle,
          backgroundColor: index % 2 === 0 ? "#d0d0d0" : "#ffffff",
          height: "25px",
          paddingTop: "5px",
          paddingLeft: "10px",
        }}
        key={index}
      >
        A({index}) = {item.itemId}; (PBC[{index}] ={" "}
        {item.PBC === "Data Error" ? "N/A" : item.PBC})
      </div>
    ));
  };

  const renderPCCSummary = (data) => {
    return data.map((item, index) => (
      <div
        style={{
          ...listStyle,
          backgroundColor: index % 2 === 0 ? "#d0d0d0" : "#ffffff",
          height: "25px",
          paddingTop: "5px",
          paddingLeft: "10px",
        }}
        key={index}
      >
        {item.itemId} = {item.PCC === "Data Error" ? "N/A" : item.PCC}
      </div>
    ));
  };

  const renderTable = (data) => {
    return (
      <table>
        <thead>
          <tr>
            <th
              onClick={() => requestSort("user")}
              style={{ cursor: "pointer" }}
            >
              Email
            </th>
            <th
              onClick={() => requestSort("itemId")}
              style={{ cursor: "pointer" }}
            >
              ItemID
            </th>
            <th
              onClick={() => requestSort("isPassed")}
              style={{ cursor: "pointer" }}
            >
              Pass/Fail
            </th>
            <th
              onClick={() => requestSort("proficiencyLevel")}
              style={{ cursor: "pointer" }}
            >
              Level
            </th>
            <th
              onClick={() => requestSort("starttime")}
              style={{ cursor: "pointer" }}
            >
              Test Date
            </th>
          </tr>
        </thead>
        <tbody>
          {data.map((item, index) => (
            <tr key={index}>
              <td style={tableStyle}>{item.user}</td>
              <td
                style={{
                  ...tableStyle,
                  textAlign: "left",
                  paddingLeft: "15px",
                }}
              >
                {item.itemId}
              </td>
              <td style={item.isPassed === "Fail" ? failStyle : tableStyle}>
                {item.isPassed}
              </td>

              <td style={tableStyle}>{item.proficiencyLevel}</td>
              <td
                style={{
                  ...tableStyle,
                  textAlign: "left",
                  paddingLeft: "15px",
                }}
              >
                {new Date(item.starttime).toLocaleDateString()}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  if (data.length === 0) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <h4 style={listStyle}>
        Total {data[0]?.totalUnique} Testlet Items: Short Code & Summary
      </h4>
      <div style={listStyle}>{totalItems} Items found</div> <br />
      {renderPassFailSummary(passFailData)} <br />
      <h4 style={listStyle}>
        PBC value (Point-Biserial-Correlation) of each testlet item
      </h4>
      {renderPBCSummary(PBCValsData)} <br />
      <h4 style={listStyle}>
        PCC value (Pearson-Correlation-Coefficient) of each pair
      </h4>
      {renderPCCSummary(PCCValsData)} <br />
      <h4 style={listStyle}>Detailed List</h4>
      <div style={listStyle}>{totalItems} Items found</div> <br />
      {renderTable(sortedData)}
    </div>
  );
};

export default ItemItemDisplay;
