import React from "react";
import dompurify from "dompurify";
import FontSize from "../../../../../components/fontsize/FontSize";

export default function QuestionPassage(props) {
  const sanitizer = dompurify.sanitize;
  const RTL = props.dir;
  const dir = RTL ? "RTL" : "";

  const curPassage = props?.passage !== null ? props.passage : "";
  let newPassage = curPassage.replace(/<u>/gi, '<span class="textHighlight">');
  newPassage = newPassage.replace(/<\/u>/gi, "</span>");

  if (dir === "RTL") {
    newPassage = newPassage.replace(/<strong>/gi, '<span class="textBold">');
    newPassage = newPassage.replace(/<\/strong>/gi, "</span>");
  }

  newPassage = newPassage
    .replace(/\r\n/g, "<br />")
    .replace(/\n\r/g, "<br />")
    .replace(/\r/g, "<br />")
    .replace(/\n/g, "<br />")
    .replace(/<br \/><br \/>$/g, "")
    .replace(/<br \/><br \/>\s*$/g, "")
    .replace(/\s*$/g, "")
    .replace(/<p><\/p>$/g, "")
    .replace(/<p>\s*<\/p>$/g, "");
  newPassage = newPassage
    .replace(/<P>/g, "")
    .replace(/<p>/g, "")
    .replace(/<\/P>/g, "<br /><br />")
    .replace(/<\/p>/g, "<br /><br />")
    .replace(/(<br \/>\s*){3,4}/g, "<br /><br />")
    .replace(/^(<br \/>\s*){2,4}/g, "");

  document.querySelectorAll(".radioButton").forEach(function (event) {
    event.style.display = "none";
  });

  return (
    <div className={[dir, "textPassage noSelect"].join(" ")}>
      <FontSize />
      <div
        dangerouslySetInnerHTML={{
          __html: sanitizer(newPassage),
        }}
      />
    </div>
  );
}
