import React from "react";
import styled, { keyframes } from "styled-components";

export default function GrammarOverview(props) {
  const grID = props.grID;
  const grTotal = props.grTotal;
  const print = props.onPrint;

  //let userEmail = ""; //TODO: (Review-->userEmail assigned but never used, can we remove?)
  let targetLang = "";
  //let levelDescript = ""; //TODO: (Review-->levelDescript assigned but never used, can we remove?)
  let skillLevel = "";
  let testDate = "";
  let timeSpent = "";
  let score = "";
  let readableDate = "";

  for (let i = 0; i < grTotal.length; i++) {
    if (grTotal[i]._id === grID) {
      //userEmail = grTotal[i].user.email; //TODO: (Review-->userEmail assigned but never used, can we remove?)
      targetLang = grTotal[i].language.description;
      //levelDescript = grTotal[i].grammarLevel.description; //TODO: (Review-->levelDescript assigned but never used, can we remove?)
      skillLevel = grTotal[i].grammarLevel._id;
      testDate = grTotal[i].endtime;
      readableDate = Date.parse(testDate);
      readableDate = new Date(readableDate);
      readableDate = readableDate.toLocaleString("default", {
        month: "short",
        day: "numeric",
        year: "numeric"
      });
      timeSpent = grTotal[i].timeSpent;
      score = grTotal[i].overallCorrectPercentage;
    }
  }

  const language = targetLang;
  const modality = "Grammar";
  const contentScore = score + 1;
  const contentAngle = 3.6 * (contentScore - 1);
  const estimatedLevel = skillLevel;

  let tomaxposition = "";
  if (estimatedLevel === "2+/3") {
    tomaxposition = "0%";
  } else if (estimatedLevel === "1+/2") {
    tomaxposition = "25%";
  } else if (estimatedLevel === "1") {
    tomaxposition = "60%";
  } else {
    tomaxposition = "75%";
  }

  //animation disabled because it breaks pdf, remove this if restoring Rotate
  //eslint-disable-next-line
  const contentArrow = keyframes`
  to {
    transform: rotate(${contentAngle}deg);
  }
`;

  const Rotate = styled.div`
    width: 100px;
    height: 100px;
    -webkit-animation: ${contentArrow}0.75s ease-out forwards;
    animation: ${contentArrow} 0.75s ease-out forwards;
    border-radius: 50%;
    display: inline-block;
    z-index: 999;
    top: -3x;
    position: relative;
  `;
  /* animation disabled because it breaks pdf, add/remove in Rotate to restore
  -webkit-animation: ${contentArrow}0.75s ease-out forwards;
    animation: ${contentArrow} 0.75s ease-out forwards;
  */

  const RotateHidden = styled.div`
    width: 100px;
    height: 100px;
    transform: rotate(${contentAngle}deg);
    border-radius: 50%;
    display: inline-block;
    z-index: 999;
    top: -3px;
    position: relative;
    display: none;
  `;

  const capitalizeLetter = (item) => {
    return item.replace(/\b(\w)/g, (s) => s.toUpperCase());
  };

  return (
    <div className="pageBreak">
      <h1 className="headingLeft">
        {capitalizeLetter(
          language
            .replace(/^(North_Korean|North Korean|Korean\/North Korean|Korean\/North_Korean)$/i, "Korean (South & North)")
            .replace(/^Korean$/i, "Korean (South)")
            .replace("_", " ")
        )}{" "}
        {capitalizeLetter(modality)} Assessment
      </h1>
      <div className="headingRight">
        <p>Taken: {readableDate}</p>
        <p>Time: {timeSpent} minutes</p>
      </div>

      <div className="clearFix noMargin" />

      <button className="smallBtn print" data-html2canvas-ignore="true" onClick={print}>
        Print
      </button>
      <div className="chartsGridContainer2">
        {/* Removed requested by Sun 1/19/24 - need to rework according to Issue #260
         <div className="gridItem">
          <div className="ILRScore">
            <div className="yourScore" tomaxposition={tomaxposition}>
              Level<span>{estimatedLevel}</span> Grammar
            </div>
            <ul>
              <li>
                LEVEL 2+/3 <br />
                <span>Working/Professional proficiency</span>
              </li>
              <li>
                LEVEL 1+/2 <br />
                <span>Elementary/Limited working proficiency</span>
              </li>
              <li>
                LEVEL 1 <br />
                <span>Basic elementary proficiency</span>
              </li>
            </ul>
          </div>
        </div> */}

        <div className="gridItem">
          <div className="contentQuestions">
            <h2 id="countContentGR" scoregr={contentScore}>
              0%
            </h2>
            <span>Summary</span>
            <div className="centerer">
              <Rotate data-html2canvas-ignore="true">
                <div className="contentArrow" />
              </Rotate>
              <RotateHidden>
                <div className="contentArrow" />
              </RotateHidden>
            </div>
            <svg id="contentChartGR" width="160" height="160" xmlns="http://www.w3.org/2000/svg">
              <g>
                {/*
                <circle
                  className="Excellent"
                  r="69.85699"
                  cy="81"
                  cx="81"
                  strokeWidth="18"
                  stroke="#91D59C"
                  fill="none"
                />

                <circle
                  className="Pass"
                  r="69.85699"
                  cy="81"
                  cx="81"
                  strokeWidth="18"
                  stroke="#73B1DA"
                  fill="none"
                />

                <circle
                  className="Fail"
                  r="69.85699"
                  cy="81"
                  cx="81"
                  strokeWidth="18"
                  stroke="#d57b7e"
                  fill="none"
                />
                */}
                <path
                  className="Fail"
                  strokeWidth="18"
                  stroke="#d57b7e"
                  fill="none"
                  d=" M 82 11 A 70 70 0 1 1 20 115"
                />
                <path
                  className="Pass"
                  strokeWidth="18"
                  stroke="#73B1DA"
                  fill="none"
                  d=" M 20 115 A 70 70 0 0 1 14 59"
                />
                <path
                  className="Excellent"
                  strokeWidth="18"
                  stroke="#91D59C"
                  fill="none"
                  d=" M 14 59 A 70 70 0 0 1 81 11"
                />
              </g>
            </svg>
            <div className="flags">
              <div className="failFlag">Unsatisfactory</div>
              <div className="passFlag">Satisfactory</div>
              <div className="excellentFlag">Excellent</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
