import React from "react";
import dompurify from "dompurify";

export default function AnswerField(props) {
  const sanitizer = dompurify.sanitize;
  const questions = props.questions;
  const quesLen = questions.length;

  const createAnswerField = () => {
    let items = [];
    if (quesLen <= 1) {
      const actID = props.actID + "_0";
      items.push(
        <div className="answerSection" key={actID}>
          <label htmlFor="userInputQSA">
            Your Answer
            <form autoComplete="off">
              <textarea
                className="QSAAnswer"
                id={actID}
                autoComplete="off"
                onBlur={props.onLoseFocusCheckNLP}
                onChange={props.onAnswerSelected}
                spellCheck={true}
                onPaste={(e) => {
                  e.preventDefault();
                  return false;
                }}
                onCopy={(e) => {
                  e.preventDefault();
                  return false;
                }}
                onCut={(e) => {
                  e.preventDefault();
                  return false;
                }}
              />
            </form>
            {props.invalidEnInput.find((thisEl) => thisEl === actID) && (
              <div className="invalidInputLanguageDiv">Please enter your answer in English.</div>
            )}
          </label>
        </div>
      );
    } else {
      for (let i = 0; i < quesLen; i++) {
        const actID = props.actID + "_" + i;
        const question = questions[i].value;

        items.push(
          <div className="answerSection" key={question}>
            <br />
            <label htmlFor="userInputQSA">
              {/* <h4>{question}</h4> */}
              <div
                style={{
                  color: "#187680",
                  fontWeight: "strong",
                  fontSize: "18px"
                }}
                dangerouslySetInnerHTML={{
                  __html: sanitizer(question)
                }}
              />
              <form autoComplete="off">
                <textarea
                  autoComplete="off"
                  className="QSAAnswer"
                  id={actID}
                  onBlur={props.onLoseFocusCheckNLP}
                  onChange={props.onAnswerSelected}
                  spellCheck={true}
                  onPaste={(e) => {
                    e.preventDefault();
                    return false;
                  }}
                  onCopy={(e) => {
                    e.preventDefault();
                    return false;
                  }}
                  onCut={(e) => {
                    e.preventDefault();
                    return false;
                  }}
                />
              </form>
              {props.invalidEnInput.find((thisEl) => thisEl === actID) && (
                <div className="invalidInputLanguageDiv">Please enter your answer in English.</div>
              )}
            </label>
          </div>
        );
      }
    }
    return items;
  };
  return createAnswerField();
}
