import React from "react";
import dompurify from "dompurify";

export default function AnswerOption(props) {
  const sanitizer = dompurify.sanitize;
  const radioGroup = "radioGroup_" + props.actID + "_0";
  const audioOption = props.question;
  const len = audioOption.length;

  const itemCheck = (event) => {
    for (let i = 0; i < len; i++) {
      document.querySelectorAll(".gridItem")[i].classList.remove("selectedAnswer");
    }
    event.currentTarget.classList.add("selectedAnswer");
  };

  document.querySelectorAll(".radioButton").forEach(function (event) {
    event.style.display = "none";
  });

  const createTextRadioButton = () => {
    let radioButton = [];
    for (let i = 0; i < len; i++) {
      const radioID = props.actID + "_" + i;
      const item = audioOption[i].value;
      const letter = String.fromCharCode(i + "A".charCodeAt(0));
      radioButton.push(
        <div key={i}>
            <input
              type="radio"
              className="radioButton"
              name={radioGroup}
              id={radioID}
              value={i}
              onChange={props.onAnswerSelected}
            />
          <label htmlFor={radioID}>
            <div className="gridItem" onClick={itemCheck}>
              <span style={{ marginRight: "30px" }}>{letter}:</span>
              <span
                style={{ fontWeight: "normal" }}
                dangerouslySetInnerHTML={{
                  __html: sanitizer(item)
                }}
              />
            </div>
          </label>
        </div>
      );
    }
    return radioButton;
  };
  return createTextRadioButton();
}
