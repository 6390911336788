import React, { useState, useEffect } from "react";

const TestletUsageFrequencyDisplay = (props) => {
  const data = props.content;
  const isData = props.isData;
  const checkAPI = props.checkAPI;
  const firstCheck = props.firstCheck;

  const [sortedColumn, setSortedColumn] = useState("testletId");
  const [sortDirection, setSortDirection] = useState("asc");

  const handleSort = (column) => {
    if (column === sortedColumn) {
      // If the same column is clicked again, toggle the sort direction
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      // If a different column is clicked, set it as the new sorting column with ascending direction
      setSortedColumn(column);
      setSortDirection("asc");
    }
  };

  const sortData = (data, column, direction) => {
    // Create a copy of the data array to avoid mutating the original data
    const sortedArray = [...data];

    // Perform sorting based on the column and direction
    sortedArray.sort((a, b) => {
      if (column === "testletId") {
        // Sort by testletId (alphabetically)
        if (direction === "asc") {
          return a._id._id.localeCompare(b._id._id);
        } else {
          return b._id._id.localeCompare(a._id._id);
        }
      } else if (column === "usageFrequency") {
        // Sort by usageFrequency (numerically)
        if (direction === "asc") {
          return a.count - b.count;
        } else {
          return b.count - a.count;
        }
      } else if (column === "itemNumber") {
        // Sort by itemNumber (numerically)
        if (direction === "asc") {
          return a._id._id.localeCompare(b._id._id);
        } else {
          return b._id._id.localeCompare(a._id._id);
        }
      } else {
        // Default case: do not change the order
        return 0;
      }
    });

    return sortedArray;
  };

  const sortedData = sortData(data, sortedColumn, sortDirection);
  const itemCount = sortedData.length;

  // const sortedData = [...data].sort((a, b) => {
  //   if (sortDirection === "asc") {
  //     return a[sortedColumn] > b[sortedColumn] ? 1 : -1;
  //   } else {
  //     return a[sortedColumn] < b[sortedColumn] ? 1 : -1;
  //   }
  // });

  // const itemCount = sortedData.length;

  if ((!isData && checkAPI) || (!isData && !checkAPI && firstCheck)) {
    return <h3>No data was found</h3>;
  }
  if (isData) {
    return (
      <div>
        <div>
          <h2>
            {itemCount} Item{itemCount !== 1 ? "s" : ""} Found
          </h2>
          <div className="table-container">
            <div className="table-row table-header">
              <div className="table-column" style={{ cursor: "auto" }}>
                Item Number
              </div>
              <div
                className="table-column"
                onClick={() => handleSort("testletId")}
              >
                Testlet ID
              </div>
              <div
                className="table-column"
                onClick={() => handleSort("usageFrequency")}
              >
                Usage Frequency
              </div>
            </div>
            {sortedData.map((item, index) => (
              <div className="table-row" key={item._id._id}>
                <div className="table-column" style={{ cursor: "auto" }}>
                  {index + 1}
                </div>
                <div className="table-column" style={{ cursor: "auto" }}>
                  {item._id._id}
                </div>
                <div className="table-column" style={{ cursor: "auto" }}>
                  {item.count}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }
};

export default TestletUsageFrequencyDisplay;
