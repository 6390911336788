import React, { useState, useEffect } from "react";
import axios from "axios";
import AdminFooter from "./adminComponents/AdminFooter";
import TagPanelDisplay from "./adminDisplay/TagPanelDisplay";

const TagPanel = () => {
  const initialState = {
    content: [],
    language: [],
    selectedLanguage: "",
    selectedModality: "",
    firstSelction: "",
    secondSelction: "",
    thirdSelction: "",
    tag: false,
    topic: false,
  };

  const [isButtonClicked, setIsButtonClicked] = useState(false);

  const [state, setState] = useState(initialState);
  const {
    language,
    selectedLanguage,
    selectedModality,
    firstSelction,
    secondSelction,
    thirdSelction,
  } = state;

  useEffect(() => {
    if (selectedModality === "reading") {
      axios
        .get('/api/language?modalities="reading"&_sort={"sequence": 1}')
        .then((res) => {
          setState({ ...state, language: res.data });
        });
    } else if (selectedModality === "listening") {
      axios
        .get('/api/language?modalities="listening"&_sort={"sequence": 1}')
        .then((res) => {
          setState({ ...state, language: res.data });
        });
    }
  }, [selectedModality]);

  let langDropdown = language.map((item, index) => (
    <option key={index} value={item._id}>
      {item.displayName}
    </option>
  ));

  const handleLanguageChange = (event) => {
    const initial = {
      firstSelction: "",
      secondSelction: "",
      thirdSelction: "",
    };

    setState({ ...state, selectedLanguage: event.target.value, ...initial });
    setIsButtonClicked(false);
  };

  const handleModalityChange = (event) => {
    const initial = {
      selectedLanguage: "",
      firstSelction: "",
      secondSelction: "",
      thirdSelction: "",
      tag: false,
      topic: false,
    };
    setState({
      ...state,
      selectedModality: event.target.value,
      ...initial,
    });

    setIsButtonClicked(false);
  };

  const handleFirstSelChange = (event) => {
    setState({ ...state, firstSelction: event.target.value });
    setIsButtonClicked(false);
  };

  const handleSecondSelChange = (event) => {
    setState({ ...state, secondSelction: event.target.value });
    setIsButtonClicked(false);
  };

  const handleThirdSelChange = (event) => {
    setState({ ...state, thirdSelction: event.target.value });
    setIsButtonClicked(false);
  };

  const getValues = (event) => {
    const clickedButtonValue = event.target.getAttribute("value");
    const user = {
      lang: selectedLanguage,
      mod: selectedModality,
      firstSel: firstSelction,
      secondSel: secondSelction,
      thirdSel: thirdSelction,
    };

    const topicUser = {
      lang: selectedLanguage,
      mod: selectedModality,
    };

    const TagValuesAreValid =
      selectedLanguage !== "Select" &&
      selectedModality !== "Select" &&
      firstSelction !== "Select" &&
      secondSelction !== "Select" &&
      thirdSelction !== "Select" &&
      selectedLanguage !== "" &&
      selectedModality !== "" &&
      firstSelction !== "" &&
      secondSelction !== "" &&
      thirdSelction !== "" &&
      firstSelction !== secondSelction &&
      firstSelction !== thirdSelction &&
      secondSelction !== thirdSelction;

    const TopicValuesAreValid =
      selectedLanguage !== "Select" &&
      selectedModality !== "Select" &&
      selectedLanguage !== "" &&
      selectedModality !== "";

    if (TagValuesAreValid) {
      console.log("values=", {
        lang: selectedLanguage,
        mod: selectedModality,
        firstSel: firstSelction,
        secondSel: secondSelction,
        thirdSel: thirdSelction,
      });

      if (clickedButtonValue === "tag") {
        axios
          .get("/api/adminReport/langTagReport", { params: user })
          .then((res) => {
            setState({ ...state, content: res.data, tag: true, topic: false });
            setIsButtonClicked(true);
            console.log("Tag=", JSON.stringify(res.data));
          })
          .catch((error) => {
            if (error.response) {
              if (error.response.status >= 400 && error.response.status < 600) {
              }
            }
          });
      }
    } else {
      if (clickedButtonValue === "tag") {
        alert("Select all items and choose different values for order menus");
      }
    }
    if (TopicValuesAreValid) {
      console.log("values=", {
        lang: selectedLanguage,
        mod: selectedModality,
      });
      if (clickedButtonValue === "topics") {
        axios
          .get("/api/adminReport/topicReport", {
            params: topicUser,
          })
          .then((res) => {
            setState({ ...state, content: res.data, tag: false, topic: true });
            setIsButtonClicked(true);
            console.log("Topics=", JSON.stringify(res.data));
          })
          .catch((error) => {
            if (error.response) {
              if (error.response.status >= 400 && error.response.status < 600) {
              }
            }
          });
      }
    } else {
      if (clickedButtonValue === "topics") {
        alert("Select modality and language");
      }
    }
  };

  const printPage = () => {
    const TopicValuesAreValid =
      selectedLanguage !== "Select" &&
      selectedModality !== "Select" &&
      selectedLanguage !== "" &&
      selectedModality !== "";

    if (TopicValuesAreValid) {
      console.log("values=", {
        lang: selectedLanguage,
        mod: selectedModality,
      });

      const PRINT_OPTIONS =
        "left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0";
      let prtContent = document.querySelector(".adminContentBox");
      let WinPrint = window.open("", "", PRINT_OPTIONS);

      WinPrint.document.body.innerHTML = prtContent.innerHTML;
      // WinPrint.document.body.style.display = "block";
      // WinPrint.document.body.style.overflow = "visible";
      WinPrint.document.close();

      try {
        WinPrint.focus();
        WinPrint.print();
      } catch (e) {
        console.error("Error occurred while printing:", e);
      } finally {
        WinPrint.close();
      }
    } else {
      alert("Select modality and language");
    }
  };

  return (
    <div>
      <div className="adminMainPanel">
        <div className="infoTitle adminInfoTitle">
          <div>Languge Tag Panel</div>
        </div>
        <div>
          Modality:
          <select
            key="modality"
            value={selectedModality}
            onChange={handleModalityChange}
          >
            <option>Select</option>
            <option value="reading">Reading</option>
            <option value="listening">Listening</option>
          </select>
        </div>
        <div>
          Language:
          <select
            key="language"
            value={selectedLanguage}
            onChange={handleLanguageChange}
          >
            <option>Select</option>
            {langDropdown}
          </select>
        </div>
        <div>
          Order restults by:
          <select
            key="firstSel"
            value={firstSelction}
            onChange={handleFirstSelChange}
          >
            <option>Select</option>
            <option value="level">Profiency Level</option>
            <option value="function">Function</option>
            <option value="competence">Competence</option>
          </select>
        </div>
        <div>
          Then by:
          <select
            key="secondSel"
            value={secondSelction}
            onChange={handleSecondSelChange}
          >
            <option>Select</option>
            <option value="level">Profiency Level</option>
            <option value="function">Function</option>
            <option value="competence">Competence</option>
          </select>
        </div>
        <div>
          Then by:
          <select
            key="thirdSel"
            value={thirdSelction}
            onChange={handleThirdSelChange}
          >
            <option>Select</option>
            <option value="level">Profiency Level</option>
            <option value="function">Function</option>
            <option value="competence">Competence</option>
          </select>
        </div>
        <div className="buttonGroup">
          <div onClick={getValues} value="tag" className="searchButton">
            Get Tag
          </div>
          <div
            onClick={getValues}
            value="topics"
            className="searchButton"
            style={{ width: "150px" }}
          >
            Topics/Sub Topics
          </div>
          <div
            onClick={printPage}
            className={`searchButton ${isButtonClicked ? "" : "disabled"}`}
            style={isButtonClicked ? {} : { pointerEvents: "none" }}
          >
            Print Results
          </div>
        </div>
        <div className="adminContentBox" style={{ overflow: "auto" }}>
          <TagPanelDisplay
            content={state.content}
            mod={state.selectedModality}
            isButtonClicked={isButtonClicked}
            firstSelection={state.firstSelction}
            secondSelection={state.secondSelction}
            thirdSelection={state.thirdSelction}
            tag={state.tag}
            topic={state.topic}
          />
        </div>{" "}
      </div>

      <AdminFooter />
    </div>
  );
};

export default TagPanel;
