import React, { useState, useEffect } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import axios from "axios";
import DateRangePicker from "./adminComponents/DateRangePicker";
import AdminFooter from "./adminComponents/AdminFooter";
import ODAUserSurveyDisplay from "./adminDisplay/ODAUserSurveyDisplay";

const ODAUserSurvey = () => {
  const initialState = {
    content: [],
    language: [],
    languageGR: [],
    selectedLanguage: "Select",
    selectedModality: "",
    // selectedSite: "",
    isData: false,
    checkAPI: false,
    firstCheck: false,
  };

  const [state, setState] = useState(initialState);

  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);

  const navigate = useNavigate();

  const date = new Date("1970-01-01");
  const isoDate = date.toISOString().slice(0, 10);

  function refreshPage() {
    window.location.reload(false);
  }

  const resetValues = () => {
    setState({
      ...state,
      content: [],
      selectedLanguage: "Select",
      selectedModality: "",
      // selectedSite: "",
    });
  };

  const {
    language,
    languageGR,
    selectedLanguage,
    selectedModality,
    // selectedSite,
    isData,
    firstCheck,
  } = state;

  useEffect(() => {
    // if (selectedModality === "reading" || selectedModality === "listening") {
    if (selectedModality === "rclc") {
      axios.get("/api/language/includeAll?modalities__ne=[]").then((res) => {
        setState({ ...state, language: res.data });
      });
    }
    if (selectedModality === "grammar") {
      axios.get('/api/language/includeAll?modalities="grammar"').then((res) => {
        setState({ ...state, languageGR: res.data });
      });
    }
  }, [selectedModality]);

  let langDropdown = language.map((item, index) => (
    <option key={index} value={item._id}>
      {item.displayName}
    </option>
  ));

  let langDropdownGR = languageGR.map((item, index) => (
    <option key={index} value={item._id}>
      {item.displayName}
    </option>
  ));

  const handleLanguageChange = (event) => {
    setState({ ...state, selectedLanguage: event.target.value });
  };

  const handleModalityChange = (event) => {
    const initial = {
      selectedLanguage: "",
      // selectedSite: "",
      isData: false,
      checkAPI: false,
      firstCheck: false,
    };
    setState({
      ...state,
      selectedModality: event.target.value,
      ...initial,
    });
  };

  // const handleSiteChange = (event) => {
  //   setState({ ...state, selectedSite: event.target.value });
  // };

  const handleDatesSelected = (fromDate, toDate) => {
    const pickFrom = new Date(fromDate);
    const isoFrom = pickFrom.toISOString().slice(0, 10);

    const pickTo = new Date(toDate);
    const isoTo = pickTo.toISOString().slice(0, 10);
    setFromDate(isoFrom);
    setToDate(isoTo);
  };

  useEffect(() => {
    setState({ ...state, selectedLanguage: "Select" });
  }, [selectedModality]);

  const getValues = () => {
    const user = {
      lang: selectedLanguage,
      mod: selectedModality,
      // site: selectedSite,
      startDate: fromDate,
      endDate: toDate,
    };

    // alert("user=" + JSON.stringify(user));
    const valuesAreValid =
      selectedLanguage !== "Select" &&
      selectedModality !== "Select" &&
      // selectedSite !== "Select" &&
      selectedLanguage !== "" &&
      selectedModality !== "" &&
      // selectedSite !== "" &&
      fromDate !== isoDate &&
      toDate !== isoDate &&
      fromDate !== null &&
      toDate !== null;

    if (valuesAreValid) {
      // alert("valid");
      console.log("values=", {
        lang: selectedLanguage,
        mod: selectedModality,
        // site: selectedSite,
        startDate: fromDate,
        endDate: toDate,
      });
      axios
        .get("/api/adminReport/surveyReport", { params: user })
        .then((res) => {
          //console.log("axios=", res.data);
          setState({ ...state, content: res.data });
          setState((prevState) => ({
            ...prevState,
            content: res.data,
            isData: true,
            checkAPI: true,
          }));
          console.log("survey=", JSON.stringify(res.data));
        })
        .catch((error) => {
          setState((prevState) => ({
            ...prevState,
            isData: false,
            firstCheck: true,
          }));
          if (error.response) {
            if (error.response.status >= 400 && error.response.status < 600) {
            }
          }
        });
    } else {
      console.log("No value can be null");
      alert("Select all items");
    }
  };

  return (
    <div className="adminMainPanel">
      <div className="infoTitle adminInfoTitle">
        <div>ODA Survey Data</div>
      </div>
      <div>
        Modality:
        <select
          key="modality"
          value={selectedModality}
          onChange={handleModalityChange}
        >
          <option>Select</option>
          <option value="rclc">RC/LC</option>
          <option value="grammar">Grammar</option>
        </select>
      </div>
      <div>
        Language:
        <select
          key="language"
          value={selectedLanguage}
          onChange={handleLanguageChange}
        >
          {/* {selectedModality === "reading" ||
          selectedModality === "listening" ? ( */}
          {selectedModality === "rclc" ? (
            <>
              <option>Select</option>
              {langDropdown}
            </>
          ) : selectedModality === "grammar" ? (
            <>
              <option>Select</option>
              {langDropdownGR}
            </>
          ) : (
            <option>Select</option>
          )}
        </select>
      </div>
      {/* <div>
        Site:
        <select key="site" value={selectedSite} onChange={handleSiteChange}>
          <option>Select</option>
          <option value="oda1">ODA 1</option>
        </select>
      </div> */}
      <DateRangePicker onDatesSelected={handleDatesSelected} />
      <div className="buttonGroup">
        <div onClick={getValues} className="searchButton">
          Get Records
        </div>
        <div onClick={refreshPage} className="searchButton">
          {/* <div onClick={resetValues} className="searchButton"> */}
          Reset Options
        </div>
      </div>
      <div className="adminContentBox" style={{ overflow: "auto" }}>
        <ODAUserSurveyDisplay
          content={state.content}
          isData={state.isData}
          checkAPI={state.checkAPI}
          firstCheck={state.firstCheck}
          mod={state.selectedModality}
        />
      </div>

      <AdminFooter />
    </div>
  );
};

export default ODAUserSurvey;
