/**
 *
 *  ReactJS reducer errorStateReducer
 *  handles global state for the purpose of
 *  handling error messages from API calls.
 *
 *  initialErrorState & errorStateReducer
 *  work together to form the reactjs useReducer hook
 *
 *  then, in ErrorProvider, useContext ErrorProvider
 *  & ErrorContext joins with the useReducer to
 *  create global access to the state and other functionality
 *
 */

//initial state of a null error state
export const initialErrorState = {
  errorStatusCode: null,
  errorUserMessage: null,
  errorUserMessageDetails: null,
  errorDevData: null,
  errorActiveThrown: false,
  errorConsoleLogErrors: {}
};

//reducer function to handle dispatch calls to modify ODA error state
export const errorStateReducer = (state, action = null) => {
  let theStatusCode = null;
  let theUserMessage = null;
  let theUserMessageDetails = null;
  if (action.hasOwnProperty("payload")) {
    if (action.payload.errorStatusCode !== null && action.type !== "APPEND_CONSOLE_ERROR") {
      theStatusCode = action.payload.errorStatusCode.toString();
      theUserMessage = action.payload.errorUserMessage.toString();
      if (action.payload.hasOwnProperty("errorUserMessageDetails")) {
        theUserMessageDetails = action.payload.errorUserMessageDetails.toString();
      }
    }

    if (action.type !== "APPEND_CONSOLE_ERROR") {
      if (action.payload.errorStatusCode === 400) {
        theUserMessage = "Malformed Request.";
      } else if (action.payload.errorStatusCode === 401) {
        theUserMessage = "Not Authorized.";
      } else if (action.payload.errorStatusCode === 403) {
        theUserMessage = "Request Forbidden.";
      } else if (action.payload.errorStatusCode === 404) {
        theUserMessage = "Resource Not Found";
      } else if (action.payload.errorStatusCode === 500) {
        theUserMessage = "Internal Server Error";
      } else if (action.payload.errorStatusCode === 501) {
        theUserMessage = "Route Unavailable.";
      } else if (action.payload.errorStatusCode === 502) {
        theUserMessage = "Bad Gateway.";
      } else if (action.payload.errorStatusCode === 503) {
        theUserMessage = "Service Temporarily Unavailable.";
      } else if (action.payload.errorStatusCode === 504) {
        theUserMessage = "Gateway Timeout.";
      } else if (action.payload.errorStatusCode >= 400 && action.payload.errorStatusCode < 600) {
        theUserMessage = "Undiagnosed Error.";
      }
    }
  }

  switch (action.type) {
    case "RESET_ERROR_STATE":
      return {
        ...state,
        errorStatusCode: null,
        errorUserMessage: null,
        errorUserMessageDetails: null,
        errorDevData: null,
        errorActiveThrown: false,
        errorConsoleLogErrors: {}
      };
    case "APPEND_CONSOLE_ERROR":
      // let theConsoleErrors = state.errorConsoleLogErrors;
      // theConsoleErrors.push(action.payload);
      return {
        ...state,
        errorConsoleLogErrors: action.payload.errorConsoleLog
      };
    case "UPDATE_ERROR_STATE":
      return {
        ...state,
        errorStatusCode: theStatusCode,
        errorUserMessage: theUserMessage,
        errorDevData: action.payload.errorDevData
      };
    case "UPDATE_ERROR_STATE_DETAILS":
      return {
        ...state,
        errorStatusCode: theStatusCode,
        errorUserMessage: theUserMessage,
        errorUserMessageDetails: theUserMessageDetails,
        errorDevData: action.payload.errorDevData
      };
    case "UPDATE_ERROR_STATUS_CODE":
      return {
        ...state,
        errorStatusCode: action.payload
      };
    case "UPDATE_ERROR_USER_MSG":
      return {
        ...state,
        errorUserMessage: action.payload
      };
    case "UPDATE_ERROR_USER_MSG_DETAILS":
      return {
        ...state,
        errorUserMessageDetails: action.payload
      };
    case "UPDATE_ERROR_DEV_MSG":
      return {
        ...state,
        errorDevData: action.payload
      };
    case "SHOWING_MODAL_TRUE":
      return {
        ...state,
        errorActiveThrown: true
      };
    default:
      return state;
  }
};
