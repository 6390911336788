export default function Speech(props) {
  const oriAudScore = props.oriAudScore;
  const modAudScore = props.modAudScore;
  const audVocabScore = props.audVocabScore;
  const scriptScore = props.scriptScore;

  return (
    <div className="profileGridContainer full pageBreak">
      <div className="speechProcessing">
        <h2>Speech Processing</h2>

        <p className="descriptor">
          Comprehension of authentic speech, such as speed, clarity, or accent.
          Depending on the level of the passage, it may also indicate the extent
          to which you needed simplified speech or transcribed vocabulary to
          answer questions.
        </p>

        <div className="profileCategory">
          <h3>Audio Delivery</h3>

          <p className="tag">
            Original Audio, correct/total
            <span className="dataLeftSmall" data="66">
              {oriAudScore}
            </span>
          </p>

          <p className="tag">
            Modified Audio, correct/attempted
            <span className="dataLeftSmall" data="100">
              {modAudScore}
            </span>
          </p>

          <p className="descriptor">
            For this level, your perfmormance indicates that you are able to:
          </p>

          <ul>
            <li>
              Understand speech that is delivered more clearly than normal
            </li>
            <li>Understand speech rate that is slower than normal</li>
            <li>Understand speech with frequent repetitions or paraphrasing</li>
          </ul>
        </div>

        <div className="profileCategory">
          <h3>Vocabulary Delivery</h3>

          <p className="tag">
            Vocabulary delivered in audio format, correct/total
            <span className="dataLeftSmall" data="47">
              {audVocabScore}
            </span>
          </p>

          <p className="tag">
            Vocabulary transcriptions, correct/attempts
            <span className="dataLeftSmall" data="100">
              {scriptScore}
            </span>
          </p>

          <p className="descriptor">
            For this level, your perfmormance indicates that you are able to:
          </p>

          <ul>
            <li>
              Understand vocabulary items in their audio forms or with
              transcription
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}
