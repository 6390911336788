import React, { useContext, useState, useCallback } from "react";
import footerLogo from "./DLIFLC_Crest.png";
import ErrorModal from "../error/ErrorModal";
import { ErrorContext } from "../../context/ErrorProvider";
import ReportProblemModal from "../modals/ReportProblemModal";
import ReportSentModal from "../modals/ReportSentModal";

const Footer = (props) => {
  const today = new Date();
  const actID = props.actID;
  const mod = props.mod;
  const template = props.template;
  const skill = props.skill;
  const [modalErrorState] = useContext(ErrorContext);
  const [showReportModal, setShowReportModal] = useState(false);
  const [showReportSentModal, setShowReportSentModal] = useState(false);

  //allows us to close the modals from the child element
  const handleRefModalState = useCallback((e) => {
    setShowReportModal(false);
  }, []);
  const handleRefModalStateConfirm = useCallback((e) => {
    setShowReportModal(false);
    setShowReportSentModal(true);
  }, []);
  const handleRefConfirmationModalState = useCallback((e) => {
    setShowReportSentModal(false);
  }, []);

  // TODO: Move all this browser info code to a separate file.
  const getBrowserInfo = () => {
    var userAgent = navigator.userAgent;
    var browserInfoArr = parseBrowserInfo(userAgent);
    let browserInfo = {
      browserUserAgent: userAgent,
      browserName: browserInfoArr[0],
      browserVersion: browserInfoArr[1],
      browserRunningDevice: browserInfoArr[2],
    };
    return browserInfo;
  };

  const parseBrowserInfo = (browserUserAgentString) => {
    // The returned array contains 3 elements, browser name, version, running device.
    var ret = [];
    if (browserUserAgentString != null) {
      browserUserAgentString = browserUserAgentString.trim();
      var lowerStr = browserUserAgentString.toLowerCase();
      if (lowerStr != "") {
        var startIdx = browserUserAgentString.indexOf("(");
        var endIdx = browserUserAgentString.indexOf(")", startIdx + 1);
        var name = "";
        var version = "";
        var deviceOS = browserUserAgentString.substring(startIdx + 1, endIdx);
        // web browser on mobile device.
        if (lowerStr.indexOf("mobile") > 0) {
          let KEYWORD_OPR = "opt/";
          let KEYWORD_CHROME = "crios/";
          let KEYWORD_FIREFOX = "fxios/";
          let KEYWORD_EDG = "edgios/";
          let KEYWORD_SAFARI = "version/";
          if (lowerStr.indexOf(KEYWORD_OPR) > -1) {
            startIdx = lowerStr.indexOf(KEYWORD_OPR);

            name = "Opera";
            version = browserUserAgentString.substring(
              startIdx + KEYWORD_OPR.length
            );
          } else if (lowerStr.indexOf(KEYWORD_CHROME) > -1) {
            startIdx = lowerStr.indexOf(KEYWORD_CHROME);

            endIdx = lowerStr.indexOf(" ", startIdx + KEYWORD_CHROME.length);
            name = "Chrome";
            version = browserUserAgentString.substring(
              startIdx + KEYWORD_CHROME.length,
              endIdx
            );
          } else if (lowerStr.indexOf(KEYWORD_FIREFOX) > -1) {
            startIdx = lowerStr.indexOf(KEYWORD_FIREFOX);
            endIdx = lowerStr.indexOf(" ", startIdx + 1);
            name = "Firefox";
            version = browserUserAgentString.substring(
              startIdx + KEYWORD_FIREFOX.length,
              endIdx
            );
          } else if (lowerStr.indexOf(KEYWORD_EDG) > -1) {
            startIdx = lowerStr.indexOf(KEYWORD_EDG);
            endIdx = lowerStr.indexOf(" ", startIdx + 1);
            name = "Microsoft Edge";
            version = browserUserAgentString.substring(
              startIdx + KEYWORD_EDG.length,
              endIdx
            );
          } else {
            name = "Safari";
            startIdx = lowerStr.indexOf(KEYWORD_SAFARI);
            endIdx = lowerStr.indexOf(" ", startIdx + 1);
            version = browserUserAgentString.substring(
              startIdx + KEYWORD_SAFARI.length,
              endIdx
            );
          }
        }
        // web browser on desktop or laptop OS.
        else {
          let KEYWORD_OPR = "opr/";
          let KEYWORD_CHROME = "chrome/";
          let KEYWORD_FIREFOX = "firefox/";
          let KEYWORD_EDG = "edg/";
          let KEYWORD_SAFARI = "version/";
          if (lowerStr.indexOf(KEYWORD_OPR) > -1) {
            startIdx = lowerStr.indexOf(KEYWORD_OPR);
            name = "Opera";
            version = browserUserAgentString.substring(
              startIdx + KEYWORD_OPR.length
            );
          } else if (lowerStr.indexOf(KEYWORD_EDG) > -1) {
            startIdx = lowerStr.indexOf(KEYWORD_EDG);
            name = "Microsoft Edge";
            version = browserUserAgentString.substring(
              startIdx + KEYWORD_EDG.length
            );
          } else if (lowerStr.indexOf(KEYWORD_FIREFOX) > -1) {
            startIdx = lowerStr.indexOf(KEYWORD_FIREFOX);
            name = "Firefox";
            version = browserUserAgentString.substring(
              startIdx + KEYWORD_FIREFOX.length
            );
          } else if (lowerStr.indexOf(KEYWORD_CHROME) > -1) {
            startIdx = lowerStr.indexOf(KEYWORD_CHROME);
            endIdx = lowerStr.indexOf(" ", startIdx + 1);
            name = "Chrome";
            version = browserUserAgentString.substring(
              startIdx + KEYWORD_CHROME.length,
              endIdx
            );
          } else {
            name = "Safari";
            startIdx = lowerStr.indexOf(KEYWORD_SAFARI);
            endIdx = lowerStr.indexOf(" ", startIdx + 1);
            version = browserUserAgentString.substring(
              startIdx + KEYWORD_SAFARI.length,
              endIdx
            );
          }
        }
        ret.push(name);
        ret.push(version);
        ret.push(deviceOS);
      }
    }
    return ret;
  };

  return (
    <footer>
      <div className="footerTop">
        {props.showReportProblem !== false && (
          <div className="reportProblem">
            <button
              className="footerBtn"
              onClick={() => setShowReportModal(true)}
            >
              Report a Problem
            </button>
          </div>
        )}
      </div>
      <div className="footerLogo">
        {props.showReportProblem !== false ? (
          <img
            src={footerLogo}
            alt="Defense Language Institute, Foreign Language Center Logo"
            className="fixReportButton"
          />
        ) : (
          <img
            src={footerLogo}
            alt="Defense Language Institute, Foreign Language Center Logo"
          />
        )}
      </div>
      <div className="copyright">
        Copyright © {today.getFullYear()}, All rights reserved
      </div>
      {showReportModal && (
        <ReportProblemModal
          lesson={actID}
          modality={mod}
          template={template}
          skill={skill}
          browserDimX={window.innerWidth}
          browserDimY={window.innerHeight}
          browserInfo={getBrowserInfo().browserName}
          browserAgent={getBrowserInfo().browserUserAgent}
          browserVersion={getBrowserInfo().browserVersion}
          browserDevice={getBrowserInfo().browserRunningDevice}
          refModalState={handleRefModalState}
          refModalStateConfirm={handleRefModalStateConfirm}
        />
      )}
      {showReportSentModal && (
        <ReportSentModal refModalState={handleRefConfirmationModalState} />
      )}
      <ErrorModal
        errorStatusCode={modalErrorState.errorStatusCode}
        errorUserMessage={modalErrorState.errorUserMessage}
        errorDevData={modalErrorState.errorDevData}
      />
    </footer>
  );
};

export default Footer;
