import React from "react";
import dompurify from "dompurify";

const KeywordDisplay = (props) => {
  const sanitizer = dompurify.sanitize;
  const data = props.content;
  const isData = props.isData;
  const keyword = props.keyword;
  const checkAPI = props.checkAPI;
  const firstCheck = props.firstCheck;

  const noMatches = data.filter(
    (item) => item.message && item.message.includes("No matches found")
  );
  const otherItems = data.filter(
    (item) => !item.message || !item.message.includes("No matches found")
  );

  const sortedData = [...otherItems, ...noMatches];

  if (
    (!isData && checkAPI) ||
    (!isData && !checkAPI && keyword !== "" && firstCheck)
  ) {
    return <h3>The keyword was not found</h3>;
  }
  if (isData) {
    return (
      <div>
        <textarea style={{ height: "100px" }}></textarea>
        <br /> <br />
        <div style={{ textAlign: "left" }}>
          {sortedData.map((item) => {
            const { _id, message, testitems } = item;

            if (testitems) {
              return (
                <div key={_id}>
                  <strong>
                    {`${_id.toUpperCase()}`} - {testitems.length} Item
                    {testitems.length !== 1 ? "s" : ""} Found
                  </strong>

                  <br />
                  <br />
                  {testitems.map((testitem, index) => {
                    const { itemId, keywordGroup, keywords } = testitem;

                    return (
                      <div key={index}>
                        <div
                          style={{
                            display: "grid",
                            gridTemplateColumns: "auto 1fr",
                            gap: "10px",
                            textAlign: "left",
                          }}
                        >
                          <div>Item ID:</div>
                          <div>{itemId}</div>
                          <div>Answer ID:</div>
                          <div>{keywordGroup}</div>
                          <div>Keyword:</div>
                          <div>
                            <div>
                              {keywords[0].map((word, index) => (
                                <React.Fragment key={index}>
                                  {index > 0 && "||"}
                                  <span
                                    dangerouslySetInnerHTML={{
                                      __html: sanitizer(
                                        word.replace(
                                          new RegExp(keyword, "gi"),
                                          `<span style="color: red">$&</span>`
                                        )
                                      ),
                                    }}
                                  />
                                </React.Fragment>
                              ))}
                            </div>
                          </div>
                        </div>
                        <hr />
                      </div>
                    );
                  })}
                </div>
              );
            } else if (message && message.includes("No matches found")) {
              return (
                <div style={{ color: "red" }} key={message}>
                  {`${message}`}
                  <br />
                  <br />
                </div>
              );
            }
            return null;
          })}
        </div>
      </div>
    );
  }
};

export default KeywordDisplay;
