import React from "react";

export default function AnswerField(props) {
  const questions = props.questions;
  const quesLen = questions.length;

  const modifiedAudio = props.modifiedAudio;
  let simpleVersion = modifiedAudio ? "inline" : "none";

  const tipTrigger = (event) => {
    const item = event.currentTarget;
    item.nextElementSibling.style.display = "inline";
  };

  const tipClose = (event) => {
    document.querySelector(".toolTipText").style.display = "none";
  };

  const createAnswerField = () => {
    let items = [];
    if (quesLen <= 1) {
      const mainID = props.actID + "_0_0";
      const modID = props.actID + "_0_1";
      items.push(
        <div className="answerSection noSelect" key={mainID}>
          <form autoComplete="off">
            <label htmlFor="userInputQSA">
              Your Answer
              <textarea
                className="QSAAnswer mainText"
                id={mainID}
                autoComplete="off"
                onChange={props.onAnswerMain}
                spellCheck={true}
                onBlur={props.onLoseFocusCheckNLP}
                onPaste={(e) => {
                  e.preventDefault();
                  return false;
                }}
                onCopy={(e) => {
                  e.preventDefault();
                  return false;
                }}
                onCut={(e) => {
                  e.preventDefault();
                  return false;
                }}
              />
              <textarea
                style={{
                  display: "none",
                  border: "1px solid #73b1da"
                }}
                autoComplete="off"
                className="QSAAnswer modText"
                id={modID}
                onChange={props.onAnswerMod}
                spellCheck={true}
                onBlur={props.onLoseFocusCheckNLP}
                onPaste={(e) => {
                  e.preventDefault();
                  return false;
                }}
                onCopy={(e) => {
                  e.preventDefault();
                  return false;
                }}
                onCut={(e) => {
                  e.preventDefault();
                  return false;
                }}
              />
              {props.invalidEnInput.find((thisEl) => thisEl === mainID) && (
                <div className="invalidInputLanguageDiv">Please enter your answer in English.</div>
              )}
              {props.invalidEnInput.find((thisEl) => thisEl === modID) && (
                <div className="invalidInputLanguageDiv">Please enter your answer in English.</div>
              )}
            </label>
          </form>
        </div>
      );
    } else {
      for (let i = 0; i < quesLen; i++) {
        const mainID = props.actID + "_" + i + "_0";
        const modID = props.actID + "_" + i + "_1";
        const question = questions[i].value;
        items.push(
          <div className="answerSection noSelect" key={mainID}>
            <form autoComplete="off">
              <label htmlFor="userInputQSA">
                <h4>{question}</h4>
                <textarea
                  className="QSAAnswer mainText"
                  id={mainID}
                  onChange={props.onAnswerMain}
                  spellCheck={true}
                  autoComplete="off"
                  onBlur={props.onLoseFocusCheckNLP}
                  onPaste={(e) => {
                    e.preventDefault();
                    return false;
                  }}
                  onCopy={(e) => {
                    e.preventDefault();
                    return false;
                  }}
                />
                <textarea
                  style={{
                    display: "none",
                    border: "1px solid #73b1da"
                  }}
                  autoComplete="off"
                  className="QSAAnswer modText"
                  id={modID}
                  onChange={props.onAnswerMod}
                  spellCheck={true}
                  onBlur={props.onLoseFocusCheckNLP}
                  onPaste={(e) => {
                    e.preventDefault();
                    return false;
                  }}
                  onCopy={(e) => {
                    e.preventDefault();
                    return false;
                  }}
                  onCut={(e) => {
                    e.preventDefault();
                    return false;
                  }}
                />
                {props.invalidEnInput.find((thisEl) => thisEl === mainID) && (
                  <div className="invalidInputLanguageDiv">Please enter your answer in English.</div>
                )}
                {props.invalidEnInput.find((thisEl) => thisEl === modID) && (
                  <div className="invalidInputLanguageDiv">Please enter your answer in English.</div>
                )}
              </label>
            </form>
          </div>
        );
      }
    }
    return items;
  };

  const toggleAudio = (e) => {
    if (props.toggleState === false) {
      e.preventDefault();
      e.stopPropagation();
      const confirmDialog = window.confirm(
        "The modified audio is either studio recorded or a brief cut from the original. Enable it only if you cannot understand the original audio. Your answers, after listening to the modified audio, will not be counted toward your final rating, but your scores for both will be shown in the diagnostic profile to provide you with additional feedback."
      );
      if (confirmDialog == true) {
        props.toggleStateFn();
        document.querySelector("#checkBox").checked = true;
        let nodelistMain = document.querySelectorAll(".LSA .mainText");
        let nodelistMod = document.querySelectorAll(".LSA .modText");

        for (let i = 0; i < nodelistMain.length; i++) {
          nodelistMain[i].style.display = "none";
          nodelistMod[i].style.display = "inline";
        }
      } else {
        return;
      }
    }
  };

  const buildup = (
    <div>
      <div>{createAnswerField()}</div>
      <div className="simpleAudio" style={{ display: simpleVersion }}>
        <p>
          Need help? Enable modified audio
          <span className="toolTip">
            <span className="tipTrigger" onClick={tipTrigger}>
              ?
            </span>
            <span className="toolTipText">
              <span className="tipClose" onClick={tipClose} />
              The modified audio is either studio recorded or a brief cut from the original. Enable it only if you
              cannot understand the original audio. Your answers, after listening to the modified audio, will not be
              counted toward your final rating, but your scores for both will be shown in the diagnostic profile to
              provide you with additional feedback.
            </span>
          </span>
        </p>

        <div className="toggle">
          <label className="switch" onClick={(e) => toggleAudio(e)}>
            <input type="checkbox" id="checkBox" disabled={props.toggleState ? true : false} />
            <span className="slider" />
          </label>
        </div>

        <span className="onOff">{props.toggleState ? "On" : "Off"}</span>
        <div className="clearFix" />
      </div>
    </div>
  );

  return <div>{buildup}</div>;
}
