import React, { useEffect } from "react";

export default function AnswerOption(props) {
  const num = props.optionNumber;
  // const len = num.length; 
  const len = props.questionWord.length;
  const actID = props.actID;
  const value = props.value;

  const testitem = props.testitem;
  const answerFieldsID = props.answerFieldsID;

  let clickCounter = props.clickCounter;

  useEffect(() => {
    if (clickCounter === 0) {
      document.querySelectorAll(".fstAttempt").forEach(function (event) {
        event.style.display = "inline";
      });
      document.querySelectorAll(".sndAttempt").forEach(function (event) {
        event.style.display = "none";
      });
    } else {
      document.querySelectorAll(".fstAttempt").forEach(function (event) {
        event.style.display = "none";
      });
      document.querySelectorAll(".sndAttempt").forEach(function (event) {
        event.style.display = "inline";
      });
    }
  }, [clickCounter]);

  const ddList = props.ddList;
  const questionWord = props.questionWord;

  let dropdown = ddList.map((item, index) => (
    <option key={item} value={index}>
      {item}
    </option>
  ));

  const createAnswerField = () => {
    let items = [];
    for (let i = 0; i < len; i++) {
      // console.log(i);
      // console.log(questionWord[i]);
      let keyNum = num[i] + "_0";
      let keyNum2 = num[i] + "_1";
      let keyNum3 = num[i] + "_2";

      let answerFieldID = answerFieldsID[i];

      items.push(
        <div className="categorizeAnswer" key={keyNum3}>
          <div className="fstAttempt" key={keyNum}>
            <p className="question">
              <span className="questionNum">{i + 1}. </span>
              {questionWord[i].value}
            </p>
            <select
              name={answerFieldID}
              data-testitem={testitem}
              defaultValue={value}
              id={answerFieldID + "_0"}
              onChange={props.onAnswerSelected}
            >
              <option value={value} disabled>
                Select
              </option>
              {dropdown}
            </select>
          </div>
          <div className="sndAttempt" key={keyNum2}>
            <p className="question">
              <span className="questionNum">{i + 1}. </span>
              {questionWord[i].value}
            </p>
            <select
              name={answerFieldID}
              data-testitem={testitem}
              defaultValue={value}
              id={answerFieldID + "_1"}
              onChange={props.onAnswerSelectedMod}
            >
              <option value={value} disabled>
                Select
              </option>
              {dropdown}
            </select>
          </div>
        </div>
      );
    }
    return items;
  };

  return <div>{createAnswerField()}</div>;
}
