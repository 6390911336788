import React, { useState, useEffect, useContext, useCallback } from "react";
import Test from "./test/Test";
import Footer from "../../../../components/footer/Footer";
import "../../../../css/index.css";
import draggableFunctions from "../../../../functions/draggableFunctions";
import { AssessmentContext } from "../../../../context/AssessmentProvider";
import { useScoreNLP } from "../../../../hooks/useScoreNLP";
import { checkEnglishInput } from "../../../../functions/genericFunctions";

/**
 * @component GLSB
 * @description Grammar Testlet Listening Transcription & Transcribing
 **/

const GLSB = (props) => {
  const testData = props.testData;
  const [state, setState] = useState({
    instruction: testData.instruction,
    actID: testData._id,
    lang: testData.language._id,
    dir: testData.language.isRightToLeft,
    modality: testData.modality,
    template: testData.template,
    skill: testData.grammarLevel,
    assessment: testData.testitems,
    userAnswerMainPre: [],
    userAnswerMain: [],
    userAnswerModPre: [],
    userAnswerMod: [],
    enUserAnswerAll: [],
    tlUserAnswerMainPre: [],
    tlUserAnswerMain: [],
    tlUserAnswerModPre: [],
    tlUserAnswerMod: [],
    hint: testData.hint,
    scoreEN: "",
    scoreTL: "",
    correctAnswer: testData.testitems,
    clickCounter: 0,
    audioFile1: testData.testitems[0].questionTexts[1],
    audioFile2: testData.testitems[1].questionTexts[1],
    audioFile3: testData.testitems[2].questionTexts[1],
    audSequence: 1,
    audioKey: Math.random(),
    anchorKey: new Date()
  });

  const [assessmentState, dispatch] = useContext(AssessmentContext);
  const [runScoreNLP, setRunScoreNLP] = useState(false);
  const [eventNLP, setEventNLP] = useState(false);
  const [storeNLPScores, setStoreNLPScores] = useState([]);
  const [showNonENTextError, setShowNonENTextError] = useState([]);
  const [finalChecking, setFinalChecking] = useState(null);
  const [theBtnName, setTheBtnName] = useState(null);

  state.userAnswerMain.length = state.correctAnswer.length;
  state.userAnswerMod.length = state.correctAnswer.length;
  state.tlUserAnswerMain.length = state.correctAnswer.length * 2;
  state.tlUserAnswerMod.length = state.correctAnswer.length;
  state.enUserAnswerAll.length = state.correctAnswer.length * 2; //6

  useEffect(() => {
    draggableFunctions();

    if (props.navLen === 20) {
      document.querySelector(".progressGrid").style.gridTemplateColumns = "repeat(20, 1fr)";
    }

    const footerLogo = document.querySelector(".footerLogo");
    footerLogo.style.top = "13px";
    footerLogo.style.height = "67px";

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    //disable next/submit until previous api call is complete; prevents overrunning api calls to change same data
    if (props.buttonWaiting) {
      const apiWaitButtons = document.querySelectorAll(".nextBtn, .submitBtn");
      const titleChanger = document.querySelector(".nextSubmitParentDiv");
      apiWaitButtons.forEach((item, index) => {
        item.classList.add("waitingForAPI");
        if (item.classList.contains("disabled")) {
          titleChanger.title =
            "Please answer the questions above before proceeding. You will also need to wait for the system to record your previous answer before proceeding.";
        } else {
          titleChanger.title = "Please wait for your assessment to save your previous answer before proceeding.";
        }
      });
      titleChanger.classList.remove("nextSubmitParentDivUnanswered");
      titleChanger.classList.add("nextSubmitParentDivWaiting");
    } else {
      const titleChanger = document.querySelector(".nextSubmitParentDiv");
      const apiWaitButtons = document.querySelectorAll(".nextBtn, .submitBtn");
      apiWaitButtons.forEach((item, index) => {
        item.classList.remove("waitingForAPI");
        if (item.classList.contains("disabled")) {
          titleChanger.title =
            "Please attempt to answers all questions before proceeding.  Note: If you feel your assessment is stuck and you can not proceed, you may skip to the next question by holding SHIFT and clicking this button.";
          titleChanger.title =
            "Please attempt to answers all questions before proceeding.  Note: If you feel your assessment is stuck and you can not proceed, you may skip to the next question by holding SHIFT and clicking this button.";
        } else {
          titleChanger.title = "Click to submit your answer(s).";
        }
      });
      titleChanger.classList.remove("nextSubmitParentDivWaiting");
      titleChanger.classList.add("nextSubmitParentDivUnanswered");
    }
  }, [props.buttonWaiting]);

  const updateUserAnswerSA2 = useCallback(
    (id, tl) => {
      const itemId = id.substr(-1);
      let countAnswer = 0;
      let checkAnswer = false;
      let getAnswer = [];

      let userAns;
      if (state.clickCounter === 0) {
        userAns = state.userAnswerMainPre;
      } else {
        userAns = state.userAnswerModPre;
      }

      let matching;
      let itemScore = 0;
      const passingScore = state.correctAnswer[itemId].answerFields[0].passingScore;

      if (userAns[itemId] !== undefined) {
        if (passingScore == "100") {
          const answerKeywordLen = state.correctAnswer[itemId].answerFields[0].answerKeywords.length;
          for (let j = 0; j < answerKeywordLen; j++) {
            const patternLen = state.correctAnswer[itemId].answerFields[0].answerKeywords[j].matchingPatterns.length;
            for (let k = 0; k < patternLen; k++) {
              const crrtAns = state.correctAnswer[itemId].answerFields[0].answerKeywords[j].matchingPatterns[k] + ".*";

              matching = userAns[itemId].userAnswer.toLowerCase().match(crrtAns.toLowerCase());
              if (matching !== null) {
                countAnswer = countAnswer + 1;
                getAnswer.push(crrtAns.toLowerCase());
                break;
              }
            }
          }
          if (countAnswer == answerKeywordLen) {
            checkAnswer = true;
          }
          return { checkAnswer, getAnswer };
        } else {
          const answerKeywordLen = state.correctAnswer[itemId].answerFields[0].answerKeywords.length;

          for (let j = 0; j < answerKeywordLen; j++) {
            let individualScore = state.correctAnswer[itemId].answerFields[0].answerKeywords[j].individualScore;
            const patternLen = state.correctAnswer[itemId].answerFields[0].answerKeywords[j].matchingPatterns.length;
            for (let k = 0; k < patternLen; k++) {
              const crrtAns = state.correctAnswer[itemId].answerFields[0].answerKeywords[j].matchingPatterns[k] + ".*";

              matching = userAns[itemId].userAnswer.toLowerCase().match(crrtAns.toLowerCase());
              if (matching !== null) {
                itemScore = itemScore + individualScore;
                getAnswer.push(crrtAns.toLowerCase());
                break;
              }
            }
          }
          if (itemScore >= passingScore) {
            checkAnswer = true;
          } else {
            //do nothing
          }
          return { checkAnswer, getAnswer };
        }
      }
    },
    [state]
  );

  const handleEnChange = useCallback(
    (event) => {
      const answerFieldsID = event.currentTarget.name;
      const testitem = event.target.getAttribute("data-testitem");
      const index = event.currentTarget.id.substr(-3, 1);
      const id = event.currentTarget.id;
      const value = event.currentTarget.value;
      let new_state = Object.assign({}, state);
      let a = new_state.userAnswerMainPre;
      let b = new_state.userAnswerMod;
      let z = new_state.userAnswerMain;

      /*test english input*/
      /* set up a dynamic array in state to track which elements have invalid characters */
      if (state.correctAnswer[index].isKeywordInEnglish) {
        if (!checkEnglishInput(value)) {
          let showNonENTextErrorTemp = showNonENTextError;
          if (
            !showNonENTextErrorTemp.find((thisEl) => thisEl === id + "_" + index) ||
            !showNonENTextErrorTemp.find((thisEl) => thisEl === id)
          ) {
            /*if invalid & doesnt yet exist; add it to error array */
            showNonENTextErrorTemp.push(id + "_" + index);
            showNonENTextErrorTemp.push(id);
            setShowNonENTextError(showNonENTextErrorTemp);
          } else {
            //do nothing
          }
        } else {
          let showNonENTextErrorTemp = showNonENTextError;
          if (showNonENTextErrorTemp.find((thisEl) => thisEl === id + "_" + index)) {
            /*if valid & exist; remove it from error array */
            let remIndex = (subEl) => subEl === id + "_" + index;
            let thisIndex = showNonENTextErrorTemp.findIndex(remIndex);
            showNonENTextErrorTemp.splice(thisIndex, 1);
          }
          if (showNonENTextErrorTemp.find((thisEl) => thisEl === id)) {
            /*if valid & exist; remove it from error array */
            let remIndex = (subEl) => subEl === id;
            let thisIndex = showNonENTextErrorTemp.findIndex(remIndex);
            showNonENTextErrorTemp.splice(thisIndex, 1);
          }
          setShowNonENTextError(showNonENTextErrorTemp);
        }
      }

      a[index] = {
        uiControlID: id,
        userAnswer: value
      };
      const answerCheck = updateUserAnswerSA2(answerFieldsID).checkAnswer;
      const answerGet = updateUserAnswerSA2(answerFieldsID).getAnswer;
      z[index] = {
        uiControlID: id,
        testlet: state.actID,
        testitem:
          testitem !== null
            ? testitem
            : index < 3
            ? state.actID + "_" + index
            : state.actID + "_" + parseInt(parseInt(index) - 3),
        answerField: answerFieldsID,
        try: 0,
        keywordMatches: answerGet,
        userAnswer: value,
        isPassed: answerCheck,
        isModifiedAudioPlayed: false,
        isTranscriptShown: false
      };

      b[index] = {
        uiControlID: id.substring(0, id.length - 1) + 1,
        testlet: state.actID,
        testitem:
          testitem !== null
            ? testitem
            : index < 3
            ? state.actID + "_" + index
            : state.actID + "_" + parseInt(parseInt(index) - 3),
        answerField: answerFieldsID,
        try: 1,
        keywordMatches: answerGet,
        userAnswer: value,
        isPassed: answerCheck,
        isModifiedAudioPlayed: false,
        isTranscriptShown: false
      };
      setState({
        ...state,
        userAnswerMainPre: a,
        userAnswerMain: z,
        userAnswerMod: b
      });

      let TransToMod = [];
      if (id != null) {
        let selID = id.substring(0, id.length - 1);
        TransToMod.push(selID + "1");
      }

      const len = state.userAnswerMain.length;
      let num = 0;
      for (let i = 0; i < len; i++) {
        if (state.userAnswerMain[i] != undefined) {
          num++;
          if (state.userAnswerMain[i].userAnswer.length == 0) {
            num--;
          }
        }
      }
      if (num == len) {
        document.querySelector(".nextBtn").classList.remove("disabled");
        const titleChanger = document.querySelector(".nextSubmitParentDiv");
        titleChanger.classList.remove("nextSubmitParentDivUnanswered");
        if (titleChanger.classList.contains("nextSubmitParentDivWaiting")) {
          titleChanger.title = "Please wait for your assessment to save your previous answer before proceeding.";
        } else {
          titleChanger.title = "Click to submit your answers.";
        }
      } else {
        document.querySelector(".nextBtn").classList.add("disabled");
        const titleChanger = document.querySelector(".nextSubmitParentDiv");
        titleChanger.classList.add("nextSubmitParentDivUnanswered");
        if (titleChanger.classList.contains("nextSubmitParentDivWaiting")) {
          titleChanger.title = "Please wait for your assessment to save your previous answer before proceeding.";
        } else {
          titleChanger.title = "Please attempt to re-answer at least one incorrect question before proceeding.";
        }
      }
    },
    [state, updateUserAnswerSA2, showNonENTextError]
  );

  const handleEnChangeMod = useCallback(
    (event) => {
      const answerFieldsID = event.currentTarget.name;
      const testitem = event.target.getAttribute("data-testitem");
      const index = event.currentTarget.id.substr(-3, 1);
      const id = event.currentTarget.id;

      const value = event.currentTarget.value;
      let new_state = Object.assign({}, state);
      let a = new_state.userAnswerModPre;
      let z = new_state.userAnswerMod;

      a[index] = {
        uiControlID: id,
        userAnswer: value
      };

      const answerCheck = updateUserAnswerSA2(answerFieldsID).checkAnswer;
      const answerGet = updateUserAnswerSA2(answerFieldsID).getAnswer;
      z[index] = {
        uiControlID: id,
        testlet: state.actID,
        testitem:
          testitem !== null
            ? testitem
            : index < 3
            ? state.actID + "_" + index
            : state.actID + "_" + parseInt(parseInt(index) - 3),
        answerField: answerFieldsID,
        try: 1,
        keywordMatches: answerGet,
        userAnswer: value,
        isPassed: answerCheck,
        isModifiedAudioPlayed: false,
        isTranscriptShown: false
      };
      setState({ ...state, userAnswerModPre: a, userAnswerMod: z });

      const len = state.userAnswerMain.length;
      let num = 0;
      for (let i = 0; i < len; i++) {
        if (state.userAnswerMod[i] != undefined) {
          num++;
          if (state.userAnswerMod[i].userAnswer.length == 0) {
            num--;
          }
        }
      }
      if (num == len) {
        document.querySelector(".nextBtn").classList.remove("disabled");
        const titleChanger = document.querySelector(".nextSubmitParentDiv");
        titleChanger.classList.remove("nextSubmitParentDivUnanswered");
        if (titleChanger.classList.contains("nextSubmitParentDivWaiting")) {
          titleChanger.title = "Please wait for your assessment to save your previous answer before proceeding.";
        } else {
          titleChanger.title = "Click to submit your answers.";
        }
      } else {
        document.querySelector(".nextBtn").classList.add("disabled");
        const titleChanger = document.querySelector(".nextSubmitParentDiv");
        titleChanger.classList.add("nextSubmitParentDivUnanswered");
        if (titleChanger.classList.contains("nextSubmitParentDivWaiting")) {
          titleChanger.title = "Please wait for your assessment to save your previous answer before proceeding.";
        } else {
          titleChanger.title = "Please attempt to re-answer at least one incorrect question before proceeding.";
        }
      }
    },
    [state, updateUserAnswerSA2]
  );

  const combineMainTLandEN = useCallback(() => {
    let result = [];
    const getUserAnswer = state.userAnswerMain;
    const getUserAnswertl = state.tlUserAnswerMain;
    const len = state.correctAnswer.length;

    for (var i = 0; i < len; i++) {
      if (getUserAnswertl[i + 3] && parseInt(JSON.stringify(getUserAnswertl[i + 3].userAnswer.length)) !== 0) {
        result.push(getUserAnswertl[i + 3]); // TL (Transcription)
      }
      result.push(getUserAnswer[i]); // English (Translation)
    }

    return result;
  }, [state]);

  const combineModTLandEN = useCallback(() => {
    let result = [];
    const getUserAnswer = state.userAnswerMod;
    const getUserAnswertl = state.tlUserAnswerMod;
    const len = state.correctAnswer.length;

    for (var i = 0; i < len; i++) {
      if (getUserAnswertl[i + 3] && parseInt(JSON.stringify(getUserAnswertl[i + 3].userAnswer.length)) !== 0) {
        result.push(getUserAnswertl[i + 3]); // TL (Transcription)
      }
      result.push(getUserAnswer[i]); // English (Translation)
    }

    return result;
  }, [state]);

  const combinedAnswersSA2 = useCallback(
    (btnName) => {
      //console.log("next btn clicked, here are the NLP Scores...");
      //console.log(storeNLPScores);
      dispatch({
        type: "APPEND_NLP_SCORES",
        payload: {
          identifier: assessmentState.assessmentModuleRef, //just GR for GR
          data: storeNLPScores
        }
      });
      const mainAns = combineMainTLandEN();
      const modAns = combineModTLandEN();
      let combinedAns = null;
      let finalAns = null;

      // console.log(btnName);
      // console.log(state.clickCounter);
      // console.log(finalChecking);
      // console.log(combinedAns);

      if (state.clickCounter === 0 && finalChecking === true) {
        combinedAns = mainAns;
      } else if (state.clickCounter >= 1 && finalChecking === true) {
        combinedAns = mainAns.concat(modAns);
      }

      //console.log("prior to send");
      if (combinedAns !== null && finalChecking !== null) {
        //console.log("sent");
        finalAns = '{"questionItemAns":' + JSON.stringify(combinedAns) + "}";
        if (!(state.clickCounter === 0 && finalChecking === null)) props.parentCallback(finalAns, btnName);
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [state, finalChecking, combineMainTLandEN, combineModTLandEN]
  );

  const updateTLUserAnswerSA2 = useCallback(
    (id) => {
      const userAnswerIndex = id.substr(-1);
      const itemId = parseInt(id.substr(-1) - 3);
      let countAnswer = 0;
      let checkAnswer = false;
      let getAnswer = [];

      let userAns;
      if (state.clickCounter === 0) {
        userAns = state.tlUserAnswerMainPre;
      } else {
        userAns = state.tlUserAnswerModPre;
      }

      let matching;
      let itemScore = 0;
      const passingScore = state.correctAnswer[itemId].answerFields[1].passingScore;

      if (userAns[userAnswerIndex] !== undefined) {
        if (passingScore == "100") {
          const answerKeywordLen = state.correctAnswer[itemId].answerFields[1].answerKeywords.length;

          for (let j = 0; j < answerKeywordLen; j++) {
            const patternLen = state.correctAnswer[itemId].answerFields[1].answerKeywords[j].matchingPatterns.length;
            for (let k = 0; k < patternLen; k++) {
              const crrtAns = state.correctAnswer[itemId].answerFields[1].answerKeywords[j].matchingPatterns[k] + ".*";

              matching = userAns[userAnswerIndex].userAnswer.toLowerCase().match(crrtAns.toLowerCase());
              if (matching !== null) {
                countAnswer = countAnswer + 1;
                getAnswer.push(crrtAns.toLowerCase());
                break;
              }
            }
          }
          if (countAnswer == answerKeywordLen) {
            checkAnswer = true;
          }

          return { checkAnswer, getAnswer };
        }

        if (passingScore == "50") {
          const answerKeywordLen = state.correctAnswer[itemId].answerFields[1].answerKeywords.length;

          if (answerKeywordLen == 1) {
            const patternLen = state.correctAnswer[itemId].answerFields[1].answerKeywords[0].matchingPatterns.length;
            for (let k = 0; k < patternLen; k++) {
              const crrtAns = state.correctAnswer[itemId].answerFields[1].answerKeywords[0].matchingPatterns[k] + ".*";

              matching = userAns[userAnswerIndex].userAnswer.toLowerCase().match(crrtAns.toLowerCase());
              if (matching !== null) {
                countAnswer = countAnswer + 1;
                getAnswer.push(crrtAns.toLowerCase());
                break;
              }
            }

            if (countAnswer == answerKeywordLen) {
              checkAnswer = true;
            }
            return { checkAnswer, getAnswer };
          } else {
            for (let j = 0; j < answerKeywordLen; j++) {
              let individualScore = state.correctAnswer[itemId].answerFields[1].answerKeywords[j].individualScore;

              const patternLen = state.correctAnswer[itemId].answerFields[1].answerKeywords[j].matchingPatterns.length;
              for (let k = 0; k < patternLen; k++) {
                const crrtAns =
                  state.correctAnswer[itemId].answerFields[1].answerKeywords[j].matchingPatterns[k] + ".*";

                matching = userAns[userAnswerIndex].userAnswer.toLowerCase().match(crrtAns.toLowerCase());
                if (matching !== null) {
                  itemScore = itemScore + individualScore;
                  getAnswer.push(crrtAns.toLowerCase());
                  break;
                }
              }
            }
            if (itemScore >= passingScore) {
              checkAnswer = true;
            } else {
              //do nothing
            }
            return { checkAnswer, getAnswer };
          }
        } else {
          const answerKeywordLen = state.correctAnswer[itemId].answerFields[1].answerKeywords.length;

          for (let j = 0; j < answerKeywordLen; j++) {
            let individualScore = state.correctAnswer[itemId].answerFields[1].answerKeywords[j].individualScore;
            const patternLen = state.correctAnswer[itemId].answerFields[1].answerKeywords[j].matchingPatterns.length;
            for (let k = 0; k < patternLen; k++) {
              const crrtAns = state.correctAnswer[itemId].answerFields[1].answerKeywords[j].matchingPatterns[k] + ".*";

              matching = userAns[userAnswerIndex].userAnswer.toLowerCase().match(crrtAns.toLowerCase());
              if (matching !== null) {
                itemScore = itemScore + individualScore;
                getAnswer.push(crrtAns.toLowerCase());
                break;
              }
            }
          }
          if (itemScore >= passingScore) {
            checkAnswer = true;
          } else {
            //do nothing
          }
          return { checkAnswer, getAnswer };
        }
      }
    },
    [state]
  );

  const handleTlChange = useCallback(
    (event) => {
      const answerFieldsID = event.currentTarget.name;
      const testitem = event.target.getAttribute("data-testitem");
      let index = event.currentTarget.id.substr(-3, 1);
      const id = event.currentTarget.id;
      const value = event.currentTarget.value;
      let new_state = Object.assign({}, state);
      let a = new_state.tlUserAnswerMainPre;
      let b = new_state.tlUserAnswerMod;
      let z = new_state.tlUserAnswerMain;

      a[index] = {
        uiControlID: id,
        userAnswer: value
      };
      const answerCheck = updateTLUserAnswerSA2(answerFieldsID).checkAnswer;
      const answerGet = updateTLUserAnswerSA2(answerFieldsID).getAnswer;

      z[index] = {
        uiControlID: id,
        testlet: state.actID,
        testitem:
          testitem !== null
            ? testitem
            : index < 3
            ? state.actID + "_" + index
            : state.actID + "_" + parseInt(parseInt(index) - 3),
        answerField: answerFieldsID,
        try: 0,
        keywordMatches: answerGet,
        userAnswer: value,
        isPassed: answerCheck,
        isModifiedAudioPlayed: false,
        isTranscriptShown: false
      };

      b[index] = {
        uiControlID: id.substring(0, id.length - 1) + 1,
        testlet: state.actID,
        testitem:
          testitem !== null
            ? testitem
            : index < 3
            ? state.actID + "_" + index
            : state.actID + "_" + parseInt(parseInt(index) - 3),
        answerField: answerFieldsID,
        try: 1,
        keywordMatches: answerGet,
        userAnswer: value,
        isPassed: answerCheck,
        isModifiedAudioPlayed: false,
        isTranscriptShown: false
      };
      setState({
        ...state,
        tlUserAnswerMainPre: a,
        tlUserAnswerMain: z,
        tlUserAnswerMod: b
      });

      let TransToMod = [];
      if (id != null) {
        let selID = id.substring(0, id.length - 1);
        TransToMod.push(selID + "1");
      }
    },
    [state, updateTLUserAnswerSA2]
  );

  const handleTlChangeMod = useCallback(
    (event) => {
      const answerFieldsID = event.currentTarget.name;
      const testitem = event.target.getAttribute("data-testitem");
      let index = event.currentTarget.id.substr(-3, 1);
      const id = event.currentTarget.id;
      const value = event.currentTarget.value;
      let new_state = Object.assign({}, state);
      let a = new_state.tlUserAnswerModPre;
      let z = new_state.tlUserAnswerMod;

      a[index] = {
        uiControlID: id,
        userAnswer: value
      };

      const answerCheck = updateTLUserAnswerSA2(answerFieldsID).checkAnswer;
      const answerGet = updateTLUserAnswerSA2(answerFieldsID).getAnswer;
      z[index] = {
        uiControlID: id,
        testlet: state.actID,
        testitem:
          testitem !== null
            ? testitem
            : index < 3
            ? state.actID + "_" + index
            : state.actID + "_" + parseInt(parseInt(index) - 3),
        answerField: answerFieldsID,
        try: 1,
        keywordMatches: answerGet,
        userAnswer: value,
        isPassed: answerCheck,
        isModifiedAudioPlayed: false,
        isTranscriptShown: false
      };
      setState({ ...state, tlUserAnswerModPre: a, tlUserAnswerMod: z });
    },
    [state, updateTLUserAnswerSA2]
  );

  const handleEvalSA2 = useCallback(
    (event) => {
      const btnName = event.currentTarget.name;
      setTheBtnName(btnName);

      let enUserAns = "";
      let tlUserAns = "";
      if (state.clickCounter === 0) {
        enUserAns = state.userAnswerMain;
        tlUserAns = state.tlUserAnswerMain;
      } else {
        enUserAns = state.userAnswerMod;
        tlUserAns = state.tlUserAnswerMod;
      }

      const enCrrtAns = [];
      const tlCrrtAns = [];

      for (let z = 0; z < state.correctAnswer.length; z++) {
        enCrrtAns.push(state.correctAnswer[z].answerFields[0]);
        tlCrrtAns.push(state.correctAnswer[z].answerFields[1]);
      }

      let crrtEnAnswerCounter = 0;
      let crrtTlAnswerCounter = 0;
      let scoreCalTL = "";
      let scoreCalEN = "";

      for (let i = 0; i < enCrrtAns.length; i++) {
        const passingScore = enCrrtAns[i].passingScore;
        let enCheckAnswer = 0;
        let itemScoreEN = 0;

        if (enUserAns[i] !== undefined) {
          if (passingScore == "100") {
            const answerKeywordLen = enCrrtAns[i].answerKeywords.length;
            for (let j = 0; j < answerKeywordLen; j++) {
              const patternLen = enCrrtAns[i].answerKeywords[j].matchingPatterns.length;
              for (let k = 0; k < patternLen; k++) {
                const enCrrtItem = enCrrtAns[i].answerKeywords[j].matchingPatterns[k] + ".*";
                const enMatching = enUserAns[i].userAnswer.toLowerCase().match(enCrrtItem.toLowerCase());
                if (enMatching !== null) {
                  enCheckAnswer = enCheckAnswer + 1;
                  break;
                }
              }
            }
            if (enCheckAnswer === answerKeywordLen) {
              crrtEnAnswerCounter = crrtEnAnswerCounter + 1;
            }
          } else {
            const answerKeywordLen = enCrrtAns[i].answerKeywords.length;
            for (let j = 0; j < answerKeywordLen; j++) {
              let individualScoreEN = enCrrtAns[i].answerKeywords[j].individualScore;
              const patternLen = enCrrtAns[i].answerKeywords[j].matchingPatterns.length;
              for (let k = 0; k < patternLen; k++) {
                const enCrrtItem = enCrrtAns[i].answerKeywords[j].matchingPatterns[k] + ".*";
                const enMatching = enUserAns[i].userAnswer.toLowerCase().match(enCrrtItem.toLowerCase());
                if (enMatching !== null) {
                  itemScoreEN = itemScoreEN + individualScoreEN;
                  break;
                }
              }
            }
            if (itemScoreEN >= passingScore) {
              crrtEnAnswerCounter = crrtEnAnswerCounter + 1;
            }
          }
        }
      }

      // For Transcription (TL)
      for (let i = 0; i < enCrrtAns.length; i++) {
        const passingScore = tlCrrtAns[i].passingScore;
        let tlCheckAnswer = 0;
        let itemScoreTL = 0;

        if (tlUserAns[i + 3] != undefined) {
          if (passingScore == "100") {
            const answerKeywordLen = tlCrrtAns[i].answerKeywords.length;
            for (let j = 0; j < answerKeywordLen; j++) {
              const patternLen = tlCrrtAns[i].answerKeywords[j].matchingPatterns.length;
              for (let k = 0; k < patternLen; k++) {
                const tlCrrtItem = tlCrrtAns[i].answerKeywords[j].matchingPatterns[k] + ".*";
                const tlMatching = tlUserAns[i + 3].userAnswer.toLowerCase().match(tlCrrtItem.toLowerCase());
                if (tlMatching !== null) {
                  tlCheckAnswer = tlCheckAnswer + 1;

                  break;
                }
              }
            }
            if (tlCheckAnswer === answerKeywordLen) {
              crrtTlAnswerCounter = crrtTlAnswerCounter + 1;
            }
          }
          if (passingScore == "50") {
            const answerKeywordLen = tlCrrtAns[i].answerKeywords.length;
            if (answerKeywordLen == 1) {
              const patternLen = tlCrrtAns[i].answerKeywords[0].matchingPatterns.length;
              for (let k = 0; k < patternLen; k++) {
                const tlCrrtItem = tlCrrtAns[i].answerKeywords[0].matchingPatterns[k] + ".*";
                const tlMatching = tlUserAns[i + 3].userAnswer.toLowerCase().match(tlCrrtItem.toLowerCase());
                if (tlMatching !== null) {
                  tlCheckAnswer = tlCheckAnswer + 1;

                  break;
                }
              }
            }
            if (tlCheckAnswer === answerKeywordLen) {
              crrtTlAnswerCounter = crrtTlAnswerCounter + 1;
            } else {
              for (let j = 0; j < answerKeywordLen; j++) {
                let individualScoreTL = tlCrrtAns[i].answerKeywords[j].individualScore;
                const patternLen = tlCrrtAns[i].answerKeywords[j].matchingPatterns.length;
                for (let k = 0; k < patternLen; k++) {
                  const tlCrrtItem = tlCrrtAns[i].answerKeywords[j].matchingPatterns[k] + ".*";
                  const tlMatching = tlUserAns[i + 3].userAnswer.toLowerCase().match(tlCrrtItem.toLowerCase());
                  if (tlMatching !== null) {
                    itemScoreTL = itemScoreTL + individualScoreTL;
                    break;
                  }
                }
              }
              if (itemScoreTL == passingScore) {
                crrtTlAnswerCounter = crrtTlAnswerCounter + 1;
              }
            }
          } else {
            const answerKeywordLen = tlCrrtAns[i].answerKeywords.length;
            for (let j = 0; j < answerKeywordLen; j++) {
              let individualScoreTL = tlCrrtAns[i].answerKeywords[j].individualScore;
              const patternLen = tlCrrtAns[i].answerKeywords[j].matchingPatterns.length;
              for (let k = 0; k < patternLen; k++) {
                const tlCrrtItem = tlCrrtAns[i].answerKeywords[j].matchingPatterns[k] + ".*";
                const tlMatching = tlUserAns[i + 3].userAnswer.toLowerCase().match(tlCrrtItem.toLowerCase());
                if (tlMatching !== null) {
                  itemScoreTL = itemScoreTL + individualScoreTL;
                  break;
                }
              }
            }
            if (itemScoreTL == passingScore) {
              crrtTlAnswerCounter = crrtTlAnswerCounter + 1;
            }
          }
        }
      }

      if (crrtEnAnswerCounter === enCrrtAns.length && state.clickCounter === 0) {
        // console.log("correct");
        setFinalChecking(true);

        //setState({ ...state, clickCounter: state.clickCounter + 1 });
        if (props.navCrrtItem === props.navLen) {
          props.submitButton();
        } else {
          props.nextButton();
        }
      } else {
        document.querySelector(".nextBtn").classList.add("disabled");
        const titleChanger = document.querySelector(".nextSubmitParentDiv");
        titleChanger.classList.add("nextSubmitParentDivUnanswered");
        if (titleChanger.classList.contains("nextSubmitParentDivWaiting")) {
          titleChanger.title =
            "Please attempt to re-answer at least one incorrect question before proceeding. You will also need to wait for the system to record your previous answer before proceeding.";
        } else {
          titleChanger.title = "Please attempt to re-answer at least one incorrect question before proceeding.";
        }
        if (state.clickCounter === 0) {
          // console.log("incorrect");
          scoreCalEN =
            "<p>You scored " +
            crrtEnAnswerCounter +
            "/" +
            enCrrtAns.length +
            " on grammar point. You may wish to try again.</p>";

          scoreCalTL = "<p>You transcribed " + crrtTlAnswerCounter + "/" + tlCrrtAns.length + " correctly.<p>";

          setState({
            ...state,
            scoreEN: scoreCalEN,
            scoreTL: scoreCalTL,
            clickCounter: state.clickCounter + 1
          });
          document.querySelector(".feedbackModal").style.display = "block";
        } else {
          setFinalChecking(true);

          if (props.navCrrtItem === props.navLen) {
            props.submitButton();
          } else {
            props.nextButton();
          }
          setState({ ...state, clickCounter: state.clickCounter + 1 });
        }
      }
      //combinedAnswersSA2(btnName);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [state, combinedAnswersSA2]
  );

  useEffect(() => {
    if (finalChecking === true) {
      combinedAnswersSA2(theBtnName);
    }
  }, [combinedAnswersSA2, theBtnName, finalChecking]);

  const playMainAudio = useCallback(
    (event) => {
      let num = parseInt(event.currentTarget.id);
      setState({ ...state, audSequence: num });      
    },
    [state]
  );

  // ** Issue #163 - disable NLP calls until post-launch period
  // //** customHook & effects to score from NLP API */
  // const [nlpScoreData, nlpScoreRunning, nlpScoreError] = useScoreNLP(
  //   eventNLP,
  //   "GLSB",
  //   assessmentState.assessmentSessionId,
  //   state.correctAnswer,
  //   runScoreNLP
  // );
  // //only execute once
  // useEffect(() => {
  //   if (runScoreNLP) {
  //     setRunScoreNLP(false); //resets to false after one run
  //   }
  // }, [runScoreNLP]);
  // //initialization call from react component
  // const checkNLP = useCallback((event) => {
  //   setEventNLP(event);
  //   setRunScoreNLP(true);
  // }, []);
  // //store the async results in a static object we can reference at the end of the testlet
  // useEffect(() => {
  //   if (nlpScoreData !== null) {
  //     let tempArr = storeNLPScores;
  //     if (nlpScoreData.testletId !== null && nlpScoreData.nlpScore !== null) {
  //       tempArr[nlpScoreData.testletId] = nlpScoreData.nlpScore;
  //       setStoreNLPScores(tempArr);
  //     }
  //     //console.log(tempArr);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [nlpScoreData]);

  const renderTest = () => {
    return (
      <>
        <Test
          assessment={state.assessment}
          actID={state.actID}
          lang={state.lang}
          dir={state.dir}
          instruction={state.instruction}
          correctAnswer={state.correctAnswer}
          hint={state.hint}
          clickCounter={state.clickCounter}
          audioFile1={state.audioFile1}
          audioFile2={state.audioFile2}
          audioFile3={state.audioFile3}
          // ** Issue #163 - disable NLP calls until post-launch period
          //onLoseFocusCheckNLP={checkNLP}
          onAnswerEnSelected={handleEnChange}
          onAnswerEnSelectedMod={handleEnChangeMod}
          invalidEnInput={showNonENTextError}
          onAnswerTlSelected={handleTlChange}
          onAnswerTlSelectedMod={handleTlChangeMod}
          onEvaluation={handleEvalSA2}
          onPlayMainAudio={playMainAudio}
          audioFN={state.audioFile}
          audKey={state.audioKey}
          anchorKey={state.anchorKey}
          scoreEN={state.scoreEN}
          scoreTL={state.scoreTL}
          audSequence={state.audSequence}
          navLen={props.navLen}
          navCrrtItem={props.navCrrtItem}
        />
        <Footer actID={state.actID} mod={state.modality} template={state.template} skill={state.skill} />
      </>
    );
  };

  return <div>{renderTest()}</div>;
};

export default GLSB;
