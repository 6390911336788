import React, { useEffect } from "react";
import FontSize from "../../../../../components/fontsize/FontSize";

export default function AssessmentPassage(props) {
  const RTL = props.dir;
  const dir = RTL ? "RTL" : "";

  const testitem = props.testitem;
  const answerFieldsID = props.answerFieldsID;

  const ddList = props.optionList;
  const num = props.optionNumber;

  const itemID_fst = answerFieldsID + "_0";
  const itemID_snd = answerFieldsID + "_1";

  const passage = props.optionContent;
  const len = passage.length;

  let clickCounter = props.clickCounter;

  useEffect(() => {
    if (clickCounter === 0) {
      document.querySelectorAll(".fstAttempt").forEach(function (event) {
        event.style.display = "inline";
      });
      document.querySelectorAll(".sndAttempt").forEach(function (event) {
        event.style.display = "none";
      });
    } else {
      document.querySelectorAll(".fstAttempt").forEach(function (event) {
        event.style.display = "none";
      });
      document.querySelectorAll(".sndAttempt").forEach(function (event) {
        event.style.display = "inline";
      });
    }
  }, [clickCounter]);

  const createAnswerField = () => {
    let items = [];
    for (let i = 0; i < len; i++) {
      const dropdown = ddList[0].map((option, index) => (
        <option key={option} value={index}>
          {option}
        </option>
      ));
      if (i === 0) {
        let shortPassage = '<span class="paragraphNumber">' + (num + 1) + ". </span> " + passage[i].value + "\u00A0";
        shortPassage = shortPassage
          .replace(/\r\n/g, "<br />")
          .replace(/\n\r/g, "<br />")
          .replace(/\r/g, "<br />")
          .replace(/\n/g, "<br />");
        shortPassage = shortPassage
          .replace(/<P>/g, "")
          .replace(/<p>/g, "")
          .replace(/<\/P>/g, "<br /><br />")          
          .replace(/<\/p>/g, "<br /><br />")
          .replace(/(<br \/>\s*){3,4}/g, "<br /><br />")
;
        items.push(
          <span key={i}>
            <span
              dangerouslySetInnerHTML={{
                __html: shortPassage
              }}
            />
            <span className="fstAttempt">
              {/* <span>&nbsp;</span> */}
              <select id={itemID_fst} name={answerFieldsID} data-testitem={testitem} onChange={props.onAnswerSelected}>
                <option key="select" value="select">
                  Select
                </option>
                {dropdown}
              </select>
            </span>
            <span className="sndAttempt">
              {/* <span>&nbsp;</span> */}
              <select
                id={itemID_snd}
                name={answerFieldsID}
                data-testitem={testitem}
                onChange={props.onAnswerSelectedMod}
              >
                <option key="select" value="select">
                  Select
                </option>
                {dropdown}
              </select>
            </span>
          </span>
        );
      } else {
        let shortPassage = "\u00A0" + passage[i].value + "\u00A0";
        shortPassage = shortPassage
          .replace(/\r\n/g, "<br />")
          .replace(/\n\r/g, "<br />")
          .replace(/\r/g, "<br />")
          .replace(/\n/g, "<br />")
          .replace(/<br \/><br \/>$/g, "")
          .replace(/<br \/><br \/>\s*$/g, "")
          .replace(/\s*$/g, "")
          .replace(/<p><\/p>$/g, "")
          .replace(/<p>\s*<\/p>$/g, "");
        shortPassage = shortPassage
          .replace(/<P>/g, "")
          .replace(/<p>/g, "")
          .replace(/<\/P>/g, "<br /><br />")
          .replace(/<\/p>/g, "<br /><br />")
          .replace(/(<br \/>\s*){3,4}/g, "<br /><br />")
;
        items.push(
          <span
            key={i}
            dangerouslySetInnerHTML={{
              __html: shortPassage
            }}
          />
        );
      }
    }
    return items;
  };
  return (
    <div>
      {num === 0 ? (
        <div className={[dir, "textPassage noSelect"].join(" ")}>
          <FontSize />
          <div>{createAnswerField()}</div>
          <hr />
        </div>
      ) : (
        <div className={[dir, "textPassage noSelect"].join(" ")}>
          <div>{createAnswerField()}</div>
          <hr />
        </div>
      )}
      <br />
    </div>
  );
}
