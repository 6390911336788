import React from "react";
import dompurify from "dompurify";
import Footer from "../../footer/Footer";
import menu from "./menucontents/MenuContents.json";

export default function About() {
  const sanitizer = dompurify.sanitize;
  const about = menu[0].about;

  return (
    <>
      {/* <h4 className="description">ABOUT ODA</h4> */}
      <h4>
        <div
          style={{ fontWeight: "normal", fontSize: "16px" }}
          dangerouslySetInnerHTML={{
            __html: sanitizer(about),
          }}
        />
      </h4>
      <br />
      <Footer showReportProblem={false} />
    </>
  );
}
