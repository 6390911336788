import React, { useContext, useEffect, useCallback } from "react";
import { AssessmentContext } from "../../context/AssessmentProvider";
import "../../css/index.css";
import upArrow from "../../img/icons/keyboardUpArrow.svg";
import textIcon from "../../img/icons/fontSize.svg";
import downArrow from "../../img/icons/keyboardDownArrow.svg";

/**
 * @component FontSize
 * @description This component will handle fontsize + stateful vars
 * @params {object} props
 *
 */

const FontSize = (props) => {
  const [assessmentState, dispatch] = useContext(AssessmentContext);
  const defaultSize = 16;
  const incrementSize = 2;
  const maxSize = 74;
  const minSize = 4;  

  const handleDefault = useCallback(() => {
    if (document.querySelector("#defaultFont") !== null) {
      changeFontSize(defaultSize);
      dispatch({
        type: "UPDATE_FONT_SIZE",
        payload: defaultSize
      });
    }
  },[assessmentState.assessmentFontSize]);

  const handleIncrease = useCallback(() => {
    const newSize = assessmentState.assessmentFontSize + incrementSize;
    if (document.querySelector("#increaseFont") !== null && newSize < maxSize) {
      changeFontSize(newSize);
      dispatch({
        type: "UPDATE_FONT_SIZE",
        payload: newSize
      });
    }
  },[assessmentState.assessmentFontSize]);

  const handleDecrease = useCallback(() => {
    const newSize = assessmentState.assessmentFontSize - incrementSize;
    if (document.querySelector("#increaseFont") !== null && newSize > minSize) {
      changeFontSize(newSize);
      dispatch({
        type: "UPDATE_FONT_SIZE",
        payload: newSize
      });
    }
  },[assessmentState.assessmentFontSize]);

  //-- Old Functions --//
  //FontSize control
  function changeFontSize(size) {
    let zoomInOuts = document.querySelectorAll(".textPassage");
    let zoomInOutsExists = document.querySelectorAll(".textPassage") !== null;
    if (zoomInOutsExists) {
      zoomInOuts.forEach(function (zoomInOut) {
        zoomInOut.style.fontSize = size + "px";
      });
    }

    let zoomInOuts1 = document.querySelectorAll(".GMCA .answerSection");
    let zoomInOuts1Exists = document.querySelectorAll(".GMCA .answerSection") !== null;
    if (zoomInOuts1Exists) {
      zoomInOuts1.forEach(function (zoomInOut1) {
        zoomInOut1.style.fontSize = size + "px";
      });
    }

    let zoomInOuts2 = document.querySelectorAll(".GMCB .answerSection");
    let zoomInOuts2Exists = document.querySelectorAll(".GMCB .answerSection") !== null;
    if (zoomInOuts2Exists) {
      zoomInOuts2.forEach(function (zoomInOut2) {
        zoomInOut2.style.fontSize = size + "px";
      });
    }

    let zoomInOut3 = document.querySelectorAll(".textPassage select");
    let zoomInOut3Exists = document.querySelectorAll(".textPassage select") !== null;
    if (zoomInOut3Exists) {
      zoomInOut3.forEach(function (zoomInOut3) {
        zoomInOut3.style.fontSize = size + "px";
        zoomInOut3.style.paddingRight = size + 4 + "px";
      });
    }
  }
  //--End old Functions--//

  //on reload, lets use the set font size
  useEffect(()=>{
    changeFontSize(assessmentState.assessmentFontSize);
  },[])

  return (
    <div className="textSize">
      <img id="increaseFont" src={upArrow} title="Increase text size" alt="Increase" onClick={handleIncrease}/>
      <img id="defaultFont" src={textIcon} title="Default text size" alt="Default text size" onClick={handleDefault} />
      <img id="decreaseFont" src={downArrow} title="Decrease text size" alt="Decrease" onClick={handleDecrease} />
    </div>
  );
};

export default FontSize;
