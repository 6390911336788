import React from "react";
import reading from "../../img/icons/reading_icon_material.svg";
import listeing from "../../img/icons/listening_icon_material.svg";
import grammar from "../../img/icons/grammar_icon_material.svg";
import readingWhite from "../../img/icons/reading_icon_material_white.svg";
import listeningWhite from "../../img/icons/listening_icon_material_white.svg";
import grammarWhite from "../../img/icons/grammar_icon_material_white.svg";

export default function Languages() {
  return (
    <div>
      <div className="callout">
        <p>
          Assessments are available in reading, listening, and grammar for
          Interagency Language Roundtable (ILR) Levels&nbsp;1&nbsp;to&nbsp;3
        </p>
      </div>

      <h4 className="offers">ODA currently offers:</h4>
      <div className="iconDescriptions">
        <img src={reading} alt="Reading Assessment" /> Reading
        <br />
        <img src={listeing} alt="Listening Assessment" /> Listening
        <br />
        <img src={grammar} alt="Grammar Assessment" /> Grammar
      </div>

      <table>
        <tbody>
          <tr>
            <th>Language</th>
            <th>
              <img src={readingWhite} alt="Reading Assessment" />
              <div className="tableHead">Reading Assessment</div>
            </th>
            <th>
              <img src={listeningWhite} alt="Listening Assessment" />
              <div className="tableHead">Listening Assessment</div>
            </th>
            <th>
              <img src={grammarWhite} alt="Grammar Assessment" />
              <div className="tableHead">Grammar Assessment</div>
            </th>
          </tr>
        </tbody>
        <tbody>
          <tr>
            <td>Arabic-Egyptian</td>
            <td className="soon">X</td>
            <td>✓</td>
            <td className="soon">X</td>
          </tr>
        </tbody>
        <tbody>
          <tr>
            <td>Arabic-Iraqi</td>
            <td className="soon">X</td>
            <td>✓</td>
            <td className="soon">X</td>
          </tr>
        </tbody>
        <tbody>
          <tr>
            <td>Arabic-Levantine</td>
            <td className="soon">X</td>
            <td>✓</td>
            <td className="soon">X</td>
          </tr>
        </tbody>
        <tbody>
          <tr>
            <td>Arabic-MSA</td>
            <td>✓</td>
            <td>✓</td>
            <td>✓</td>
          </tr>
        </tbody>
        <tbody>
          <tr>
            <td>Balochi</td>
            <td className="soon">X</td>
            <td>✓</td>
            <td className="soon">X</td>
          </tr>
        </tbody>
        <tbody>
          <tr>
            <td>Chinese</td>
            <td>✓</td>
            <td>✓</td>
            <td>✓</td>
          </tr>
        </tbody>
        <tbody>
          <tr>
            <td>Dari</td>
            <td>✓</td>
            <td>✓</td>
            <td className="soon">X</td>
          </tr>
        </tbody>
        <tbody>
          <tr>
            <td>English</td>
            <td>✓</td>
            <td>✓</td>
            <td>✓</td>
          </tr>
        </tbody>
        <tbody>
          <tr>
            <td>Farsi</td>
            <td>✓</td>
            <td>✓</td>
            <td>✓</td>
          </tr>
        </tbody>
        <tbody>
          <tr>
            <td>French</td>
            <td>✓</td>
            <td>✓</td>
            <td className="soon">X</td>
          </tr>
        </tbody>
        <tbody>
          <tr>
            <td>Hebrew</td>
            <td>✓</td>
            <td>✓</td>
            <td className="soon">X</td>
          </tr>
        </tbody>
        <tbody>
          <tr>
            <td>Korean (South)</td>
            <td>✓</td>
            <td>✓</td>
            <td>✓</td>
          </tr>
        </tbody>
        <tbody>
          <tr>
            <td>Korean (South + North)</td>
            <td>✓</td>
            <td>✓</td>
            <td className="soon">X</td>
          </tr>
        </tbody>
        <tbody>
          <tr>
            <td>Pashto</td>
            <td>✓</td>
            <td>✓</td>
            <td className="soon">X</td>
          </tr>
        </tbody>
        <tbody>
          <tr>
            <td>Portuguese</td>
            <td>✓</td>
            <td>✓</td>
            <td className="soon">X</td>
          </tr>
        </tbody>
        <tbody>
          <tr>
            <td>Russian</td>
            <td>✓</td>
            <td>✓</td>
            <td>✓</td>
          </tr>
        </tbody>
        <tbody>
          <tr>
            <td>Serbian / Croatian</td>
            <td>✓</td>
            <td>✓</td>
            <td className="soon">X</td>
          </tr>
        </tbody>
        <tbody>
          <tr>
            <td>Somali</td>
            <td>✓</td>
            <td>✓</td>
            <td className="soon">X</td>
          </tr>
        </tbody>
        <tbody>
          <tr>
            <td>Spanish</td>
            <td>✓</td>
            <td>✓</td>
            <td>✓</td>
          </tr>
        </tbody>
        <tbody>
          <tr>
            <td>Tagalog</td>
            <td>✓</td>
            <td>✓</td>
            <td>✓</td>
          </tr>
        </tbody>
        <tbody>
          <tr>
            <td>Turkish</td>
            <td>✓</td>
            <td>✓</td>
            <td className="soon">X</td>
          </tr>
        </tbody>
        <tbody>
          <tr>
            <td>Urdu</td>
            <td>✓</td>
            <td>✓</td>
            <td className="soon">X</td>
          </tr>
        </tbody>
      </table>

      <div className="finePrint">
        <span className="asterisk">★</span> In development now
      </div>
    </div>
  );
}
