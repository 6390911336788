import "../../css/index.css";
import React, { useContext } from "react";
import { AssessmentContext } from "../../context/AssessmentProvider";

/**
 * @component NewTestTipsModal
 * @description displays a list of test tips at the start of a given test (state.counter === 0)
 * @param {object} props
 **/

export default function NewTestTipsModal(props) {
  const [assessmentState] = useContext(AssessmentContext);
  //console.log(props);
  return (
    <div className="modalDialog" id="NewTestTipsModal">
      <div className="NewTestTipsModal">
        <div className="feedbackHeader" id="modalHeader">
          ODA Assessment Tips
        </div>
        <h4>Some tips to help you with your assessment:</h4>
        <h5>
          <ul>
            {assessmentState.assessmentModality === "GR" ? (
              <React.Fragment>
                <li>
                  Your progress is saved after each question is answered.
                  <br />
                  <br />
                </li>
                <ul>
                  <li>
                    Should you need to stop or pause your assessment, simply
                    close or navigate away from the page. <br />
                    <br />
                  </li>
                  <li>
                    You may resume an incomplete assessment from the "Resume an
                    Assessment" button on your assessment dashboard at any time.
                    <br />
                    <br />
                  </li>
                </ul>
                <li>
                  For this grammar assessment, you will be presented with a
                  single feedback hint when answering at least one question
                  incorrectly. Use the information provided by this hint to
                  attempt to correct at least one question before moving on.
                  <br />
                  <br />
                </li>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <li>
                  Answer questions in English unless otherwise stated.
                  <br />
                  <br />
                </li>
                <li>
                  Your progress is saved after each question is answered.
                  <br />
                  <br />
                </li>
                <ul>
                  <li>
                    Should you need to stop or pause your assessment, simply
                    close or navigate away from the page. <br />
                    <br />
                  </li>
                  <li>
                    You may resume an incomplete assessment from the "Resume an
                    Assessment" button on your assessment dashboard at any time.
                    <br />
                    <br />
                  </li>
                </ul>
                <li>
                  Avoid generic words such as "it" in responses when a specific
                  word can be used.
                  <br />
                  <br />
                </li>
                <li>
                  Reading & Listening assessments contain multiple sets that
                  will scale according to your performance. On average, expect
                  to complete 3 to 5 sets per reading or listening
                  assessment. The progress tracker resets after each set is
                  submitted. <br />
                </li>
              </React.Fragment>
            )}
          </ul>
        </h5>
        <button
          className="okTestTips outlined"
          onClick={(e) => props.handleRefModalTestTips(e)}
        >
          OK
        </button>
        <br />
        <br />
        <br />
      </div>
    </div>
  );
}
