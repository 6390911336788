import { useEffect } from "react";
import dompurify from "dompurify";
import FontSize from "../../../../../components/fontsize/FontSize";

export default function QuestionPassage(props) {
  const sanitizer = dompurify.sanitize;
  const RTL = props.dir;
  const dir = RTL ? "RTL" : "";

  const setHTOData = (index, id, value) => {
    props.ongetHTOData(index, id, value);
  };

  const lang = props.lang;
  const actID = props.actID + "_0";
  const passage = props.passage;

  const fragmentedPassage = breakDownPassage(passage);
  function breakDownPassage(m) {
    const theText = m;
    const CM = lang === "cmn" ? true : false;
    const interacText = [];
    const delimiter = /\s/;
    let inUnderline = false;
    let passage = theText
      .replace(/<U>/g, " | ")
      .replace(/<u>/g, " | ")
      .replace(/<\/U>/g, " || ")
      .replace(/<\/u>/g, " || ");
    passage = passage
      .replace(/<P>/g, " ~` ")
      .replace(/<p>/g, " ~` ")
      .replace(/<\/P>/g, " `~` ")
      .replace(/<\/p>/g, " `~` ");
    passage = passage.replace(/nbsp/g, " &&& ");
    passage = passage
      .replace(/<[^>]+>/g, "")
      .replace(/'/g, "'")
      .replace(/"/g, '"')
      .replace(/#/g, "# ");
    passage = passage
      .replace(/\r\n/g, "<br />")
      .replace(/\n\r/g, "<br />")
      .replace(/\r/g, "<br />")
      .replace(/\n/g, "<br />")
      .replace(/<br \/><br \/>$/g, "")
      .replace(/<br \/><br \/>\s*$/g, "");
    passage = passage
      .replace(/<P>/g, "")
      .replace(/<p>/g, "")
      .replace(/<\/P>/g, "<br /><br />")
      .replace(/<\/p>/g, "<br /><br />")
      .replace(/(<br \/>\s*){3,4}/g, "<br /><br />")
;
    const words = passage.split(delimiter);
    let xtrChar = "";
    let word = "";
    for (let i = 0; i < words.length; i += 1) {
      const frstChar = words[i].charAt(0);
      const oQ = frstChar === '"' ? true : false;
      if (oQ) {
        xtrChar = frstChar;
        word = words[i].substring(1, words[i].length);
      } else {
        xtrChar = "";
        word = words[i];
      }
      const lstC = word.charAt(word.length - 1);
      let condition = false;
      if (
        lstC === "." ||
        lstC === "," ||
        lstC === "!" ||
        lstC === "?" ||
        lstC === ";" ||
        lstC === ":" ||
        lstC === "-" ||
        lstC === '"'
      ) {
        condition = true;
      }
      if (!condition) {
        shw(xtrChar, word, "");
      } else {
        separate(xtrChar, word, "");
      }
    }
    function separate(xtrChar, word, extra) {
      const lstC = word.charAt(word.length - 1);
      let condition = false;
      if (
        lstC === "." ||
        lstC === "," ||
        lstC === "!" ||
        lstC === "?" ||
        lstC === ";" ||
        lstC === ":" ||
        lstC === "-" ||
        lstC === '"'
      ) {
        condition = true;
      }
      if (condition) {
        extra = lstC + extra;
        word = word.substring(0, word.length - 1);
        separate(xtrChar, word, extra);
      } else {
        shw(xtrChar, word, extra);
      }
    }
    function shw(xtrChar, word, extra) {
      word = word.replace(/'/g, "&#39;");
      let wordlink = "";

      if (word === "|") {
        wordlink = ' <i style="background-color:#cfe7ea"><b>';
      } else if (word === "||") {
        wordlink = "</b></i> ";
      } else if (word === "~`") {
        wordlink = "<p>";
      } else if (word === "`~`") {
        wordlink = "</p>";
      } else if (word === "&&&") {
        wordlink = "&nbsp;";
      } else {
        if (inUnderline) {
          if (word !== "") {
            wordlink = xtrChar + "<span>" + word + "</span>" + extra;
          }
        } else {
          wordlink = CM
            ? xtrChar + "<span>" + word + "</span>" + extra
            : " " + xtrChar + "<span>" + word + "</span>" + extra + " ";
        }
      }
      interacText.push(wordlink);
    }
    return interacText.join("").replace(/#\/span/g, "</span>");
  }

  useEffect(() => {
    const selects = document.querySelectorAll(".HTO .textPassage span");
    selects.forEach(function (select) {
      select.addEventListener("click", function (e) {
        const index = 0;
        let multiClickAnswer = "";
        this.classList.toggle("selectedHighlight");
        let highlitedItems = document.querySelectorAll(".selectedHighlight");
        for (let i = 0; i < highlitedItems.length; i++) {
          multiClickAnswer += highlitedItems[i].textContent + " "; //" " is to add a white space between words
        }

        let allCollected = multiClickAnswer.replace(/\s+$/, ""); //to remove the final white space
        allCollected = allCollected.replace(/['“]+/g, ""); //to remove the first part of quotaion (some in Chinese)
        allCollected = allCollected.replace(/['”]+/g, ""); //to remove the second part of quotaion (some in Chinese)

        let finalAnswer = document.querySelector("#selectedZone span");
        finalAnswer.textContent = "";
        finalAnswer.textContent = allCollected;
        setHTOData(index, actID, allCollected);
      });
    });
  }, []);

  return (
    <div className={[dir, "textPassage noSelect"].join(" ")} unselectable="on">
      <FontSize />
      <div
        dangerouslySetInnerHTML={{
          __html: sanitizer(fragmentedPassage)
        }}
      />
    </div>
  );
}
