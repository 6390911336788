import React, { useEffect } from "react";
import dompurify from "dompurify";
import FontSize from "../../../../../components/fontsize/FontSize";

export default function QuestionPassage(props) {
  const sanitizer = dompurify.sanitize;
  const RTL = props.dir;
  const dir = RTL ? "RTL" : "";

  const curPassage = props?.passage !== null ? props.passage : "";
  let newPassage = curPassage.replace(/<u>/gi, '<span class="textHighlight placeHolder">');
  newPassage = newPassage.replace(/<\/u>/gi, "</span>");
  newPassage = newPassage
    .replace(/\r\n/g, "<br />")
    .replace(/\n\r/g, "<br />")
    .replace(/\r/g, "<br />")
    .replace(/\n/g, "<br />")
    .replace(/<br \/><br \/>$/g, "")
    .replace(/<br \/><br \/>\s*$/g, "")
    .replace(/\s*$/g, "")
    .replace(/<p><\/p>$/g, "")
    .replace(/<p>\s*<\/p>$/g, "");
  newPassage = newPassage
    .replace(/<P>/g, "")
    .replace(/<p>/g, "")
    .replace(/<\/P>/g, "<br /><br />")
    .replace(/<\/p>/g, "<br /><br />")
    .replace(/(<br \/>\s*){3,4}/g, "<br /><br />").replace(/^(<br \/>\s*){2,4}/g,"");
  
  // Control for error caused when no matches are found
  const matches = newPassage.match(/placeHolder/g);
  const num = matches ? matches.length + 1 : 1;
  
  let replacedPassage = newPassage;
  for (let i = 1; i < num; i++) {
    replacedPassage = replacedPassage.replace(/placeHolder/, "xlate" + i);
  }

  useEffect(() => {
    const selects = document.querySelectorAll(".CMT .xlateWord");
    selects.forEach(function (select) {
      select.addEventListener("mouseover", function hover() {
        let check = this.id;
        check = "." + check;
        if (document.querySelector(check) !== null) {
          document.querySelector(check).classList.toggle("activeHighlight");
        }
      });
      select.addEventListener("mouseleave", function leave() {
        let check = this.id;
        check = "." + check;
        if (document.querySelector(check) !== null) {
          document.querySelector(check).classList.toggle("activeHighlight");
        }
      });
    });
  }, []);

  return (
    <div className={[dir, "textPassage noSelect"].join(" ")}>
      <FontSize />
      <div
        dangerouslySetInnerHTML={{
          __html: sanitizer(replacedPassage)
        }}
      />
    </div>
  );
}
