import React from "react";
import PassageAnswerField from "../test/PassageAnswerField";
import Next from "../../../../../components/testlet/Next";

export default function Test(props) {
  const renderAssessment = (options, index) => {
    const item = options._id;
    return (
      <PassageAnswerField
        key={index}
        testitem={item}
        optionContent={options.questionTexts}
        optionNumber={index}
        totalNumber={props.assessment}
        actID={props.actID}
        answerFieldsID={options.answerFields[0]._id}
        dir={props.dir}
        clickCounter={props.clickCounter}
        instruction={props.instruction}
        onLoseFocusCheckNLP={props.onLoseFocusCheckNLP}
        onAnswerSelected={props.onAnswerSelected}
        onAnswerSelectedMod={props.onAnswerSelectedMod}
      />
    );
  };

  return (
    <div className="templateInner GMSA">
      <div className="passageDiv">{props.assessment.map(renderAssessment)}</div>
      <br />
      <Next
        onEvaluation={props.onEvaluation}
        score={props.score}
        hint={props.hint}
        clickCounter={props.clickCounter}
        navLen={props.navLen}
        navCrrtItem={props.navCrrtItem}
      />
    </div>
  );
}
