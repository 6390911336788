import React from "react";

export default function AnswerField(props) {
  const actID = props.actID + "_0";

  return (
    <div className="answerSection noSelect">
      <label htmlFor="userInputQSA">
        Your Answer
        <form autoComplete="off">
          <textarea
            className="QSAAnswer"
            id={actID}
            autoComplete="off"
            onChange={props.onAnswerSelected}
            spellCheck={true}
            onBlur={props.onLoseFocusCheckNLP}
            onPaste={(e) => {
              e.preventDefault();
              return false;
            }}
            onCopy={(e) => {
              e.preventDefault();
              return false;
            }}
            onCut={(e) => {
              e.preventDefault();
              return false;
            }}
          />
        </form>
        {props.invalidEnInput.find((thisEl) => thisEl === actID) && (
          <div className="invalidInputLanguageDiv">Please enter your answer in English.</div>
        )}
      </label>
    </div>
  );
}
