import React from "react";
import PassageAnswerOption from "../test/PassageAnswerOption";
import Next from "../../../../../components/testlet/Next";
import Audio2 from "../../../../../components/testlet/Audio2";

export default function Test(props) {
  const renderAssessment = (options, index) => {
    const number = options.multipleChoiceAns[0].map((option, index) => {
      return index;
    });

    const questions = options.questions.map((option, index) => {
      return option;
    });

    const ddList = options.multipleChoiceAns[0].map((option, index) => {
      return option;
    });

    const answerField = options.answerFields.map((option, index) => {
      return option._id;
    });

    return (
      <PassageAnswerOption
        key={index}
        optionQuestion={questions}
        optionNumber={number}
        ddList={ddList}
        answerFieldsID={answerField}
        testitem={props.assessment[0]._id}
        actID={props.actID}
        value={props.value}
        lang={props.lang}
        dropdownOptions={props.dropdownOptions}
        clickCounter={props.clickCounter}
        onAnswerSelected={props.onAnswerSelected}
        onAnswerSelectedMod={props.onAnswerSelectedMod}
      />
    );
  };

  return (
    <div className="templateInner GLCI" key={props.anchorKey}>
      <Audio2
        key={props.audKey}
        assessment={props.assessment}
        instruction={props.instruction}
        lang={props.lang}
        actID={props.actID}
      />

      <div className="answerSection noSelect">
        {props.assessment.map(renderAssessment)}
      </div>
      <Next
        onEvaluation={props.onEvaluation}
        score={props.score}
        hint={props.hint}
        clickCounter={props.clickCounter}
        navLen={props.navLen}
        navCrrtItem={props.navCrrtItem}
      />
    </div>
  );
}
