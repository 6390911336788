import "../../css/index.css";

/** 
 * @component RequestDataSentModal
 * @description 
 * @param {object} props
**/

export default function RequestDeleteDataSentModal(props) {
  return (    
    <div className="modalDialog" id="modalDialog">
      <div className="reportIssueModal">
        <div className="feedbackHeader" id="modalHeader">
          Account Deletion Request Sent
        </div>  
        <h4>Account Deletion Request Sent!</h4>
        <h5>
        Your request to permanently delete your account data has been sent.
        <br /><br />
        A member of the ODA team will reach out to you to confirm this request.
        <br /><br />
        <b><u>WARNING:</u> This action is final.</b> 
        </h5>                               
        <button className="outlined" onClick={e => props.refModalState(e)}>Close</button>          
        <br /><br /><br />
      </div>      
    </div>      
  );
}