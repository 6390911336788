import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import AuthAdmin from "../../../../contents/AuthAdmin";

const AdminFooter = () => {
  const navigate = useNavigate();
  const adminuser = secureLocalStorage.getItem("adminuser");
  const role = adminuser.content.roles;
  const panels = adminuser.content.adminPanels;
  const content = adminuser.content;
  let itemList = "";
  const data = [
    { text: "main panel", url: "/adminreport/mainpanel" },
    { text: "item-user relation", url: "/adminreport/itemusercorrelation" },
    { text: "item-item relation", url: "/adminreport/itemitemcorrelation" },
    { text: "group/class DPs", url: "/adminreport/groupdps" },

    { text: "keyword", url: "/adminreport/keywordpanel" },
    { text: "tags", url: "/adminreport/tagpanel" },
    { text: "user answer search", url: "/adminreport/useranswersearchpanel" },
    {
      text: "question by primary tag",
      url: "/adminreport/questionbyprimarytag",
    },
    { text: "passage by PL", url: "/adminreport/passagebyproficiencylevel" },

    { text: "data types search", url: "/adminreport/datatypessearch" },
    { text: "TUF", url: "/adminreport/testletusagefrequency" },

    { text: "ODA testlet inventory", url: "/adminreport/odatestletinventory" },
    { text: "ODA usage", url: "/adminreport/odausage" },
    { text: "ODA user survey", url: "/adminreport/usersurvey" },
  ];

  const mainmenu = () => {
    navigate("/adminreport", {
      state: {
        content: content,
      },
    });
  };

  const logout = () => {
    // AuthAdmin.logout(() => {
    console.log("clearing local storage from AdminFooter");
    localStorage.removeItem("token");
    localStorage.removeItem("content");
    localStorage.removeItem("@secure.j.adminuser");
    navigate("/admin");
    // });
  };

  const [isMainMenuItemHovered, setIsMainMenuItemHovered] = useState(false);
  const [isFooterMenuItemHovered, setIsFooterMenuItemHovered] = useState(false);
  const handleMainMenuItemMouseOver = () => {
    setIsMainMenuItemHovered(true);
  };

  const handleMainMenuItemMouseOut = () => {
    setIsMainMenuItemHovered(false);
  };

  const handleFooterMenuItemMouseOver = () => {
    setIsFooterMenuItemHovered(true);
  };

  const handleFooterItemMouseOut = () => {
    setIsFooterMenuItemHovered(false);
  };

  const createList = () => {
    if (role == "sysadmin") {
      itemList = data;
    } else {
      itemList = panels
        .map((panel) => {
          const matchedItem = data[panel._id - 1];
          return matchedItem
            ? { text: matchedItem.text, url: matchedItem.url }
            : null;
        })
        .filter(Boolean);
    }
    const links = itemList.map((item, index) => (
      <Link
        key={index}
        to={item.url.replace(/\s/g, "").toLowerCase()}
        className="adminFooterItem"
        onMouseOver={(e) => {
          e.target.style.fontWeight = "bold";
        }}
        onMouseOut={(e) => {
          e.target.style.fontWeight = "";
        }}
      >
        {item.text}
      </Link>
    ));

    return links;
  };

  const mainMenuStyle = {
    fontWeight: isMainMenuItemHovered ? "bold" : "normal",
    cursor: "pointer",
    color: "#187680",
    fontSize: "12pt",
    textDecoration: "underline",
  };
  const footerMenuStyle = {
    fontWeight: isFooterMenuItemHovered ? "bold" : "normal",
    cursor: "pointer",
  };

  return (
    <div>
      <div className="adminFooter">
        <span
          className="adminFooterItem"
          onClick={mainmenu}
          onMouseOver={handleMainMenuItemMouseOver}
          onMouseOut={handleMainMenuItemMouseOut}
          style={mainMenuStyle}
        >
          MAIN MENU
        </span>
        {createList()}

        <span
          className="adminFooterItem"
          onClick={logout}
          onMouseOver={handleFooterMenuItemMouseOver}
          onMouseOut={handleFooterItemMouseOut}
          style={footerMenuStyle}
        >
          Sign Out
        </span>
      </div>
    </div>
  );
};

export default AdminFooter;
