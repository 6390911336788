/**
 * function to analyze ODA module score and determine pass/fail
 *
 * @param {string} moduleReference
 * @param {array} scoreMatrix
 * @param {integer} passingScore
 * @returns {array} [{string} moduleReference, {boolean} boolPassedNotFailed]
 * @author drew
 */
export function attemptODAScoreRouting(moduleReference, scoreMatrix, passingScore) {
  //declare constants
  const mappedLevels = ["L1", "L1P", "L2", "L2P", "L3"];

  //validate inputs
  if (typeof moduleReference === "string" || typeof moduleReference === String) {
    //regex check here for Ref.XXX.XXX
  } else {
    throw new Error("Malformed module reference string.");
  }

  if (
    (typeof passingScore === "number" || typeof passingScore === Number) &&
    (passingScore === -999 || (passingScore >= 0 && passingScore <= 100))
  ) {
    //validated, continue
  } else {
    throw new Error("Malformed passing score value.");
  }

  if (typeof scoreMatrix === "object" || typeof scoreMatrix === Object) {
    for (let f = 0; f <= 4; f++) {
      for (let g = 0; g <= 1; g++) {
        if (typeof scoreMatrix[mappedLevels[f]][g] === "number" || typeof scoreMatrix[mappedLevels[f]][g] === Number) {
          if (
            scoreMatrix[mappedLevels[f]][g] === -999 ||
            (scoreMatrix[mappedLevels[f]][g] >= 0 && scoreMatrix[mappedLevels[f]][g] <= 100)
          ) {
            // was <= 4 for some reason, changed to 100
            //validated, continue
          } else {
            throw new Error("Malformed score matrix array error 3.");
          }
        } else {
          throw new Error("Malformed score matrix array error 1.");
        }
      }
    }
  } else {
    throw new Error("Malformed score matrix array error 2.");
  }

  //Ref.L2.M1 --> Ref | L2 | M1
  let referenceSplit = moduleReference.split("."); //split the reference
  let calculatedScore = scoreMatrix[referenceSplit[1]][0]; //grab the A score to start off with
  let boolPassedNotFailed = null;

  //if reference is for module2, average score from module1+2 is needed
  if (referenceSplit[2] === "M2") {
    calculatedScore = (calculatedScore + scoreMatrix[referenceSplit[1]][1]) / 2;
  }

  //set the boolean true or false for pass/fail score
  if (calculatedScore >= passingScore) {
    boolPassedNotFailed = true;
  } else {
    boolPassedNotFailed = false;
  }

  //return outputs
  return [moduleReference, boolPassedNotFailed];
}
