import React, { useRef } from "react";
import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";
// import dompurify from "dompurify";
import { IoPlay } from "react-icons/io5";
import { IoPause } from "react-icons/io5";
import { GiSpeaker } from "react-icons/gi";
import { IoIosRewind } from "react-icons/io";
import { IoMdFastforward } from "react-icons/io";

export default function Audio(props) {
  // const sanitizer = dompurify.sanitize;
  const LO = props.LO;
  const lang = props.lang;
  const mainAudio = "audio/" + lang + "/" + LO + "/" + props.mainAudio;
  const audioPlayer = useRef(null);

  return (
    <div>
      <h2>Listen to the passage:</h2>
      <AudioPlayer
        id="ODAPlayer2"
        autoPlay={false}
        autoPlayAfterSrcChange={false}
        header={
          <div className="rhap_textCenter">
            Main Passage {props.toggleState ? "(Modified)" : ""}
            <span className="modifiedFlag" />
          </div>
        }
        footer={null}
        customAdditionalControls={[]}
        ref={audioPlayer.main}
        src={props.toggleState ? null : mainAudio}
                customIcons={{
                  play: <IoPlay />,
                  pause: <IoPause />,
                  rewind: <IoIosRewind />,
                  forward: <IoMdFastforward />,
                  volume: <GiSpeaker />,
                }}
        // onPlay={(e) => console.log("onPlay")}
      />

      <h3>Give the meaning of the following words as used in the passage:</h3>
    </div>
  );
}
