import React, { useRef } from "react";
import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";
import dompurify from "dompurify";
import { IoPlay } from "react-icons/io5";
import { IoPause } from "react-icons/io5";
import { GiSpeaker } from "react-icons/gi";
import { IoIosRewind } from "react-icons/io";
import { IoMdFastforward } from "react-icons/io";

export default function Audio(props) {
  const sanitizer = dompurify.sanitize;
  const lang = props.lang;
  const LO = props.actID;
  let SQID = parseInt(props.audSequence);
  let title = "Passage" + SQID;
  const audFN1 = "audio/" + lang + "/" + LO + "/" + props.audioFN1;
  const audFN2 = "audio/" + lang + "/" + LO + "/" + props.audioFN2;
  const audFN3 = "audio/" + lang + "/" + LO + "/" + props.audioFN3;
  const audioPlayer = useRef(null);

  const instruction = (
    <div className="audPassInst" dangerouslySetInnerHTML={{ __html: sanitizer(props.instruction) }} />
  );

  return (
    <div>
      {instruction}
      <AudioPlayer
        id="ODAPlayer2"
        autoPlay={false}
        autoPlayAfterSrcChange={true}
        header={
          <div className="rhap_textCenter">
            {title}
            <span className="modifiedFlag" />
          </div>
        }
        footer={null}
        customAdditionalControls={[]}
        ref={audioPlayer.main}
        src={SQID === 1 ? audFN1 : SQID === 2 ? audFN2 : SQID === 3 ? audFN3 : audFN1}
                customIcons={{
                  play: <IoPlay />,
                  pause: <IoPause />,
                  rewind: <IoIosRewind />,
                  forward: <IoMdFastforward />,
                  volume: <GiSpeaker />,
                }}
        // onPlay={(e) => console.log("onPlay")}
      />
    </div>
  );
}
