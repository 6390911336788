import React, { useState, useEffect } from "react";
import axios from "axios";
import AdminFooter from "./adminComponents/AdminFooter";
import DataTypesSearchDisplay from "./adminDisplay/DataTypesSearchDisplay";

const DataTypesSearch = () => {
  const initialState = {
    content: [],
    language: [],
    selectedLanguage: "",
    selectedModality: "",
    selectedSite: "",
    selectedLevel: "",
    selectedDataType: "Select",
    isData: false,
    checkAPI: false,
    firstCheck: false,
  };

  const [state, setState] = useState(initialState);
  const {
    language,
    selectedLanguage,
    selectedModality,
    selectedSite,
    selectedLevel,
    selectedDataType,
    isData,
    firstCheck,
  } = state;

  useEffect(() => {
    if (selectedModality === "reading") {
      axios
        .get('/api/language?modalities="reading"&_sort={"sequence": 1}')
        .then((res) => {
          setState({ ...state, language: res.data });
        });
    } else if (selectedModality === "listening") {
      axios
        .get('/api/language?modalities="listening"&_sort={"sequence": 1}')
        .then((res) => {
          setState({ ...state, language: res.data });
        });
    }
  }, [selectedModality]);

  let langDropdown = language.map((item, index) => (
    <option key={index} value={item._id}>
      {item.displayName}
    </option>
  ));

  const handleLanguageChange = (event) => {
    setState({ ...state, selectedLanguage: event.target.value });
  };

  const handleModalityChange = (event) => {
    const initial = {
      selectedLanguage: "",
      selectedSite: "",
      selectedLevel: "",
      selectedDataType: "",
      isData: false,
      checkAPI: false,
      firstCheck: false,
    };
    setState({
      ...state,
      selectedModality: event.target.value,
      ...initial,
    });
  };

  const handleSiteChange = (event) => {
    setState({ ...state, selectedSite: event.target.value });
  };

  const handleLevelChange = (event) => {
    setState({ ...state, selectedLevel: event.target.value });
  };

  const handleTypeChange = (event) => {
    setState({ ...state, selectedDataType: event.target.value });
  };

  useEffect(() => {
    setState({ ...state, selectedDataType: "Select" });
  }, [selectedModality]);

  const getValues = () => {
    const user = {
      site: selectedSite,
      lang: selectedLanguage,
      mod: selectedModality,
      lvl: selectedLevel,
      data: selectedDataType,
    };

    const valuesAreValid =
      selectedLanguage !== "Select" &&
      selectedModality !== "Select" &&
      selectedSite !== "Select" &&
      selectedLevel !== "Select" &&
      selectedDataType !== "Select" &&
      selectedLanguage !== "" &&
      selectedModality !== "" &&
      selectedSite !== "" &&
      selectedLevel !== "" &&
      selectedDataType !== "";

    if (valuesAreValid) {
      console.log("values=", {
        site: selectedSite,
        language: selectedLanguage,
        modality: selectedModality,
        level: selectedLevel,
        data: selectedDataType,
      });

      axios
        .get("/api/adminReport/dataTypesReport", { params: user })
        .then((res) => {
          setState((prevState) => ({
            ...prevState,
            content: res.data,
            isData: true,
            checkAPI: true,
          }));
          console.log("intest=", JSON.stringify(res.data));
        })
        .catch((error) => {
          setState((prevState) => ({
            ...prevState,
            isData: false,
            firstCheck: true,
          }));
          if (error.response) {
            if (error.response.status >= 400 && error.response.status < 600) {
            }
          }
        });
    } else {
      console.log(
        selectedLanguage,
        selectedModality,
        selectedSite,
        selectedLevel,
        selectedDataType
      );
      alert("Select all items");
    }
  };

  const resetValues = () => {
    setState(initialState);
  };

  return (
    <div className="adminMainPanel">
      <div className="infoTitle adminInfoTitle">
        <div>Data Types Searches</div>
      </div>
      <div>
        Modality:
        <select
          key="modality"
          value={selectedModality}
          onChange={handleModalityChange}
        >
          <option>Select</option>
          <option value="reading">Reading</option>
          <option value="listening">Listening</option>
        </select>
      </div>
      <div>
        Language:
        <select
          key="language"
          value={selectedLanguage}
          onChange={handleLanguageChange}
        >
          <option>Select</option>
          {langDropdown}
        </select>
      </div>
      <div>
        Site:
        <select key="site" value={selectedSite} onChange={handleSiteChange}>
          <option>Select</option>
          <option value="oda">ODA</option>
        </select>
      </div>
      <div>
        Level:
        <select key="level" value={selectedLevel} onChange={handleLevelChange}>
          <option>Select</option>
          <option value="1">1</option>
          <option value="1+">1+</option>
          <option value="2">2</option>
          <option value="2+">2+</option>
          <option value="3">3</option>
        </select>
      </div>
      <div>
        Data Type:
        <select
          key="listening"
          value={selectedDataType}
          onChange={handleTypeChange}
        >
          {selectedModality === "reading" ? (
            <>
              <option>Select</option>
              <option value="characterCount">Character Count</option>
              <option value="textType">Text Type</option>
              <option value="wordCount">Word Count</option>
              <option value="floTopic">FLO Topc</option>
              <option value="subtopic">Subtopic</option>
              <option value="textFunction">Text Function</option>
              <option value="copyrightSource">Copyright Source</option>
              <option value="copyrightDate">Copyright Date</option>
              <option value="developer">Developer</option>
              <option value="levelRange">Level Range</option>
            </>
          ) : selectedModality === "listening" ? (
            <>
              <option>Select</option>
              <option value="audioLength">Audio Length</option>
              <option value="wpm">WPM</option>
              <option value="authentic">Authentic/Simulated</option>
              <option value="sourceType">Source Type</option>
              <option value="speechRate">Speech Rate</option>
              <option value="speechMedium">Speech Medium</option>
              <option value="noiseLevel">Noise Level</option>
              <option value="noiseNature">Nature of Noise</option>
              <option value="accentRegion">Region of Accent</option>
              <option value="accentExtent">Extent of Accent</option>
              <option value="wordCount">Word Count</option>
              <option value="subtopic">Subtopic</option>
              <option value="floTopic">FLO Topc</option>
              <option value="textFunction">Text Function</option>
              <option value="copyrightSource">Copyright Source</option>
              <option value="copyrightDate">Copyright Date</option>
              <option value="developer">Developer</option>
              <option value="levelRange">Level Range</option>
            </>
          ) : (
            <option>Select</option>
          )}
        </select>
      </div>
      <div className="buttonGroup">
        <div onClick={getValues} className="searchButton">
          Get Data Types
        </div>
        <div onClick={resetValues} className="searchButton">
          Reset Options
        </div>
      </div>
      <div className="adminContentBox" style={{ overflow: "auto" }}>
        <DataTypesSearchDisplay
          content={state.content}
          isData={state.isData}
          checkAPI={state.checkAPI}
          firstCheck={state.firstCheck}
        />
      </div>

      <AdminFooter />
    </div>
  );
};

export default DataTypesSearch;
